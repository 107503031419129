import React, { useCallback, useEffect, useRef, useState } from "react"
import "./AgentSettings.scss"
import { WithTitle } from "../../utility/common/withTitle"
import { Agent, AgentType } from "../../models/agent"
import PageLayout from "../../components/PageLayout/PageLayout"
import AddButton from "../../components/AddButton/AddButton"
import cn from "classnames"
import { getSlots } from "../../store/slots/thunks"
import { deleteAgent, getAgents } from "../../store/agents/thunks"
import { useDispatch, useSelector } from "react-redux"
import { selectCurrentProject } from "../../store/projects/selectors"
import { useTranslation } from "react-i18next"
import AgentList from "../../components/AgentList/AgentList"
import AgentForm from "../../components/AgentForm/AgentForm"
import AgentTypeForm from "../../components/AgentTypeForm/AgentTypeForm"
import { getQueues } from "../../store/queues/thunks"
import InfoIcon from "../../components/InfoIcon/InfoIcon"
import usePermissionsCheck from "../../utility/common/usePermissionsCheck"
import { ModifyAgents } from "../../permissions"
import { useModal } from "../../utility/common/useModal"
import AlertDialog from "../../components/AlertDialog/AlertDialog"
import { formTranslation } from "../../locales/form"
import SettingsContextMenuItem from "../../components/SettingsContextMenuItem/SettingsContextMenuItem"
import { faTrash } from "@fortawesome/pro-light-svg-icons/faTrash"
import Can from "../../components/Can/Can"
import { testId } from "../../utility/tests/testId"
import { getClientTypes } from "../../store/users/thunks"
import { useParams } from "react-router-dom"
import { FormPathParams, FormType, getAddPath, getEditPath } from "../../utility/common/urlFormHelper"
import { selectAgents } from "../../store/agents/selectors"
import { isAgentTypeExists } from "../../utility/agents/agentValidation"
import { FormChangeCallback } from "../ProjectSettings/ProjectSettings"

const tNamespace = "agent:"

enum SidebarContentType {
    chooseAgentTypeForm,
    addAgentForm,
    updateAgentForm
}

interface SidebarContent extends WithTitle {
    type: SidebarContentType
    agentType: AgentType
    agent?: Agent
    settings?: JSX.Element[]
    stepBack?: boolean
    onBack?: () => void
}

interface Props {
    onFormChange: FormChangeCallback
}

const AgentSettings: React.FC<Props> = props => {
    const { t } = useTranslation()
    const { onFormChange } = props
    const dispatch = useDispatch()
    const editAllowed = usePermissionsCheck([ModifyAgents])

    const {
        current: { formId, param }
    } = useRef(useParams<FormPathParams>())

    const agents = useSelector(selectAgents)
    const project = useSelector(selectCurrentProject)

    const [selectedAgentId, setSelectedAgentId] = useState<string | null>(null)
    const [sidebarClosed, setSidebarClosed] = useState(true)
    const [sidebarContent, setSidebarContent] = useState<SidebarContent | null>(null)

    const [agentToDelete, setAgentToDelete] = useState<string | null>(null)
    const { modalOpen, openModal, closeModal, onExited } = useModal(() => (
        <AlertDialog
            show={modalOpen}
            title={t(`${tNamespace}delete-confirmation.title`)}
            message={t(`${tNamespace}delete-confirmation.message`)}
            submitBtnText={t(formTranslation.delete)}
            onClose={closeModal}
            onSubmit={handleDeleteAgent}
            variant="danger"
            onExited={onExited}
        />
    ))

    useEffect(() => {
        if (project) {
            dispatch(getAgents(project.id))
            dispatch(getQueues(project.id))
            dispatch(getClientTypes(project.id))
            dispatch(getSlots(project.id))
        }
    }, [dispatch, project])

    const goToRoot = useCallback(() => {
        onFormChange()
    }, [onFormChange])

    const closeSidebar = useCallback(() => {
        setSidebarClosed(true)
        setSelectedAgentId(null)
    }, [])

    const closeSidebarWithRouting = useCallback(() => {
        closeSidebar()
        goToRoot()
    }, [closeSidebar, goToRoot])

    const openSidebar = useCallback(() => setSidebarClosed(false), [])

    const handleChooseAgentType = useCallback(
        (updateLocationState = false) => {
            closeSidebar()
            if (updateLocationState) {
                onFormChange(getAddPath())
            }
            setSidebarContent({
                type: SidebarContentType.chooseAgentTypeForm,
                agentType: AgentType.Unknown,
                title: t(`${tNamespace}add-agent`)
            })
            openSidebar()
        },
        [closeSidebar, openSidebar, t, onFormChange]
    )

    const handleAddAgent = useCallback(
        (type: AgentType, updateLocationState = false) => {
            if (updateLocationState) {
                onFormChange(getAddPath(type))
            }
            setSidebarContent({
                type: SidebarContentType.addAgentForm,
                agentType: type,
                title: t(`${tNamespace}add-agent`),
                stepBack: true,
                onBack: handleChooseAgentType
            })
        },
        [t, handleChooseAgentType, onFormChange]
    )

    const handleUpdateAgent = useCallback(
        (agent: Agent, updateLocationState = false) => {
            closeSidebar()
            if (updateLocationState) {
                onFormChange(getEditPath(agent.Id))
            }
            setSelectedAgentId(agent.Id)
            const settings = [
                <SettingsContextMenuItem
                    key={agent.Id}
                    id={agent.Id}
                    icon={faTrash}
                    text={t(formTranslation.delete)}
                    danger
                    onClick={() => {
                        setAgentToDelete(agent.Id)
                        openModal()
                    }}
                    testId={testId.deleteAgent}
                />
            ]
            setSidebarContent({
                type: SidebarContentType.updateAgentForm,
                agentType: agent.Type,
                title: t(`${tNamespace}agent-settings`),
                agent,
                settings
            })
            openSidebar()
        },
        [closeSidebar, openSidebar, t, openModal, onFormChange]
    )

    const handleDeleteAgent = useCallback(() => {
        if (project && agentToDelete) {
            dispatch(deleteAgent(project.id, agentToDelete, closeSidebar))
        }
    }, [project, dispatch, closeSidebar, agentToDelete])

    const renderSidebarContent = useCallback(() => {
        if (!sidebarContent) return null

        switch (sidebarContent.type) {
            case SidebarContentType.chooseAgentTypeForm:
                return <AgentTypeForm onSelect={(type: AgentType) => handleAddAgent(type, true)} />
            case SidebarContentType.addAgentForm:
                return (
                    <AgentForm
                        agent={sidebarContent.agent}
                        agentType={sidebarContent.agentType}
                        submitCallback={closeSidebarWithRouting}
                    />
                )
            case SidebarContentType.updateAgentForm:
                return (
                    <AgentForm
                        agent={sidebarContent.agent}
                        agentType={sidebarContent.agentType}
                        submitCallback={closeSidebarWithRouting}
                        disabled={!editAllowed}
                    />
                )
        }
    }, [closeSidebarWithRouting, handleAddAgent, sidebarContent, editAllowed])

    useEffect(() => {
        if (!agents || !formId) return

        switch (formId) {
            case FormType.Add:
                param
                    ? param && isAgentTypeExists(param)
                        ? handleAddAgent(param)
                        : goToRoot()
                    : handleChooseAgentType()
                break
            case FormType.Edit:
                if (!param) goToRoot()

                const agent = agents.find(a => a.Id === param)
                agent ? handleUpdateAgent(agent) : goToRoot()
                break
        }
    }, [handleAddAgent, handleUpdateAgent, handleChooseAgentType, agents, goToRoot, formId, param])

    return (
        <PageLayout isSidebarCollapsed={sidebarClosed}>
            <PageLayout.Content className="agents">
                <div className="agents__header">
                    <span className="agents__title">{t(`${tNamespace}agents`)}</span>
                    <InfoIcon placement="bottom" id="popoverAgents" content={t(`${tNamespace}page-desc`)} />
                    <Can permission={ModifyAgents}>
                        <AddButton
                            variant="outline-primary"
                            onClick={() => handleChooseAgentType(true)}
                            className={cn("agents__btn-add", !sidebarClosed ? "hidden" : undefined)}
                            text={t(`${tNamespace}add-agent`)}
                            testId={testId.addAgent}
                        />
                    </Can>
                </div>
                <AgentList onClick={(agent: Agent) => handleUpdateAgent(agent, true)} selectedAgent={selectedAgentId} />
            </PageLayout.Content>
            <PageLayout.Sidebar
                title={sidebarContent ? sidebarContent.title : ""}
                onClose={closeSidebar}
                onCloseClick={closeSidebarWithRouting}
                settings={editAllowed ? sidebarContent?.settings : undefined}
                stepBack={sidebarContent?.stepBack}
                onBack={sidebarContent?.onBack}
            >
                {renderSidebarContent()}
            </PageLayout.Sidebar>
        </PageLayout>
    )
}

export default AgentSettings
