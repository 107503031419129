import { PaginationRequest, PaginationResponse } from "../../models/pagination"
import { apiInstance } from "../instances"

//TODO: переписать на что-то более универсальное, когда будет возможность
const paginationController = {
    getItems: <ResponseType>(
        projectId: string,
        type: string,
        request?: PaginationRequest
    ): Promise<PaginationResponse<ResponseType>> => {
        return apiInstance.post(`${projectId}/${type}/page`, request).then(response => response.data)
    }
}

export default paginationController
