import React, { useCallback } from "react"
import { ChannelFormProps } from "../ChannelForm/ChannelForm"
import { FormikProps } from "formik"
import { AppearanceValues, InterfaceValues, WebChatChannelValues } from "../../models/webChatValues"
import { WithT } from "i18next"
import { shallowEqual, useSelector } from "react-redux"
import { selectCreateChannelState, selectUpdateChannelState } from "../../store/channels/selectors"
import { Col, Row } from "react-bootstrap"
import { nameof, nameof2 } from "../../utility/common/nameof"
import { formTranslation } from "../../locales/form"
import ColorCircleInput from "../ColorCircleInput/ColorCircleInput"
import { defaultWebChatColors } from "../../utility/channels/defaultWebChatColors"
import ColorInput from "../ColorInput/ColorInput"
import { Locale, locales } from "../../locales"
import DebouncedValidatableInput from "../ValidatableInput/DebouncedValidatableInput"
import DebouncedCheckBox from "../CheckBoxValidatableInput/DebouncedCheckBox"
import ChannelFormWrapper from "../ChannelFormWrapper/ChannelFormWrapper"

const tNamespace = "channel:form."

const FormikWebChatChannelFormAppearance: React.FC<ChannelFormProps & FormikProps<WebChatChannelValues> & WithT> =
    props => {
        const { t, handleSubmit, channel, setFieldValue, disabled } = props

        const handleMobileVersionChange = useCallback(
            (v: boolean) => {
                setFieldValue(
                    nameof2<WebChatChannelValues, InterfaceValues>("InterfaceSettings", "disableClosing"),
                    v,
                    false
                )
                setFieldValue(
                    nameof2<WebChatChannelValues, InterfaceValues>("InterfaceSettings", "disableHeader"),
                    v,
                    false
                )
                setFieldValue(
                    nameof2<WebChatChannelValues, InterfaceValues>("InterfaceSettings", "alwaysOpen"),
                    v,
                    false
                )
            },
            [setFieldValue]
        )

        const asyncState = useSelector(channel ? selectUpdateChannelState : selectCreateChannelState, shallowEqual)

        return (
            <ChannelFormWrapper
                className="webchat-form"
                onSubmit={handleSubmit}
                disabled={disabled}
                loading={asyncState.inProcess}
            >
                <div className="channel-form__section channel-form__section_border">
                    <DebouncedValidatableInput
                        id="formHeaderText"
                        type="text"
                        name={nameof2<WebChatChannelValues, AppearanceValues>("AppearanceSettings", "headerText")}
                        label={t(`${tNamespace}chat-title`)}
                        placeholder={t(formTranslation.support)}
                    />
                    <DebouncedValidatableInput
                        id="formHeaderDescription"
                        type="text"
                        name={nameof2<WebChatChannelValues, AppearanceValues>(
                            "AppearanceSettings",
                            "headerDescription"
                        )}
                        label={t(`${tNamespace}chat-subtitle`)}
                        placeholder={t(formTranslation.enterText)}
                    />
                </div>
                <div className="channel-form__section channel-form__section_border">
                    <DebouncedValidatableInput
                        id="formLanguage"
                        as="select"
                        name={nameof2<WebChatChannelValues, AppearanceValues>("AppearanceSettings", "language")}
                        label={t(`${tNamespace}interface-language`)}
                    >
                        {locales.map((locale: Locale) => (
                            <option value={locale.id} key={locale.id}>
                                {locale.name}
                            </option>
                        ))}
                    </DebouncedValidatableInput>
                    <span className="channel-form__section-subtitle">{t(`${tNamespace}interface-color`)}</span>
                    <Row>
                        <Col>
                            <ColorCircleInput
                                id="formBaseColorCircle"
                                name={nameof2<WebChatChannelValues, AppearanceValues>(
                                    "AppearanceSettings",
                                    "baseColor"
                                )}
                                colors={defaultWebChatColors}
                                className="channel-form__color-circle"
                            />
                        </Col>
                        <Col>
                            <ColorInput
                                id="formBaseColor"
                                name={nameof2<WebChatChannelValues, AppearanceValues>(
                                    "AppearanceSettings",
                                    "baseColor"
                                )}
                                className="channel-form__color-input channel-form__color-input_border"
                                simplified={true}
                            />
                        </Col>
                    </Row>
                    <DebouncedValidatableInput
                        id="formTextColor"
                        as="select"
                        name={nameof2<WebChatChannelValues, AppearanceValues>("AppearanceSettings", "textColor")}
                        label={t(`${tNamespace}text-color`)}
                    >
                        <option value="null">{t(`${tNamespace}auto-color`)}</option>
                        <option value="light">{t(`${tNamespace}light-color`)}</option>
                        <option value="dark">{t(`${tNamespace}dark-color`)}</option>
                    </DebouncedValidatableInput>
                </div>
                <div className="channel-form__section">
                    <span className="channel-form__section-subtitle">{t(`${tNamespace}mobile-version`)}</span>
                    <DebouncedCheckBox
                        inline
                        id="formMobileVersion"
                        name={nameof<WebChatChannelValues>("MobileVersionEnabled")}
                        label={t(`${tNamespace}mobile-version-app`)}
                        onChange={handleMobileVersionChange}
                    />
                </div>
            </ChannelFormWrapper>
        )
    }

export default FormikWebChatChannelFormAppearance
