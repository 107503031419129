import * as constants from "./constants"
import tasksController from "../../api/controllers/tasks"
import { MoveTaskRequest, RerouteTaskRequest, SwitchTaskRequest } from "../../models/task"
import { Dispatch } from "../../utility/common/storeHelper"
import { handleHttpException } from "../handleHttpException"
import { actions } from "./slice"

export function moveTask(tenantId: string, request: MoveTaskRequest, callback: () => void) {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(actions.moveTaskProcess())
            const moved = await tasksController.move(tenantId, request)
            dispatch(actions.moveTaskSuccess(moved))
            callback()
        } catch (e) {
            handleHttpException(e, constants.MOVE_TASK_FAILED_MESSAGE, err => actions.moveTaskFailed(err), dispatch)
        }
    }
}

export function rerouteTask(request: RerouteTaskRequest, callback: () => void) {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(actions.rerouteTaskProcess())
            await tasksController.reroute(request)
            dispatch(actions.rerouteTaskSuccess())
            callback()
        } catch (e) {
            handleHttpException(
                e,
                constants.REROUTE_TASK_FAILED_MESSAGE,
                err => actions.rerouteTaskFailed(err),
                dispatch
            )
        }
    }
}

export const getAutomaticProcessing = (projectId: string) => async (dispatch: Dispatch) => {
    dispatch(actions.getAutomaticProcessingProcess())
    try {
        const result = await tasksController.getAutomaticProcessing(projectId)
        dispatch(actions.getAutomaticProcessingSuccess(result))
    } catch (e) {
        handleHttpException(
            e,
            constants.GET_AUTOMATIC_PROCESSING_FAILED,
            actions.getAutomaticProcessingFailed,
            dispatch
        )
    }
}

export function switchTask(request: SwitchTaskRequest, callback: () => void) {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(actions.switchTaskProcess())
            await tasksController.switch(request)
            dispatch(actions.switchTaskSuccess())
            callback()
        } catch (e) {
            handleHttpException(e, constants.SWITCH_TASK_FAILED_MESSAGE, err => actions.switchTaskFailed(err), dispatch)
        }
    }
}
