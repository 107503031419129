import { useTranslation } from "react-i18next"
import styles from "./AIAssistent.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faClone, faFileAlt, faLightbulbExclamation, faPlus } from "@fortawesome/pro-light-svg-icons"
import { Button } from "react-bootstrap"
import SettingsButton from "../../SettingsButton/SettingsButton"
import SettingsContextMenu from "../../SettingsContextMenu/SettingsContextMenu"
import SettingsContextMenuItem from "../../SettingsContextMenuItem/SettingsContextMenuItem"
import { useCallback } from "react"
import { useAppDispatch, useAppSelector } from "../../../store/store"
import { actions } from "../../../store/dialogs/slice"
import {
    selectAIAssistHintMessage,
    selectAIAssistRequestTypes,
    selectDialogId,
    selectOperatorClientId
} from "../../../store/dialogs/selectors"
import Spinner from "../../Spinner/Spinner"
import { getAIAssistHintByClientId, getAIAssistHintByDialogId } from "../../../store/dialogs/thunks"
import replace from "lodash/replace"
import { selectCurrentProjectId } from "../../../store/projects/selectors"
import { selectCurrentBranch } from "../../../store/knowledgeBase/selectors"
import { selectCurrentUserLogin } from "../../../store/users/selectors"
import { getArticle, getRelatedCategories } from "../../../store/knowledgeBase/thunks"
import Async from "../../Async/Async"
import ErrorMessage from "../../ErrorMessage/ErrorMessage"
import { dialogDraftsLocalStoreDriver } from "../../../api/controllers/dialogs"

const tAiAssistentNamespace = "dialogs:aiassistent"

const AIAssistentGptHint: React.FC = () => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()

    const aiAssistHintMessage = useAppSelector(selectAIAssistHintMessage)
    const aIAssistRequestTypes = useAppSelector(selectAIAssistRequestTypes)
    const selectedDialogId = useAppSelector(selectDialogId)
    const selectedOperatorClientId = useAppSelector(selectOperatorClientId)
    const projectId = useAppSelector(selectCurrentProjectId)
    const { CurrentCatalogCode, CurrentCategoryId } = useAppSelector(selectCurrentBranch)
    const login = useAppSelector(selectCurrentUserLogin)

    const handleOpenArticle = useCallback(
        (articleLink: string) => {
            if (!projectId || !login) {
                return
            }

            const symbCodeId = articleLink.split("/").slice(-1)[0]

            dispatch(actions.openArticleSidebar())
            dispatch(getRelatedCategories(projectId, CurrentCategoryId, symbCodeId, CurrentCatalogCode))
            dispatch(getArticle(projectId, login, symbCodeId, false))
        },
        [dispatch, login, projectId, CurrentCategoryId, CurrentCatalogCode]
    )

    const handleMessageUse = useCallback(
        (opType: "replace" | "add") => {
            const draftEntityId = selectedDialogId ?? selectedOperatorClientId

            if (!aiAssistHintMessage.data || !draftEntityId) {
                return
            }

            const draftMessage = dialogDraftsLocalStoreDriver.get(draftEntityId)
            const gptMessage = replace(aiAssistHintMessage.data.message, /\n/g, " ")
            const conMessage = draftMessage && opType === "add" ? `${draftMessage}\n ${gptMessage}` : gptMessage

            // При набранном тексте и повторной вставке не изменялось состояние inputInitial, потому что вводимый текст
            // и вставленный текст явлиются разными сущностями, по итоге состояние inputInitial оставалось тем же,
            // теперь же оно каждый раз будет новым за счет задания стейту его начального состояния.
            dispatch(actions.clearMessageInputInitial())

            setTimeout(() => dispatch(actions.setMessageInputInitial(conMessage)), 0)
            dispatch(actions.setMessageInput(conMessage))
        },
        [dispatch, aiAssistHintMessage.data, selectedDialogId, selectedOperatorClientId]
    )

    const handleRequestAIAssistByReqType = useCallback(
        (reqType: string) => {
            if (selectedDialogId) {
                dispatch(getAIAssistHintByDialogId(selectedDialogId, reqType))
            } else if (selectedOperatorClientId) {
                dispatch(getAIAssistHintByClientId(selectedOperatorClientId, reqType))
            }
        },
        [dispatch, selectedDialogId, selectedOperatorClientId]
    )

    const hintSettings = aIAssistRequestTypes.data
        ? Object.entries(aIAssistRequestTypes.data).map(([k, v]) => (
              <SettingsContextMenuItem onClick={() => handleRequestAIAssistByReqType(k)} key={k} id={k} text={v} />
          ))
        : []

    return (
        <div className={styles.gptHint}>
            <header>
                <span>{t(`${tAiAssistentNamespace}.gpthint`)}</span>
                <SettingsContextMenu items={hintSettings} placement="bottom-end">
                    <SettingsButton iconSize="sm" className={styles.gptHint__settingIcon} />
                </SettingsContextMenu>
            </header>
            <div className={styles.gptHint__wrapper}>
                <Async
                    dataState={aiAssistHintMessage}
                    processView={<Spinner size={30} className={styles.loader} />}
                    errorView={({ message }) => <ErrorMessage text={message} />}
                    nonDataView={
                        <div className={styles.gptHint__scrollable}>
                            <span className={styles.errorMessage}>
                                <FontAwesomeIcon icon={faLightbulbExclamation} size="2x" />
                                {t(`${tAiAssistentNamespace}.hint-no-content`)}
                            </span>
                        </div>
                    }
                    validator={data => !!data?.message}
                >
                    {({ data }) => (
                        <div className={styles.gptHint__scrollable}>
                            <p>{data.message}</p>
                            <div className={styles.gptHint__links}>
                                {data.supportArticles?.map(({ ref, title }) => (
                                    <Button variant="white" key={ref} onClick={() => handleOpenArticle(ref)}>
                                        <FontAwesomeIcon icon={faFileAlt} /> {title}
                                    </Button>
                                ))}
                            </div>
                        </div>
                    )}
                </Async>
            </div>
            <div className={styles.gptHint__btnsManage}>
                <Button variant="primary" onClick={() => handleMessageUse("add")}>
                    <FontAwesomeIcon icon={faPlus} /> {t(`${tAiAssistentNamespace}.add-message`)}
                </Button>
                <Button variant="primary" onClick={() => handleMessageUse("replace")}>
                    <FontAwesomeIcon icon={faClone} /> {t(`${tAiAssistentNamespace}.replace-message`)}
                </Button>
            </div>
        </div>
    )
}

export { AIAssistentGptHint }
