import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import AsyncState from "../../core/asyncState"
import { SystemError } from "../../core/error"
import {
    ClassifierConfigDto,
    ClassifierConfigurationProcessed,
    ClassifierStatus,
    CurrentClassifier,
    isClassifierConfigurationProcessed,
    UpdateCurrentClassifierPayload
} from "../../models/classifier"
import {
    ClassifierEventDto,
    ClassifierModels,
    GetClassifierModelsSuccessPayload,
    UpdateClassifierStatusPayload
} from "../../models/classifierEvent"
import { processClassifier } from "../../utility/classifier/classifierRequest"
import { getLinkedClassifier } from "../../utility/classifier/classifierForm"
import {
    eventToModel,
    isCurrentClassifier,
    isDateTimeEmpty,
    isReplaceRequired
} from "../../utility/classifier/classifierEvent"

const defaultClassifier: CurrentClassifier = {
    ClassifierId: "",
    ClassifierConfigId: "",
    Status: ClassifierStatus.Stopped,
    Active: false,
    Version: ""
}

export type ClassifiersState = Readonly<{
    classifiers: AsyncState<ClassifierConfigurationProcessed[]>
    classifierServers: AsyncState<string[]>
    models: ClassifierModels
    getModels: AsyncState<void>
    trainingClassifier: ClassifierEventDto | null
    createClassifier: AsyncState<void>
    updateClassifier: AsyncState<void>
    deleteClassifier: AsyncState<void>
    activateClassifier: AsyncState<void>
    deactivateClassifier: AsyncState<void>
    startTraining: AsyncState<void>
    stopTraining: AsyncState<void>
    rollbackClassifier: AsyncState<void>
    currentClassifier: CurrentClassifier
}>

const initialState: ClassifiersState = {
    classifiers: AsyncState.create(),
    classifierServers: AsyncState.create(),
    models: {},
    getModels: AsyncState.create(),
    trainingClassifier: null,
    createClassifier: AsyncState.create(),
    updateClassifier: AsyncState.create(),
    deleteClassifier: AsyncState.create(),
    activateClassifier: AsyncState.create(),
    deactivateClassifier: AsyncState.create(),
    startTraining: AsyncState.create(),
    stopTraining: AsyncState.create(),
    rollbackClassifier: AsyncState.create(),
    currentClassifier: defaultClassifier
}

const classifiers = createSlice({
    name: "classifiers",
    initialState,
    reducers: {
        getClassifiersProcess(state) {
            state.classifiers = state.classifiers.toProcess()
        },
        getClassifiersSuccess(state, action: PayloadAction<ClassifierConfigDto[]>) {
            const processedClassifiers = action.payload.map(c =>
                processClassifier(c, getLinkedClassifier(action.payload, c))
            )
            state.classifiers = state.classifiers.toSuccess(processedClassifiers)
            state.models = {}
        },
        getClassifiersFailed(state, action: PayloadAction<SystemError>) {
            state.classifiers = state.classifiers.toFailed(action.payload)
        },
        getClassifierServersProcess(state) {
            state.classifierServers = state.classifierServers.toProcess()
        },
        getClassifierServersSuccess(state, action: PayloadAction<string[]>) {
            state.classifierServers = state.classifierServers.toSuccess(action.payload)
        },
        getClassifierServersFailed(state, action: PayloadAction<SystemError>) {
            state.classifierServers = state.classifierServers.toFailed(action.payload)
        },
        getModelsProcess(state) {
            state.getModels = state.getModels.toProcess()
        },
        getModelsSuccess(state, action: PayloadAction<GetClassifierModelsSuccessPayload>) {
            state.getModels = state.getModels.toSuccess()
            state.models = {
                ...state.models,
                [action.payload.configId]: action.payload.models
            }
        },
        getModelsFailed(state, action: PayloadAction<SystemError>) {
            state.getModels = state.getModels.toFailed(action.payload)
        },
        createClassifierProcess(state) {
            state.createClassifier = state.createClassifier.toProcess()
        },
        createClassifierSuccess(state, action: PayloadAction<ClassifierConfigDto[]>) {
            state.createClassifier = state.createClassifier.toSuccess()
            state.classifiers = state.classifiers.map(v => [
                ...v,
                ...action.payload.map(c => processClassifier(c, getLinkedClassifier(action.payload, c)))
            ])
        },
        createClassifierFailed(state, action: PayloadAction<SystemError>) {
            state.createClassifier = state.createClassifier.toFailed(action.payload)
        },
        updateClassifierProcess(state) {
            state.updateClassifier = state.updateClassifier.toProcess()
        },
        updateClassifierSuccess(state, action: PayloadAction<ClassifierConfigDto>) {
            state.updateClassifier = state.updateClassifier.toSuccess()
            state.classifiers = state.classifiers.map(v =>
                v.map(c =>
                    c.ClassifierConfigId === action.payload.ClassifierConfigId
                        ? processClassifier(action.payload, c.LinkedClassifierConfigId)
                        : c
                )
            )
        },
        updateClassifierFailed(state, action: PayloadAction<SystemError>) {
            state.updateClassifier = state.updateClassifier.toFailed(action.payload)
        },
        deleteClassifierProcess(state) {
            state.deleteClassifier = state.deleteClassifier.toProcess()
        },
        deleteClassifierSuccess(state, action: PayloadAction<string>) {
            state.deleteClassifier = state.deleteClassifier.toSuccess()
            state.classifiers = state.classifiers.map(v => v.filter(c => c.ClassifierConfigId !== action.payload))
        },
        deleteClassifierFailed(state, action: PayloadAction<SystemError>) {
            state.deleteClassifier = state.deleteClassifier.toFailed(action.payload)
        },
        activateClassifierProcess(state) {
            state.activateClassifier = state.activateClassifier.toProcess()
        },
        activateClassifierSuccess(state, action: PayloadAction<ClassifierConfigDto>) {
            state.activateClassifier = state.activateClassifier.toSuccess()
            state.classifiers = state.classifiers.map(v =>
                v.map(c =>
                    c.ClassifierConfigId === action.payload.ClassifierConfigId
                        ? processClassifier(action.payload, c.LinkedClassifierConfigId)
                        : c
                )
            )
        },
        activateClassifierFailed(state, action: PayloadAction<SystemError>) {
            state.activateClassifier = state.activateClassifier.toFailed(action.payload)
        },
        deactivateClassifierProcess(state) {
            state.deactivateClassifier = state.deactivateClassifier.toProcess()
        },
        deactivateClassifierSuccess(state, action: PayloadAction<ClassifierConfigDto>) {
            state.deactivateClassifier = state.deactivateClassifier.toSuccess()
            state.classifiers = state.classifiers.map(v =>
                v.map(c =>
                    c.ClassifierConfigId === action.payload.ClassifierConfigId
                        ? processClassifier(action.payload, c.LinkedClassifierConfigId)
                        : c
                )
            )
        },
        deactivateClassifierFailed(state, action: PayloadAction<SystemError>) {
            state.deactivateClassifier = state.activateClassifier.toFailed(action.payload)
        },
        startTrainingProcess(state) {
            state.startTraining = state.startTraining.toProcess()
        },
        startTrainingSuccess(state, action: PayloadAction<ClassifierConfigDto>) {
            state.startTraining = state.startTraining.toSuccess()
            state.classifiers = state.classifiers.map(v =>
                v.map(c =>
                    c.ClassifierConfigId === action.payload.ClassifierConfigId
                        ? processClassifier(action.payload, c.LinkedClassifierConfigId)
                        : c
                )
            )
        },
        startTrainingFailed(state, action: PayloadAction<SystemError>) {
            state.startTraining = state.startTraining.toFailed(action.payload)
        },
        stopTrainingProcess(state) {
            state.stopTraining = state.stopTraining.toProcess()
        },
        stopTrainingSuccess(state, action: PayloadAction<ClassifierConfigDto>) {
            state.stopTraining = state.stopTraining.toSuccess()
            state.classifiers = state.classifiers.map(v =>
                v.map(c =>
                    c.ClassifierConfigId === action.payload.ClassifierConfigId
                        ? processClassifier(action.payload, c.LinkedClassifierConfigId)
                        : c
                )
            )
        },
        stopTrainingFailed(state, action: PayloadAction<SystemError>) {
            state.stopTraining = state.stopTraining.toFailed(action.payload)
        },
        rollbackClassifierProcess(state) {
            state.rollbackClassifier = state.rollbackClassifier.toProcess()
        },
        rollbackClassifierSuccess(state, action: PayloadAction<ClassifierConfigDto>) {
            state.rollbackClassifier = state.rollbackClassifier.toSuccess()
            state.classifiers = state.classifiers.map(v =>
                v.map(c =>
                    c.ClassifierConfigId === action.payload.ClassifierConfigId
                        ? processClassifier(action.payload, c.LinkedClassifierConfigId)
                        : c
                )
            )
        },
        rollbackClassifierFailed(state, action: PayloadAction<SystemError>) {
            state.rollbackClassifier = state.rollbackClassifier.toFailed(action.payload)
        },
        updateTrainingClassifier(state, action: PayloadAction<ClassifierEventDto | null>) {
            if (!action.payload) {
                state.trainingClassifier = action.payload
                return
            }
            isCurrentClassifier(state.currentClassifier, action.payload) && (state.trainingClassifier = action.payload)
        },
        addNewModel(state, action: PayloadAction<ClassifierEventDto>) {
            const classifierConfigId = action.payload.ClassifierConfigId
            state.models = {
                ...state.models,
                [classifierConfigId]: [eventToModel(action.payload), ...(state.models[classifierConfigId] ?? [])]
            }
        },
        updateModelMetrics(state, action: PayloadAction<ClassifierEventDto>) {
            const classifierConfigId = action.payload.ClassifierConfigId
            if (!state.models[classifierConfigId]) return

            state.models = {
                ...state.models,
                [classifierConfigId]: state.models[classifierConfigId].map(m =>
                    m.ClassifierId === action.payload.ClassifierId ? { ...m, Metrics: action.payload.Metrics } : m
                )
            }
        },
        updateModelReceivedAt(state, action: PayloadAction<ClassifierEventDto>) {
            const classifierConfigId = action.payload.ClassifierConfigId
            if (!state.models[classifierConfigId]) return

            state.models = {
                ...state.models,
                [classifierConfigId]: state.models[classifierConfigId].map(m =>
                    m.ClassifierId === action.payload.ClassifierId
                        ? {
                              ...m,
                              LearnedAt: isDateTimeEmpty(m.LearnedAt) ? action.payload.ReceivedAt : m.LearnedAt
                          }
                        : m
                )
            }
        },
        updateModelUser(state, action: PayloadAction<ClassifierEventDto>) {
            const classifierConfigId = action.payload.ClassifierConfigId
            if (!state.models[classifierConfigId]) return

            state.models = {
                ...state.models,
                [classifierConfigId]: state.models[classifierConfigId].map(m =>
                    m.ClassifierId === action.payload.ClassifierId
                        ? { ...m, LearningStartedByUser: action.payload.TriggeredByUser || m.LearningStartedByUser }
                        : m
                )
            }
        },
        updateClassifierId(state, action: PayloadAction<ClassifierEventDto>) {
            state.classifiers = state.classifiers.map(v =>
                v.map(c =>
                    c.ClassifierConfigId === action.payload.ClassifierConfigId
                        ? { ...c, ClassifierId: action.payload.ClassifierId }
                        : c
                )
            )
            if (isCurrentClassifier(state.currentClassifier, action.payload)) {
                state.currentClassifier = { ...state.currentClassifier, ClassifierId: action.payload.ClassifierId }
            }
        },
        updateClassifierStatus(state, action: PayloadAction<UpdateClassifierStatusPayload>) {
            state.classifiers = state.classifiers.map(v =>
                v.map(c =>
                    c.ClassifierConfigId === action.payload.ClassifierConfigId
                        ? { ...c, Status: action.payload.NewStatus }
                        : c
                )
            )
            if (action.payload.ClassifierConfigId === state.currentClassifier.ClassifierConfigId) {
                state.currentClassifier = { ...state.currentClassifier, Status: action.payload.NewStatus }
            }
        },
        updateCurrentClassifier(state, action: PayloadAction<UpdateCurrentClassifierPayload>) {
            if (!isReplaceRequired(action.payload, state.currentClassifier.ClassifierConfigId)) return

            const classifier = action.payload.Classifier
            state.currentClassifier = classifier
                ? {
                      ClassifierId: classifier.ClassifierId,
                      ClassifierConfigId: classifier.ClassifierConfigId,
                      Status: classifier.Status,
                      Active: classifier.Active,
                      Type: classifier.Type,
                      Version: classifier.Version,
                      LinkedClassifierConfigId:
                          state.currentClassifier.ClassifierConfigId === classifier.ClassifierConfigId &&
                          isClassifierConfigurationProcessed(classifier)
                              ? classifier?.LinkedClassifierConfigId
                              : undefined
                  }
                : defaultClassifier
        }
    }
})

export default classifiers.reducer

export const actions = classifiers.actions
