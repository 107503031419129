import { Formik } from "formik"
import { Form } from "react-bootstrap"
import { preventSubmitOnEnter } from "../../utility/common/preventSubmitOnEnter"
import RadioButtonValidatableInput from "../RadioButtonValidatableInput/RadioButtonValidatableInput"
import { useTranslation } from "react-i18next"
import { useActions } from "../../hooks/useAction"
import LoadingButton from "../LoadingButton/LoadingButton"
import styles from "./DialogTransferModal.module.scss"
import { DIALOG_TRANSFER_DIALOG_ID } from "./DialogTransferModal"
import { DialogTransferOperatorSearch } from "./DialogTransferOperatorSearch"
import CreatableGroupedTagSelect from "../CreatableTagSelect/CreatableGroupedTagSelect"
import { validationSchema } from "./validationSchema"
import TooltipTrigger from "../TooltipTrigger/TooltipTrigger"
import { FormikFormObserver } from "../../helpers/formik"
import { useCallback, useMemo } from "react"
import {
    dialogsApi,
    useLazyGetDialogTransferAvailableQueuesByOperatorQuery,
    useLazyGetDialogTransferAvailableQueuesQuery
} from "../../api/controllers/dialogs"
import { useParams } from "react-router-dom"
import { mapResponseQueuesToGroupedQueue } from "./helpers"
import { useAppSelector } from "../../store/store"
import { selectDialogId } from "../../store/dialogs/selectors"

const tNamespace = "dialogs:transfer."

export enum EDialogTransferType {
    Operator = "operator",
    Queue = "queue"
}

export type TDialogTransferModalFormValues = {
    transferType: EDialogTransferType
    transferMessage: string
    selectedOperatorId: string
    queueIdOnOperatorRefused: string
    selectedQueueId: string
}

const initialValues: TDialogTransferModalFormValues = {
    transferType: EDialogTransferType.Operator,
    transferMessage: "",
    queueIdOnOperatorRefused: "",
    selectedQueueId: "",
    selectedOperatorId: ""
}

export interface IDialogTransferModalFormProps {
    onSubmitForm: (formData: TDialogTransferModalFormValues) => void
    defaultInitialValues?: TDialogTransferModalFormValues
    onCancel?: () => void
    includeCurrentUser: boolean
}

export const DialogTransferModalForm: React.FC<IDialogTransferModalFormProps> = props => {
    const { onSubmitForm, onCancel, includeCurrentUser, defaultInitialValues = {} } = props
    const [getAvailableQueues, getAvailableQueuesQuery] = useLazyGetDialogTransferAvailableQueuesQuery()
    const [getAvailableQueuesByOperator, getAvailableQueuesByOperatorQuery] =
        useLazyGetDialogTransferAvailableQueuesByOperatorQuery()

    const selectedDialogId = useAppSelector(selectDialogId) as string
    const { data: dialogData } = useAppSelector(dialogsApi.endpoints.getDialog.select(selectedDialogId))
    const { projectId } = useParams<{ projectId: string }>()

    const internalProjectId = dialogData?.Project.Id ?? projectId

    const { t } = useTranslation()
    const { hideDialog } = useActions()

    const handleOnCancelModal = () => {
        onCancel && onCancel()
        hideDialog({
            dialogId: DIALOG_TRANSFER_DIALOG_ID
        })
    }

    const handleFormikInstanceChange = useCallback(
        (formData: TDialogTransferModalFormValues) => {
            if (formData.transferType === EDialogTransferType.Queue) {
                getAvailableQueues(internalProjectId)
            }

            if (formData.transferType === EDialogTransferType.Operator && formData.selectedOperatorId) {
                getAvailableQueuesByOperator({
                    projectId: internalProjectId,
                    operatorId: formData.selectedOperatorId
                })
            }
        },
        [internalProjectId, getAvailableQueues, getAvailableQueuesByOperator]
    )

    const availableQueuesOptions = useMemo(
        () => mapResponseQueuesToGroupedQueue(getAvailableQueuesQuery.data?.Categories),
        [getAvailableQueuesQuery.data?.Categories]
    )

    const availableQueuesByOperatorOptions = useMemo(
        () => mapResponseQueuesToGroupedQueue(getAvailableQueuesByOperatorQuery.data?.Categories),
        [getAvailableQueuesByOperatorQuery.data?.Categories]
    )

    return (
        <Formik<TDialogTransferModalFormValues>
            enableReinitialize
            validateOnMount
            initialValues={{ ...initialValues, ...defaultInitialValues }}
            validationSchema={validationSchema}
            onSubmit={onSubmitForm}
        >
            {formikProps => {
                const operatorTransferType = formikProps.values.transferType === EDialogTransferType.Operator

                return (
                    <Form
                        className={styles.dialogTransferModal__form}
                        onSubmit={formikProps.handleSubmit}
                        onKeyPress={preventSubmitOnEnter}
                    >
                        <RadioButtonValidatableInput
                            name="transferType"
                            value={EDialogTransferType.Operator}
                            id="operator-transfer-radio"
                            label={t(`${tNamespace}operator-transfer`)}
                            className={styles.dialogTransferModal__formRow}
                        />
                        <DialogTransferOperatorSearch
                            name="selectedOperatorId"
                            className={styles.dialogTransferModal__formRow_double}
                            disabled={!operatorTransferType}
                            disabledInlineErrors
                            includeCurrentUser={includeCurrentUser}
                        />
                        <CreatableGroupedTagSelect
                            name="queueIdOnOperatorRefused"
                            id="queue-id-on-operator-refused"
                            className={styles.dialogTransferModal__formRow_double}
                            placeholder={t(`${tNamespace}choose-queue`)}
                            label={t(`${tNamespace}queue-on-operator-refused`)}
                            options={availableQueuesByOperatorOptions}
                            disabled={!operatorTransferType}
                            asSelector
                            t={t}
                        />
                        <RadioButtonValidatableInput
                            name="transferType"
                            id="queue-transfer-radio"
                            className={styles.dialogTransferModal__formRow}
                            value={EDialogTransferType.Queue}
                            label={t(`${tNamespace}queue-transfer`)}
                        />
                        <CreatableGroupedTagSelect
                            name="selectedQueueId"
                            id="selected-queue-id"
                            className={styles.dialogTransferModal__formRow_double}
                            placeholder={t(`${tNamespace}choose-queue`)}
                            options={availableQueuesOptions}
                            disabled={operatorTransferType}
                            disabledInlineErrors
                            asSelector
                            t={t}
                        />

                        {/*
                          Функционал ниже скрыт до своей полноценной реализации в рамках задачи: https://youtrack.craft-talk.ru/issue/CLOUD-3798
                        */}

                        {/*<DebouncedValidatableInput*/}
                        {/*    name="transferMessage"*/}
                        {/*    className={cn(*/}
                        {/*        styles.dialogTransferModal__formRow_double,*/}
                        {/*        styles.dialogTransferModal__textarea*/}
                        {/*    )}*/}
                        {/*    id="transfer-message-textarea"*/}
                        {/*    as="textarea"*/}
                        {/*    label={t(`${tNamespace}transfer-message`)}*/}
                        {/*    placeholder={t(`${tNamespace}input-text`)}*/}
                        {/*/>*/}

                        <div className={styles.dialogTransferModal__actions}>
                            <LoadingButton as="div" variant="light" onClick={handleOnCancelModal}>
                                {t(`${tNamespace}cancel`)}
                            </LoadingButton>
                            <TooltipTrigger
                                id="transfer-submit-btn-tooltip"
                                placement="top"
                                content={Object.values(formikProps.errors)
                                    .map(err => t(`${err}`))
                                    .join("\n")}
                                condition={Boolean(Object.keys(formikProps.errors).length)}
                                delay={{ hide: 0, show: 450 }}
                            >
                                <LoadingButton
                                    as="button"
                                    variant="primary"
                                    type="submit"
                                    disabled={Boolean(Object.keys(formikProps.errors).length)}
                                >
                                    {t(`${tNamespace}transfer`)}
                                </LoadingButton>
                            </TooltipTrigger>
                        </div>
                        <FormikFormObserver
                            onValuesChange={handleFormikInstanceChange}
                            observeFields={["transferType", "selectedOperatorId"]}
                        />
                    </Form>
                )
            }}
        </Formik>
    )
}
