import { useRef } from "react"

const useScrollToTargetByRef = <T extends HTMLElement>() => {
    const targetRef = useRef<T>(null)
    const scrollToTargetRef = () => {
        if (targetRef.current) {
            targetRef.current.scrollIntoView({
                behavior: "smooth"
            })
        }
    }

    return { targetRef, scrollToTargetRef }
}

export default useScrollToTargetByRef
