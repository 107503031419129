import { IHub } from "../interfaces/IHub"
import { Store } from "../../store/store"
import { Hub } from "../hub"
import { logError } from "../../utility/common/logError"
import { actions } from "../../store/projects/slice"

const PROJECT_FROM_COPY_CREATED = "ProjectFromCopyCreated"

class UsersHubV2 {
    private _hub: IHub
    private _store: Store

    constructor(store: Store) {
        const reduxState = store.getState()
        const useAllTransportSignalR =
            reduxState.config.config.data?.WebConfig.appSettings.useAllTransportSignalR === true

        this._hub = new Hub(`/users-hub-v2`, useAllTransportSignalR)
        this._store = store

        this.registerServerEvents()
    }

    async connect() {
        await this._hub.connect()
    }

    async disconnect() {
        await this._hub.disconnect()
    }

    private registerServerEvents() {
        const dispatch = this._store.dispatch

        this._hub.registerEventJson(PROJECT_FROM_COPY_CREATED, project => {
            try {
                dispatch(actions.incrementalNextCopyWizardStage())
                localStorage.removeItem("ProjectStillCreatingId")
            } catch (e) {
                logError(e)
            }
        })
    }
}

export default UsersHubV2
