import { apiInstanceV1, instanceConfig } from "../instances"
import { ProjectUser } from "../../models/projectUser"
import { BlockUserRequest, CreateProjectFromCopyRequest } from "../../models/project"
import { InviteUsersRequest, UpdateProjectRequest } from "../../models/project"
import { apiInstance } from "../instances"
import { CreateProjectRequest, Project } from "../../models/project"
import { FileUpload } from "../../models/file"
import { GetReferenceTypesRequest, GetReferenceTypesResponse } from "../../models/reference"
import { DiffUpdateSettingsResponse, GetSettingsResponse, ProjectSettings } from "../../models/projectSettings"
import { AxiosProgressEvent } from "axios"

const projectsController = {
    create: (request: CreateProjectRequest): Promise<Project> =>
        apiInstance.post("/projects", request, { timeout: 60000 }).then(response => response.data),
    update: (request: UpdateProjectRequest): Promise<Project> =>
        apiInstance.put("/projects", request).then(response => response.data),
    updateLogo: (projectId: string, logo?: FileUpload<Blob>): Promise<Project> => {
        const formData = new FormData()
        if (logo) {
            formData.append("logo", logo.Content, logo.Name)
        }

        return apiInstance.put(`projects/${projectId}/logo`, formData).then(response => response.data)
    },
    getProjectCreatingStatus: (projectId: string): Promise<boolean> =>
        apiInstance.get(`projects/${projectId}/still_creating`).then(response => response.data),
    getProjects: (): Promise<Project[]> => apiInstance.get("/projects").then(response => response.data),
    invite: (request: InviteUsersRequest): Promise<ProjectUser[]> =>
        apiInstance.post("/projects/invite", request).then(response => response.data),
    exists: (projectId: string): Promise<boolean> =>
        apiInstance.post(`projects/${projectId}/exists`).then(response => response.data),
    block: (request: BlockUserRequest): Promise<void> =>
        apiInstance.post("/projects/block", request).then(response => response.data),
    upload: (file: File, onUploadProgress: (progressEvent: AxiosProgressEvent) => void): Promise<string> => {
        const formData = new FormData()
        formData.append("projectArchive", file)
        return apiInstance
            .post(`/projects/upload`, formData, {
                timeout: 600000,
                onUploadProgress
            })
            .then(response => response.data)
    },
    createFromCopy: (request: CreateProjectFromCopyRequest): Promise<Project> =>
        apiInstance.post("/projects/create_from_copy", request, { timeout: 60000 }).then(response => response.data),
    getExportUrl: (projectId: string, addDialogHistory: boolean): string => {
        return `${instanceConfig.baseURL}/projects/${projectId}/export?addDialogHistory=${addDialogHistory}`
    },
    getReferenceTypes: (request: GetReferenceTypesRequest): Promise<GetReferenceTypesResponse> =>
        apiInstanceV1.post("/reference/select_strict", request).then(response => response.data),
    getReferenceValues: (request: GetReferenceTypesRequest): Promise<GetReferenceTypesResponse> =>
        apiInstanceV1.post("/reference/select", request).then(response => response.data),
    getSettings: (projectId: string): Promise<GetSettingsResponse> =>
        apiInstance.get(`/projects/${projectId}/settings`).then(response => response.data),
    updateSettings: (projectId: string, settings: ProjectSettings): Promise<DiffUpdateSettingsResponse> =>
        apiInstance.put(`/projects/${projectId}/settings`, settings).then(response => response.data)
}

export default projectsController
