import { useState, useCallback, useEffect, ReactNode } from "react"
import ReactDOM from "react-dom"

export type TPortal = {
    children: ReactNode
}

const usePortal = (el: Element | null) => {
    const [portal, setPortal] = useState<ReturnType<typeof createPortal>>({
        render: () => null,
        remove: () => false
    })

    const createPortal = useCallback(el => {
        const Portal = ({ children }: TPortal) => {
            const r = ReactDOM.createPortal(children, el)

            if (!r) {
                return null
            }

            return r
        }

        const remove = () => ReactDOM.unmountComponentAtNode(el)

        return { render: Portal, remove }
    }, [])

    useEffect(() => {
        if (el) portal.remove()

        const newPortal = createPortal(el)
        setPortal(newPortal)

        return () => {
            newPortal.remove()
        }
    }, [el])

    return portal.render
}

export { usePortal }
