import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useModal } from "../../utility/common/useModal"
import AlertDialog from "../AlertDialog/AlertDialog"
import { formTranslation } from "../../locales/form"
import SettingsContextMenuItem from "../SettingsContextMenuItem/SettingsContextMenuItem"
import { faPen, faTrash } from "@fortawesome/pro-light-svg-icons"
import { CellProps, Column } from "react-table"
import Can from "../Can/Can"
import { ModifyKnowledgeBase } from "../../permissions"
import SettingsContextMenu from "../SettingsContextMenu/SettingsContextMenu"
import SettingsButton from "../SettingsButton/SettingsButton"
import ProjectSettingsLayout from "../ProjectSettingsLayout/ProjectSettingsLayout"
import { SurveyDto } from "../../models/survey"
import SettingsTableWithoutPagination from "../SettingsTable/SettingsTableWithoutPagination/SettingsTableWithoutPagination"
import { testId } from "../../utility/tests/testId"

const tNamespace = "surveys:"

interface Props {
    projectId: string
    title: string
    surveys: SurveyDto[]
    onAddClick: () => void
    onSettingsClick: (survey: SurveyDto) => void
    onDeleteClick: (projectId: string, survey: SurveyDto) => void
}

const ProjectSurveys: React.FC<Props> = props => {
    const { projectId, title, surveys, onAddClick, onSettingsClick, onDeleteClick } = props
    const { t } = useTranslation()

    const [surveyToDelete, setSurveyToDelete] = useState<SurveyDto | null>(null)
    const { modalOpen, openModal, closeModal, onExited } = useModal(() => (
        <AlertDialog
            show={modalOpen}
            title={t(`${tNamespace}delete-confirmation.title`)}
            message={t(`${tNamespace}delete-confirmation.message`)}
            submitBtnText={t(formTranslation.delete)}
            onClose={closeModal}
            onSubmit={() => {
                surveyToDelete && onDeleteClick(projectId, surveyToDelete)
            }}
            variant="danger"
            onExited={onExited}
        />
    ))

    const getItems = (survey: SurveyDto) => {
        return (
            <>
                <SettingsContextMenuItem
                    id="project-survey-edit"
                    icon={faPen}
                    text={t(formTranslation.edit)}
                    onClick={() => onSettingsClick(survey)}
                />
                <SettingsContextMenuItem
                    id="project-survey-delete"
                    icon={faTrash}
                    text={t(formTranslation.delete)}
                    useSeparator={true}
                    danger={true}
                    onClick={() => {
                        setSurveyToDelete(survey)
                        openModal()
                    }}
                />
            </>
        )
    }

    const columns: Column<SurveyDto>[] = [
        {
            Header: t(formTranslation.title).toString(),
            accessor: "Title"
        },
        {
            id: "Settings",
            disableSortBy: true,
            Cell: (props: CellProps<SurveyDto>) => (
                <Can permission={ModifyKnowledgeBase}>
                    <SettingsContextMenu items={getItems(props.row.original)}>
                        <SettingsButton />
                    </SettingsContextMenu>
                </Can>
            )
        }
    ]

    return (
        <ProjectSettingsLayout.Container
            onButtonClick={onAddClick}
            title={title}
            buttonText={t(`${tNamespace}add-survey`)}
            permission={ModifyKnowledgeBase}
            addButton
            testId={testId.addSurvey}
        >
            <SettingsTableWithoutPagination columns={columns} data={surveys} onRowClick={onSettingsClick} />
        </ProjectSettingsLayout.Container>
    )
}

export default ProjectSurveys
