import React from "react"
import styles from "./SettingsButton.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEllipsisHAlt } from "@fortawesome/pro-light-svg-icons/faEllipsisHAlt"
import cn from "classnames"
import { ClassProps } from "../../utility/common/props"
import { testId } from "../../utility/tests/testId"
import { SizeProp } from "@fortawesome/fontawesome-svg-core"

export interface SettingsButtonProps extends ClassProps {
    onClick?: () => void
    iconSize?: SizeProp
    iconCls?: string
}

const SettingsButton: React.FC<SettingsButtonProps> = props => {
    const { className, onClick, iconSize = "lg", iconCls } = props

    return (
        <div className={cn(styles.btnSettings, className)} onClick={onClick} data-test-id={testId.settingsButton}>
            <FontAwesomeIcon icon={faEllipsisHAlt} size={iconSize} className={cn(styles.btnSettings__icon, iconCls)} />
        </div>
    )
}

export default SettingsButton
