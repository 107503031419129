import React, { useMemo } from "react"
import styles from "./SurveyForm.module.scss"
import { FieldArray, FormikProps } from "formik"
import { WithT } from "i18next"
import { Form } from "react-bootstrap"
import LoadingButton from "../../LoadingButton/LoadingButton"
import { formTranslation } from "../../../locales/form"
import { shallowEqual, useSelector } from "react-redux"
import { nameof } from "../../../utility/common/nameof"
import DebouncedValidatableInput from "../../ValidatableInput/DebouncedValidatableInput"
import { preventSubmitOnEnter } from "../../../utility/common/preventSubmitOnEnter"
import { selectCreateSurveyState, selectUpdateSurveyState } from "../../../store/surveys/selectors"
import { SurveyDto, SurveyFieldValue, SurveyValues } from "../../../models/survey"
import AddButton from "../../AddButton/AddButton"
import SurveyField from "../SurveyField/SurveyField"
import EditSurveyField from "../EditSurveyField/EditSurveyField"
import { v4 as uuidV4 } from "uuid"
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd"
import { selectSlots } from "../../../store/slots/selectors"
import { getSlotTitlesMap } from "../../../utility/knowledgeBase/slot"
import IdField from "../../IdField/IdField"
import cn from "classnames"

const tNamespace = "surveys:form."

interface Props {
    survey?: SurveyDto
    onAddSlot: (values: SurveyValues, survey?: SurveyDto) => void
}

const getEmptyField = (slotId: string, slotTitle: string): SurveyFieldValue => ({
    Id: uuidV4(),
    Comment: "",
    SlotId: slotId,
    IsEditing: true,
    IsAdding: true,
    EditedComment: slotTitle,
    IncreasedSize: false
})

export const pushField = (slotId: string, slotTitle: string, survey: SurveyValues): SurveyValues => {
    survey.Fields.push(getEmptyField(slotId, slotTitle))
    return survey
}

const FormikSurveyForm: React.FC<Props & FormikProps<SurveyValues> & WithT> = props => {
    const { values, survey, handleSubmit, t, onAddSlot } = props
    const asyncState = useSelector(survey ? selectUpdateSurveyState : selectCreateSurveyState, shallowEqual)

    const slots = useSelector(selectSlots)
    const slotsMap = useMemo(() => getSlotTitlesMap(slots), [slots])

    return (
        <Form className={styles.surveyForm} onSubmit={handleSubmit} onKeyPress={preventSubmitOnEnter}>
            <div className={styles.surveyForm__content}>
                {survey && <IdField id={values.Id} className={styles.surveyForm__section} />}
                <div className={styles.surveyForm__section}>
                    <DebouncedValidatableInput
                        id="formName"
                        type="text"
                        name={nameof<SurveyValues>("Title")}
                        label={t(formTranslation.title)}
                    />
                </div>
                <div className={styles.surveyForm__section}>
                    <div className={styles["surveyForm__section-subtitle"]}>{t(`${tNamespace}survey-fields`)}</div>
                    <FieldArray
                        name={nameof<SurveyValues>("Fields")}
                        render={({ move, remove, name }) => (
                            <DragDropContext
                                onDragEnd={(result: DropResult) => {
                                    if (!result.destination || result.destination.index === result.source.index) return
                                    move(result.source.index, result.destination.index)
                                }}
                            >
                                <Droppable droppableId="droppable">
                                    {provided => (
                                        <div ref={provided.innerRef} {...provided.droppableProps}>
                                            {values.Fields.map((f, index) =>
                                                f.IsEditing ? (
                                                    <EditSurveyField
                                                        key={f.Id}
                                                        index={index}
                                                        t={t}
                                                        title={slotsMap[f.SlotId] ?? f.SlotId}
                                                        name={`${name}[${index}]`}
                                                        onDelete={() => remove(index)}
                                                        className={cn(
                                                            styles.surveyForm__field,
                                                            f.IncreasedSize && styles.surveyForm__increasedFieldSize
                                                        )}
                                                        increasedSize={f.IncreasedSize}
                                                    />
                                                ) : (
                                                    <SurveyField
                                                        key={f.Id}
                                                        index={index}
                                                        title={slotsMap[f.SlotId] ?? f.SlotId}
                                                        name={`${name}[${index}]`}
                                                        onDelete={() => remove(index)}
                                                        className={cn(
                                                            styles.surveyForm__field,
                                                            f.IncreasedSize && styles.surveyForm__increasedFieldSize
                                                        )}
                                                    />
                                                )
                                            )}
                                            {provided.placeholder}
                                            <AddButton
                                                variant="light"
                                                onClick={() => onAddSlot(values, survey)}
                                                text={t(`${tNamespace}add-field`)}
                                            />
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        )}
                    />
                </div>
            </div>
            <div className={styles.surveyForm__footer}>
                <LoadingButton type="submit" loading={asyncState.inProcess} variant="primary" block>
                    {t(formTranslation.save)}
                </LoadingButton>
            </div>
        </Form>
    )
}

export default FormikSurveyForm
