import { IHub } from "../interfaces/IHub"
import { logError } from "../../utility/common/logError"
import store, { Store } from "../../store/store"
import { actions } from "../../store/dialogs/slice"
import { dialogsApi } from "../controllers/dialogs"
import { DialogBadge, IGetOperatorClientBadgesResponse } from "../../models/Dialogs/dialog"
import { ApiMessage, GetUpdatedMessagesResponse } from "../../models/Dialogs/message"
import { selectMessagesPaginationState } from "../../store/dialogs/selectors"
import DialogsHubV2, { DETACH_DIALOG, HANDLE_MESSAGE, HANDLE_ROUTED_DIALOG } from "./dialogs-hub-v2"
import { handlersApplyHelper, TCallbackFn } from "./helpers"
import { getAIAssistHintByDialogId, getAISuggestsByDialogId } from "../../store/dialogs/thunks"

class DialogsHubAddons {
    private _hub: IHub | null = null
    private _store: Store
    private _callbacks: Record<string, TCallbackFn> = {}

    constructor(store: Store) {
        this._store = store
    }

    bindDialogsHubRoot(rootClassInstance: DialogsHubV2) {
        this._hub = rootClassInstance.getInstance()
    }

    private async detachDialogCallback(data: unknown) {
        const dispatch = this._store.dispatch

        try {
            const incomingEntity = data as { Id: string }

            const getDialogBadgesSelector = dialogsApi.endpoints.getDialogBadges.select()
            const { data: dialogBadges } = getDialogBadgesSelector(store.getState())

            const dialogBadgesOriginal = store.getState().dialogs.dialogBadgesOriginal
            const incomingDialogBadgesOnSearchMode = store.getState().dialogs.incomingDialogBadgesOnSearchMode

            if (dialogBadgesOriginal.length) {
                const filteredData = dialogBadgesOriginal.filter(x => x.Id !== incomingEntity.Id)
                dispatch(actions.setDialogBadgesOriginal(filteredData))
                dispatch(
                    dialogsApi.endpoints.getActiveDialogsCount.initiate(undefined, {
                        forceRefetch: true
                    })
                )
            }

            if (incomingDialogBadgesOnSearchMode.length) {
                const filteredData = incomingDialogBadgesOnSearchMode.filter(x => x.Id !== incomingEntity.Id)
                dispatch(actions.setIncomingDialogBadgesOnSearchMode(filteredData))
            }

            if (dialogBadges?.length) {
                const filteredData = dialogBadges.filter(x => x.Id !== incomingEntity.Id)

                let nextCurrentDialogId = "",
                    nextCurrentClientId = ""

                if (filteredData.length > 0) {
                    nextCurrentDialogId = filteredData[0].Id
                    nextCurrentClientId = filteredData[0].Client.OmniUserId
                }

                const projectId = dialogBadges?.[0].Project?.Id
                const clientByDialogId = dialogBadges?.find(x => x.Id === incomingEntity.Id)?.Client

                dispatch(
                    actions.setMessagesPaginationState({
                        OmniUserId: nextCurrentClientId,
                        ProjectId: projectId,
                        FromTodayDialogs: true,
                        StartTime: 0,
                        Count: 100
                    })
                )

                dispatch(actions.setCurrentDialogId(nextCurrentDialogId))

                if (projectId && clientByDialogId) {
                    const getOperatorClientsBadgesSelector = dialogsApi.endpoints.getOperatorClientsBadges.select()
                    const getOperatorClientsBadgesQuery = getOperatorClientsBadgesSelector(store.getState())

                    if (Array.isArray(getOperatorClientsBadgesQuery.data)) {
                        dispatch(
                            dialogsApi.util.updateQueryData("getOperatorClientsBadges", undefined, oldState => {
                                const clientEntry: IGetOperatorClientBadgesResponse = {
                                    OmniUserId: clientByDialogId.OmniUserId,
                                    Firstname: clientByDialogId.Firstname,
                                    Lastname: clientByDialogId.Lastname,
                                    Middlename: clientByDialogId.Middlename,
                                    Picture: clientByDialogId.Picture,
                                    ProjectId: projectId
                                }

                                const oldStateFiltered = oldState.filter(
                                    x => x.OmniUserId !== clientByDialogId.OmniUserId
                                )

                                const newState = [clientEntry, ...oldStateFiltered]
                                Object.assign(oldState, newState)
                            })
                        )
                    }
                }

                dispatch(dialogsApi.util.upsertQueryData("getDialogBadges", undefined, filteredData))
            } else {
                dispatch(actions.setCurrentDialogId(""))
            }
        } catch (e) {
            logError(e)
        }
    }

    private async messageIncomingCallback(data: unknown) {
        const dispatch = this._store.dispatch

        try {
            const incomingEntity = data as ApiMessage
            const currentOperator = store.getState().users.currentUser
            const currentMessagePagination = selectMessagesPaginationState(store.getState())

            if (incomingEntity.Case !== "Message") {
                return
            }

            if (
                incomingEntity.Fields.Sender.Id === currentOperator.data?.Login ||
                incomingEntity.Fields.Sender.Id === `DEFAULT___${currentOperator.data?.Login}`
            ) {
                dispatch(
                    dialogsApi.util.updateQueryData("getDialogBadges", undefined, oldState => {
                        const idxIntoList = oldState.findIndex(
                            x => x.Client.IdInChannel === incomingEntity.Fields.DialogId
                        )

                        if (idxIntoList > -1) {
                            oldState[idxIntoList] = {
                                ...oldState[idxIntoList],
                                UnreadMessages: 0,
                                IsReplied: true
                            }
                        }
                    })
                )

                return
            }

            const selectedDialogId = store.getState().dialogs.selectedDialogId

            if (selectedDialogId === incomingEntity.Fields.DialogId && currentMessagePagination) {
                dispatch(
                    dialogsApi.util.updateQueryData("getDialogMessages", currentMessagePagination, oldState => {
                        const newState: GetUpdatedMessagesResponse = {
                            ...oldState,
                            Messages: [incomingEntity, ...oldState.Messages]
                        }

                        Object.assign(oldState, newState)
                    })
                )

                dispatch(getAIAssistHintByDialogId(selectedDialogId))
                dispatch(getAISuggestsByDialogId(selectedDialogId))
            }

            const getDialogBadgesSelector = dialogsApi.endpoints.getDialogBadges.select()
            const getDialogBadgesQuery = getDialogBadgesSelector(store.getState())

            if (getDialogBadgesQuery.data?.length) {
                dispatch(
                    dialogsApi.util.updateQueryData("getDialogBadges", undefined, oldState => {
                        const idxIntoList = oldState.findIndex(
                            x => x.Client.OmniUserId === incomingEntity.Fields.Sender.Id
                        )

                        if (idxIntoList > -1) {
                            const incomingEntityFields = {
                                ...oldState[idxIntoList],
                                UnreadMessages: oldState[idxIntoList].UnreadMessages + 1
                            }

                            const incomingEntityText = incomingEntity.Fields.Text
                            const incomingEntityAttachments = incomingEntity.Fields.Attachments

                            if (incomingEntityText) {
                                incomingEntityFields.Preview = incomingEntityText
                            } else if (incomingEntityAttachments.length) {
                                incomingEntityFields.Preview = "dialogs:dialogs-list.description.attachment"
                            }

                            oldState[idxIntoList] = incomingEntityFields
                        }
                    })
                )
            }
        } catch (e) {
            logError(e)
        }
    }

    private async attachDialogCallback(data: unknown) {
        const dispatch = this._store.dispatch

        try {
            dispatch(actions.unsetCurrentOperatorClientId())

            const dialogsSearchCriterion = store.getState().dialogs.searchCriterion
            const incomingDialogBadgesOnSearchMode = store.getState().dialogs.incomingDialogBadgesOnSearchMode

            const incomingEntity = data as { Badge: DialogBadge }

            if (dialogsSearchCriterion && incomingDialogBadgesOnSearchMode) {
                dispatch(
                    actions.setIncomingDialogBadgesOnSearchMode([
                        ...incomingDialogBadgesOnSearchMode,
                        incomingEntity.Badge
                    ])
                )

                return
            }

            const getDialogBadgesSelector = dialogsApi.endpoints.getDialogBadges.select()
            const getDialogBadgesQuery = getDialogBadgesSelector(store.getState())

            const projectId = incomingEntity.Badge.Project?.Id

            if (projectId) {
                const selectedDialogId = store.getState().dialogs.selectedDialogId

                if (!selectedDialogId) {
                    dispatch(actions.setCurrentDialogId(incomingEntity.Badge.Id))
                    dispatch(
                        dialogsApi.endpoints.getDialogTopics.initiate({
                            ProjectId: projectId
                        })
                    )
                    dispatch(
                        actions.setMessagesPaginationState({
                            OmniUserId: incomingEntity.Badge.Client.OmniUserId,
                            ProjectId: projectId,
                            StartTime: 0,
                            Count: 100
                        })
                    )
                }
            }

            if (getDialogBadgesQuery.data?.length === 0) {
                dispatch(actions.setCurrentDialogId(incomingEntity.Badge.Id))
            }

            dispatch(
                dialogsApi.util.updateQueryData("getDialogBadges", undefined, oldState => {
                    const isDialogAlreadyExists = oldState.some(x => x.Id === incomingEntity.Badge.Id)

                    if (isDialogAlreadyExists) {
                        return
                    }

                    const newState = [incomingEntity.Badge, ...oldState]
                    Object.assign(oldState, newState)
                })
            )

            const dialogBadgesOriginal = store.getState().dialogs.dialogBadgesOriginal
            dispatch(actions.setDialogBadgesOriginal([...dialogBadgesOriginal, incomingEntity.Badge]))
            dispatch(
                dialogsApi.endpoints.getActiveDialogsCount.initiate(undefined, {
                    forceRefetch: true
                })
            )
        } catch (e) {
            logError(e)
        }
    }

    unregisterServerEvents() {
        if (!this._hub) {
            return
        }

        handlersApplyHelper(this._callbacks, this._hub.unregisterEvent.bind(this._hub))
    }

    registerServerEvents() {
        if (!this._hub) {
            return
        }

        this._callbacks = {
            [DETACH_DIALOG]: this.detachDialogCallback.bind(this),
            [HANDLE_MESSAGE]: this.messageIncomingCallback.bind(this),
            [HANDLE_ROUTED_DIALOG]: this.attachDialogCallback.bind(this)
        }

        handlersApplyHelper(this._callbacks, this._hub.registerEvent.bind(this._hub))
    }
}

export default DialogsHubAddons
