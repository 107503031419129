import React from "react"
import styles from "./RetrainClassifierPopoverForm.module.scss"
import { Formik } from "formik"
import { useTranslation } from "react-i18next"
import DebouncedCheckBox from "../../CheckBoxValidatableInput/DebouncedCheckBox"
import { nameof } from "../../../utility/common/nameof"
import { RetrainClassifierOptions } from "../../../models/classifier"
import InfoIcon from "../../InfoIcon/InfoIcon"
import { Button, Form } from "react-bootstrap"
import { formTranslation } from "../../../locales/form"
import LoadingButton from "../../LoadingButton/LoadingButton"
import AsyncState from "../../../core/asyncState"
import { useSelector } from "react-redux"
import { selectStartTrainingClassifierState } from "../../../store/classifiers/selectors"

const tNamespace = "classifiers:train."
const tTooltipNamespace = "classifiers:tooltip."

interface Props {
    onSubmit: (values: RetrainClassifierOptions) => void
    onCancel: () => void
    asyncState: AsyncState<void>
    retrainEmbeddings?: boolean
}

const RetrainClassifierPopoverForm: React.FC<Props> = props => {
    const { onSubmit, onCancel, retrainEmbeddings = true } = props
    const { t } = useTranslation()
    const asyncState = useSelector(selectStartTrainingClassifierState)

    return (
        <Formik
            initialValues={{
                RetrainModel: true,
                RetrainEmbeddings: retrainEmbeddings,
                RetrainSpellCorrector: false
            }}
            onSubmit={onSubmit}
        >
            {({ handleSubmit }) => (
                <Form className={styles["retrain-classifier-popover-form"]} onSubmit={handleSubmit}>
                    <DebouncedCheckBox
                        id="formRetrainSpellCorrector"
                        name={nameof<RetrainClassifierOptions>("RetrainSpellCorrector")}
                        label={t(`${tNamespace}retrain-spelling-correction`)}
                        icon={
                            <InfoIcon
                                id="popoverRetrainErrorCorrection"
                                content={t(`${tTooltipNamespace}retrain-spelling-correction`)}
                            />
                        }
                    />
                    <DebouncedCheckBox
                        id="formRetrainEmbeddings"
                        name={nameof<RetrainClassifierOptions>("RetrainEmbeddings")}
                        label={t(`${tNamespace}retrain-embeddings`)}
                        icon={
                            <InfoIcon
                                id="popoverRetrainEmbeddings"
                                content={t(`${tTooltipNamespace}retrain-embeddings`)}
                            />
                        }
                        disabled={retrainEmbeddings ?? false}
                    />
                    <div className={styles["retrain-classifier-popover-form__footer"]}>
                        <Button
                            className={styles["retrain-classifier-popover-form__cancel"]}
                            variant="light"
                            onClick={onCancel}
                            disabled={asyncState.inProcess}
                        >
                            {t(formTranslation.cancel)}
                        </Button>
                        <LoadingButton
                            className={styles["retrain-classifier-popover-form__submit"]}
                            loading={asyncState.inProcess}
                            variant="primary"
                            type="submit"
                        >
                            {t(`${tNamespace}start-training`)}
                        </LoadingButton>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

export default RetrainClassifierPopoverForm
