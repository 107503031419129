import { ChannelTypeString, isWhatsappChannelType, WhatsappChannelType } from "../../models/channel"
import { AuthType } from "../../models/webChatValues"
import * as Yup from "yup"
import { WhatsappAuthType } from "../../models/channelValues"
import { formTranslation } from "../../locales/form"

const tNamespace = "channel:error."

const channelTypes: string[] = Object.values(ChannelTypeString)

export const isChannelTypeExists = (type: string): type is ChannelTypeString => {
    return channelTypes.includes(type)
}

export const getValidationSchema = (channelType: string): Yup.ObjectSchema => {
    const tokenNotRequiredChannels: string[] = [
        ChannelTypeString.MSBot,
        ChannelTypeString.Teams,
        ChannelTypeString.Email,
        WhatsappChannelType.InfoBIP,
        WhatsappChannelType.StreamTel,
        WhatsappChannelType.MFMS,
        ChannelTypeString.WebChat,
        ChannelTypeString.Instagram
    ]

    const botIdValidationRequired: string[] = [ChannelTypeString.Telegram]

    const isWhatsapp = isWhatsappChannelType(channelType)

    return Yup.object().shape({
        Title: Yup.string().requiredExcludeEmpty(`${tNamespace}title-required`),
        BotSettings: Yup.object().shape({
            Id: Yup.string().requiredExcludeEmpty(`${tNamespace}id-required`),
            ...(!tokenNotRequiredChannels.includes(channelType) && {
                Token: Yup.string().requiredExcludeEmpty(`${tNamespace}token-required`)
            }),
            ...(botIdValidationRequired.includes(channelType) && {
                BotId: Yup.string().requiredExcludeEmpty(`${tNamespace}id-required`)
            })
        }),
        WebHookSettings: Yup.object().shape({
            WebHook: Yup.string(),
            PreprocessorWebHook: Yup.string().default("")
        }),
        ...(channelType === ChannelTypeString.WebChat && {
            AuthSettings: Yup.object().shape({
                salt: Yup.string().when(["type", "useHash", "isShownSalt"], {
                    is: (type, useHash, isShownSalt) =>
                        type === AuthType.JavaScriptAuthorization && useHash && isShownSalt,
                    then: Yup.string().requiredExcludeEmpty(`${tNamespace}salt-required`)
                }),
                type: Yup.string(),
                useHash: Yup.boolean()
            }),
            SyncSettings: Yup.object().shape({
                mediatorId: Yup.string().when("enabled", {
                    is: true,
                    then: Yup.string().requiredExcludeEmpty(`${tNamespace}chat-required`)
                })
            }),
            DialogEvaluationSettings: Yup.object().shape({
                ratingAliases: Yup.array().when("ratingAliasesEnabled", {
                    is: true,
                    then: Yup.array().of(Yup.string().requiredExcludeEmpty(`${tNamespace}rating-required`))
                }),
                ratingAnswerText: Yup.string().when("showRatingBox", {
                    is: true,
                    then: Yup.string().requiredExcludeEmpty(`${tNamespace}message-required`)
                })
            }),
            MessageSettings: Yup.object().shape({
                userTypingInterval: Yup.number()
                    .min(5, `${tNamespace}less-then-5`)
                    .integer(`${tNamespace}integer-required`)
                    .when("sendUserIsTyping", {
                        is: true,
                        then: Yup.number().required(`${tNamespace}interval-required`)
                    })
            }),
            AuthAdvanced: Yup.object().shape({
                personalDataText: Yup.string().when("usePersonalDataAgreement", {
                    is: true,
                    then: Yup.string().requiredExcludeEmpty(formTranslation.enterText)
                }),
                userFormFields: Yup.string().test("json", `${tNamespace}json-invalid`, value => {
                    if (!value) {
                        return true
                    }

                    try {
                        JSON.parse(value)
                        return true
                    } catch (error) {
                        return false
                    }
                })
            })
        }),
        ...(isWhatsapp && whatsappValidationSchema()),
        ...(!isWhatsapp && {
            Params: Yup.object().shape({
                ...getParamsValidationSchemaByType(channelType)
            })
        })
    })
}

const getParamsValidationSchemaByType = (type: string) => {
    switch (type) {
        case ChannelTypeString.MSBot:
        case ChannelTypeString.Teams:
            return {
                MicrosoftAppId: Yup.string().requiredExcludeEmpty(`${tNamespace}app-id-required`),
                MicrosoftAppPassword: Yup.string().requiredExcludeEmpty(`${tNamespace}app-password-required`),
                BotId: Yup.string().requiredExcludeEmpty(`${tNamespace}bot-id-required`)
            }
        case ChannelTypeString.Viber:
            return {
                bot_id: Yup.string().requiredExcludeEmpty(`${tNamespace}bot-id-required`)
            }
        case ChannelTypeString.VK:
            return {
                group_id: Yup.string().requiredExcludeEmpty(`${tNamespace}group-id-required`),
                confirmation_code: Yup.string().requiredExcludeEmpty(`${tNamespace}confirmation-code-required`)
            }
        case ChannelTypeString.Odnoklassniki:
            return {
                page_id: Yup.string().requiredExcludeEmpty(`${tNamespace}page-id-required`)
            }
        case ChannelTypeString.Email:
            return {
                imap: Yup.string().requiredExcludeEmpty(`${tNamespace}server-required`),
                imap_port: Yup.string().requiredExcludeEmpty(`${tNamespace}port-required`),
                imap_login: Yup.string().requiredExcludeEmpty(`${tNamespace}login-required`),
                imap_password: Yup.string().requiredExcludeEmpty(formTranslation.passwordRequired),
                smtp: Yup.string().requiredExcludeEmpty(`${tNamespace}server-required`),
                smtp_port: Yup.string().requiredExcludeEmpty(`${tNamespace}port-required`),
                smtp_login: Yup.string().requiredExcludeEmpty(`${tNamespace}login-required`),
                smtp_password: Yup.string().requiredExcludeEmpty(formTranslation.passwordRequired),
                email: Yup.string().requiredExcludeEmpty(formTranslation.emailRequired),
                subject: Yup.string().requiredExcludeEmpty(`${tNamespace}subject-required`)
            }
        case ChannelTypeString.Facebook:
            return {
                page_id: Yup.string().requiredExcludeEmpty(`${tNamespace}page-id-required`),
                verifytoken: Yup.string().requiredExcludeEmpty(`${tNamespace}verifytoken-required`)
            }
        case ChannelTypeString.YandexDialogs:
            return {
                chat_id: Yup.string().requiredExcludeEmpty(`${tNamespace}chat-id-required`),
                yandex_dialogs_url: Yup.string().requiredExcludeEmpty(`${tNamespace}yandex-dialogs-url-required`),
                api_token: Yup.string().requiredExcludeEmpty(`${tNamespace}api-token-required`)
            }
        case ChannelTypeString.Instagram:
            return {
                bot_id: Yup.string().requiredExcludeEmpty(`${tNamespace}bot-id-required`),
                verify_token: Yup.string().requiredExcludeEmpty(`${tNamespace}verifytoken-required`)
            }
        default:
            return {}
    }
}

export const whatsappValidationSchema = () => ({
    ProviderSettings: Yup.object().shape({
        login: Yup.string().when("provider", {
            is: WhatsappChannelType.StreamTel,
            then: Yup.string().requiredExcludeEmpty(`${tNamespace}login-required`)
        }),
        apipassword: Yup.string().when("provider", {
            is: WhatsappChannelType.StreamTel,
            then: Yup.string().requiredExcludeEmpty(formTranslation.passwordRequired)
        }),
        sourceAddress: Yup.string().when("provider", {
            is: WhatsappChannelType.StreamTel,
            then: Yup.string().requiredExcludeEmpty(`${tNamespace}sender-name-required`)
        }),
        scenario_key: Yup.string().when("provider", {
            is: WhatsappChannelType.InfoBIP,
            then: Yup.string().requiredExcludeEmpty(`${tNamespace}scenario-key-required`)
        }),
        host_url: Yup.string().when("provider", {
            is: WhatsappChannelType.InfoBIP,
            then: Yup.string().requiredExcludeEmpty(`${tNamespace}server-required`)
        }),
        api_key: Yup.string().when(["provider", "authType"], {
            is: (provider, authType) =>
                provider === WhatsappChannelType.InfoBIP && authType === WhatsappAuthType.ApiKey,
            then: Yup.string().requiredExcludeEmpty(`${tNamespace}api-key-required`)
        }),
        username: Yup.string().when(["provider", "authType"], {
            is: (provider, authType) =>
                provider === WhatsappChannelType.InfoBIP && authType === WhatsappAuthType.LoginPassword,
            then: Yup.string().requiredExcludeEmpty(`${tNamespace}login-required`)
        }),
        password: Yup.string().when(["provider", "authType"], {
            is: (provider, authType) =>
                provider === WhatsappChannelType.InfoBIP && authType === WhatsappAuthType.LoginPassword,
            then: Yup.string().requiredExcludeEmpty(formTranslation.passwordRequired)
        }),
        subject: Yup.string().when("provider", {
            is: WhatsappChannelType.MFMS,
            then: Yup.string().requiredExcludeEmpty(`${tNamespace}sender-name-required`)
        }),
        apikey: Yup.string().when("provider", {
            is: WhatsappChannelType.MFMS,
            then: Yup.string().requiredExcludeEmpty(formTranslation.passwordRequired)
        })
    }),
    Params: Yup.object().shape({
        phone_number: Yup.string().requiredExcludeEmpty(`${tNamespace}phone-number-required`)
    })
})
