import React from "react"
import styles from "./MessageBasicLoader.module.scss"
import useDelay from "../../../../../utility/common/useDelay"
import Skeleton from "../../../../Skeleton/Skeleton"
import cn from "classnames"

export interface MessageBasicLoaderProps {
    isOutgoing?: boolean
}

const MessageBasicLoader: React.FC<MessageBasicLoaderProps> = props => {
    const { isOutgoing } = props

    const display = useDelay()

    if (!display) {
        return null
    }

    return (
        <div className={cn(styles.messageBasicLoader, isOutgoing ? styles.messageBasicLoader_out : undefined)}>
            <div className={styles.messageBasicLoader__body}>
                <div className={styles.messageBasicLoader__picture}>
                    <Skeleton />
                </div>
                <div className={styles.messageBasicLoader__message}>
                    <Skeleton />
                </div>
            </div>
        </div>
    )
}

export default MessageBasicLoader
