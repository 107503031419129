import React, { useCallback, useState } from "react"
import { nameof2 } from "../../../utility/common/nameof"
import { FileValues, WebChatChannelValues } from "../../../models/webChatValues"
import FormAccordion from "../../FormAccordion/FormAccordion"
import { useTranslation } from "react-i18next"
import ValidatableInputWithControl from "../../ValidatableInputWithControl/ValidatableInputWithControl"
import { fileSizes } from "../../../utility/channels/webChatValues"
import { useFormikContext } from "formik"
import DebouncedValidatableInput from "../../ValidatableInput/DebouncedValidatableInput"
import DebouncedCheckBox from "../../CheckBoxValidatableInput/DebouncedCheckBox"
import { Col, Form, Row } from "react-bootstrap"
import { formTranslation } from "../../../locales/form"

const tNamespace = "channel:form."

const WebChatFilesSettings: React.FC = () => {
    const { t } = useTranslation()
    const { values, setFieldValue } = useFormikContext<WebChatChannelValues>()

    const [allowedFileNameRegexEnabled, setAllowedFileNameRegexEnabled] = useState(
        !!values.FileSettings.allowedFileNameRegex
    )
    const handleAllowedFileNameRegexToggle = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const checked = e.target.checked
            setAllowedFileNameRegexEnabled(checked)
            if (!checked) {
                setFieldValue(
                    nameof2<WebChatChannelValues, FileValues>("FileSettings", "allowedFileNameRegex"),
                    "",
                    false
                )
            }
        },
        [setFieldValue]
    )

    return (
        <FormAccordion title={t(`${tNamespace}files`)}>
            <DebouncedCheckBox
                id="formShowImageDownloadLink"
                name={nameof2<WebChatChannelValues, FileValues>("FileSettings", "showImageDownloadLink")}
                label={t(`${tNamespace}downloading-images`)}
            />
            <ValidatableInputWithControl
                id="formAllowedFileNameRegex"
                type="text"
                name={nameof2<WebChatChannelValues, FileValues>("FileSettings", "allowedFileNameRegex")}
                label={t(`${tNamespace}files-allowed-for-upload`)}
                isEnabled={allowedFileNameRegexEnabled}
                onToggle={handleAllowedFileNameRegexToggle}
                placeholder={t(formTranslation.enterRegex)}
            />
            <DebouncedValidatableInput
                id="formFileRegexErrorMessage"
                type="text"
                name={nameof2<WebChatChannelValues, FileValues>("FileSettings", "fileRegexErrorMessage")}
                label={t(`${tNamespace}error-text-invalid-file`)}
                placeholder={t(formTranslation.enterText)}
            />
            <ValidatableInputWithControl
                id="formUploadSizeLimit"
                type="number"
                name={nameof2<WebChatChannelValues, FileValues>("FileSettings", "uploadSizeLimitValue")}
                selectName={nameof2<WebChatChannelValues, FileValues>("FileSettings", "uploadSizeLimitUnit")}
                label={t(`${tNamespace}file-size-limit`)}
                options={fileSizes}
                placeholder={t(formTranslation.enterNumber)}
            />
            <DebouncedValidatableInput
                id="formOversizedFileErrorMessage"
                type="text"
                name={nameof2<WebChatChannelValues, FileValues>("FileSettings", "oversizedFileErrorMessage")}
                label={t(`${tNamespace}error-text-large-file`)}
                placeholder={t(formTranslation.enterText)}
            />
            <DebouncedCheckBox
                id="formUseCaptcha"
                name={nameof2<WebChatChannelValues, FileValues>("FileSettings", "useCaptcha")}
                label={t(`${tNamespace}use-captcha`)}
            />
            <DebouncedValidatableInput
                id="formCaptchaSiteKey"
                type="text"
                name={nameof2<WebChatChannelValues, FileValues>("FileSettings", "captchaSiteKey")}
                label={t(`${tNamespace}captcha-site-key`)}
                placeholder={t(formTranslation.enterText)}
            />
            <DebouncedValidatableInput
                id="formCaptchaSecretKey"
                type="textCaptchaSiteKey"
                name={nameof2<WebChatChannelValues, FileValues>("FileSettings", "captchaSecretKey")}
                label={t(`${tNamespace}captcha-secret-key`)}
                placeholder={t(formTranslation.enterText)}
            />
            <Form.Group as={Row} controlId="formCaptchaFileCount" className="horizontal-left-input">
                <Col className="captcha-count">
                    <DebouncedValidatableInput
                        id="formCaptchaFileCount"
                        type="number"
                        name={nameof2<WebChatChannelValues, FileValues>("FileSettings", "captchaFileCount")}
                    />
                </Col>
                <Form.Label column>{t(`${tNamespace}captcha-file-count`)}</Form.Label>
            </Form.Group>
            <Form.Group as={Row} controlId="formMaxFileCount" className="horizontal-left-input">
                <Col className="captcha-count">
                    <DebouncedValidatableInput
                        id="formMaxFileCount"
                        type="number"
                        name={nameof2<WebChatChannelValues, FileValues>("FileSettings", "maxFileCount")}
                    />
                </Col>
                <Form.Label column>{t(`${tNamespace}max-file-count`)}</Form.Label>
            </Form.Group>
            <DebouncedValidatableInput
                id="form"
                type="textCaptchaSiteKey"
                name={nameof2<WebChatChannelValues, FileValues>("FileSettings", "maxFileCountErrorMessage")}
                label={t(`${tNamespace}max-file-error`)}
                placeholder={t(formTranslation.enterText)}
            />
            <DebouncedCheckBox
                id="formSecuredFileLinks"
                name={nameof2<WebChatChannelValues, FileValues>("FileSettings", "securedFileLinks")}
                label={t(`${tNamespace}secured-file-links`)}
            />
            <DebouncedCheckBox
                id={"formFilesLinksCompatibility"}
                name={nameof2<WebChatChannelValues, FileValues>("FileSettings", "filesLinksCompatibility")}
                label={t(`${tNamespace}file-links-compatibility`)}
            />
        </FormAccordion>
    )
}

export default WebChatFilesSettings
