import React from "react"
import "./ClassifierTypeForm.scss"
import TypeFormCard from "../../TypeFormCard/TypeFormCard"
import { useTranslation } from "react-i18next"
import { ClassifierType } from "../../../models/classifier"
import usePermissionsCheck from "../../../utility/common/usePermissionsCheck"
import { Supervisor } from "../../../permissions"
import { mapTypeToLabel } from "../../../utility/classifier/classifierForm"
import ClassifierIcon from "../ClassifierIcon/ClassifierIcon"

const tNamespace = "classifiers:classifier-type-description."

const classifierTypesBase = [ClassifierType.WMD, ClassifierType.LOGREG, ClassifierType.RULE_BASED]
const classifierTypesForSupervisor = [
    ClassifierType.GRU,
    ClassifierType.KNN,
    ClassifierType.BERT,
    ClassifierType.KNN_ELASTIC
]

interface Props {
    onSelect: (type: ClassifierType) => void
}

const ClassifierTypeForm: React.FC<Props> = props => {
    const { t } = useTranslation()
    const { onSelect } = props

    const isSupervisor = usePermissionsCheck([Supervisor])
    const classifierTypesAllowed = isSupervisor
        ? [...classifierTypesBase, ...classifierTypesForSupervisor]
        : classifierTypesBase

    return (
        <div className="classifier-type-form">
            <div className="classifier-type-form__content">
                {classifierTypesAllowed.map(classifierType => (
                    <TypeFormCard
                        key={classifierType}
                        icon={<ClassifierIcon className="classifier-type-form__icon" type={classifierType} />}
                        onSelect={() => onSelect(classifierType)}
                        title={mapTypeToLabel[classifierType]}
                        description={t(`${tNamespace}${classifierType.toLowerCase()}`)}
                    />
                ))}
            </div>
        </div>
    )
}

export default ClassifierTypeForm
