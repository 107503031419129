import React, { useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import cn from "classnames"
import { IconProp } from "@fortawesome/fontawesome-svg-core"

import ValidatableInput from "../ValidatableInput/ValidatableInput"
import { formTranslation } from "../../locales/form"
import { SlotType } from "../../models/slot"
import { GlobalUserSurveySlot } from "../../models/globalUserSurvey"
import styles from "./GlobalUserSurveyField.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export enum GlobalUserSurveyFieldActionNames {
    undo = "undo",
    accept = "accept"
}

type GlobalUserSurveyFieldActionProps = {
    name: GlobalUserSurveyFieldActionNames
    icon: IconProp
    onClick?: (slot: GlobalUserSurveySlot) => void
    className?: string
}

export interface GlobalUserSurveyFieldProps {
    slot: GlobalUserSurveySlot
    initialValues: { [key: string]: string | string[] }
    name: string
    id: string
    containerClassName?: string
    actions?: GlobalUserSurveyFieldActionProps[]
    actionsContainerClassName?: string
    titleClassName?: string
    contentClassName?: string
    disabled?: boolean
}

const GlobalUserSurveyField = ({
    slot,
    name,
    id,
    initialValues,
    containerClassName,
    actions,
    actionsContainerClassName,
    titleClassName,
    contentClassName,
    disabled
}: GlobalUserSurveyFieldProps) => {
    const { t } = useTranslation()
    const [isEditing, setEditing] = useState(false)
    const containerRef = useRef<HTMLDivElement>(null)

    const handleClickStaticField = () => {
        setEditing(() => true)
    }

    const onClickAction = (actionCallback?: (slot: GlobalUserSurveySlot) => void) => {
        setEditing(() => false)

        if (actionCallback) {
            actionCallback(slot)
        }
    }

    const renderFieldInner = () => {
        switch (slot.Type) {
            case SlotType.Dictionary:
                return (
                    <div className={styles.questionerSlot__inputField_typeSelect}>
                        <ValidatableInput
                            id={id}
                            name={name}
                            as="select"
                            type="text"
                            disabled={disabled || !slot.Domain.length}
                        >
                            <option value="">{t(formTranslation.nothingSelected)}</option>
                            {slot.Domain.map(option => (
                                <option value={option.Id} key={option.Id}>
                                    {option.Title}
                                </option>
                            ))}
                        </ValidatableInput>
                    </div>
                )
            default:
                return (
                    <>
                        <ValidatableInput id={id} name={name} type="text" disabled={disabled} />
                    </>
                )
        }
    }

    const getInitialValue = () => {
        const value =
            slot.Type === SlotType.Dictionary
                ? slot.Domain.find(d => d.Id === initialValues[slot.SlotId])?.Title || ""
                : initialValues[slot.SlotId]

        return value
    }

    return (
        <div
            className={cn(styles.questionerSlot__section, containerClassName)}
            onClick={!isEditing ? handleClickStaticField : undefined}
            ref={containerRef}
        >
            {isEditing ? (
                <>
                    <label className={cn(styles.questionerSlot__title, titleClassName)} htmlFor={id}>
                        {slot.Title}
                    </label>
                    {renderFieldInner()}
                    {!disabled && Array.isArray(actions) && (
                        <div
                            className={cn(
                                styles.questionerSlot__actionsContainer,
                                slot.Type === SlotType.Dictionary && styles.questionerSlot__dictionaryActionsContainer,
                                actionsContainerClassName
                            )}
                        >
                            {actions.map(({ name, icon, onClick, className }) => {
                                return (
                                    <button
                                        key={name}
                                        onClick={() => onClickAction(onClick)}
                                        className={cn(styles.questionerSlot__actionsButton, className)}
                                    >
                                        <FontAwesomeIcon icon={icon} />
                                    </button>
                                )
                            })}
                        </div>
                    )}
                </>
            ) : (
                <>
                    <div className={cn(styles.questionerSlot__title, titleClassName)}>{slot.Title}</div>
                    <div className={cn(styles.questionerSlot__content, contentClassName)}>{getInitialValue()}</div>
                </>
            )}
        </div>
    )
}

export default GlobalUserSurveyField
