import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleUp, faAngleDown, IconDefinition } from "@fortawesome/pro-light-svg-icons"
import styles from "./Collapser.module.scss"
import { ReactNode, useCallback, useEffect, useState } from "react"
import cn from "classnames"

export interface ICollapserProps extends Omit<React.HTMLAttributes<HTMLDivElement>, "title"> {
    title: ReactNode | string
    value?: boolean
    expanderCls?: string
    expandedIcon?: IconDefinition
    collapsedIcon?: IconDefinition
    BodyProps?: React.HTMLAttributes<HTMLDivElement>
    onExpand?: (state: boolean) => void
}

export const Collapser: React.FC<React.PropsWithChildren<ICollapserProps>> = ({ children, ...props }) => {
    const {
        title,
        BodyProps,
        value = false,
        expanderCls,
        expandedIcon = faAngleDown,
        collapsedIcon = faAngleUp,
        onExpand,
        ...rootProps
    } = props

    const [expanded, setExpanded] = useState<boolean>(value)

    const onExpandTmp = useCallback(() => {
        setExpanded(prev => {
            if (onExpand) {
                onExpand(!prev)
            }

            return !prev
        })
    }, [onExpand])

    useEffect(() => {
        setExpanded(value)
    }, [value, onExpand])

    return (
        <div {...rootProps} className={cn(styles.collapser, rootProps.className)}>
            <div className={cn(styles.collapser__expander, expanderCls)} onClick={onExpandTmp}>
                <div className={styles.collapser__title}>{title}</div>
                <FontAwesomeIcon icon={expanded ? expandedIcon : collapsedIcon} />
            </div>
            <div
                {...BodyProps}
                className={cn(styles.collapser__body, BodyProps?.className, expanded && styles.collapser__body_visible)}
            >
                {children}
            </div>
        </div>
    )
}
