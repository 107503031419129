import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Col, Container, Form, Row } from "react-bootstrap"
import { Formik, FormikProps } from "formik"
import { preventSubmitOnEnter } from "../../utility/common/preventSubmitOnEnter"
import { nameof } from "../../utility/common/nameof"
import "./ProjectSettingsForm.scss"
import ValidatableInput from "../ValidatableInput/ValidatableInput"
import { WithT } from "i18next"
import { Project, UpdateProjectRequest } from "../../models/project"
import { Dispatch } from "../../utility/common/storeHelper"
import { useDispatch, useSelector } from "react-redux"
import { selectUpdateLogoState, selectUpdateProjectState } from "../../store/projects/selectors"
import { FileUpload } from "../../models/file"
import { updateLogo, updateProject } from "../../store/projects/thunks"
import ImageUploadModal from "../ImageUploadModal/ImageUploadModal"
import { formTranslation } from "../../locales/form"
import LoadingButton from "../LoadingButton/LoadingButton"
import Can from "../Can/Can"
import { Admin, Supervisor } from "../../permissions"
import { getTranslatedLanguageName } from "../../utility/common/language"
import IconButton from "../IconButton/IconButton"
import { faDownload } from "@fortawesome/pro-light-svg-icons/faDownload"
import projectsController from "../../api/controllers/projects"
import ImageUploadPlaceholder from "../ImageUploadPlaceholder/ImageUploadPlaceholder"
import { testId } from "../../utility/tests/testId"
import * as Yup from "yup"

export const tNamespace = "project:"

export interface SettingsValues {
    name: string
    defaultRole?: string
}

interface Props {
    project: Project
}

const FormikProjectSettingsForm: React.FC<Props & FormikProps<SettingsValues> & WithT> = props => {
    const { project, t, handleSubmit, touched, errors } = props
    const dispatch = useDispatch<Dispatch>()

    const [show, setShow] = useState(false)
    const updateProjectState = useSelector(selectUpdateProjectState)
    const updateLogoState = useSelector(selectUpdateLogoState)

    const handleLogoModalClose = () => setShow(false)
    const handleLogoModalOpen = () => setShow(true)
    const uploadLogo = async (logo?: FileUpload<Blob>) => {
        await dispatch(updateLogo(project.id, logo))
        setShow(false)
    }

    const [addDialogsHistory, setAddDialogHistory] = useState(false)
    const handleDialogHistoryChange = () => {
        setAddDialogHistory(!addDialogsHistory)
    }

    return (
        <Form
            className="project-settings-form"
            onSubmit={handleSubmit}
            data-test-id={testId.projectGeneralSettingsForm}
            onKeyPress={preventSubmitOnEnter}
        >
            <Container fluid className="p-0">
                <Row>
                    <Col md={3} className="section">
                        <div className="section__header">{t(`${tNamespace}logo`)}</div>
                        <div className="section__body">
                            <Can permission={Admin}>
                                {(can: boolean) => (
                                    <ImageUploadPlaceholder<Project>
                                        t={t}
                                        src={project.logo}
                                        disabled={!can}
                                        onClick={handleLogoModalOpen}
                                        uploadState={updateLogoState}
                                    />
                                )}
                            </Can>
                            <ImageUploadModal
                                circularCrop={false}
                                show={show}
                                onClose={handleLogoModalClose}
                                onUpload={uploadLogo}
                                uploading={updateLogoState.inProcess}
                                pictureSrc={project.logo}
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={3} className="section">
                        <div className="section__header">{t(`${tNamespace}project-name`)}</div>
                        <div className="section__body">
                            <ValidatableInput id="formName" type="text" name={nameof<SettingsValues>("name")} />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={3} className="section">
                        <div className="section__header">{t(`${tNamespace}project-language`)}</div>
                        <div className="section__body">{getTranslatedLanguageName(project.language)}</div>
                    </Col>
                </Row>
                <Can permission={Supervisor}>
                    <Row>
                        <Col md={3} className="section">
                            <div className="section__header">{t(`${tNamespace}project-export`)}</div>
                            <div className="section__body">
                                <Form.Check
                                    label={t(`${tNamespace}include-dialogs-history`)}
                                    onChange={handleDialogHistoryChange}
                                    checked={addDialogsHistory}
                                />
                                <IconButton
                                    className="project-settings-form__download"
                                    icon={faDownload}
                                    href={projectsController.getExportUrl(project.id, addDialogsHistory)}
                                    variant="outline-primary"
                                >
                                    {t(`${tNamespace}download`)}
                                </IconButton>
                            </div>
                        </Col>
                    </Row>
                </Can>
                {Object.values(touched).some(v => v === true) && Object.values(errors).every(v => v === undefined) && (
                    <Can permission={Admin}>
                        <Row>
                            <Col md={3}>
                                <Row className="project-settings-form__submit justify-content-md-center">
                                    <Col md="auto">
                                        <LoadingButton
                                            type="submit"
                                            className="btn-submit"
                                            loading={updateProjectState.inProcess}
                                            variant="primary"
                                            data-test-id={testId.saveProjectSettings}
                                        >
                                            {t(formTranslation.save)}
                                        </LoadingButton>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Can>
                )}
            </Container>
        </Form>
    )
}

const defaultValues = (project: Project): SettingsValues => {
    return {
        name: project.name
    }
}

const createRequest = (project: Project, values: SettingsValues): UpdateProjectRequest => {
    return {
        Id: project.id,
        Name: values.name
    }
}

const ProjectSettingsForm: React.FC<Props> = props => {
    const { project } = props
    const dispatch = useDispatch()
    const { t } = useTranslation()

    return (
        <Formik
            enableReinitialize={true}
            validationSchema={Yup.object().shape({
                name: Yup.string().requiredExcludeEmpty(t(formTranslation.titleRequired))
            })}
            initialValues={defaultValues(project)}
            onSubmit={(values: SettingsValues, { resetForm }) => {
                dispatch(updateProject(createRequest(project, values), () => resetForm()))
            }}
        >
            {formikProps => <FormikProjectSettingsForm {...props} {...formikProps} t={t} />}
        </Formik>
    )
}

export default ProjectSettingsForm
