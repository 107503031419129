import React, { RefObject, useRef } from "react"
import cn from "classnames"
import { faPaperclip, faArrowToTop, faTrash } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ClassProps } from "../../utility/common/props"
import { MessageAttachment } from "../../models/Dialogs/message"
import style from "./FileUploader.module.scss"
import { useTranslation } from "react-i18next"
import { uploadFile } from "../../utility/common/files"
import { useField } from "formik"

export enum imageTypes {
    jpeg = ".jpeg",
    jpg = ".jpg",
    png = ".png",
    svg = ".svg"
}

export interface FileUploaderProps extends ClassProps {
    name: string
    disabled: boolean
}

const tNamespace = "channel:form."

const FileUploader: React.FC<FileUploaderProps> = props => {
    const { t } = useTranslation()
    const { className, name, disabled } = props
    const inputFileRef = useRef<HTMLInputElement>(null)
    const linkFileRef = useRef<HTMLAnchorElement>(null)
    const [field, , { setValue }] = useField(name)
    const imageType = "image"
    const fileType = "file"

    const specifyAttachmentType = (fileName: string) => {
        for (const type of Object.values(imageTypes)) {
            if (fileName.endsWith(type)) {
                return imageType
            }
        }
        return fileType
    }

    const handleClickRedirect = (elementRef: RefObject<HTMLInputElement | HTMLAnchorElement>) => {
        if (elementRef && elementRef.current) {
            elementRef.current.click()
        }
    }

    const handleSelectFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files == null || e.target.files.length === 0) {
            return
        }
        const selectedFile = e.target.files[0]
        const fileUrl = await uploadFile(selectedFile)
        const attachment: Omit<MessageAttachment, "Size"> = {
            Url: fileUrl,
            ContentType: specifyAttachmentType(selectedFile.name),
            Name: selectedFile.name
        }
        setValue(attachment)
    }

    const handleFileDelete = () => {
        setValue(undefined)
        if (inputFileRef.current) {
            inputFileRef.current.value = ""
        }
    }

    return (
        <div className={cn(style.fileUploader, className, disabled && style.fileUploader_disabled)}>
            <FontAwesomeIcon
                size={"lg"}
                icon={faPaperclip}
                className={style.fileUploader__leftIcon}
                onClick={() => {
                    !disabled && handleClickRedirect(inputFileRef)
                }}
            />
            <div>{field.value ? field.value.Name : t(`${tNamespace}add-file`)}</div>
            <input
                type="file"
                className={style.fileUploader__input}
                ref={inputFileRef}
                onChange={handleSelectFile}
                disabled={disabled}
            />
            <FontAwesomeIcon
                icon={faTrash}
                size={"lg"}
                className={style.fileUploader__deleteIcon}
                onClick={disabled ? undefined : handleFileDelete}
            />
            <FontAwesomeIcon
                icon={faArrowToTop}
                size={"lg"}
                className={style.fileUploader__downloadIcon}
                onClick={() => {
                    !disabled && handleClickRedirect(linkFileRef)
                }}
            />
            <a
                href={field.value ? field.value.Url + "?file_name=" + field.value.Name : ""}
                className={style.fileUploader__fileLink}
                ref={linkFileRef}
                target="_blank"
                rel="noopener noreferrer"
            />
        </div>
    )
}

export default FileUploader
