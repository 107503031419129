import styles from "./DialogTransferOperatorSearchDropdown.module.scss"
import { ISearchOperatorsByCriterionResponse } from "../../../models/Dialogs/dialog"
import { colorByName } from "../../../helpers/color"
import { useMemo } from "react"
import { TOption } from "../helpers"
import { useTranslation } from "react-i18next"

const tNamespace = "queues:settings"
const testId = "dialogTransferOperatorListItem"

export type TSelectedSearchOperator = {
    OperatorId: string
    Fullname: string
}

export interface IDialogTransferOperatorListItemProps {
    operator: ISearchOperatorsByCriterionResponse
    roles: TOption[]
    onSelect: (operator: TSelectedSearchOperator) => void
    isHimSelf?: boolean
}

export const DialogTransferOperatorListItem: React.FC<IDialogTransferOperatorListItemProps> = props => {
    const {
        onSelect,
        roles = [],
        operator: { OperatorId, Firstname, Lastname, Middlename, RoleId },
        isHimSelf
    } = props

    const { t } = useTranslation()

    const shortName = [Lastname[0], Firstname[0]].join("").toUpperCase()
    const Fullname = [Lastname, Firstname, Middlename].join(" ")

    const handleSelectOperator = () => {
        onSelect({
            Fullname,
            OperatorId
        })
    }

    const roleTitle = useMemo(() => roles.find(x => x.value === RoleId)?.label, [roles, RoleId])

    return (
        <div className={styles.container__operatorsListItem} onClick={handleSelectOperator} data-testid={testId}>
            <div
                className={styles.container__operatorsListItem__thumb}
                style={{ backgroundColor: colorByName(shortName) }}
            >
                {shortName}
            </div>
            <div className={styles.container__operatorsListItem__title}>{Fullname}</div>
            <div className={styles.container__operatorsListItem__role}>
                {!isHimSelf ? roleTitle : t(`${tNamespace}.to-myself`)}
            </div>
        </div>
    )
}
