import React, { useCallback, useEffect, useMemo, useRef } from "react"
import { ClassProps } from "../../utility/common/props"
import Editor, { Props as EditorProps } from "rich-markdown-editor"
import KeyboardNode, { CodeBlock, MarkdownNode, QuickActionsNode } from "./extensions/Keyboard/KeyboardNode"
import { WithT } from "i18next"
import { Dispatch } from "../../utility/common/storeHelper"
import { makeArticleContentEditorDictionary } from "../../utility/articleContentEditor/dictionary"
import { getArticleRedactorEmbeds } from "../../utility/knowledgeBase/articleRedactorEmbeds"
import { articleContentEditorTranslation } from "../../locales/articleContentEditor"
import { uploadFile } from "../../utility/common/files"
import { PageLayoutContentId } from "../PageLayout/PageLayout"
import { getPerformanceObserver, logPerformanceObserverInitializingError } from "../../performance"
import { useParams } from "react-router-dom"
import RichMarkdownEditor from "rich-markdown-editor"
import { setCaretAtStartEnd } from "../../helpers/text"

type Deps = ClassProps & WithT

export interface ContentEditorProps extends Deps {
    allowedFileExtensions?: string[]
    allowedVideoExtensions?: string[]
    allowedAudioExtensions?: string[]
    content: string
    onInit?: () => void
    onDocStateChange?: (getContent: () => string) => void
    onChange?: (getContent: () => string) => void
    isEditable?: boolean
    projectId?: string
    onOpenArticle?: (articleCode: string) => void
    onChangeLocation?: (path: string) => void
    onDispatch?: Dispatch
    disableExtensions?: EditorProps["disableExtensions"]
    onCreateCommands?: (commands: ContentEditorCommands) => void
    externalIdsForSelectionToolbar?: string[]
    channels?: string[]
    articleSymbolCode?: string
    isInArticle?: boolean
    isInChat?: boolean
    initialCarePos?: "start" | "end"
}

// Список команд можно дополнять, исходя из своих нужд, тут не все (их более 30 на самом деле)
export type ContentEditorCommands = {
    link: ({ href }: { href?: string }) => void
    ordered_list: () => void
    bullet_list: () => void
}

export const DEFAULT_EDITOR_DIS_EXT: NonNullable<EditorProps["disableExtensions"]> = [
    "code_block",
    "code_fence",
    "container_notice",
    "emoji",
    "widget",
    "quickactions"
]

const ContentEditor: React.FC<ContentEditorProps> = props => {
    const {
        projectId,
        t,
        onOpenArticle,
        onChangeLocation,
        onDispatch,
        content,
        allowedFileExtensions = [],
        allowedVideoExtensions = [],
        allowedAudioExtensions = [],
        isEditable,
        onInit,
        onDocStateChange,
        onChange,
        className,
        disableExtensions = DEFAULT_EDITOR_DIS_EXT,
        onCreateCommands,
        externalIdsForSelectionToolbar,
        channels,
        articleSymbolCode,
        isInArticle,
        isInChat,
        initialCarePos
    } = props

    const ref = useRef<RichMarkdownEditor>(null)
    const { shareId } = useParams<{ shareId?: string }>()
    const rmeDom = ref.current?.view.dom

    useEffect(() => {
        if (initialCarePos && content && rmeDom) {
            const caretStartPos = initialCarePos === "start"
            setCaretAtStartEnd(rmeDom, caretStartPos)
        }
    }, [content, rmeDom, initialCarePos])

    useEffect(() => {
        let observer: PerformanceObserver | undefined

        try {
            observer = getPerformanceObserver("ContentEditor")
            observer?.observe({ type: "event", buffered: true })
        } catch (e) {
            logPerformanceObserverInitializingError("ContentEditor", e)
        }

        return () => {
            observer?.disconnect()
        }
    }, [])

    const dictionary = useMemo(() => makeArticleContentEditorDictionary(t), [t])

    const embeds = useMemo(
        () => getArticleRedactorEmbeds(t, allowedFileExtensions, allowedVideoExtensions, allowedAudioExtensions),
        [t, allowedFileExtensions, allowedVideoExtensions, allowedAudioExtensions]
    )

    const extensions = useMemo(() => {
        if ((projectId || shareId) && onOpenArticle && onChangeLocation && onDispatch) {
            return [
                new KeyboardNode({
                    projectId,
                    onOpenArticle,
                    onDispatch,
                    t,
                    onChangeLocation,
                    channels,
                    articleSymbolCode
                }),
                new CodeBlock({ projectId, onOpenArticle, onDispatch, t }),
                new MarkdownNode({ projectId, onOpenArticle, onDispatch, t }),
                new QuickActionsNode({ projectId, onOpenArticle, onDispatch, t })
            ]
        } else {
            return []
        }
    }, [projectId, shareId, onOpenArticle, onChangeLocation, onDispatch, t, channels, articleSymbolCode])

    const placeholder = isInArticle
        ? t(articleContentEditorTranslation["newLineBlock"])
        : t(articleContentEditorTranslation["newLineEmpty"])

    const handleCreateCommands = (commands: ContentEditorCommands) => {
        onCreateCommands && onCreateCommands(commands)
    }

    const getValue = useCallback(() => {
        if (!isEditable && isInChat) {
            return t(articleContentEditorTranslation["readOnly"])
        }
        return content ?? "\\" // Simple hack to define value as empty string, breaks nothing
    }, [isEditable, isInChat, content])

    return (
        <Editor
            ref={ref}
            className={className}
            defaultValue={content}
            value={getValue()}
            placeholder={placeholder}
            readOnly={!isEditable}
            autoFocus={isEditable}
            onChange={onChange}
            onDocStateChange={onDocStateChange}
            onInit={onInit}
            uploadImage={isEditable ? uploadFile : undefined}
            uploadFile={isEditable ? uploadFile : undefined}
            uploadVideo={isEditable ? uploadFile : undefined}
            onCreateCommands={handleCreateCommands}
            uploadAduio={isEditable ? uploadFile : undefined}
            allowedFileExtensions={allowedFileExtensions}
            allowedVideoExtensions={allowedVideoExtensions}
            allowedAudioExtensions={allowedAudioExtensions}
            embeds={embeds}
            dictionary={dictionary}
            extensions={extensions}
            disableExtensions={disableExtensions}
            scrollableContainerId={PageLayoutContentId}
            hiddenTopOffset={120}
            externalIdsForSelectionToolbar={externalIdsForSelectionToolbar}
            isInArticle={isInArticle}
        />
    )
}

export default ContentEditor
