import { Channel, ChannelTypeString, WebChatChannel, WhatsappChannelType } from "../../models/channel"
import { AuthType, IFrameMode, WebChatChannelValues } from "../../models/webChatValues"
import {
    getAuthSettings,
    getElement,
    getInitialMessageSettingsRequest,
    getPromoSettingsRequest
} from "./webChatSetting"
import {
    ChannelValues,
    ProviderSettingsValues,
    WhatsappAuthType,
    WhatsAppChannelValues
} from "../../models/channelValues"
import { ParamValues } from "../../models/parameterDeclaration"
import { Time } from "../common/time"
import { inlineEcapedJson } from "../../helpers/json"

export const buildChannelRequest = (projectId: string, type: string, values: ChannelValues): Channel => {
    const Params: ParamValues = {
        ...values.Params,
        ...(type === ChannelTypeString.Email && {
            refresh_interval: values.Params.refresh_interval * 1000 * 60,
            imap_enable_ssl_certificate_check: values.Params.imap_enable_ssl_certificate_check
        }),
        ...(type === ChannelTypeString.Instagram && {
            "disable-score": !values.Params.dialog_score
        }),
        ...(type === ChannelTypeString.Email && {
            "disable-score": !values.Params.enable_score
        })
    }

    switch (type) {
        case ChannelTypeString.Instagram:
            delete Params["dialog_score"]
            break
        case ChannelTypeString.Email:
            delete Params.enable_score
            break
    }

    return {
        Id: values.BotSettings.Id,
        Title: values.Title,
        ProjectId: projectId,
        Type: type,
        BotId: values.BotSettings.BotId,
        Token: values.BotSettings.Token,
        WebHook: values.WebHook,
        PreprocessorWebHook: values.PreprocessorWebHook,
        Params,
        ServerAddress: values.ServerAddress
    }
}

const getWhatsappProviderSettings = (values: ProviderSettingsValues) => {
    switch (values.provider) {
        case WhatsappChannelType.InfoBIP:
            const settings: ParamValues = {
                scenario_key: values.scenario_key,
                host_url: values.host_url
            }
            if (values.authType === WhatsappAuthType.ApiKey) {
                settings.api_key = values.api_key
            } else if (values.authType === WhatsappAuthType.LoginPassword) {
                settings.username = values.username
                settings.password = values.password
            }
            return settings
        case WhatsappChannelType.StreamTel:
            return {
                login: values.login,
                apipassword: values.apipassword,
                sourceAddress: values.sourceAddress
            }
        case WhatsappChannelType.MFMS:
            return {
                subject: values.subject,
                apikey: values.apikey
            }
        default:
            return {}
    }
}

export const buildWhatsappChannelRequest = (projectId: string, values: WhatsAppChannelValues): Channel => {
    const {
        login,
        apipassword,
        sourceAddress,
        username,
        password,
        host_url,
        scenario_key,
        api_key,
        apikey,
        subject,
        ...params
    } = values.Params

    return {
        Id: values.BotSettings.Id,
        Title: values.Title,
        ProjectId: projectId,
        Type: values.ProviderSettings.provider,
        BotId: values.BotSettings.BotId,
        Token: values.BotSettings.Token,
        WebHook: values.WebHook,
        PreprocessorWebHook: values.PreprocessorWebHook,
        ServerAddress: values.ServerAddress,
        Params: {
            ...params,
            ...getWhatsappProviderSettings(values.ProviderSettings)
        }
    }
}

export const buildWebChatChannelRequest = (projectId: string, values: WebChatChannelValues): WebChatChannel => {
    const request = buildChannelRequest(projectId, ChannelTypeString.WebChat, values)

    const { externalPushServiceUrl, ...pushMessageClientConfig } = values.PushMessageSettings
    const { ...pushMessageSettings } = values.MessageSettings
    pushMessageClientConfig.pushMessageTimeout = Time.secondsToMs(pushMessageClientConfig.pushMessageTimeout)
    pushMessageClientConfig.subscriptionRequestDelay = Time.hoursToMs(pushMessageClientConfig.subscriptionRequestDelay)
    pushMessageSettings.userTypingInterval = Time.secondsToMs(pushMessageSettings.userTypingInterval)

    const {
        allowedFileNameRegex,
        uploadSizeLimitValue,
        uploadSizeLimitUnit,
        captchaSecretKey,
        captchaFileCount,
        oversizedFileErrorMessage,
        maxFileCount,
        maxFileCountErrorMessage,
        securedFileLinks,
        ...fileClientConfig
    } = values.FileSettings

    const { ratingAliasesEnabled, ratingAliases, ...dialogEvaluationClientConfig } = values.DialogEvaluationSettings
    const {
        rootElementValue,
        rootElementType,
        openChatButtonSelectorValue,
        openChatButtonSelectorType,
        iFrameMode,
        iFrameHost,
        debug,
        ...systemClientConfig
    } = values.SystemSettings

    const syncEnabled = values.AuthSettings.type === AuthType.JavaScriptAuthorization && values.SyncSettings.enabled
    return {
        ...request,
        Params: {
            ...request.Params,
            cobrowsing_permission: values.SystemSettings.cobrowsingEnabled,
            file_max_size_mb: uploadSizeLimitValue,
            file_max_size_error_message: oversizedFileErrorMessage,
            max_file_count: maxFileCount,
            max_file_count_error_message: maxFileCountErrorMessage
        },
        MainConfig: {
            mediatorId: syncEnabled ? values.SyncSettings.mediatorId : values.BotSettings.Id,
            namespace: values.SyncSettings.namespace,
            namespaceCobrowsing: values.SyncSettings.namespaceCobrowsing,
            ...getAuthSettings(values.AuthSettings),
            historyMessageCount: values.HistorySettings.historyMessageCount,
            historyFromActiveDialog: values.HistorySettings.historyFromActiveDialog,
            externalPushServiceUrl,
            requireAuthData: values.AuthAdvanced.requireAuthData,
            ...(debug && { debug }),
            allowedFileNameRegex,
            captchaSecretKey,
            captchaFileCount,
            securedFileLinks
        },
        ClientConfig: {
            ...values.AppearanceSettings,
            showHistory: values.HistorySettings.showHistory,
            ...pushMessageClientConfig,
            ...values.OperatorSettings,
            ...values.InterfaceSettings,
            ...getPromoSettingsRequest(values.PromoSettings),
            phoneNumberMask: values.AuthAdvanced.phoneNumberMask,
            allowedSymbolsRegexp: values.AuthAdvanced.allowedSymbolsRegexp,
            clearNonValidUserData: values.AuthAdvanced.clearNonValidUserData,
            userFormFields: inlineEcapedJson(values.AuthAdvanced.userFormFields),
            usePersonalDataAgreement: values.AuthAdvanced.usePersonalDataAgreement,
            personalDataText: values.AuthAdvanced.personalDataText,
            authButtonText: values.AuthAdvanced.authButtonText,
            ...dialogEvaluationClientConfig,
            ...values.BlockSettings,
            ...getInitialMessageSettingsRequest(values.InitialMessageSettings),
            uploadSizeLimit: uploadSizeLimitValue ? uploadSizeLimitValue + uploadSizeLimitUnit : "",
            oversizedFileErrorMessage: oversizedFileErrorMessage,
            ratingAliases: ratingAliasesEnabled ? ratingAliases : [],
            ...systemClientConfig,
            customTransport: JSON.stringify([values.SystemSettings.customTransport]),
            rootElement: getElement(rootElementValue, rootElementType),
            openChatButtonSelector: getElement(openChatButtonSelectorValue, openChatButtonSelectorType),
            iFrameMode: iFrameMode === IFrameMode.specifiedHost ? iFrameHost : iFrameMode,
            ...fileClientConfig,
            ...pushMessageSettings
        }
    }
}
