import filesController from "../../api/controllers/files"

export const uploadFile = async (file: File) => {
    const url = await filesController.uploadFileB64({
        Name: file.name,
        Content: file
    })
    return url
}

export const getFileExt = (url: string): string => url.slice(url.lastIndexOf(".") + 1, url.length)

export const fileEndings = ["B", "KB", "MB", "GB", "TB"]

// Getting size in bytes
export const getBeautifiedFileSize = (size: number): string => {
    let resultStr = `${size} B`

    for (let i = 0; i < fileEndings.length; i++) {
        const currEndingIndex = fileEndings.length - i - 1
        const currSize = Math.floor(size / 1024 ** currEndingIndex)
        if (currSize > 0) {
            resultStr = `${currSize} ${fileEndings[currEndingIndex]}`
            return resultStr
        }
    }
    return resultStr
}
