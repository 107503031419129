import React, { useEffect } from "react"
import "./ProjectSidebar.scss"
import MenuItemComponent from "../MenuItem/MenuItem"
import { MenuItem } from "../../models/menu"
import ProjectSelector, { ProjectSelectorLoading } from "../ProjectSelector/ProjectSelector"
import MenuItemUser from "../MenuItemUser/MenuItemUser"
import Sidebar from "../Sidebar/Sidebar"
import { useDispatch, useSelector } from "react-redux"
import { selectCurrentProject, selectFrameLoadingState, selectOtherProjects } from "../../store/projects/selectors"
import { push } from "connected-react-router"
import { projectPath } from "../../routerPaths"
import { selectCurrentMainMenu } from "../../store/menu/selectors"
import { selectCurrentUser } from "../../store/users/selectors"
import { useTranslation } from "react-i18next"
import MenuItemRest from "../MenuItemRest/MenuItemRest"
import OperatorStatusSelector, { OperatorStatusSelectorLoading } from "../OperatorStatusSelector/OperatorStatusSelector"
import { selectOperatorStatuses, selectOperatorStatusState } from "../../store/userOperator/selectors"
import { updateOperatorStatus } from "../../store/userOperator/thunks"
import AsyncWithoutError from "../Async/AsyncWithoutError"
import UpdateAppButton from "../UpdateAppButton/UpdateAppButton"

const tNamespace = "project:"

export interface ProjectSidebarProps {
    dialogMenu?: MenuItem
    userProfile?: MenuItem
    mainMenuItems: MenuItem[]
    menuItemsRest: MenuItem[]
    onToggleSidebar?: () => void
    selectMenu: (menuItemId: string) => void
}

const ProjectSidebar: React.FC<ProjectSidebarProps> = props => {
    const { mainMenuItems, menuItemsRest, onToggleSidebar, selectMenu, dialogMenu, userProfile } = props
    const { t, i18n } = useTranslation()
    const dispatch = useDispatch()
    const operatorStatusState = useSelector(selectOperatorStatusState)
    const frameState = useSelector(selectFrameLoadingState)
    const operatorStatuses = useSelector(selectOperatorStatuses)
    const selectedMainMenu = useSelector(selectCurrentMainMenu)
    const user = useSelector(selectCurrentUser)
    const selectedProject = useSelector(selectCurrentProject)
    const nonSelectedProjects = useSelector(selectOtherProjects)

    const getSelectedMenu = (menuId: string) => (selectedMainMenu ? selectedMainMenu === menuId : false)
    const selectProjectUrl = (projectId: string) => {
        dispatch(push(`${projectPath}/${projectId}/${selectedMainMenu || ""}`))
    }

    useEffect(() => {
        const handleChangeMenuMessage = (e: MessageEvent) => {
            const menuItem = e.data.menuItem

            if (menuItem) {
                selectMenu(menuItem)
            }
        }

        window.addEventListener("message", handleChangeMenuMessage)

        return () => window.removeEventListener("message", handleChangeMenuMessage)
    }, [selectMenu])

    if (!user) return null

    return (
        <Sidebar
            className="project-sidebar"
            header={
                <>
                    {dialogMenu && (
                        <MenuItemComponent
                            id={dialogMenu.id}
                            title={t(`${tNamespace}${dialogMenu.title}`)}
                            isSelected={getSelectedMenu(dialogMenu.id)}
                            onClick={() => selectMenu(dialogMenu.id)}
                            testId={dialogMenu.id}
                        />
                    )}
                    <AsyncWithoutError
                        dataState={operatorStatusState}
                        processView={<OperatorStatusSelectorLoading t={t} />}
                    >
                        {({ data }) => (
                            <OperatorStatusSelector
                                currentStatus={data}
                                statuses={operatorStatuses}
                                onChange={status => dispatch(updateOperatorStatus(status))}
                            />
                        )}
                    </AsyncWithoutError>
                </>
            }
            content={
                <>
                    {selectedProject && nonSelectedProjects && !frameState.inProcess ? (
                        <ProjectSelector
                            selectedProject={selectedProject}
                            nonSelectedProject={nonSelectedProjects}
                            selectProject={selectProjectUrl}
                        />
                    ) : (
                        <ProjectSelectorLoading t={t} />
                    )}
                    {selectedProject &&
                        mainMenuItems.map(menuItem => (
                            <MenuItemComponent
                                id={menuItem.id}
                                key={menuItem.id}
                                title={
                                    i18n.exists(`${tNamespace}${menuItem.title}`)
                                        ? t(`${tNamespace}${menuItem.title}`)
                                        : menuItem.title
                                }
                                isSelected={getSelectedMenu(menuItem.id)}
                                onClick={() => selectMenu(menuItem.id)}
                                testId={menuItem.id}
                                icon={menuItem.icon}
                            />
                        ))}
                    <MenuItemRest
                        onClick={selectMenu}
                        getSelectedMenu={getSelectedMenu}
                        items={selectedProject ? menuItemsRest : []}
                        className="project-sidebar__rest"
                        onToggleSidebar={onToggleSidebar}
                    />
                </>
            }
            footer={
                <>
                    <UpdateAppButton t={t} />
                    {userProfile && (
                        <MenuItemUser
                            user={user}
                            selectMenu={selectMenu}
                            getSelectedMenu={getSelectedMenu}
                            className="project-sidebar__user-menu"
                        />
                    )}
                </>
            }
        />
    )
}

export default ProjectSidebar
