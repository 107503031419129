import React, { Ref } from "react"
import { useTranslation } from "react-i18next"
import { format } from "date-fns"
import cn from "classnames"

import IdField from "../../../../IdField/IdField"
import Rating from "../Rating/Rating"
import { DialogFinishMessage } from "../../../../../models/Dialogs/message"
import { getDateLocale } from "../../../../../locales"
import { getLanguage } from "../../../../../utility/common/language"
import styles from "./MessageEndDialog.module.scss"

export type MessageEndDialogProps = {
    message: DialogFinishMessage
    className?: string
    lastMessageRef?: Ref<HTMLDivElement>
}

const tNamespace = "dialogs:closing."

const MessageEndDialog = (props: MessageEndDialogProps) => {
    const { message, className, lastMessageRef } = props
    const { i18n, t } = useTranslation()
    const locale = { locale: getDateLocale(getLanguage(i18n)) }

    const text = `${t(`${tNamespace}status`)} ${format(new Date(message.Timestamp), "dd-MM-yyyy HH:mm", locale)}`

    return (
        <div ref={lastMessageRef} className={cn(styles.messageEndDialog, className)}>
            <IdField id={message.DialogId} className={styles.messageEndDialog__id} />
            <div className={styles.messageEndDialog__text}>{text}</div>
            {message.Score && <Rating score={message.Score} />}
        </div>
    )
}

export default MessageEndDialog
