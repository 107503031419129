import React, { useCallback, useMemo, useState } from "react"
import { nameof2, nameof3 } from "../../../utility/common/nameof"
import { InitialMessageMode, InitialMessageValues, WebChatChannelValues } from "../../../models/webChatValues"
import FormAccordion from "../../FormAccordion/FormAccordion"
import { useTranslation } from "react-i18next"
import RadioButtonValidatableInput from "../../RadioButtonValidatableInput/RadioButtonValidatableInput"
import { useFormikContext } from "formik"
import DebouncedValidatableInput from "../../ValidatableInput/DebouncedValidatableInput"
import { reorder } from "../../../utility/scenario/scenario"
import { DropResult } from "react-beautiful-dnd"
import { InitialButtonsEditor } from "../../InitialButtonsEditor/InitialButtonsEditor"
import { InitialMessageText } from "../../../models/webChatConfig"
import { formatButtons } from "../../../utility/channels/webChatinInitialMessageFormat"
import FileUploader from "../../FileUploader/FileUploader"
import { useDispatch, useSelector } from "react-redux"
import { selectCurrentProjectId } from "../../../store/projects/selectors"
import { selectAgents } from "../../../store/agents/selectors"
import InputWithDropMenuAndToggle, { DropMenuOption } from "../../InputWithDropMenuAndToggle/InputWithDropMenuAndToggle"
import { getJsAgentsId } from "../../../utility/channels/getJsAgentsId"
import { formTranslation } from "../../../locales/form"

const tNamespace = "channel:form."

export interface InitialButton {
    Id: string
    action_text: string
    action_id: string
}

export interface initialWebhookOption extends DropMenuOption {
    value: string
    label: string
    useJsAgent: boolean
}

const WebChatInitialMessageSettings: React.FC = () => {
    const { t } = useTranslation()
    const { values, setFieldValue } = useFormikContext<WebChatChannelValues>()
    const actions = values.InitialMessageSettings.initialMessageText.actions
        ? formatButtons(values.InitialMessageSettings.initialMessageText.actions)
        : []
    const [buttons, setButtons] = useState(actions)
    const projectId = useSelector(selectCurrentProjectId)
    const dispatch = useDispatch()
    const agents = useSelector(selectAgents)

    const agentOptions = agents ? getJsAgentsId(agents) : []

    const initialMessageTextDisabled = useMemo(
        () => values.InitialMessageSettings.mode !== InitialMessageMode.showInitialMessage,
        [values.InitialMessageSettings.mode]
    )
    const [allowedInitialFromWebhookEnabled, setAllowedInitialFromWebhookEnabled] = useState(
        values.InitialMessageSettings.getInitialMessageFromWebhook
    )
    const handleWebhookInitialToggle = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const checked = e.target.checked
            setAllowedInitialFromWebhookEnabled(checked)
            if (!checked) {
                setFieldValue(
                    nameof2<WebChatChannelValues, InitialMessageValues>(
                        "InitialMessageSettings",
                        "getInitialMessageFromWebhook"
                    ),
                    false,
                    false
                )
            } else {
                setFieldValue(
                    nameof2<WebChatChannelValues, InitialMessageValues>(
                        "InitialMessageSettings",
                        "getInitialMessageFromWebhook"
                    ),
                    true,
                    false
                )
            }
        },
        [setFieldValue]
    )
    const handleCreateOption = (option: DropMenuOption): initialWebhookOption => {
        const newOption: initialWebhookOption = { ...option, useJsAgent: false }
        return newOption
    }

    const onDragEnd = useCallback(
        (result: DropResult) => {
            if (!result.destination || result.destination.index === result.source.index) return
            const updateButtons = reorder(buttons, result.source.index, result.destination.index)
            setButtons(updateButtons)
            setFieldValue(
                nameof3<WebChatChannelValues, InitialMessageValues, InitialMessageText>(
                    "InitialMessageSettings",
                    "initialMessageText",
                    "actions"
                ),
                updateButtons,
                false
            )
        },
        [buttons]
    )
    return (
        <FormAccordion title={t(`${tNamespace}initial-message`)}>
            <RadioButtonValidatableInput
                value={InitialMessageMode.sendInitialMessageOnOpen}
                id="formInitialMessageSettings1"
                name={nameof2<WebChatChannelValues, InitialMessageValues>("InitialMessageSettings", "mode")}
                label={t(`${tNamespace}initial-message-when-opening-chat`)}
            />
            <RadioButtonValidatableInput
                value={InitialMessageMode.sendInitialMessageOnStartDialog}
                id="formInitialMessageSettings2"
                name={nameof2<WebChatChannelValues, InitialMessageValues>("InitialMessageSettings", "mode")}
                label={t(`${tNamespace}initial-message-on-dialog-start`)}
            />
            <RadioButtonValidatableInput
                value={InitialMessageMode.showInitialMessage}
                id="formInitialMessageSettings3"
                name={nameof2<WebChatChannelValues, InitialMessageValues>("InitialMessageSettings", "mode")}
                label={t(`${tNamespace}static-initial-message`)}
            />
            <div className="extra-settings">
                <InputWithDropMenuAndToggle
                    id={"formGetInitialMessageFromWebhook"}
                    name={nameof2<WebChatChannelValues, InitialMessageValues>(
                        "InitialMessageSettings",
                        "initialMessageWebhook"
                    )}
                    isEnabled={allowedInitialFromWebhookEnabled}
                    options={agentOptions}
                    onToggle={handleWebhookInitialToggle}
                    onCreateOption={handleCreateOption}
                    disabled={initialMessageTextDisabled}
                    placeholder={t(`${tNamespace}initilal-from-webhook-placeholder`)}
                    label={t(`${tNamespace}initilal-from-webhook-label`)}
                    withAdd
                />
                <DebouncedValidatableInput
                    id="formOperatorInitialName"
                    as="textarea"
                    name={nameof2<WebChatChannelValues, InitialMessageValues>(
                        "InitialMessageSettings",
                        "initialMessageOperatorName"
                    )}
                    label={t(`${tNamespace}static-welcome-message-operator`)}
                    className="webchat-form__welcome-message-operator"
                    disabled={initialMessageTextDisabled}
                    type="string"
                />
                <DebouncedValidatableInput
                    id="formPromoWelcomeMessage"
                    as="textarea"
                    name={nameof3<WebChatChannelValues, InitialMessageValues, InitialMessageText>(
                        "InitialMessageSettings",
                        "initialMessageText",
                        "text"
                    )}
                    label={t(`${tNamespace}static-welcome-message-text`)}
                    className="webchat-form__welcome-message-text"
                    placeholder={t(formTranslation.enterText)}
                    disabled={initialMessageTextDisabled || allowedInitialFromWebhookEnabled}
                    type="string"
                />
                <InitialButtonsEditor
                    buttons={buttons}
                    onUpdateButtons={setButtons}
                    onDragEnd={onDragEnd}
                    disabled={initialMessageTextDisabled || allowedInitialFromWebhookEnabled}
                    projectId={projectId}
                    dispatch={dispatch}
                />
                <FileUploader
                    name={nameof3<WebChatChannelValues, InitialMessageValues, InitialMessageText>(
                        "InitialMessageSettings",
                        "initialMessageText",
                        "attachment"
                    )}
                    disabled={initialMessageTextDisabled || allowedInitialFromWebhookEnabled}
                />
            </div>
        </FormAccordion>
    )
}

export default WebChatInitialMessageSettings
