import React, { FormEvent, useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import FormContent from "../../FormHelpers/Content/FormContent"
import FormSection from "../../FormHelpers/Section/FormSection"
import { nameof2, nameof3 } from "../../../utility/common/nameof"
import { AgentProjectSettings } from "../../../models/projectSettings"
import DebouncedCheckBox from "../../CheckBoxValidatableInput/DebouncedCheckBox"
import FormSubSection from "../../FormHelpers/SubSection/FormSubSection"
import DebouncedValidatableInput from "../../ValidatableInput/DebouncedValidatableInput"
import AgentSelectControl from "../AgentSelectControl"
import { Formik, FormikProps } from "formik"
import {
    DialogSettingsValues,
    DialogTimeoutProjectSettingsValues,
    TimeoutSettingsValues
} from "../../../models/projectSettingsValues"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { selectProjectSettings, selectUpdateProjectSettingsState } from "../../../store/projects/selectors"
import ProjectSettingsLayout from "../../ProjectSettingsLayout/ProjectSettingsLayout"
import TimeoutControl from "../TimeoutControl"
import FormSectionTitle from "../../FormHelpers/SectionTitle/FormSectionTitle"
import { getAgents } from "../../../store/agents/thunks"
import { formTranslation } from "../../../locales/form"
import { useDifferenceCount } from "../../../utility/project/projectSettings"
import { Form } from "react-bootstrap"
import { preventSubmitOnEnter } from "../../../utility/common/preventSubmitOnEnter"
import { updateProjectSettings } from "../../../store/projects/thunks"
import {
    buildUpdateDialogProjectSettingsRequest,
    defaultDialogProjectSettings,
    getDialogSettingsInitialValues
} from "./helpers"
import { testId } from "../../../utility/tests/testId"

const tNamespace = "project:project-settings.dialog."

interface FormProps extends FormikProps<DialogSettingsValues> {
    onChange: (e: FormEvent<HTMLFormElement>, initialValues: DialogSettingsValues, values: DialogSettingsValues) => void
}

const FormikDialogProjectSettingsForm: React.FC<FormProps> = props => {
    const { t } = useTranslation()
    const { onChange, initialValues, values } = props
    return (
        <Form
            onChange={e => onChange(e, initialValues, values)}
            data-test-id={testId.projectDialogSettingsForm}
            onKeyPress={preventSubmitOnEnter}
        >
            <ProjectSettingsLayout.Section border>
                <FormContent>
                    <FormSection>
                        <FormSection>
                            <FormSectionTitle>{t(`${tNamespace}dialog-timeout`)}</FormSectionTitle>
                        </FormSection>

                        <TimeoutControl
                            id="formFirstTimeoutValue"
                            name={nameof3<
                                DialogSettingsValues,
                                DialogTimeoutProjectSettingsValues,
                                TimeoutSettingsValues
                            >("DialogTimeout", "FirstTimeout", "Value")}
                            label={t(`${tNamespace}first-timeout-value`)}
                        />
                        <DebouncedCheckBox
                            id="formFirstTimeoutMessageEnabled"
                            name={nameof3<
                                DialogSettingsValues,
                                DialogTimeoutProjectSettingsValues,
                                TimeoutSettingsValues
                            >("DialogTimeout", "FirstTimeout", "MessageEnabled")}
                            label={t(`${tNamespace}message`)}
                        />
                        <FormSubSection>
                            <DebouncedValidatableInput
                                as="textarea"
                                id="formFirstTimeoutMessage"
                                type="text"
                                name={nameof3<
                                    DialogSettingsValues,
                                    DialogTimeoutProjectSettingsValues,
                                    TimeoutSettingsValues
                                >("DialogTimeout", "FirstTimeout", "Message")}
                                disabled={!values.DialogTimeout.FirstTimeout.MessageEnabled}
                            />
                        </FormSubSection>
                    </FormSection>
                </FormContent>
            </ProjectSettingsLayout.Section>
            <ProjectSettingsLayout.Section border>
                <FormContent>
                    <FormSection>
                        <TimeoutControl
                            id="formSecondTimeoutValue"
                            name={nameof3<
                                DialogSettingsValues,
                                DialogTimeoutProjectSettingsValues,
                                TimeoutSettingsValues
                            >("DialogTimeout", "SecondTimeout", "Value")}
                            label={t(`${tNamespace}second-timeout-value`)}
                        />
                        <DebouncedCheckBox
                            id="formSecondTimeoutMessageEnabled"
                            name={nameof3<
                                DialogSettingsValues,
                                DialogTimeoutProjectSettingsValues,
                                TimeoutSettingsValues
                            >("DialogTimeout", "SecondTimeout", "MessageEnabled")}
                            label={t(`${tNamespace}message`)}
                        />
                        <FormSubSection>
                            <DebouncedValidatableInput
                                as="textarea"
                                id="formSecondTimeoutMessage"
                                type="text"
                                name={nameof3<
                                    DialogSettingsValues,
                                    DialogTimeoutProjectSettingsValues,
                                    TimeoutSettingsValues
                                >("DialogTimeout", "SecondTimeout", "Message")}
                                disabled={!values.DialogTimeout.SecondTimeout.MessageEnabled}
                            />
                        </FormSubSection>
                    </FormSection>

                    <FormSection>
                        <TimeoutControl
                            id="formFinishDialogTimeoutValue"
                            name={nameof3<
                                DialogSettingsValues,
                                DialogTimeoutProjectSettingsValues,
                                TimeoutSettingsValues
                            >("DialogTimeout", "FinishDialogTimeout", "Value")}
                            label={t(`${tNamespace}finish-timeout-value`)}
                        />
                        <DebouncedCheckBox
                            id="formFinishDialogTimeoutMessageEnabled"
                            name={nameof3<
                                DialogSettingsValues,
                                DialogTimeoutProjectSettingsValues,
                                TimeoutSettingsValues
                            >("DialogTimeout", "FinishDialogTimeout", "MessageEnabled")}
                            label={t(`${tNamespace}message`)}
                        />
                        <FormSubSection>
                            <DebouncedValidatableInput
                                as="textarea"
                                id="formFinishDialogTimeoutMessage"
                                type="text"
                                name={nameof3<
                                    DialogSettingsValues,
                                    DialogTimeoutProjectSettingsValues,
                                    TimeoutSettingsValues
                                >("DialogTimeout", "FinishDialogTimeout", "Message")}
                                disabled={!values.DialogTimeout.FinishDialogTimeout.MessageEnabled}
                            />
                        </FormSubSection>
                    </FormSection>
                </FormContent>
            </ProjectSettingsLayout.Section>

            <ProjectSettingsLayout.Section border>
                <FormContent>
                    <FormSection>
                        <TimeoutControl
                            id="formDialogLifeTimeoutValue"
                            name={nameof3<
                                DialogSettingsValues,
                                DialogTimeoutProjectSettingsValues,
                                TimeoutSettingsValues
                            >("DialogTimeout", "DialogLifeTimeout", "Value")}
                            label={t(`${tNamespace}dialog-life-timeout-value`)}
                        />
                        <TimeoutControl
                            id="formDialogInQueueTimeout"
                            name={nameof3<
                                DialogSettingsValues,
                                DialogTimeoutProjectSettingsValues,
                                TimeoutSettingsValues
                            >("DialogTimeout", "DialogInQueueTimeout", "Value")}
                            label={t(`${tNamespace}dialog-in-queue-timeout`)}
                        />
                        <DebouncedCheckBox
                            id="formDialogInQueueTimeoutMessageEnabled"
                            name={nameof3<
                                DialogSettingsValues,
                                DialogTimeoutProjectSettingsValues,
                                TimeoutSettingsValues
                            >("DialogTimeout", "DialogInQueueTimeout", "MessageEnabled")}
                            label={t(`${tNamespace}message`)}
                        />
                        <FormSubSection>
                            <DebouncedValidatableInput
                                as="textarea"
                                id="formDialogInQueueTimeoutMessage"
                                type="text"
                                name={nameof3<
                                    DialogSettingsValues,
                                    DialogTimeoutProjectSettingsValues,
                                    TimeoutSettingsValues
                                >("DialogTimeout", "DialogInQueueTimeout", "Message")}
                                disabled={!values.DialogTimeout.DialogInQueueTimeout.MessageEnabled}
                            />
                        </FormSubSection>
                    </FormSection>
                </FormContent>
            </ProjectSettingsLayout.Section>
            <ProjectSettingsLayout.Section>
                <FormContent>
                    <FormSection>
                        <FormSection>
                            <FormSectionTitle>{t(`${tNamespace}agents`)}</FormSectionTitle>
                        </FormSection>

                        <AgentSelectControl
                            id="formDefaultAgent"
                            name={nameof2<DialogSettingsValues, AgentProjectSettings>("Agent", "DefaultAgent")}
                            label={t(`${tNamespace}default-agent`)}
                        />
                        <AgentSelectControl
                            id="formDefaultAgentForOutgoingDialog"
                            name={nameof2<DialogSettingsValues, AgentProjectSettings>(
                                "Agent",
                                "DefaultAgentForOutgoingDialog"
                            )}
                            label={t(`${tNamespace}default-agent-for-outgoing-dialog`)}
                        />
                        <AgentSelectControl
                            id="formOperatorAgent"
                            name={nameof2<DialogSettingsValues, AgentProjectSettings>("Agent", "OperatorAgent")}
                            label={t(`${tNamespace}operator-agent`)}
                        />
                        <AgentSelectControl
                            id="formSystemEventsAgent"
                            name={nameof2<DialogSettingsValues, AgentProjectSettings>("Agent", "SystemEventsAgent")}
                            label={t(`${tNamespace}system-events-agent`)}
                        />
                    </FormSection>
                </FormContent>
            </ProjectSettingsLayout.Section>
        </Form>
    )
}

interface Props {
    projectId: string
    title: string
    open: boolean
}

const DialogProjectSettingsForm: React.FC<Props> = props => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { projectId, title, open } = props
    const settings = useSelector(selectProjectSettings)
    const updateProjectSettingsState = useSelector(selectUpdateProjectSettingsState, shallowEqual)

    const { differenceCount, onOpenSettings, onChange, reset } = useDifferenceCount()
    const initialValues = useMemo(() => settings && getDialogSettingsInitialValues(settings), [settings])

    useEffect(() => {
        dispatch(getAgents(projectId))
    }, [dispatch, projectId])

    useEffect(() => {
        if (open && settings) {
            onOpenSettings(defaultDialogProjectSettings, {
                DialogTimeout: settings?.DialogTimeout,
                Agent: settings?.Agent
            })
        }
    }, [onOpenSettings, settings, open])

    if (!initialValues) return null

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values: DialogSettingsValues) => {
                dispatch(updateProjectSettings(projectId, buildUpdateDialogProjectSettingsRequest(values), reset))
            }}
        >
            {formikProps => (
                <ProjectSettingsLayout.Container
                    title={title}
                    onButtonClick={formikProps.handleSubmit}
                    buttonText={t(formTranslation.save)}
                    hideButton={!differenceCount}
                    notifications={differenceCount}
                    loading={updateProjectSettingsState.inProcess}
                >
                    <FormikDialogProjectSettingsForm {...formikProps} onChange={onChange} />
                </ProjectSettingsLayout.Container>
            )}
        </Formik>
    )
}

export default DialogProjectSettingsForm
