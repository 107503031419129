import { Task, TaskDto, TaskOfAutomaticProcessing, TaskOfAutomaticProcessingDto, TaskStatus } from "../../models/task"
import { ChannelTypeString, SimpleChannel, SimpleChannelDto } from "../../models/channel"
import { channelTypeStringConverter } from "../channels/channelTypeStringConverter"

export const taskConverter = {
    fromAutomaticProcessing(value: TaskOfAutomaticProcessing): Task {
        const getChannel = (value: TaskOfAutomaticProcessing) => {
            return {
                Id: value.ChannelId,
                Type: value.ChannelType as ChannelTypeString,
                Title: value.ChannelId
            }
        }
        return {
            Id: value.Id,
            Type: "Dialog",
            Status: TaskStatus.Pending,
            Channel: getChannel(value),
            CreatedAt: value.StartedAt,
            AssignedAt: value.RoutedAt,
            TenantId: value.TenantId,
            Client: value.Client
        }
    },
    toTaskModel(taskDto: TaskDto): Task {
        const convertChannelType = (channelDto: SimpleChannelDto): SimpleChannel => {
            return {
                ...channelDto,
                Type: channelTypeStringConverter.toChannelTypeString(channelDto.Type)
            }
        }
        return {
            ...taskDto,
            Channel: convertChannelType(taskDto.Channel)
        }
    },
    toTaskOfAutomaticProcessingModel(task: TaskOfAutomaticProcessingDto): TaskOfAutomaticProcessing {
        return {
            ...task,
            ChannelType: channelTypeStringConverter.toChannelTypeString(task.ChannelType)
        }
    }
}
