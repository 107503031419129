import React from "react"
import styles from "./InputWithDropMenuAndToggle.module.scss"
import { Form, InputGroup } from "react-bootstrap"
import { ClassProps } from "../../utility/common/props"
import Toggle from "../Toggle/Toggle"
import { useField } from "formik"
import { useTranslation } from "react-i18next"
import { CreatableSelect, Select } from "../Select/Select"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCaretDown } from "@fortawesome/pro-light-svg-icons/faCaretDown"
import { formTranslation } from "../../locales/form"
import { ValueType } from "react-select"

export interface DropMenuOption {
    value: string
    label: string
}

export interface InputWithDropMenuAndToggleProps extends ClassProps {
    id: string
    name: string
    type?: string
    readonly?: boolean
    plaintext?: boolean
    label?: string
    isEnabled?: boolean
    options: DropMenuOption[]
    onToggle: (e: React.ChangeEvent<HTMLInputElement>) => void
    onCreateOption?: (option: DropMenuOption) => void
    disabled?: boolean
    placeholder: string
    withAdd?: boolean
    onChange?: (option: ValueType<DropMenuOption, false>) => void
}

const DropdownIndicator: React.FC = () => {
    return (
        <FontAwesomeIcon className={styles.inputWithDropMenuAndToggle__dropdownIcon} icon={faCaretDown} size={"lg"} />
    )
}

const InputWithDropMenuAndToggle: React.FC<InputWithDropMenuAndToggleProps> = props => {
    const {
        id,
        label,
        className,
        isEnabled,
        onToggle,
        options,
        onCreateOption,
        disabled,
        name,
        placeholder,
        withAdd,
        onChange,
        ...controlProps
    } = props
    const [field, meta, { setValue }] = useField(name)
    const { t } = useTranslation()
    const blocked = isEnabled === undefined ? false : !isEnabled
    const getLabel = () => {
        const option = options.find(option => option.value === field.value)
        return option ? option.label : placeholder
    }
    const isDisabled = disabled || blocked
    const components = {
        IndicatorSeparator: null,
        DropdownIndicator: DropdownIndicator
    }
    return (
        <Form.Group className={styles.inputWithDropMenuAndToggle} controlId={id}>
            {label && <Form.Label>{label}</Form.Label>}
            <InputGroup className={styles.inputWithDropMenuAndToggle__wrap}>
                <InputGroup.Prepend>
                    <InputGroup.Text>
                        <Toggle
                            id={`${id}-toggle`}
                            icons={false}
                            checked={isEnabled}
                            onChange={onToggle}
                            disabled={disabled}
                            small
                        />
                    </InputGroup.Text>
                </InputGroup.Prepend>
                {withAdd ? (
                    <CreatableSelect
                        components={components}
                        className={styles.inputWithDropMenuAndToggle__inputSelect}
                        isDisabled={isDisabled}
                        options={options}
                        onChange={option => {
                            setValue(option)
                        }}
                        value={field.value}
                        onCreateOption={(inputValue: string) => {
                            const option: DropMenuOption = { value: inputValue, label: inputValue }
                            const newOption = onCreateOption ? onCreateOption(option) : option
                            setValue(newOption)
                        }}
                        placeholder={placeholder}
                        name={name}
                        formatCreateLabel={(inputValue: string) => `${t(formTranslation.add)}: ${inputValue}`}
                        openMenuOnFocus={true}
                    />
                ) : (
                    <Select
                        components={components}
                        name={name}
                        options={options}
                        isDisabled={isDisabled}
                        placeholder={placeholder}
                        onChange={onChange}
                        className={styles.inputWithDropMenuAndToggle__inputSelect}
                        noOptionsMessage={() => `${t(formTranslation.noResultsFound)}`}
                        value={{ label: getLabel(), value: field.value }}
                    />
                )}
            </InputGroup>
        </Form.Group>
    )
}

export default InputWithDropMenuAndToggle
