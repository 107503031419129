import React, { useCallback, useEffect, useRef, useState } from "react"
import styles from "./DialogMessageInput.module.scss"
import { User } from "../../../models/user"
import { FormikProvider, useFormik } from "formik"
import { Button, Form } from "react-bootstrap"
import { ClassProps } from "../../../utility/common/props"
import UserCard from "../../UserCard/UserCard"
import { preventSubmitOnEnter } from "../../../utility/common/preventSubmitOnEnter"
import { useTranslation } from "react-i18next"
import { formTranslation } from "../../../locales/form"
import { FormikHelpers, FormikProps } from "formik/dist/types"
import { useGetAllowedFileExtensions } from "../../../utility/knowledgeBase/articleRedactorEmbeds"
import { useHotkeys } from "react-hotkeys-hook"
import { testId } from "../../../utility/tests/testId"
import IconButton from "../../IconButton/IconButton"
import { faPaperPlaneTop } from "../../../assets/images/font-awesome-exported/faPaperPlaneTop"
import ContentEditor, { ContentEditorCommands, DEFAULT_EDITOR_DIS_EXT } from "../../ContentEditor/ContentEditor"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    faExclamationCircle,
    faImage,
    faLink,
    faListOl,
    faListUl,
    faPaperclip,
    faPauseCircle
} from "@fortawesome/pro-light-svg-icons"
import { uploadFile } from "../../../utility/common/files"
import { MessageAttachment } from "../../../models/Dialogs/message"
import TooltipTrigger from "../../TooltipTrigger/TooltipTrigger"
import { actions } from "../../../store/dialogs/slice"
import { useProjectSettingCheck } from "../../../utility/common/useProjectSettingCheck"
import AdvancedDropdownButton from "../../ActionDropdownButton/ActionDropdownButton"
import { DialogSendMessageAction, DialogSendMessageActionsIds } from "../../../models/Dialogs/dialog"
import { NewWorkplaceOperatorProjectSettings } from "../../../models/projectSettings"
import { useAppDispatch, useAppSelector } from "../../../store/store"
import cn from "classnames"
import { selectMessageInputInitial } from "../../../store/dialogs/selectors"

export const MSG_INPUT_FORM_MAX_HEIGHT = 265
const MESSAGE_LINK_BUTTON_ID = "dialog-message-input-link-button"
const EXTERNAL_IDS_FOR_SELECTION_TOOLBAR = [MESSAGE_LINK_BUTTON_ID]
const CONTROL_BUTTONS_COLOR = "#A2A2A2"

const tNamespace = "knowledgeBase:article-content-editor"
const tMessageActionsNamespace = "dialogs:send-message-actions"

interface MessageInputInitialValue {
    text: string
}

export interface DialogMessageInputProps extends ClassProps {
    user?: User
    onCancel?: () => void
    onSend: (message: string) => Promise<void>
    onAttachmentSelect?: (attachment: MessageAttachment) => void
    onEnableHold: () => Promise<void>
    placeholder?: string
    inputClassName?: string
    readonlyChat?: boolean
    isAdvanced?: boolean
    omniUserId: string
}

export enum imageTypes {
    jpeg = ".jpeg",
    jpg = ".jpg",
    png = ".png",
    svg = ".svg"
}

const DialogMessageInput: React.FC<DialogMessageInputProps> = props => {
    const {
        className,
        inputClassName,
        placeholder,
        user,
        onSend,
        onEnableHold,
        onCancel,
        onAttachmentSelect,
        isAdvanced = false,
        readonlyChat = false
    } = props

    const { t } = useTranslation()
    const inputRef = useRef<HTMLTextAreaElement>(null)
    const inputFileRef = useRef<HTMLInputElement>(null)
    const formWrapperRef = useRef<HTMLFormElement>(null)

    const allowedFileExtensions = useGetAllowedFileExtensions()
    const [formWrapperFixedHeight, setFormWrapperFixedHeightState] = useState<boolean>(false)
    const commands = useRef<ContentEditorCommands | null>(null)
    const sendMessageActionsAsButtons = useProjectSettingCheck<
        NewWorkplaceOperatorProjectSettings,
        "SendMessageActionsAsButtons"
    >("SendMessageActionsAsButtons")

    const [sendButtonOption, setSendButtonOptions] = useState("")
    const inputMessageInitial = useAppSelector(selectMessageInputInitial)

    const dispatch = useAppDispatch()

    const imageType = "image"
    const fileType = "file"

    const definedSendMessageActions: DialogSendMessageAction[] = [
        {
            Id: DialogSendMessageActionsIds.SendMessageAndHold,
            Title: t(`${tMessageActionsNamespace}.send-and-enable-hold`)
        }
    ]

    const clearProseMirrorArea = () => {
        const proseMirrorDiv = document.querySelector(".ProseMirror[contenteditable='true']")

        if (proseMirrorDiv) {
            while (proseMirrorDiv.lastChild) {
                proseMirrorDiv.removeChild(proseMirrorDiv.lastChild)
            }
        }
    }

    const handleOnSubmit = async (
        values: MessageInputInitialValue,
        { resetForm }: FormikHelpers<MessageInputInitialValue>
    ) => {
        await onSend(values.text)
        dispatch(actions.setMessageInputInitial(""))

        switch (sendButtonOption) {
            case DialogSendMessageActionsIds.SendMessageAndHold: {
                await onEnableHold()
                setSendButtonOptions("")
            }
        }

        clearProseMirrorArea()
        resetForm({ values: { text: "" } })
    }

    const formikProps = useFormik<MessageInputInitialValue>({
        onSubmit: handleOnSubmit,
        initialValues: { text: inputMessageInitial },
        enableReinitialize: true
    })

    useHotkeys("ctrl+enter", () => formikProps.handleSubmit(), {
        enableOnFormTags: true,
        enableOnContentEditable: true
    })

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus()
        }
    }, [])

    const onFormWrapperResize = useCallback(() => {
        const formWrapperEl = formWrapperRef.current

        if (!formWrapperEl) {
            return
        }

        const formWrapperElSizes = formWrapperEl.getBoundingClientRect()
        const currHeight = formWrapperElSizes.height

        setFormWrapperFixedHeightState(currHeight >= MSG_INPUT_FORM_MAX_HEIGHT)
    }, [])

    useEffect(() => {
        const resizeObserver = new ResizeObserver(onFormWrapperResize)
        const formWrapperEl = formWrapperRef.current

        if (!formWrapperEl) {
            return
        }

        resizeObserver.observe(formWrapperEl)

        return () => {
            if (!formWrapperEl) {
                return
            }

            resizeObserver.unobserve(formWrapperEl)
        }
    }, [onFormWrapperResize])

    const specifyAttachmentType = (fileName: string) => {
        for (const type of Object.values(imageTypes)) {
            if (fileName.endsWith(type)) {
                return imageType
            }
        }

        return fileType
    }

    const handleFileSelectClick = (accept = "*") => {
        if (!inputFileRef.current) {
            return
        }

        inputFileRef.current.accept = accept
        inputFileRef.current.click()
    }

    const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files == null || e.target.files.length === 0) {
            return
        }

        const selectedFile = e.target.files[0]
        const fileUrl = await uploadFile(selectedFile)
        const attachment: MessageAttachment = {
            Url: fileUrl,
            ContentType: specifyAttachmentType(selectedFile.name),
            Name: selectedFile.name,
            Size: selectedFile.size
        }

        if (onAttachmentSelect) {
            onAttachmentSelect(attachment)
        }

        e.target.value = ""
    }

    const handleCreateCommands = (comingCommands: ContentEditorCommands) => {
        commands.current = comingCommands
    }

    const handleClickLink = () => {
        if (commands.current && typeof commands.current.link === "function") {
            commands.current.link({ href: "" })
        }
    }

    const handleClickOrderedList = () => {
        if (commands.current && typeof commands.current.ordered_list === "function") {
            commands.current.ordered_list()
        }
    }

    const handleClickBulletList = () => {
        if (commands.current && typeof commands.current.bullet_list === "function") {
            commands.current.bullet_list()
        }
    }

    const handleSendMessageActionSelect = (key: string) => {
        setSendButtonOptions(key)
    }

    const renderSendButtons = useCallback(() => {
        const buttons = []
        const isAnySeparatedButtons =
            sendMessageActionsAsButtons && Object.values(sendMessageActionsAsButtons).some(setting => setting)

        if (!isAnySeparatedButtons) {
            return (
                <AdvancedDropdownButton
                    options={definedSendMessageActions}
                    callback={formikProps.handleSubmit}
                    onSelect={handleSendMessageActionSelect}
                    disabled={readonlyChat}
                    button={
                        <IconButton
                            key="message-input_submit-button"
                            variant="primary"
                            type="submit"
                            className={styles.messageInput__submit}
                            icon={faPaperPlaneTop}
                            testId={testId.messageInputSubmitButton}
                            disabled={readonlyChat}
                        />
                    }
                />
            )
        }

        if (sendMessageActionsAsButtons?.Hold) {
            buttons.push(
                <IconButton
                    key="message-input_submit-button-hold"
                    variant="primary"
                    type="submit"
                    className={styles.messageInput__submit}
                    icon={faPauseCircle}
                    testId={testId.messageInputSubmitAndHoldButton}
                    disabled={readonlyChat}
                    onClick={() => setSendButtonOptions(DialogSendMessageActionsIds.SendMessageAndHold)}
                />
            )
        }

        buttons.push(
            <IconButton
                key="message-input_submit-button"
                variant="primary"
                type="submit"
                className={styles.messageInput__submit}
                icon={faPaperPlaneTop}
                testId={testId.messageInputSubmitButton}
                disabled={readonlyChat}
            />
        )

        return buttons
    }, [readonlyChat, sendMessageActionsAsButtons])

    const getEditor = (formikProps: FormikProps<MessageInputInitialValue>) => {
        if (isAdvanced) {
            const handleAdvancedChange = (getContent: () => string) => {
                const filteredCont = getContent()
                    .replace(/^[\\\n]+/gm, "")
                    .replace(/[\n\\]+$/g, "")

                formikProps.setFieldValue("text", filteredCont)
                dispatch(actions.setMessageInput(filteredCont))
            }

            return (
                // TODO: понять, нужно ли здесь использовать lazy
                <ContentEditor
                    t={t}
                    initialCarePos="end"
                    onChange={handleAdvancedChange}
                    //onDocStateChange={handleAdvancedChange}
                    content={inputMessageInitial}
                    className={cn(
                        styles.messageInput__control,
                        styles.messageInput__control_advanced,
                        readonlyChat && styles.messageInput__control_disabled,
                        inputClassName,
                        formWrapperFixedHeight && styles.messageInput__control_stable
                    )}
                    allowedFileExtensions={allowedFileExtensions}
                    isEditable={!readonlyChat}
                    disableExtensions={[
                        ...DEFAULT_EDITOR_DIS_EXT,
                        "checkbox_list",
                        "heading",
                        "table",
                        "hr",
                        "blockquote"
                    ]}
                    onCreateCommands={handleCreateCommands}
                    externalIdsForSelectionToolbar={EXTERNAL_IDS_FOR_SELECTION_TOOLBAR}
                    isInChat
                />
            )
        } else {
            return (
                <Form.Control
                    name="text"
                    as="textarea"
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    value={formikProps.values.text}
                    className={cn(styles.messageInput__control, inputClassName)}
                    placeholder={placeholder}
                    ref={inputRef}
                    contentEditable={!readonlyChat}
                />
            )
        }
    }

    return (
        <FormikProvider value={formikProps}>
            <Form
                ref={formWrapperRef}
                className={cn(styles.messageInput, className)}
                onSubmit={formikProps.handleSubmit}
                onKeyPress={preventSubmitOnEnter}
                data-test-id={testId.messageInput}
            >
                <div className={styles.messageInput__wrapper}>
                    {user && (
                        <div className={styles.messageInput__user}>
                            <UserCard
                                lastName={user.LastName}
                                firstName={user.FirstName}
                                picture={user.Picture}
                                isNewWorkplace
                            />
                        </div>
                    )}
                    {getEditor(formikProps)}
                    <div className={styles.messageInput__tooltipContainer}>
                        <TooltipTrigger
                            id="dialog-message-input-tooltip"
                            children={
                                <FontAwesomeIcon icon={faExclamationCircle} size="lg" color={CONTROL_BUTTONS_COLOR} />
                            }
                            content={
                                <div className={styles.messageInput__tooltip}>
                                    <p>{t(`${tNamespace}.tooltip-text-title`)}</p>
                                    <p>{t(`${tNamespace}.tooltip-text-content`)}</p>
                                </div>
                            }
                        />
                    </div>
                </div>
                <div className={styles.messageInput__footer}>
                    <div
                        className={cn(
                            styles.messageInput__quickButtons,
                            readonlyChat && styles.messageInput__quickButtons_disabled
                        )}
                    >
                        <FontAwesomeIcon
                            onClick={() => handleFileSelectClick()}
                            icon={faPaperclip}
                            className={styles.messageInput__quickButton}
                            color={CONTROL_BUTTONS_COLOR}
                        />
                        <FontAwesomeIcon
                            onClick={() => handleFileSelectClick("image/*")}
                            icon={faImage}
                            className={`${styles.messageInput__quickButton} ${styles.messageInput__imageButton}`}
                            color={CONTROL_BUTTONS_COLOR}
                        />
                        <FontAwesomeIcon
                            onMouseDown={handleClickLink}
                            icon={faLink}
                            className={`${styles.messageInput__quickButton} ${styles.messageInput__linkButton}`}
                            color={CONTROL_BUTTONS_COLOR}
                            id={MESSAGE_LINK_BUTTON_ID}
                        />
                        <FontAwesomeIcon
                            onMouseDown={handleClickOrderedList}
                            icon={faListOl}
                            className={styles.messageInput__quickButton}
                            color={CONTROL_BUTTONS_COLOR}
                        />
                        <FontAwesomeIcon
                            onMouseDown={handleClickBulletList}
                            icon={faListUl}
                            className={styles.messageInput__quickButton}
                            color={CONTROL_BUTTONS_COLOR}
                        />
                    </div>
                    <div className={styles.messageInput__buttonGroup}>
                        {onCancel && (
                            <Button variant="light" onClick={onCancel}>
                                {t(formTranslation.cancel)}
                            </Button>
                        )}
                        {renderSendButtons()}
                    </div>
                </div>
                <input
                    type="file"
                    className={styles.messageInput__fileInput}
                    ref={inputFileRef}
                    onChange={handleFileChange}
                />
            </Form>
        </FormikProvider>
    )
}

export default DialogMessageInput
