export const inlineEcapedJson = <T>(input: T, stringify = false) =>
    (stringify ? JSON.stringify(input) : String(input))
        .replace(/\n/g, "")
        .replace(/(?<={|,)\s*"([^"]+)"\s*:\s*"([^"]+)"\s*(?=}|,)/gm, '"$1":"$2"')

export const prettyJson = <T extends string>(input: T, indent = 2, parseFirst = false) =>
    JSON.stringify(parseFirst ? JSON.parse(input) : input, null, indent)

export const parseJson = (data: string) => {
    try {
        return JSON.parse(data)
    } catch (e) {
        return data
    }
}
