export const throttle = (fn: Function, timeout: number) => {
    let ready = true
    return (...args: unknown[]) => {
        if (!ready) {
            return
        }
        ready = false
        fn(...args)
        setTimeout(() => {
            ready = true
        }, timeout)
    }
}
