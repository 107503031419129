export interface FrontendConfig {
    WebConfig: Config
}

export interface Config {
    isDefault: boolean
    appSettings: AppSettings
    queue: QueueConfig
    webChat: WebChatConfig
}

interface AppSettings {
    signUpEnabled: boolean
    inviteSignUpEnabled: boolean
    selfSignUpEnabled: boolean
    authMode: "cookie" | "oidc"
    customHtmlBody?: string
    externalScript?: string
    useAllTransportSignalR?: boolean
}

interface QueueConfig {
    tenantId: string
}

interface WebChatConfig {
    advancedSettingsEnabled: boolean
}

export const defaultWebConfig: Config = {
    isDefault: true,
    appSettings: {
        inviteSignUpEnabled: true,
        selfSignUpEnabled: false,
        signUpEnabled: true,
        authMode: "cookie"
    },
    queue: {
        tenantId: "cloud"
    },
    webChat: {
        advancedSettingsEnabled: false
    }
}

export const defaultConfig: FrontendConfig = {
    WebConfig: defaultWebConfig
}
