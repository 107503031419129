import React, { ReactNode, useContext } from "react"
import cn from "classnames"
import { ClassProps } from "../../utility/common/props"
import styles from "./Article.module.scss"
import ScrollToTopButton from "../ScrollToTopButton/ScrollToTopButton"
import { ScrollToTopButtonContext } from "../PageLayout/PageLayout"
import { ArticleContext } from "../../pages/KnowledgeBase/KnowledgeBase"
import useScroll from "../../hooks/useScroll"
import useScrollToTargetByRef from "../../hooks/useScrollToTargetByRef"

export interface ArticleProps extends ClassProps {
    symbolCode: string
    headerTop?: ReactNode
    headerRight?: ReactNode
    prepended?: ReactNode
    title: ReactNode | string
    widgets?: ReactNode
    body?: ReactNode | string
    isExpanded: boolean
    testId?: string
    withoutHeader?: boolean
}

const Article: React.FC<ArticleProps> = props => {
    const { className, headerTop, prepended, headerRight, title, body, widgets, isExpanded, testId, withoutHeader } =
        props

    const isScrollToTopButtonVisible = useContext(ScrollToTopButtonContext)
    const scrollableEl = document.getElementById("page-layout-content")

    const scrollToTop = useScroll({
        scrollableEl
    })

    const { isInWorkplace } = useContext(ArticleContext)
    const { targetRef, scrollToTargetRef } = useScrollToTargetByRef<HTMLDivElement>()

    return (
        <div>
            <div className={cn(styles.article, className)} data-testid={testId}>
                {prepended && <div className={styles.article__prepended}>{prepended}</div>}
                {!withoutHeader && (
                    <div className={cn(styles.article__header, isExpanded && styles.article_expanded)}>
                        <div className={styles.article__headerTop}>{headerTop}</div>
                        <div className={styles.article__headerSection}>
                            <div className={styles.article__title}>{title}</div>
                            {headerRight && <div className={styles.article__headerRight}>{headerRight}</div>}
                        </div>
                    </div>
                )}
                {widgets && (
                    <div
                        ref={targetRef}
                        className={cn(styles.article__parameters, isExpanded && styles.article_expanded)}
                    >
                        {widgets}
                    </div>
                )}
                {body && <div className={cn(styles.article__body, isExpanded && styles.article_expanded)}>{body}</div>}
            </div>
            {isScrollToTopButtonVisible && (
                <div className={cn(styles.scrollButton, isExpanded && styles.scrollButton_expanded)}>
                    <ScrollToTopButton onClick={() => (isInWorkplace ? scrollToTargetRef() : scrollToTop())} />
                </div>
            )}
        </div>
    )
}

export default Article
