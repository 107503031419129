import React from "react"
import styles from "./TaskContent.module.scss"
import { ClassProps } from "../../utility/common/props"
import cn from "classnames"
import { useTranslation } from "react-i18next"
import OutdatedMessageList, { OutdatedMessageListProps } from "../OutdatedMessageList/OutdatedMessageList"

const tNamespace = "queues:task."

type Deps = Omit<OutdatedMessageListProps, "reverseInOut"> & ClassProps

export type TaskContentProps = Deps

const TaskContent: React.FC<TaskContentProps> = props => {
    const { className, messages, ...listProps } = props
    const { t } = useTranslation()

    return (
        <div className={cn(styles.taskContent, className)}>
            {messages.length === 0 ? (
                <div className={styles.taskContent__empty}>{t(`${tNamespace}no-messages`)}</div>
            ) : (
                <OutdatedMessageList {...listProps} messages={messages} reverseInOut={false} />
            )}
        </div>
    )
}

export default TaskContent
