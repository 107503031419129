import { Dropdown as DropdownBS } from "react-bootstrap"
import styles from "./Dropdown.module.scss"
import React, { ReactNode, useCallback, useRef, useState } from "react"
import { useOutsideClick } from "../../utility/common/useOutsideClick"
import { faAngleDown, faAngleUp } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export type IDropdownItem = {
    title: ReactNode | string
    name: string
}

export interface IDropdownProps {
    items: IDropdownItem[]
    onListItemSelect?: (name: string) => void
    onTriggerClick?: (e: React.MouseEvent<HTMLElement>) => void
    throughMode?: boolean
    showArrowIcon?: boolean
}

export const Dropdown: React.FC<React.PropsWithChildren<IDropdownProps>> = ({
    onListItemSelect,
    onTriggerClick,
    children,
    items = [],
    throughMode,
    showArrowIcon
}) => {
    const [menuShown, setMenuShown] = useState<boolean>(false)

    const dropdownRef = useRef<HTMLDivElement>(null)
    useOutsideClick<HTMLDivElement>(() => setMenuShown(false), dropdownRef)

    const handleDropdownItemClick = (name: string) => {
        if (onListItemSelect) {
            onListItemSelect(name)
        }

        setMenuShown(false)
    }

    const handleOnTriggerClick = useCallback(
        (e: React.MouseEvent<HTMLElement>) => {
            if (onTriggerClick) {
                onTriggerClick(e)
            }

            setMenuShown(prev => !prev)
        },
        [onTriggerClick]
    )

    if (throughMode) {
        return <>{children}</>
    }

    return (
        <>
            <DropdownBS show={menuShown} ref={dropdownRef} className={styles.dropdown__container}>
                <DropdownBS.Toggle onClick={handleOnTriggerClick} className={styles.dropdown__toggle}>
                    <div className={styles.dropdown__toggleContent}>{children}</div>
                    <div className={styles.dropdown__toggleButton}>
                        {showArrowIcon && <FontAwesomeIcon icon={menuShown ? faAngleUp : faAngleDown} />}
                    </div>
                </DropdownBS.Toggle>
                <DropdownBS.Menu className={styles.dropdown__list}>
                    {items.map(x => (
                        <DropdownBS.Item
                            key={x.name}
                            className={styles.dropdown__item}
                            onClick={() => handleDropdownItemClick(x.name)}
                        >
                            {x.title}
                        </DropdownBS.Item>
                    ))}
                </DropdownBS.Menu>
            </DropdownBS>
        </>
    )
}
