import { useCallback } from "react"
import paginationController from "../../api/controllers/pagination"
import { PaginationRequest, RequestType } from "../../models/pagination"
import useAsyncState from "./useAsyncState"

function usePaginationRequest<ResponseType>(
    projectId: string,
    requestType: RequestType,
    error: string,
    pageFrom?: number,
    pageSize?: number,
    searchValue?: string
) {
    const loadPage = useCallback(() => {
        const paginationRequest: PaginationRequest = {
            PageFrom: pageFrom,
            PageSize: pageSize,
            Search: searchValue ? searchValue : undefined
        }

        return paginationController.getItems<ResponseType>(projectId, requestType, paginationRequest)
    }, [pageFrom, pageSize, projectId, requestType, searchValue])

    return useAsyncState(loadPage, error)
}

export default usePaginationRequest
