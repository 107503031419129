import { useSelector } from "react-redux"
import { selectProjectSettings } from "../../store/projects/selectors"
import { useMemo } from "react"

const findValueInNestedObject = <T>(object: unknown, key: string): T | undefined => {
    if (!object || typeof object !== "object") {
        return undefined
    }

    if (object && key in object) {
        return (object as Record<string, T>)[key]
    }

    for (const nestedKey in object as Record<string, unknown>) {
        const nestedValue = findValueInNestedObject<T>((object as Record<string, unknown>)[nestedKey], key)

        if (nestedValue !== undefined) {
            return nestedValue
        }
    }

    return undefined
}

export const useProjectSettingCheck = <T, K extends keyof T>(setting: K & string): T[K] | undefined => {
    const settings = useSelector(selectProjectSettings)

    return useMemo(() => findValueInNestedObject<T[K]>(settings, setting), [setting, settings])
}
