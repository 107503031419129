import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { LinkOpeningType, MessageValues, WebChatChannelValues } from "../../../../models/webChatValues"
import FormAccordion from "../../../FormAccordion/FormAccordion"
import DebouncedCheckBox from "../../../CheckBoxValidatableInput/DebouncedCheckBox"
import { nameof2 } from "../../../../utility/common/nameof"
import DebouncedValidatableInput from "../../../ValidatableInput/DebouncedValidatableInput"
import RadioButtonValidatableInput from "../../../RadioButtonValidatableInput/RadioButtonValidatableInput"
import { ParamValues } from "../../../../models/parameterDeclaration"
import ValidatableInputWithControl from "../../../ValidatableInputWithControl/ValidatableInputWithControl"
import { useFormikContext } from "formik"
import { formTranslation } from "../../../../locales/form"
import InfoIcon from "../../../InfoIcon/InfoIcon"

const tNamespace = "channel:form."
const tTooltipNamespace = "channel:tooltip."

const WebChatMessageSettings: React.FC = () => {
    const { t } = useTranslation()
    const { values, setFieldValue } = useFormikContext<WebChatChannelValues>()
    const [userTypingIntervalEnabled, setUserTypingIntervalEnabled] = useState(values.MessageSettings.sendUserIsTyping)
    const handleTypingIntervalButtonToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
        const checked = e.target.checked
        setUserTypingIntervalEnabled(checked)
        if (!checked) {
            setFieldValue(
                nameof2<WebChatChannelValues, MessageValues>("MessageSettings", "sendUserIsTyping"),
                false,
                false
            )
        } else {
            setFieldValue(
                nameof2<WebChatChannelValues, MessageValues>("MessageSettings", "userTypingInterval"),
                5,
                false
            )
            setFieldValue(
                nameof2<WebChatChannelValues, MessageValues>("MessageSettings", "sendUserIsTyping"),
                true,
                false
            )
        }
    }

    return (
        <FormAccordion title={t(`${tNamespace}messages`)}>
            <DebouncedValidatableInput
                id="formOutgoingMessageMaxLength"
                type="number"
                name={nameof2<WebChatChannelValues, ParamValues>("Params", "outgoing_message_max_length")}
                label={t(`${tNamespace}message-max-length`)}
                placeholder={t(formTranslation.enterNumber)}
            />
            <div className="channel-form__section">
                <ValidatableInputWithControl
                    id="formSendUserIsTyping"
                    type="number"
                    name={nameof2<WebChatChannelValues, MessageValues>("MessageSettings", "userTypingInterval")}
                    label={t(`${tNamespace}send-user-is-typing`)}
                    isEnabled={userTypingIntervalEnabled}
                    onToggle={handleTypingIntervalButtonToggle}
                    placeholder={t(formTranslation.enterNumber)}
                />
                <DebouncedCheckBox
                    id="formForwardMessage"
                    name={nameof2<WebChatChannelValues, MessageValues>("MessageSettings", "forwardMessage")}
                    label={t(`${tNamespace}quoting-messages`)}
                />
                <DebouncedCheckBox
                    id="formShowClientServiceMessage"
                    name={nameof2<WebChatChannelValues, MessageValues>("MessageSettings", "showClientServiceMessage")}
                    label={t(`${tNamespace}show-service-messages`)}
                />
                <DebouncedValidatableInput
                    id="formLocationMessage"
                    type="text"
                    name={nameof2<WebChatChannelValues, MessageValues>("MessageSettings", "locationMessage")}
                    label={t(`${tNamespace}location-request-message`)}
                    placeholder={t(formTranslation.enterText)}
                />
            </div>
            {t(`${tNamespace}option-to-open-links-inside-message`)}
            <div className="channel-form__section">
                <RadioButtonValidatableInput
                    value={LinkOpeningType.sameTab}
                    id="formLinkOpening1"
                    name={nameof2<WebChatChannelValues, MessageValues>("MessageSettings", "linkOpeningBehaviour")}
                    label={t(`${tNamespace}in-current-tab`)}
                />
                <RadioButtonValidatableInput
                    value={LinkOpeningType.newTab}
                    id="formLinkOpening2"
                    name={nameof2<WebChatChannelValues, MessageValues>("MessageSettings", "linkOpeningBehaviour")}
                    label={t(`${tNamespace}in-new-tab`)}
                />
                <RadioButtonValidatableInput
                    value={LinkOpeningType.newWindow}
                    id="formLinkOpening3"
                    name={nameof2<WebChatChannelValues, MessageValues>("MessageSettings", "linkOpeningBehaviour")}
                    label={t(`${tNamespace}in-new-window`)}
                />
            </div>
            <DebouncedCheckBox
                id={"formSelectClickedButtons"}
                name={nameof2<WebChatChannelValues, MessageValues>("MessageSettings", "selectClickedButtons")}
                label={t(`${tNamespace}select-clicked-buttons`)}
                icon={
                    <InfoIcon
                        id="popoverSelectClickedButtons"
                        content={t(`${tTooltipNamespace}select-clicked-buttons-info`)}
                    />
                }
            />
        </FormAccordion>
    )
}

export default WebChatMessageSettings
