import { Dispatch } from "../common/storeHelper"
import { ChannelProcessor, ChannelTypeString } from "../../models/channel"
import channelsController from "../../api/controllers/channels"
import { OptionsType } from "react-select/src/types"
import { OptionType } from "../../components/AsyncSearchableInput/AsyncSearchableInput"
import { apiCallExceptionToSystemError } from "../common/apiCallExceptionToSystemError"
import { GET_CHANNEL_FAILED_MESSAGE } from "../../store/knowledgeBase/constants"
import { saveNotificationError } from "../../store/notifications/thunks"

export const searchChannel =
    (dispatch: Dispatch, filter: string[], projectId?: string) => async (): Promise<OptionsType<OptionType>> => {
        try {
            if (!projectId) {
                return []
            }
            const processor: ChannelProcessor = await channelsController.getChannels(projectId)
            return processor.channels
                .filter(channel => channel.Type === ChannelTypeString.WebChat && filter.includes(channel.Id))
                .map(channel => {
                    return {
                        value: channel.Id,
                        label: channel.Title
                    }
                })
        } catch (e) {
            const error = apiCallExceptionToSystemError(GET_CHANNEL_FAILED_MESSAGE, e)
            saveNotificationError(dispatch, error)
            return []
        }
    }
