import AsyncState from "../../core/asyncState"
import { AsyncErrorProps, AsyncStrictDataProps } from "../../utility/common/asyncHelper"
import { useTranslation } from "react-i18next"
import { getSystemErrorMessage } from "../../core/error"
import React from "react"

interface AsyncStrictProps<T> {
    dataState: AsyncState<T>
    processView: JSX.Element
    errorView: (props: AsyncErrorProps) => JSX.Element
    children: (props: AsyncStrictDataProps<T>) => JSX.Element
}

function AsyncStrict<T>(props: AsyncStrictProps<T>): JSX.Element {
    const { dataState, errorView, processView, children } = props
    const { t } = useTranslation()
    if (dataState.inProcess) {
        return processView
    }
    if (dataState.error) {
        return errorView({ message: getSystemErrorMessage(dataState.error, t) })
    }
    return <>{typeof dataState.data !== "undefined" ? children({ data: dataState.data }) : null}</>
}

export default AsyncStrict
