import React from "react"
import "./ButtonsList.scss"
import { Draggable, DraggableProvided, DraggableRubric, DraggableStateSnapshot } from "react-beautiful-dnd"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGripLinesVertical } from "@fortawesome/pro-light-svg-icons/faGripLinesVertical"
import { Handle, Position } from "react-flow-renderer"
import { MessagedNodeButton } from "./MessageNode"
import CloseButton from "../../../CloseButton/CloseButton"
import TextareaAutosize from "react-textarea-autosize"
import { testId } from "../../../../utility/tests/testId"
import { useResizeWhenZoom } from "../../../../utility/scenario/useResizeWhenZoom"

export const renderItem =
    (params: ButtonsListProps) =>
    (provided: DraggableProvided, snapshot: DraggableStateSnapshot, rubric: DraggableRubric) => {
        const { disabled, buttons, onAddBlock, isConnectable } = params
        const item = buttons[rubric.source.index]

        const resizableRef = useResizeWhenZoom<HTMLDivElement>(snapshot)

        return (
            <div className="buttons-list-item" ref={provided.innerRef} {...provided.draggableProps}>
                <div
                    ref={resizableRef}
                    className="buttons-list-item__btn"
                    data-testid={`${testId.draggableButton}-${item.Id}`}
                >
                    {!disabled && (
                        <span {...provided.dragHandleProps} className="buttons-list-item__drag-icon">
                            <FontAwesomeIcon icon={faGripLinesVertical} />
                        </span>
                    )}
                    <TextareaAutosize
                        className="buttons-list-item__input"
                        defaultValue={item.Title}
                        disabled={disabled}
                        onBlur={e => (buttons[rubric.source.index].Title = e.target.value)}
                        maxRows={2}
                    />
                    <CloseButton
                        onClick={() => params.onUpdateButtons(buttons.filter(btn => btn.Id !== item.Id))}
                        dataTestId={`${testId.btnClose}-${item.Id}`}
                    />
                    <Handle
                        id={item.Id}
                        type="source"
                        position={Position.Right}
                        className="message-node__handle"
                        onClick={() => onAddBlock(item.Id)}
                        isConnectable={isConnectable}
                    />
                </div>
            </div>
        )
    }

export interface ButtonsListProps {
    buttons: MessagedNodeButton[]
    onAddBlock: (sourceHandle: string) => void
    onUpdateButtons: (updated: MessagedNodeButton[]) => void
    disabled: boolean
    isConnectable: boolean
}

const ButtonsList: React.FC<ButtonsListProps> = props => {
    const { buttons, disabled } = props
    return (
        <>
            {buttons.map((btn, index) => (
                <Draggable draggableId={btn.Id} index={index} isDragDisabled={disabled} key={btn.Id}>
                    {renderItem(props)}
                </Draggable>
            ))}
        </>
    )
}

export default ButtonsList
