import React, { useEffect, useState } from "react"
import { FormControlProps } from "react-bootstrap/FormControl"
import TooltipTrigger from "../TooltipTrigger/TooltipTrigger"
import { Form, FormControl, InputGroup } from "react-bootstrap"
import { ClassProps } from "../../utility/common/props"
import styles from "./ActionInput.module.scss"
import cn from "classnames"

export interface ActionInputProps extends FormControlProps, ClassProps {
    id: string
    tooltipLabel?: string
    isTooltipShow?: boolean
    actions: React.ReactNode
    error?: string
    testId?: string
}

const ActionInput: React.FC<ActionInputProps> = props => {
    const { id, tooltipLabel, className, actions, isTooltipShow, error, testId, ...controlProps } = props
    const [show, setShow] = useState(false)

    useEffect(() => {
        let timeout: NodeJS.Timeout | null = null
        const onClick = () => {
            setShow(true)
            timeout = setTimeout(() => {
                setShow(false)
            }, 1000)
        }
        onClick()
        return () => {
            if (timeout) {
                clearTimeout(timeout)
            }
        }
    }, [tooltipLabel])

    return (
        <div className={styles.actionInput}>
            <TooltipTrigger id={id} condition={!!tooltipLabel} placement="top" content={tooltipLabel} show={show}>
                <InputGroup>
                    <FormControl
                        {...controlProps}
                        data-test-id={testId}
                        className={cn(styles.actionInput__control, !actions && styles.actionInput__control_empty)}
                    />
                    {actions && (
                        <InputGroup.Prepend className={styles.actionInput__actions}>{actions}</InputGroup.Prepend>
                    )}
                </InputGroup>
            </TooltipTrigger>
            <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
        </div>
    )
}

export default ActionInput
