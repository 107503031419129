import React from "react"
import { ChannelType } from "../../models/channel"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    faSkype,
    faViber,
    faTelegram,
    faWhatsapp,
    faVk,
    faOdnoklassnikiSquare,
    faFacebookMessenger,
    faYandexInternational,
    faMicrosoft,
    faInstagram
} from "@fortawesome/free-brands-svg-icons"
import { faAt, faPhoneAlt, faComment } from "@fortawesome/pro-light-svg-icons"
import "./ChannelIcon.scss"
import { SizeProp } from "@fortawesome/fontawesome-svg-core"

export interface ChannelInfoProps {
    type: ChannelType
    className?: string
    size?: SizeProp
}

const ChannelInfo: React.FC<ChannelInfoProps> = props => {
    const { type, className, size } = props
    switch (type) {
        case ChannelType.WebChat:
            return <FontAwesomeIcon icon={faComment} className={className} size={size} />
        case ChannelType.Phone:
            return <FontAwesomeIcon icon={faPhoneAlt} className={className} size={size} />
        case ChannelType.Telegram:
            return <FontAwesomeIcon icon={faTelegram} className={className} size={size} />
        case ChannelType.Viber:
            return <FontAwesomeIcon icon={faViber} className={className} size={size} />
        case ChannelType.Whatsapp:
            return <FontAwesomeIcon icon={faWhatsapp} className={className} size={size} />
        case ChannelType.Skype:
            return <FontAwesomeIcon icon={faSkype} className={className} size={size} />
        case ChannelType.Email:
            return <FontAwesomeIcon icon={faAt} className={className} size={size} />
        case ChannelType.VK:
            return <FontAwesomeIcon icon={faVk} className={className} size={size} />
        case ChannelType.Odnoklassniki:
            return <FontAwesomeIcon icon={faOdnoklassnikiSquare} className={className} size={size} />
        case ChannelType.Facebook:
            return <FontAwesomeIcon icon={faFacebookMessenger} className={className} size={size} />
        case ChannelType.MSBot:
        case ChannelType.Teams:
            return <FontAwesomeIcon icon={faMicrosoft} className={className} size={size} />
        case ChannelType.YandexAlisa:
        case ChannelType.YandexDialogs:
            return <FontAwesomeIcon icon={faYandexInternational} className={className} size={size} />
        case ChannelType.Instagram:
            return <FontAwesomeIcon icon={faInstagram} className={className} size={size} />
        case ChannelType.Test:
        case ChannelType.Unknown:
        default:
            return <FontAwesomeIcon icon={faComment} className={className} size={size} />
    }
}

export default ChannelInfo
