import React, { useEffect, useState } from "react"
import InfoField from "../InfoField/InfoField"

export interface UpdatedTimeProps {
    title: string
    titleFallback?: string
    timestamp: number
    format?: (ms: number) => string
}

const calculateTime = (timestamp: number): number => Date.now() - timestamp

const UpdatedTime: React.FC<UpdatedTimeProps> = props => {
    const { title, titleFallback, timestamp, format } = props
    const [updatedTimestamp, setUpdatedTimestamp] = useState(calculateTime(timestamp))

    useEffect(() => {
        let timer: NodeJS.Timeout | null = null

        if (timestamp) {
            timer = setInterval(() => {
                setUpdatedTimestamp(calculateTime(timestamp))
            }, 1000)
        }

        return () => {
            if (timer) {
                clearInterval(timer)
            }
        }
    }, [timestamp])

    return (
        <InfoField name={title}>
            {timestamp ? (format ? format(updatedTimestamp) : updatedTimestamp) : titleFallback}
        </InfoField>
    )
}

export default UpdatedTime
