import React, { useCallback } from "react"
import styles from "./ModalBox.module.scss"
import LoadingButton from "../LoadingButton/LoadingButton"
import { ButtonVariant } from "react-bootstrap/esm/types"
import cls from "classnames"

export type TAction = {
    label: string
    variant: ButtonVariant
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
    loading?: boolean
}

export interface IModalBoxProps extends React.HTMLAttributes<HTMLDivElement> {
    dialogTitle: React.ReactNode
    actions: TAction[]
    adornmentRight?: React.ReactNode | React.ReactNode[]
    adornmentFooterLeft?: React.ReactNode | React.ReactNode[]
    adornmentFooterRight?: React.ReactNode | React.ReactNode[]
}

export const ModalBox: React.FC<React.PropsWithChildren<IModalBoxProps>> = ({
    adornmentRight,
    dialogTitle,
    adornmentFooterLeft,
    adornmentFooterRight,
    children,
    actions = [],
    ...props
}) => {
    const renderActions = useCallback(
        () =>
            actions.map(props => (
                <LoadingButton {...props} as="div" key={props.label}>
                    {props.label}
                </LoadingButton>
            )),
        [actions]
    )

    return (
        <div {...props} className={cls(styles.modalBox, props.className)}>
            <header className={styles.modalBox__header}>
                <h5>{dialogTitle}</h5>
                <div>{adornmentRight}</div>
            </header>
            <main className={styles.modalBox__content}>{children}</main>
            <footer>
                {adornmentFooterLeft}
                <div className={styles.modalBox__actions}>{renderActions()}</div>
                {adornmentFooterRight}
            </footer>
        </div>
    )
}
