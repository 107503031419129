type TClickableExcludedClass = string | undefined

export interface IDetectDocumentAnyClickProps {
    targetEl: HTMLElement
    clickableExcludedCls?: TClickableExcludedClass[]
}

export const detectDocumentAnyClick = (props: IDetectDocumentAnyClickProps) => {
    const { targetEl, clickableExcludedCls = [] } = props

    return clickableExcludedCls
        .filter(cl => cl)
        .some(cl => {
            const closestEl = targetEl.closest(`.${cl}`)

            if ([targetEl.className, closestEl?.className].includes(cl)) {
                return true
            }

            return false
        })
}
