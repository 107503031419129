import { useState } from "react"
import store from "../store/store"
import { dialogsApi } from "./controllers/dialogs"

export type TDialogApiEndpoints = keyof typeof dialogsApi.endpoints

type K<S extends TDialogApiEndpoints> = typeof dialogsApi.endpoints[S]["select"]

export interface IUseGetCachedEndpointData<M extends TDialogApiEndpoints> {
    endpoint: M
    selectArgs?: Parameters<K<M>>[0] | null
}

export const useGetDialogApiCachedEndpointData = <M extends TDialogApiEndpoints>({
    endpoint,
    selectArgs = undefined
}: IUseGetCachedEndpointData<M>) => {
    const select = dialogsApi.endpoints[endpoint].select
    const getGenSelector = select(selectArgs as keyof typeof select)
    return getGenSelector(store.getState()) as unknown as ReturnType<ReturnType<K<M>>>
}

export const useLazyGetDialogApiCachedEndpointData = <M extends TDialogApiEndpoints>({
    endpoint,
    selectArgs = undefined
}: IUseGetCachedEndpointData<M>) => {
    const [data, setData] = useState<ReturnType<ReturnType<K<M>>>>()

    const trigger = () => {
        const select = dialogsApi.endpoints[endpoint].select
        const getGenSelector = select(selectArgs as keyof typeof select)
        const data = getGenSelector(store.getState()) as unknown as ReturnType<ReturnType<K<M>>>

        setData(data)
        return data
    }

    return { trigger, data }
}
