import { InitialButton } from "../../components/WebChatChannelForm/WebChatAdvancedSettings/WebChatInitialMessageSettings"
import { InitialMessageText } from "../../models/webChatConfig"

export const formatButtons = (buttons: InitialButton[]) => {
    const formatedButtons = buttons.map((button, index) => {
        return {
            Id: button.Id ? button.Id : "initialButton" + index,
            action_text: button.action_text,
            action_id: button.action_id
        }
    })
    return formatedButtons
}

export const formatInitialMessage = (buttons: InitialButton[], initialMessageText: InitialMessageText) => {
    const newInitial: InitialMessageText = {
        text: initialMessageText.text,
        actions: formatButtons(buttons),
        attachment: initialMessageText.attachment
    }
    return newInitial
}
