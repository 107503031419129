export const getAllSiblings = <T extends ChildNode>(elemOrig: HTMLElement, filter: (_: T) => boolean) => {
    const sibs: T[] = []

    let elem = elemOrig.parentNode?.firstChild as T

    if (!elem) {
        return sibs
    }

    do {
        if (elem.nodeType === 3) {
            continue
        }

        if (!filter || filter(elem)) {
            sibs.push(elem)
        }
    } while ((elem = elem.nextSibling as T))

    return sibs
}
