import React, { useCallback, useMemo } from "react"
import "./ChannelForm.scss"
import { Channel, ChannelTypeString } from "../../models/channel"
import { ChannelDeclarations } from "../../models/parameterDeclaration"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { selectChannelsDeclarations } from "../../store/channels/selectors"
import { TabEntry } from "../../types/tabEntry"
import WebChatChannelForm from "../WebChatChannelForm/WebChatChannelForm"
import ValidatableFormTabs, { ValidateTabsCallback } from "../ValidatableFormTabs/ValidatableFormTabs"
import WhatsappChannelForm from "../WhatsAppChannelForm/WhatsappChannelForm"
import { TFunction } from "i18next"
import ChannelFormByType from "./ChannelFormByType"

const tNamespace = "channel:form."

export interface Props {
    channel?: Channel
    channelType: string
    submitCallback: (channel: Channel) => void
    disabled?: boolean
}

export interface ChannelFormProps extends Props {
    tabEntries: TabEntry[]
    declarations: ChannelDeclarations
    validateTabs?: ValidateTabsCallback
    projectId?: string
}

const getUpdateChannelTabEntries = (t: TFunction, type: string): TabEntry[] => {
    const baseTabs = [
        { key: "main", value: t(`${tNamespace}main`) },
        { key: "appearance", value: t(`${tNamespace}appearance`) },
        { key: "advanced", value: t(`${tNamespace}advanced`) }
    ]

    switch (type) {
        case ChannelTypeString.YandexDialogs:
        case ChannelTypeString.YandexAlisa:
            return [baseTabs[0], baseTabs[2]]
        default:
            return baseTabs
    }
}

const ChannelForm: React.FC<Props> = props => {
    const { channelType } = props
    const { t } = useTranslation()
    const declarations = useSelector(selectChannelsDeclarations)
    const tabEntries = useMemo(() => getUpdateChannelTabEntries(t, channelType), [t, channelType])

    const renderChannelForm = useCallback((): JSX.Element | null => {
        if (!declarations) return null

        if (channelType.startsWith(ChannelTypeString.Whatsapp)) {
            return <WhatsappChannelForm {...props} tabEntries={tabEntries} declarations={declarations} />
        }

        switch (channelType) {
            case ChannelTypeString.WebChat:
                return <WebChatChannelForm {...props} tabEntries={tabEntries} declarations={declarations} />
            default:
                return <ChannelFormByType {...props} tabEntries={tabEntries} declarations={declarations} />
        }
    }, [declarations, channelType, props, tabEntries])

    return (
        <ValidatableFormTabs id="channel-settings-tabs" alwaysReload={false} entries={tabEntries}>
            {renderChannelForm()}
        </ValidatableFormTabs>
    )
}

export default ChannelForm
