import { RootState } from "../rootReducer"
import { getItemById } from "../../utility/common/storeHelper"
import { Project } from "../../models/project"

export const selectGetProjectsState = (state: RootState) => state.project.projects
export const selectAllProjects = (state: RootState) => state.project.projects.data
export const selectCurrentProjectState = (state: RootState) => state.project.selectProject
export const selectCurrentProject = (state: RootState) => state.project.selectProject.data
export const selectCurrentProjectId = (state: RootState) =>
    state.project.selectProject.data && state.project.selectProject.data.id
export const selectProjectById = (state: RootState, projectId: string) =>
    state.project.projects.data ? getItemById<Project>(state.project.projects.data, projectId) : undefined
export const selectOtherProjects = (state: RootState) => state.project.nonSelectedProjects
export const selectProjectWizardReady = (state: RootState) => state.project.wizard.ready
export const selectProjectWizardStage = (state: RootState) => state.project.wizard.stage
export const selectProjectWizardName = (state: RootState) =>
    state.project.wizardFormValues && state.project.wizardFormValues.ProjectName
export const selectCreatedProject = (state: RootState) => state.project.wizard.projectId
export const selectCreatProjectState = (state: RootState) => state.project.createProject
export const selectProjectWizardFormValues = (state: RootState) => state.project.wizardFormValues

export const selectInviteUsersState = (state: RootState) => state.project.inviteUsers
export const selectUpdateProjectState = (state: RootState) => state.project.updateProject
export const selectUpdateLogoState = (state: RootState) => state.project.updateLogo
export const selectFrameLoadingState = (state: RootState) => state.project.frameLoading
export const selectFrameForceUnloaded = (state: RootState) => state.project.frameForceUnloaded
export const selectFrameActivate = (state: RootState) => state.project.frameActive

export const selectUploadProjectState = (state: RootState) => state.project.uploadProject
export const selectProjectCopyWizardReady = (state: RootState) => state.project.copyWizard.ready
export const selectProjectCopyWizardStage = (state: RootState) => state.project.copyWizard.stage
export const selectProjectCopyWizardName = (state: RootState) =>
    state.project.copyWizardFormValues && state.project.copyWizardFormValues.ProjectName
export const selectCreatedProjectCopy = (state: RootState) => state.project.copyWizard.projectId
export const selectCreateProjectCopyState = (state: RootState) => state.project.createProjectCopy
export const selectProjectCopyWizardFormValues = (state: RootState) => state.project.copyWizardFormValues
export const selectReferences = (state: RootState) => state.project.references.data
export const selectGatProjectSettingsState = (state: RootState) => state.project.getSettings
export const selectUpdateProjectSettingsState = (state: RootState) => state.project.updateSettings
export const selectProjectSettings = (state: RootState) => state.project.settings
export const selectCustomSections = (state: RootState) =>
    state.project.settings?.Operator?.NewWorkplace?.CustomSections.Values
export const selectCustomMenus = (state: RootState) => state.project.settings?.System?.CustomMenus.Values
export const selectBlockedUsers = (state: RootState) => state.project.blockUser
