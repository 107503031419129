import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import AsyncState from "../../core/asyncState"
import { SystemError } from "../../core/error"
import {
    Channel,
    ChannelDto,
    ChannelProcessor,
    ChannelNames,
    WebChatAdditionalData,
    WebChatAdditionalDataDict
} from "../../models/channel"
import { ChannelDeclarations, ChannelDeclarationsDto, WebhooksPrefixes } from "../../models/parameterDeclaration"
import { mapChannelDtoToChannel, mapToChannelDeclarations } from "../../utility/channels/channelTranslator"
import { ChannelScenario } from "../../models/scenario"

export type ChannelsState = Readonly<{
    channels: AsyncState<Channel[]>
    channelsDeclarations: AsyncState<ChannelDeclarations>
    createChannel: AsyncState<void>
    updateChannel: AsyncState<void>
    deleteChannel: AsyncState<void>
    channelsState: { [id: string]: "wait" | "ready" }
    additionalData: WebChatAdditionalDataDict
    webhooksPrefixes: AsyncState<WebhooksPrefixes>
    channelNames: AsyncState<ChannelNames>
    channelScenario: AsyncState<ChannelScenario>
    channelsDataWithoutConfigs: AsyncState<ChannelDto[]>
}>

type GetChannelsAction = {
    processor: ChannelProcessor
    declarations: ChannelDeclarations
}
type ChangeChannelAction = {
    channel: ChannelDto
    declarations: ChannelDeclarations
}

type SetWebChatAdditionalDataAction = {
    channelId: string
    data: WebChatAdditionalData
}

const initialState: ChannelsState = {
    channels: AsyncState.create(),
    channelsDeclarations: AsyncState.create(),
    createChannel: AsyncState.create(),
    updateChannel: AsyncState.create(),
    deleteChannel: AsyncState.create(),
    channelsState: {},
    additionalData: {},
    webhooksPrefixes: AsyncState.create(),
    channelNames: AsyncState.create(),
    channelScenario: AsyncState.create(),
    channelsDataWithoutConfigs: AsyncState.create()
}

const channels = createSlice({
    name: "channels",
    initialState,
    reducers: {
        getChannelsProcess(state) {
            state.channels = state.channels.toProcess()
        },
        getChannelsSuccess(state, action: PayloadAction<GetChannelsAction>) {
            const {
                processor: { channels, webChatAdditionalData },
                declarations
            } = action.payload
            state.channels = state.channels.toSuccess(channels.map(c => mapChannelDtoToChannel(c, declarations)))
            state.additionalData = { ...(webChatAdditionalData ?? {}) }
        },
        getChannelsFailed(state, action: PayloadAction<SystemError>) {
            state.channels = state.channels.toFailed(action.payload)
        },
        getChannelsDeclarationsProcess(state) {
            state.channelsDeclarations = state.channelsDeclarations.toProcess()
        },
        getChannelsDeclarationsSuccess(state, action: PayloadAction<ChannelDeclarationsDto>) {
            state.channelsDeclarations = state.channelsDeclarations.toSuccess(mapToChannelDeclarations(action.payload))
        },
        getChannelsDeclarationsFailed(state, action: PayloadAction<SystemError>) {
            state.channelsDeclarations = state.channelsDeclarations.toFailed(action.payload)
        },
        createChannelProcess(state) {
            state.createChannel = state.createChannel.toProcess()
        },
        createChannelSuccess(state, action: PayloadAction<ChangeChannelAction>) {
            const { channel, declarations } = action.payload
            state.createChannel = state.createChannel.toSuccess()
            state.channelsState = {
                ...state.channelsState,
                [channel.Id]: "wait"
            }
            state.channels = state.channels.map(v => [...v, { ...mapChannelDtoToChannel(channel, declarations) }])
        },
        createChannelFailed(state, action: PayloadAction<SystemError>) {
            state.createChannel = state.createChannel.toFailed(action.payload)
        },
        updateChannelProcess(state) {
            state.updateChannel = state.updateChannel.toProcess()
        },
        updateChannelSuccess(state, action: PayloadAction<ChangeChannelAction>) {
            const { channel, declarations } = action.payload
            const processedChannel = {
                ...mapChannelDtoToChannel(channel, declarations)
            }
            state.updateChannel = state.updateChannel.toSuccess()
            state.channels = state.channels.map(v => v.map(c => (c.Id === processedChannel.Id ? processedChannel : c)))
        },
        updateChannelFailed(state, action: PayloadAction<SystemError>) {
            state.updateChannel = state.updateChannel.toFailed(action.payload)
        },
        setWebChatAdditionalData(state, action: PayloadAction<SetWebChatAdditionalDataAction>) {
            const { channelId, data } = action.payload
            state.channelsState = {
                ...state.channelsState,
                [channelId]: "ready"
            }
            state.additionalData = {
                ...state.additionalData,
                [channelId]: data
            }
        },
        setChannelReady(state, action: PayloadAction<string>) {
            state.channelsState = {
                ...state.channelsState,
                [action.payload]: "ready"
            }
        },
        deleteChannelProcess(state) {
            state.deleteChannel = state.deleteChannel.toProcess()
        },
        deleteChannelSuccess(state, action: PayloadAction<string>) {
            state.deleteChannel = state.deleteChannel.toSuccess()
            state.channels = state.channels.map(v => v.filter(channel => channel.Id !== action.payload))
        },
        deleteChannelFailed(state, action: PayloadAction<SystemError>) {
            state.deleteChannel = state.deleteChannel.toFailed(action.payload)
        },
        getWebhooksPrefixesProcess(state) {
            state.webhooksPrefixes = state.webhooksPrefixes.toProcess()
        },
        getWebhooksPrefixesSuccess(state, action: PayloadAction<WebhooksPrefixes>) {
            state.webhooksPrefixes = state.webhooksPrefixes.toSuccess(action.payload)
        },
        getWebhooksPrefixesFailed(state, action: PayloadAction<SystemError>) {
            state.webhooksPrefixes = state.webhooksPrefixes.toFailed(action.payload)
        },
        getChannelsNamesProcess(state) {
            state.channelNames = state.channelNames.toProcess()
        },
        getChannelsNamesSuccess(state, action: PayloadAction<ChannelNames>) {
            state.channelNames = state.channelNames.toSuccess(action.payload)
        },
        getChannelsNamesFailed(state, action: PayloadAction<SystemError>) {
            state.channelNames = state.channelNames.toFailed(action.payload)
        },
        getChannelScenarioProcess(state) {
            state.channelScenario = state.channelScenario.toProcess()
        },
        getChannelScenarioSuccess(state, action: PayloadAction<ChannelScenario>) {
            state.channelScenario = state.channelScenario.toSuccess(action.payload)
        },
        getChannelScenarioFailed(state, action: PayloadAction<SystemError>) {
            state.channelScenario = state.channelScenario.toFailed(action.payload)
        }
    }
})

export default channels.reducer

export const actions = channels.actions
