import React, { useCallback, useEffect, useMemo } from "react"
import "./WebChatChannelForm.scss"
import { WebChatChannelValues } from "../../models/webChatValues"
import { Formik } from "formik"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import Tab from "react-bootstrap/Tab"
import { getValuesFromWebChatChannel, getWebChatDefaultValues } from "../../utility/channels/webChatValues"
import { ChannelFormProps } from "../ChannelForm/ChannelForm"
import { createChannel, updateChannel } from "../../store/channels/thunks"
import { TabPaneElement } from "../ValidatableFormTabs/ValidatableFormTabs"
import { selectCurrentProject } from "../../store/projects/selectors"
import { getValidationSchema } from "../../utility/channels/channelValidation"
import { buildWebChatChannelRequest } from "../../utility/channels/channelRequest"
import { generateChannelId } from "../../utility/channels/channel"
import FormikWebChatChannelFormGeneral from "./WebChatChannelGeneral"
import FormikWebChatChannelFormAppearance from "./WebChatChannelAppearance"
import FormikWebChatChannelFormAdvanced from "./WebChatChannelAdvanced"
import { isWebChat, isWebChatChannelDeclaration } from "../../utility/channels/channelTranslator"
import { saveNotificationError } from "../../store/notifications/thunks"
import { createSystemError } from "../../core/error"
import { FETCH_CHANNELS_DECLARATIONS_FAILED_MESSAGE } from "../../store/channels/constants"
import { errorTranslation } from "../../locales/error"
import OnSubmitValidationError from "../OnSubmitValidationError/OnSubmitValidationError"
import { getSlots } from "../../store/slots/thunks"

const WebChatChannelForm: React.FC<ChannelFormProps> = props => {
    const { t } = useTranslation()
    const project = useSelector(selectCurrentProject)
    const dispatch = useDispatch()
    const { channel, channelType, tabEntries, declarations, submitCallback, validateTabs } = props

    const mainRef = React.useRef<TabPaneElement>(null)
    const appearanceRef = React.useRef<TabPaneElement>(null)
    const advancedRef = React.useRef<TabPaneElement>(null)

    const validateWebChatTabs = useCallback(() => {
        validateTabs && validateTabs([mainRef, appearanceRef, advancedRef])
    }, [validateTabs])

    const showDeclarationsError = useCallback(() => {
        const error = createSystemError(FETCH_CHANNELS_DECLARATIONS_FAILED_MESSAGE, {
            reason: "Declarations for webchat not exists",
            reasonKey: errorTranslation.webchatDeclarationsNotExist
        })
        saveNotificationError(dispatch, error)
    }, [dispatch])

    const channelId = useMemo(() => (channel ? channel.Id : generateChannelId()), [channel])
    const validationSchema = useMemo(() => getValidationSchema(channelType), [channelType])

    const initialValues = useMemo(
        () =>
            project && declarations && declarations.webchat
                ? channel && isWebChat(channel)
                    ? getValuesFromWebChatChannel(project.language, channel, declarations.webchat)
                    : getWebChatDefaultValues(channelId, project.language, declarations.webchat)
                : null,
        [channel, channelId, declarations, project]
    )

    useEffect(() => {
        if (project) {
            dispatch(getSlots(project.id))
        }
    }, [project])

    if (!project || (channel && !isWebChat(channel)) || !initialValues) {
        return null
    }

    if (!isWebChatChannelDeclaration(declarations.webchat)) {
        showDeclarationsError()
        return null
    }
    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values: WebChatChannelValues) => {
                if (!declarations.webchat) {
                    showDeclarationsError()
                    return
                }
                const request = buildWebChatChannelRequest(project.id, values)
                if (channel) {
                    dispatch(updateChannel(request, declarations, submitCallback))
                } else {
                    dispatch(createChannel(request, declarations, submitCallback))
                }
            }}
        >
            {formikProps => (
                <>
                    <OnSubmitValidationError formikProps={formikProps} onCallback={validateWebChatTabs} />
                    <Tab.Pane eventKey={tabEntries[0].key} ref={mainRef}>
                        <FormikWebChatChannelFormGeneral {...props} {...formikProps} t={t} />
                    </Tab.Pane>
                    <Tab.Pane eventKey={tabEntries[1].key} ref={appearanceRef}>
                        <FormikWebChatChannelFormAppearance {...props} {...formikProps} t={t} />
                    </Tab.Pane>
                    <Tab.Pane eventKey={tabEntries[2].key} ref={advancedRef}>
                        <FormikWebChatChannelFormAdvanced {...props} {...formikProps} t={t} />
                    </Tab.Pane>
                </>
            )}
        </Formik>
    )
}

export default WebChatChannelForm
