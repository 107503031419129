import { Hub } from "../hub"
import { IHub } from "../interfaces/IHub"
import { ApiMessage } from "../../models/Dialogs/message"
import { DialogBadge } from "../../models/Dialogs/dialog"
import store, { Store } from "../../store/store"
import i18next from "i18next"
import { push } from "connected-react-router"
import { actions } from "../../store/dialogs/slice"
import { buildClientFullnameHelper, getBadgeByClientIdHelper, notificationAudioNockHelper } from "./helpers"
import { pushNotification } from "../../utility/notifications/pushNotifications"
import { logDebug } from "../../utility/common/logError"
import { MESSAGES_PER_PAGE } from "../../components/Dialogs/DialogChat/DialogChat"
import { NotificationsTypes, useTabNotification } from "../../utility/common/useTabNotification"
import { dialogsApi } from "../controllers/dialogs"

export const HANDLE_ROUTED_DIALOG = "HandleRoutedDialog"
export const HANDLE_MESSAGE = "HandleMessage"
export const DETACH_DIALOG = "DetachDialog"

const tDialogsNotificationsNamespace = "dialogs:notifications."

class DialogsHubV2 {
    private _hub: IHub
    private _store: Store

    constructor(store: Store) {
        const reduxState = store.getState()
        const useAllTransportSignalR =
            reduxState.config.config.data?.WebConfig.appSettings.useAllTransportSignalR === true

        this._hub = new Hub(`/dialogs-hub-v2`, useAllTransportSignalR)
        this._store = store

        this.registerServerEvents()
    }

    async subscribe() {
        await this._hub.subscribe("Subscribe")
    }

    async unsubscribe() {
        await this._hub.unsubscribe("Unsubscribe")
    }

    getInstance(): IHub {
        return this._hub
    }

    private checkOldWorkspaceLoaded() {
        const nodeEl = document.querySelector('[name="crafttalk-workplace-old-frame"]')

        if (nodeEl) {
            throw new Error("Old workspace frame node already loaded. Passing")
        }
    }

    private registerServerEvents() {
        const dispatch = this._store.dispatch

        this._hub.registerEvent(HANDLE_MESSAGE, async data => {
            try {
                this.checkOldWorkspaceLoaded()

                const incomingEntity = data as ApiMessage
                const currentOperator = store.getState().users.currentUser

                if (incomingEntity.Case !== "Message") {
                    return
                }

                if (
                    incomingEntity.Fields.Sender.Id === currentOperator.data?.Login ||
                    incomingEntity.Fields.Sender.Id === `DEFAULT___${currentOperator.data?.Login}`
                ) {
                    return
                }

                dispatch(
                    dialogsApi.endpoints.getActiveDialogsCount.initiate(undefined, {
                        forceRefetch: true
                    })
                )

                const badge = await getBadgeByClientIdHelper(incomingEntity.Fields.Sender.Id, dispatch)

                const client = badge?.Client
                const clientId = client!.OmniUserId!
                const unreadMessageCount = badge?.UnreadMessages || 0

                const title =
                    buildClientFullnameHelper(client) || i18next.t(`${tDialogsNotificationsNamespace}unknown-client`)!

                const body = [
                    i18next.t(`${tDialogsNotificationsNamespace}unread-messages`, {
                        value: unreadMessageCount + 1
                    }),
                    incomingEntity.Fields.Text
                ].join(" ")

                const projectId = badge!.Project.Id!
                const dialogId = incomingEntity.Fields.DialogId

                dispatch(dialogsApi.endpoints.putDisableDialogHold.initiate(dialogId))
                notificationAudioNockHelper()

                pushNotificationWrapHelper({
                    title,
                    body,
                    projectId,
                    dialogId,
                    clientId,
                    dispatch
                })

                useTabNotification(NotificationsTypes.NewMessage, String(unreadMessageCount + 1))
            } catch (e) {
                logDebug(e)
            }
        })

        this._hub.registerEvent(HANDLE_ROUTED_DIALOG, async data => {
            try {
                this.checkOldWorkspaceLoaded()

                dispatch(
                    dialogsApi.endpoints.getActiveDialogsCount.initiate(undefined, {
                        forceRefetch: true
                    })
                )

                const incomingEntity = data as { Badge: DialogBadge }
                const title = i18next.t(`${tDialogsNotificationsNamespace}new-dialog`)
                const client = incomingEntity.Badge.Client
                const clientId = client.OmniUserId

                const clientFullname =
                    buildClientFullnameHelper(client) || i18next.t(`${tDialogsNotificationsNamespace}unknown-client`)!

                const body = [i18next.t(`${tDialogsNotificationsNamespace}client`), clientFullname].join(" ")
                const projectId = incomingEntity.Badge.Project.Id
                const dialogId = incomingEntity.Badge.Id

                notificationAudioNockHelper()

                pushNotificationWrapHelper({
                    title,
                    body,
                    projectId,
                    dialogId,
                    clientId,
                    dispatch
                })

                useTabNotification(NotificationsTypes.DialogRouted)
            } catch (e) {
                logDebug(e)
            }
        })
    }
}

export default DialogsHubV2

export interface IPushNotificationWrapHelperFnArgs {
    title: string
    body: string
    dialogId: string
    projectId: string
    clientId: string
    dispatch: Store["dispatch"]
}

export const pushNotificationWrapHelper = ({
    title,
    body,
    projectId,
    dialogId,
    clientId,
    dispatch
}: IPushNotificationWrapHelperFnArgs) => {
    const isPushNotificationEnabled =
        store.getState().project.settings?.Operator?.Notifications?.PushNotificationEnabled

    if (!isPushNotificationEnabled) {
        return null
    }

    const pushNotificationTimeout = store.getState().project.settings?.Operator?.Notifications?.PushNotificationTimeout
    const timeout = pushNotificationTimeout ? pushNotificationTimeout * 1000 : undefined

    return pushNotification({
        title,
        body,
        onClick() {
            dispatch(push(`/project/${projectId}/dialogs`))
            dispatch(actions.setCurrentDialogId(dialogId))
            dispatch(actions.unsetCurrentOperatorClientId())

            window.focus()

            dispatch(
                actions.setMessagesPaginationState({
                    OmniUserId: clientId,
                    StartTime: 0,
                    Count: MESSAGES_PER_PAGE,
                    FromTodayDialogs: true,
                    ProjectId: projectId
                })
            )
        },
        timeout
    })
}
