import React, { useCallback, useEffect, useRef, useState } from "react"
import "./ChannelScenario.scss"
import PageLayout from "../../components/PageLayout/PageLayout"
import Can from "../../components/Can/Can"
import { ModifyChannels } from "../../permissions"
import AddButton from "../../components/AddButton/AddButton"
import cn from "classnames"
import { useTranslation } from "react-i18next"
import { WithTitle } from "../../utility/common/withTitle"
import BlockTypeFormForChannels from "../../components/BlockTypeForm/BlockTypeFormForChannels"
import ChooseArticleForm from "../../components/ChooseArticleForm/ChooseArticleForm"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { selectCurrentProjectId } from "../../store/projects/selectors"
import { Channel } from "../../models/channel"
import ChannelForm from "../../components/ChannelForm/ChannelForm"
import usePermissionsCheck from "../../utility/common/usePermissionsCheck"
import ChannelTypeForm from "../../components/ChannelTypeForm/ChannelTypeForm"
import { addChannelBlock, updateChannelBlock } from "../../utility/scenario/scenarioGraph"
import { Elements, isEdge, OnLoadParams } from "react-flow-renderer"
import { getChannels, getChannelScenario, getChannelsDeclarations } from "../../store/channels/thunks"
import {
    selectChannels,
    selectChannelScenario,
    selectChannelsDeclarations,
    selectGetChannelScenarioState,
    selectGetChannelsState
} from "../../store/channels/selectors"
import ScenarioGraph from "../../components/Graph/ScenarioGraph"
import { Connection, Scenario, ScenarioBlockType } from "../../models/scenario"
import ArticleNode from "../../components/Graph/nodes/Article/ArticleNode"
import ChannelNode from "../../components/Graph/nodes/Channel/ChannelNode"
import { logError } from "../../utility/common/logError"
import { channelScenarioToGraph } from "../../utility/scenario/scenario"
import { ScenarioContext } from "../../components/ScenarioEditor/ScenarioContext"
import Skeleton from "../../components/Skeleton/Skeleton"
import ErrorMessage from "../../components/ErrorMessage/ErrorMessage"
import AsyncState from "../../core/asyncState"
import AsyncWithoutData from "../../components/Async/AsyncWithoutData"

const tNamespace = "scenarioEditor:"
const tChannelNamespace = "channel:"

const nodeTypes = {
    [ScenarioBlockType.Channel]: ChannelNode,
    [ScenarioBlockType.Article]: ArticleNode
}

enum SidebarContentType {
    chooseBlockTypeForm,
    chooseArticleForm,
    chooseChannelTypeForm,
    channelForm
}

interface SidebarContentBase extends WithTitle {
    stepBack?: boolean
    onBack?: () => void
}

interface ChooseBlockTypeFormContent extends SidebarContentBase {
    type: SidebarContentType.chooseBlockTypeForm
    connection: Connection
}

interface ChooseArticleFormContent extends SidebarContentBase {
    type: SidebarContentType.chooseArticleForm
    connection: Connection
}

interface ChooseChannelTypeFormContent extends SidebarContentBase {
    type: SidebarContentType.chooseChannelTypeForm
    connection: Connection
}

interface ChannelFormContent extends SidebarContentBase {
    type: SidebarContentType.channelForm
    channelType: string
    channel?: Channel
    connection?: Connection
}

type SidebarContent =
    | ChooseBlockTypeFormContent
    | ChooseArticleFormContent
    | ChooseChannelTypeFormContent
    | ChannelFormContent

const ChannelScenario: React.FC = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const scenarioRef = useRef<Scenario>()
    const channelsInitialized = useRef(false)

    const scenario = useSelector(selectChannelScenario)
    const channels = useSelector(selectChannels)

    const projectId = useSelector(selectCurrentProjectId)
    const editAllowed = usePermissionsCheck([ModifyChannels])
    const channelDeclarations = useSelector(selectChannelsDeclarations)

    const [sidebarClosed, setSidebarClosed] = useState(true)
    const [sidebarContent, setSidebarContent] = useState<SidebarContent | null>(null)

    const closeSidebar = useCallback(() => setSidebarClosed(true), [])
    const openSidebar = useCallback(() => setSidebarClosed(false), [])

    // Scenario state
    const [elements, setElements] = useState<Elements>([])
    const [instance, setInstance] = useState<OnLoadParams | null>(null)
    const [selectedNode, setSelectedNode] = useState<string | null>(null)

    const handleAddBlock = useCallback(
        (source?: string, sourceHandle?: string) => {
            closeSidebar()
            setSidebarContent({
                type: SidebarContentType.chooseBlockTypeForm,
                title: t(`${tNamespace}add-block`),
                connection: { source, sourceHandle }
            })
            openSidebar()
        },
        [closeSidebar, openSidebar, t]
    )

    const handleAddArticle = useCallback(
        (connection: Connection) => {
            closeSidebar()
            setSidebarContent({
                type: SidebarContentType.chooseArticleForm,
                title: t(`${tNamespace}go-to-article`),
                connection,
                stepBack: true,
                onBack: handleAddBlock
            })
            openSidebar()
        },
        [openSidebar, closeSidebar, t, handleAddBlock]
    )

    const handleAddChannelBlock = useCallback(
        (connection: Connection) => {
            closeSidebar()
            setSidebarContent({
                type: SidebarContentType.chooseChannelTypeForm,
                title: t(`${tChannelNamespace}add-channel`),
                connection,
                stepBack: true,
                onBack: handleAddBlock
            })
            openSidebar()
        },
        [openSidebar, closeSidebar, t, handleAddBlock]
    )

    const handleOpenChannelForm = useCallback(
        (channelType: string, channel?: Channel, connection?: Connection) => {
            closeSidebar()
            setSidebarContent({
                type: SidebarContentType.channelForm,
                title: t(`${tChannelNamespace}channel-settings`),
                connection,
                channel,
                channelType,
                ...(connection && {
                    stepBack: true,
                    onBack: () => handleAddChannelBlock(connection)
                })
            })
            openSidebar()
        },
        [openSidebar, closeSidebar, t, handleAddChannelBlock]
    )

    const renderSidebarContent = () => {
        if (!sidebarContent) return null

        switch (sidebarContent.type) {
            case SidebarContentType.chooseBlockTypeForm:
                return (
                    <BlockTypeFormForChannels
                        onSelect={closeSidebar}
                        onAddArticle={handleAddArticle}
                        onAddChannel={handleAddChannelBlock}
                        connection={sidebarContent.connection}
                    />
                )
            case SidebarContentType.chooseArticleForm: {
                return (
                    <ChooseArticleForm
                        projectId={projectId}
                        onSelect={closeSidebar}
                        connection={sidebarContent.connection}
                    />
                )
            }
            case SidebarContentType.chooseChannelTypeForm:
                return (
                    <ChannelTypeForm
                        onSelect={(type: string) => {
                            handleOpenChannelForm(type, undefined, sidebarContent.connection)
                        }}
                    />
                )
            case SidebarContentType.channelForm:
                return (
                    <ChannelForm
                        channel={sidebarContent.channel}
                        channelType={sidebarContent.channelType}
                        submitCallback={(channel: Channel) => {
                            closeSidebar()
                            if (instance) {
                                sidebarContent.connection
                                    ? addChannelBlock(
                                          channel,
                                          handleAddBlock,
                                          setElements,
                                          instance,
                                          sidebarContent.connection
                                      )
                                    : updateChannelBlock(channel, setElements)
                            }
                        }}
                        disabled={!editAllowed}
                    />
                )
            default:
                return null
        }
    }

    const handleSelectionChange = (selections: Elements, selectedId: string | null) => {
        let isChannelSelected = false

        setElements(els =>
            els.map(e => {
                if (isEdge(e)) return e

                if (e.id === selectedId && e.type === ScenarioBlockType.Channel) {
                    if (e.data.Channel) {
                        handleOpenChannelForm(e.data.Channel.Type, e.data.Channel)
                        isChannelSelected = true
                    } else {
                        logError("Channel for block not exist:", e.data.ChannelId)
                    }
                }

                return e
            })
        )

        if (!isChannelSelected) closeSidebar()
    }

    useEffect(() => {
        if (scenario && channels && scenarioRef.current !== scenario && !channelsInitialized.current) {
            scenarioRef.current = scenario
            channelsInitialized.current = true
            setElements(channelScenarioToGraph(scenario, handleAddBlock, setElements, channels))
        }
    }, [handleAddBlock, setElements, scenario, channels])

    useEffect(() => {
        if (!channelDeclarations) {
            dispatch(getChannelsDeclarations())
        }
    }, [channelDeclarations, dispatch])

    useEffect(() => {
        dispatch(getChannelScenario())
    }, [dispatch])

    useEffect(() => {
        projectId && channelDeclarations && dispatch(getChannels(projectId, channelDeclarations))
    }, [dispatch, projectId, channelDeclarations])

    const apiState = AsyncState.merge2(
        useSelector(selectGetChannelsState, shallowEqual),
        useSelector(selectGetChannelScenarioState, shallowEqual)
    )

    return (
        <PageLayout isSidebarCollapsed={sidebarClosed}>
            <ScenarioContext.Provider
                value={{
                    elements,
                    setElements,
                    instance,
                    setInstance,
                    selectedNode,
                    setSelectedNode,
                    handleAddBlock,
                    closeSidebar,
                    projectId
                }}
            >
                <PageLayout.Content className="channel-scenario" overlappingSidebar>
                    <div className="channel-scenario__header">
                        <span className="channel-scenario__title">{t(`${tNamespace}start-scenario`)}</span>
                        <Can permission={ModifyChannels}>
                            <AddButton
                                variant="outline-primary"
                                onClick={() => handleAddBlock()}
                                className={cn("channel-scenario__btn-add", !sidebarClosed ? "hidden" : undefined)}
                                text={t(`${tNamespace}add-block`)}
                                disabled={!apiState.data || !editAllowed}
                            />
                        </Can>
                    </div>
                    <div className="channel-scenario__editor">
                        <AsyncWithoutData
                            state={apiState}
                            processView={
                                <div className="channel-scenario__loader">
                                    <Skeleton />
                                </div>
                            }
                            errorView={({ message }) => <ErrorMessage text={message} />}
                        >
                            <ScenarioGraph onSelectionChange={handleSelectionChange} nodeTypes={nodeTypes} />
                        </AsyncWithoutData>
                    </div>
                </PageLayout.Content>
                <PageLayout.Sidebar
                    title={sidebarContent ? sidebarContent.title : ""}
                    onClose={closeSidebar}
                    stepBack={sidebarContent ? sidebarContent.stepBack : false}
                    onBack={sidebarContent ? sidebarContent.onBack : undefined}
                >
                    {renderSidebarContent()}
                </PageLayout.Sidebar>
            </ScenarioContext.Provider>
        </PageLayout>
    )
}

export default ChannelScenario
