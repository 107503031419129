import cn from "classnames"
import styles from "./GalleryPreviewer.module.scss"
import { faAngleLeft, faAngleRight, faArrowToBottom, faTimes } from "@fortawesome/pro-light-svg-icons"
import { useActions } from "../../hooks/useAction"
import { useAppSelector } from "../../store/store"
import { useCallback, useMemo } from "react"
import { ItemType, getFileInfo, selectIcon } from "../AttachmentItem/AttachmentItem"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { fetchFileVirtual } from "../../helpers/file"
import DialogSystem from "../DialogSystem/DialogSystem"
import { selectDialogMeta } from "../../store/dialogSystem/selectors"
import { testId } from "../../utility/tests/testId"
import noPhotoImg from "../../assets/images/nophoto.jpg"

export type TGalleryViewDialogStateItem = {
    url: string
    name: string
    mime: string
}

export type TGalleryViewDialogStateMeta = TGalleryViewDialogStateItem & {
    items: TGalleryViewDialogStateItem[]
}

export const GALLERY_VIEW_DIALOG_ID = "GALLERY_VIEW_DIALOG_ID"

export type IGalleryViewDialogProps = Partial<React.ComponentPropsWithRef<typeof DialogSystem>>

export const GalleryViewDialog: React.FC<IGalleryViewDialogProps> = ({ ...props }) => {
    const { hideDialog, setDialogMeta } = useActions()

    const metaData = useAppSelector(
        state => selectDialogMeta(state, GALLERY_VIEW_DIALOG_ID) as TGalleryViewDialogStateMeta
    )

    const handleDir = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>, dir: "prev" | "next") => {
            e.stopPropagation()

            const currUrl = metaData.url
            let currItemIdx = metaData.items.findIndex(x => x.url === currUrl)
            let nextItemEntity = null

            if (dir === "next") {
                currItemIdx++
                nextItemEntity = metaData.items[currItemIdx]

                if (!nextItemEntity) {
                    nextItemEntity = metaData.items[0]
                }
            } else {
                currItemIdx--
                nextItemEntity = metaData.items[currItemIdx]

                if (!nextItemEntity) {
                    nextItemEntity = metaData.items[metaData.items.length - 1]
                }
            }

            setDialogMeta({
                dialogId: GALLERY_VIEW_DIALOG_ID,
                dialogMeta: {
                    ...metaData,
                    ...nextItemEntity
                }
            })
        },
        [metaData]
    )

    const handleDownload = useCallback(() => {
        if (!metaData?.url) {
            return
        }

        fetchFileVirtual(metaData.url, metaData.name)
    }, [metaData])

    const handleImgLoadError = useCallback((e: React.SyntheticEvent<HTMLImageElement, Event>) => {
        e.currentTarget.src = noPhotoImg
        e.currentTarget.onerror = null
    }, [])

    const mimeContent = useMemo(() => {
        if (!metaData?.url) {
            return null
        }

        const { itemType, fileExtension } = getFileInfo(metaData.url)

        switch (itemType) {
            case ItemType.file:
            case ItemType.video:
                return (
                    <div className={styles.galleryPreviewer__iconWrapper}>
                        <FontAwesomeIcon color="#1066A8" size="10x" icon={selectIcon(itemType, fileExtension)} />
                    </div>
                )
            case ItemType.image:
                return (
                    <div className={styles.galleryPreviewer__imageWrapper}>
                        <img
                            src={metaData.url}
                            className={cn(
                                styles.galleryPreviewer__attachmentImage,
                                "galleryPreviewer__attachmentImage"
                            )}
                            alt={metaData.name}
                            onError={handleImgLoadError}
                        />
                    </div>
                )
        }
    }, [metaData])

    return (
        <DialogSystem {...props} dialogId={GALLERY_VIEW_DIALOG_ID} hideDisabled>
            <div
                data-testid={testId.galleryPreviewer}
                className={cn(styles.galleryPreviewer__container, "galleryPreviewer__container-main")}
            >
                <header className={styles.galleryPreviewer__toolbar}>
                    <h4 className={styles.galleryPreviewer__title}>{metaData?.name}</h4>
                    <div className={styles.galleryPreviewer__manage}>
                        <button className={styles.galleryPreviewer__manageButton} onClick={handleDownload}>
                            <FontAwesomeIcon color="#3a3a3a" size="3x" icon={faArrowToBottom} />
                        </button>
                        <button
                            className={styles.galleryPreviewer__manageButton}
                            onClick={() =>
                                hideDialog({
                                    dialogId: GALLERY_VIEW_DIALOG_ID
                                })
                            }
                        >
                            <FontAwesomeIcon color="#3a3a3a" size="3x" icon={faTimes} />
                        </button>
                    </div>
                </header>
                <div
                    className={styles.galleryPreviewer__content}
                    onClick={() =>
                        hideDialog({
                            dialogId: GALLERY_VIEW_DIALOG_ID
                        })
                    }
                >
                    <button
                        onClick={e => handleDir(e, "prev")}
                        className={cn(
                            styles.galleryPreviewer__contentButton,
                            styles.galleryPreviewer__contentButton_prev
                        )}
                    >
                        <FontAwesomeIcon color="#fff" size="3x" icon={faAngleLeft} />
                    </button>
                    {mimeContent}
                    <button
                        onClick={e => handleDir(e, "next")}
                        className={cn(
                            styles.galleryPreviewer__contentButton,
                            styles.galleryPreviewer__contentButton_next
                        )}
                    >
                        <FontAwesomeIcon color="#fff" size="3x" icon={faAngleRight} />
                    </button>
                </div>
            </div>
        </DialogSystem>
    )
}
