import { useTranslation } from "react-i18next"
import styles from "./AIAssistent.module.scss"
import cn from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFileAlt, faLightbulbExclamation } from "@fortawesome/pro-light-svg-icons"
import { useAppDispatch, useAppSelector } from "../../../store/store"
import { selectAISuggest } from "../../../store/dialogs/selectors"
import Spinner from "../../Spinner/Spinner"
import { useCallback } from "react"
import { actions } from "../../../store/dialogs/slice"
import { selectCurrentProjectId } from "../../../store/projects/selectors"
import { selectCurrentBranch } from "../../../store/knowledgeBase/selectors"
import { selectCurrentUserLogin } from "../../../store/users/selectors"
import { getArticle, getRelatedCategories } from "../../../store/knowledgeBase/thunks"
import Async from "../../Async/Async"
import ErrorMessage from "../../ErrorMessage/ErrorMessage"

const tAiAssistentNamespace = "dialogs:aiassistent"

const AIAssistentSuggest: React.FC = () => {
    const { t } = useTranslation()
    const aISuggest = useAppSelector(selectAISuggest)
    const dispatch = useAppDispatch()
    const projectId = useAppSelector(selectCurrentProjectId)
    const { CurrentCatalogCode, CurrentCategoryId } = useAppSelector(selectCurrentBranch)
    const login = useAppSelector(selectCurrentUserLogin)

    const handleOpenKBArticleById = useCallback(
        (symbCodeId: string) => {
            if (!projectId || !login) {
                return
            }

            dispatch(actions.openArticleSidebar())
            dispatch(getRelatedCategories(projectId, CurrentCategoryId, symbCodeId, CurrentCatalogCode))
            dispatch(getArticle(projectId, login, symbCodeId, false))
        },
        [dispatch, login, projectId, CurrentCategoryId, CurrentCatalogCode]
    )

    return (
        <div className={styles.relatedAticles}>
            <header>{t(`${tAiAssistentNamespace}.articles`)}</header>
            <div className={styles.relatedAticles__list}>
                <Async
                    dataState={aISuggest}
                    processView={<Spinner size={30} className={styles.loader} />}
                    errorView={({ message }) => <ErrorMessage text={message} />}
                    nonDataView={
                        <div className={styles.relatedAticles__viewport}>
                            <span className={styles.errorMessage}>
                                <FontAwesomeIcon icon={faLightbulbExclamation} size="2x" />
                                {t(`${tAiAssistentNamespace}.suggest-no-content`)}
                            </span>
                        </div>
                    }
                    validator={data => !!data?.length}
                >
                    {({ data }) => (
                        <div className={styles.relatedAticles__viewport}>
                            {data.map(x => (
                                <div
                                    key={x.intent.intent_id}
                                    className={cn(
                                        styles.relatedAticles__item,
                                        styles.aiAssistent__contentBox_clickable
                                    )}
                                    onClick={() => handleOpenKBArticleById(x.intent.symbol_code)}
                                >
                                    <FontAwesomeIcon icon={faFileAlt} size="lg" />
                                    <span className={styles.relatedAticles__item_title}>{x.intent.title}</span>
                                    <span className={styles.relatedAticles__item_percent}>
                                        {Math.round(x.score * 100)}%
                                    </span>
                                </div>
                            ))}
                        </div>
                    )}
                </Async>
            </div>
        </div>
    )
}

export { AIAssistentSuggest }
