import React, { useRef } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import TextareaAutosize from "react-textarea-autosize"
import PopoverTrigger from "../PopoverTrigger/PopoverTrigger"
import { faGripLinesVertical } from "@fortawesome/pro-light-svg-icons/faGripLinesVertical"
import { faCog } from "@fortawesome/pro-light-svg-icons/faCog"
import cn from "classnames"
import CloseButton from "../CloseButton/CloseButton"
import IconControl from "../IconControl/IconControl"
import { ClassProps } from "../../utility/common/props"
import { DraggableProvided } from "react-beautiful-dnd"
import style from "./ContentButton.module.scss"
import { FieldInputProps } from "formik"

export interface ContentButtonProps extends ClassProps {
    disabled: boolean
    content: React.ReactNode
    onHandleClick?: () => void
    defaultValue: string
    onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void
    placeholder?: string
    onDelete: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
    provided?: DraggableProvided
    shown: boolean
    onShowPopover: () => void
    onHidePopover: () => void
    field?: FieldInputProps<string>
}

export const ContentButton: React.FC<ContentButtonProps> = props => {
    const {
        className,
        disabled,
        content,
        defaultValue,
        onBlur,
        placeholder,
        onDelete,
        provided,
        shown,
        onShowPopover,
        onHidePopover,
        field
    } = props
    const ref = useRef<HTMLDivElement>(null)
    return (
        <div ref={ref} className={cn(style.contentButton, className, disabled && style.contentButton_disabled)}>
            <PopoverTrigger
                content={content}
                className={style.contentButton__popover}
                id="edit-initial-button"
                show={shown}
                placement="bottom-start"
                containerPadding={20}
                container={ref}
            >
                <div className={style.contentButton__btn}>
                    {provided && !disabled && (
                        <span {...provided.dragHandleProps} className={style.contentButton__dragIcon}>
                            <FontAwesomeIcon icon={faGripLinesVertical} size={"lg"} />
                        </span>
                    )}
                    <TextareaAutosize
                        className={style.contentButton__textarea}
                        placeholder={placeholder ? placeholder : undefined}
                        maxRows={2}
                        disabled={disabled}
                        value={field?.value ?? defaultValue}
                        onChange={field?.onChange}
                        onBlur={onBlur}
                        name={field?.name}
                    />
                    <div className={style.contentButton__settingsButtons}>
                        <IconControl
                            icon={faCog}
                            className={style.contentButton__settingsBtn}
                            onClick={event => {
                                shown ? onHidePopover() : onShowPopover()
                            }}
                        />
                        <CloseButton className={style.contentButton__settingsBtn} onClick={onDelete} />
                    </div>
                </div>
            </PopoverTrigger>
        </div>
    )
}
