import { IGetDialogTransferAvailableQueues } from "../../models/Dialogs/dialog"
import { Role } from "../../models/role"

export type TOption = {
    value: string
    label: string
}

export type TGroupedQueuesReturn = Array<{
    label: string
    options: TOption[]
}>

export const mapResponseQueuesToGroupedQueue = (
    income: IGetDialogTransferAvailableQueues["Categories"] = []
): TGroupedQueuesReturn =>
    income.map(x => ({
        label: x.Name,
        options: x.Queues.map(x => ({
            value: x.Id,
            label: x.Name
        }))
    }))

export const mapResponseRolesToListRoles = (income: Role[] = []): TOption[] =>
    income.map(x => ({
        label: x.Title,
        value: x.Id
    }))
