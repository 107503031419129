import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import AsyncState from "../../core/asyncState"
import { SystemError } from "../../core/error"
import {
    ArticleComment,
    ArticleCommentInput,
    ArticleCommentInputStates,
    ArticleCommentThread,
    NewArticleReplyAddedPayload
} from "../../models/articleComment"
import { articleCommentInputDefault } from "../../utility/knowledgeBase/articleCommentDefault"

export interface SaveArticleCommentInputValuePayload {
    ArticleCode: string
    InputValue: ArticleCommentInput
}

export type KnowledgeBaseCommentsState = Readonly<{
    inputStates: ArticleCommentInputStates
    currentComments: AsyncState<ArticleComment[]>
    currentThread: AsyncState<ArticleCommentThread>
    addComment: AsyncState<void>
    addReply: AsyncState<void>
}>

const initialState: KnowledgeBaseCommentsState = {
    inputStates: {},
    currentComments: AsyncState.create(),
    currentThread: AsyncState.create(),
    addComment: AsyncState.create(),
    addReply: AsyncState.create()
}

const cleanInput = (states: ArticleCommentInputStates, articleCode: string, ifEmpty = false) => {
    const stateCopy = { ...states }
    const inputState = stateCopy[articleCode]
    if (inputState && (!ifEmpty || inputState.MessageText.length === 0)) {
        delete stateCopy[articleCode]
    }
    return stateCopy
}

const knowledgeBaseComments = createSlice({
    name: "knowledgeBaseComments",
    initialState,
    reducers: {
        enableInputMode(state, action: PayloadAction<string>) {
            state.inputStates = {
                ...state.inputStates,
                [action.payload]: articleCommentInputDefault
            }
        },
        saveInputValue(state, action: PayloadAction<SaveArticleCommentInputValuePayload>) {
            const { ArticleCode, InputValue } = action.payload
            state.inputStates = {
                ...state.inputStates,
                [ArticleCode]: InputValue
            }
        },
        cancelInputIfEmpty(state, action: PayloadAction<string>) {
            state.inputStates = cleanInput(state.inputStates, action.payload, true)
        },
        cancelInput(state, action: PayloadAction<string>) {
            state.inputStates = cleanInput(state.inputStates, action.payload)
        },
        addCommentProcess(state) {
            state.addComment = state.addComment.toProcess()
        },
        addCommentSuccess(state, action: PayloadAction<string>) {
            state.inputStates = cleanInput(state.inputStates, action.payload)
            state.addComment = state.addComment.toSuccess(void 0)
        },
        addCommentError(state, action: PayloadAction<SystemError>) {
            state.addComment = state.addComment.toFailed(action.payload)
        },
        addReplyProcess(state) {
            state.addReply = state.addReply.toProcess()
        },
        addReplySuccess(state, action: PayloadAction<string>) {
            state.inputStates = cleanInput(state.inputStates, action.payload)
            state.addReply = state.addReply.toSuccess(void 0)
        },
        addReplyError(state, action: PayloadAction<SystemError>) {
            state.addReply = state.addReply.toFailed(action.payload)
        },
        saveComment(state, action: PayloadAction<ArticleComment>) {
            state.currentComments = state.currentComments.map(d => {
                d.unshift(action.payload)
                return d
            })
        },
        saveReply(state, action: PayloadAction<NewArticleReplyAddedPayload>) {
            state.currentComments = state.currentComments.map(d =>
                d.map(c => {
                    if (c.Id === action.payload.ThreadId) {
                        c.ReplyCount++
                    }
                    return c
                })
            )
            state.currentThread = state.currentThread.map(d => {
                if (action.payload.ThreadId === d.RootComment.Id) {
                    d.Items.push(action.payload.Value)
                }
                return d
            })
        },
        getCurrentCommentsProcess(state) {
            state.currentComments = state.currentComments.toProcess()
        },
        getCurrentCommentsSuccess(state, action: PayloadAction<ArticleComment[]>) {
            state.currentComments = state.currentComments.toSuccess(action.payload)
        },
        getCurrentCommentsFailed(state, action: PayloadAction<SystemError>) {
            state.currentComments = state.currentComments.toFailed(action.payload)
        },
        getCurrentThreadProcess(state) {
            state.currentThread = state.currentThread.toProcess()
        },
        getCurrentThreadSuccess(state, action: PayloadAction<ArticleCommentThread>) {
            state.currentThread = state.currentThread.toSuccess(action.payload)
        },
        getCurrentThreadFailed(state, action: PayloadAction<SystemError>) {
            state.currentThread = state.currentThread.toFailed(action.payload)
        }
    }
})

export default knowledgeBaseComments.reducer

export const actions = knowledgeBaseComments.actions
