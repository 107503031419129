import React, { useEffect, useState } from "react"
import { DebugWebchat } from "../WebchatCard/WebchatCard"
import styles from "./WebchatCard.module.scss"
export interface WebchatFrameProps {
    debugWebchat: DebugWebchat
}

const WebchatFrame: React.FC<WebchatFrameProps> = props => {
    const { debugWebchat } = props
    const host = document.domain?.match("localhost")
        ? process.env.REACT_APP_WEBCHAT_HOST_AND_PORT
        : window.location.origin
    const url = `${host}/webchat/scenario/${debugWebchat.channelId}/${debugWebchat.articleId}/${debugWebchat.showInput}`
    const [height, setHeight] = useState<number>()
    const processIframeMessage = (event: MessageEvent) => {
        const data = event.data
        if (data.notificationType === "WEBCHAT-INFO") {
            setHeight(data.height)
        }
    }
    useEffect(() => {
        window.addEventListener("message", processIframeMessage)
        return () => {
            window.removeEventListener("message", processIframeMessage)
        }
    }, [])
    return <iframe className={styles.webchatFrame} height={height} src={url}></iframe>
}

export default WebchatFrame
