import { QueryDefinition, QueryStatus } from "@reduxjs/toolkit/query"
import { AsyncDataProps, AsyncErrorProps } from "../../utility/common/asyncHelper"
import { BaseQueryError, BaseQueryFn } from "@reduxjs/toolkit/src/query/baseQueryTypes"
import { SerializedError } from "@reduxjs/toolkit"

// Redux Toolkit prohibits import an original UseQueryStateDefaultResult. Therefore, I just made own
// eslint-disable-next-line
type UseQueryStateBaseResult<D extends QueryDefinition<any, any, any, any>, R> = {
    /**
     * Where `data` tries to hold data as much as possible, also re-using
     * data from the last arguments passed into the hook, this property
     * will always contain the received data from the query, for the current query arguments.
     */
    currentData?: R
    /**
     * Query has not started yet.
     */
    isUninitialized: boolean
    /**
     * Query is currently loading for the first time. No data yet.
     */
    isLoading: boolean
    /**
     * Query is currently fetching, but might have data from an earlier request.
     */
    isFetching: boolean
    /**
     * Query has data from a successful load.
     */
    isSuccess: boolean
    /**
     * Query is currently in "error" state.
     */
    isError: boolean
    status: QueryStatus
    data?: R
    /**
     * The received error if applicable
     */
    error?:
        | SerializedError
        // Redux Toolkit prohibits import an original UseQueryStateDefaultResult. Therefore, I just made own
        // eslint-disable-next-line
        | (D extends QueryDefinition<any, infer BaseQuery, any, any> ? BaseQueryError<BaseQuery> : never)
    endpointName?: string
    /**
     * Time that the latest query started
     */
    startedTimeStamp?: number
    /**
     * Time that the latest query was fulfilled
     */
    fulfilledTimeStamp?: number
}

export interface AsyncQueryProps<
    QueryArg,
    BaseQuery extends BaseQueryFn,
    TagTypes extends string,
    ResultType,
    ReducerPath extends string = string,
    S = UseQueryStateBaseResult<QueryDefinition<QueryArg, BaseQuery, TagTypes, ResultType, ReducerPath>, ResultType>
> {
    query: S & { originalArgs?: QueryArg }
    defaultView?: JSX.Element
    processView?: JSX.Element
    errorView: (props: AsyncErrorProps) => JSX.Element
    children: (props: AsyncDataProps<ResultType>) => JSX.Element
    validator?: (data: S & { originalArgs?: QueryArg }) => boolean
    emptyDataView?: JSX.Element
}

function AsyncQuery<
    QueryArg,
    BaseQuery extends BaseQueryFn,
    TagTypes extends string,
    ResultType,
    ReducerPath extends string = string
>(props: AsyncQueryProps<QueryArg, BaseQuery, TagTypes, ResultType, ReducerPath>) {
    const { query, processView, children, errorView, defaultView, validator, emptyDataView = null } = props
    const { data, error, isError, isSuccess, isLoading, isUninitialized } = query

    if (isUninitialized && defaultView) {
        return defaultView
    }

    if (isLoading && processView) {
        return processView
    }

    if (isError && error) {
        // TODO add error message when real API will come
        return errorView({ message: "Error occurred" })
    }

    if (validator) {
        return isSuccess && data && validator(query) ? children({ data }) : emptyDataView
    } else {
        return isSuccess && data ? children({ data }) : emptyDataView
    }
}

export default AsyncQuery
