import { AnyAction } from "redux"
import { History } from "history"
import { combineReducers } from "@reduxjs/toolkit"
import { CombinedState } from "@reduxjs/toolkit/src/query/core/apiState"

// reducers
import { connectRouter, RouterState } from "connected-react-router"
import ProjectReducer, { ProjectState } from "./projects/slice"
import MenuReducer, { MenuState } from "./menu/slice"
import DialogSystemReducer, { IDialogSystemSlotState } from "./dialogSystem/slice"
import AuthReducer, { AuthState } from "./auth/slice"
import QueuesReducer, { QueuesState } from "./queues/slice"
import OperatorsReducer, { OperatorsState } from "./operators/slice"
import ConfigReducer, { ConfigState } from "./config/slice"
import SignUpReducer, { SignUpState } from "./signUp/slice"
import RolesReducer, { RolesState } from "./roles/slice"
import NotificationReducer, { NotificationState } from "./notifications/slice"
import UsersReducer, { UsersState } from "./users/slice"
import UserOperatorReducer, { UserOperatorState } from "./userOperator/slice"
import ChannelsReducer, { ChannelsState } from "./channels/slice"
import AgentsReducer, { AgentsState } from "./agents/slice"
import KnowledgeBaseReducer, { KnowledgeBaseState } from "./knowledgeBase/slice"
import ClassifiersReducer, { ClassifiersState } from "./classifiers/slice"
import KnowledgeBaseCommentsReducer, { KnowledgeBaseCommentsState } from "./knowledgeBaseComments/slice"
import PasswordReducer, { PasswordState } from "./password/slice"
import KnowledgeBaseSubscriptionsReducer, { KnowledgeBaseSubscriptionsState } from "./knowledgeBaseSubscriptions/slice"
import TasksReducer, { TasksState } from "./tasks/slice"
import SlotReducer, { SlotsState } from "./slots/slice"
import StickerReducer, { StickersState } from "./stickers/slice"
import SurveysReducer, { SurveysState } from "./surveys/slice"
import DialogsReducer, { DialogsSlice } from "./dialogs/slice"
import { queryApi } from "../api/api"

export interface RootState {
    router: RouterState
    project: ProjectState
    menu: MenuState
    dialogSystem: IDialogSystemSlotState
    auth: AuthState
    queues: QueuesState
    operators: OperatorsState
    config: ConfigState
    signUp: SignUpState
    roles: RolesState
    userOperator: UserOperatorState
    users: UsersState
    notifications: NotificationState
    channels: ChannelsState
    agents: AgentsState
    knowledgeBase: KnowledgeBaseState
    knowledgeBaseComments: KnowledgeBaseCommentsState
    knowledgeBaseSubscriptions: KnowledgeBaseSubscriptionsState
    classifiers: ClassifiersState
    password: PasswordState
    tasks: TasksState
    slots: SlotsState
    stickers: StickersState
    surveys: SurveysState
    dialogs: DialogsSlice
    queryApi: CombinedState<{}, string, "queryApi">
}

export const createRootReducer = (history: History) =>
    combineReducers<RootState, AnyAction>({
        router: connectRouter(history),
        project: ProjectReducer,
        menu: MenuReducer,
        dialogSystem: DialogSystemReducer,
        auth: AuthReducer,
        queues: QueuesReducer,
        operators: OperatorsReducer,
        config: ConfigReducer,
        signUp: SignUpReducer,
        roles: RolesReducer,
        users: UsersReducer,
        userOperator: UserOperatorReducer,
        notifications: NotificationReducer,
        channels: ChannelsReducer,
        agents: AgentsReducer,
        knowledgeBase: KnowledgeBaseReducer,
        knowledgeBaseComments: KnowledgeBaseCommentsReducer,
        knowledgeBaseSubscriptions: KnowledgeBaseSubscriptionsReducer,
        classifiers: ClassifiersReducer,
        password: PasswordReducer,
        tasks: TasksReducer,
        slots: SlotReducer,
        stickers: StickerReducer,
        surveys: SurveysReducer,
        dialogs: DialogsReducer,
        queryApi: queryApi.reducer
    })

export default createRootReducer
