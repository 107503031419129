import React from "react"
import { nameof2 } from "../../../utility/common/nameof"
import { HistoryValues, WebChatChannelValues } from "../../../models/webChatValues"
import { Col, Form, Row } from "react-bootstrap"
import FormAccordion from "../../FormAccordion/FormAccordion"
import { useTranslation } from "react-i18next"
import DebouncedValidatableInput from "../../ValidatableInput/DebouncedValidatableInput"
import DebouncedCheckBox from "../../CheckBoxValidatableInput/DebouncedCheckBox"
import { useFormikContext } from "formik"

const tNamespace = "channel:form."

const WebChatHistorySettings: React.FC = () => {
    const { t } = useTranslation()
    const { values } = useFormikContext<WebChatChannelValues>()

    return (
        <FormAccordion title={t(`${tNamespace}history`)}>
            <DebouncedCheckBox
                id="formShowHistory"
                name={nameof2<WebChatChannelValues, HistoryValues>("HistorySettings", "showHistory")}
                label={t(`${tNamespace}history-keeping`)}
            />
            <DebouncedCheckBox
                id="forHistoryFromActiveDialog"
                name={nameof2<WebChatChannelValues, HistoryValues>("HistorySettings", "historyFromActiveDialog")}
                label={t(`${tNamespace}active-dialog-only`)}
                disabled={!values.HistorySettings.showHistory}
            />
            <Form.Group as={Row} controlId="formHistoryMessageCount" className="horizontal-left-input">
                <Col style={{ maxWidth: "20%" }}>
                    <DebouncedValidatableInput
                        id="formOperatorName"
                        type="number"
                        name={nameof2<WebChatChannelValues, HistoryValues>("HistorySettings", "historyMessageCount")}
                        disabled={!values.HistorySettings.showHistory}
                    />
                </Col>
                <Form.Label column>{t(`${tNamespace}messages-number-in-history`)}</Form.Label>
            </Form.Group>
        </FormAccordion>
    )
}

export default WebChatHistorySettings
