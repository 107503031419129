import React, { createRef, RefObject, useCallback, useMemo, useRef } from "react"
import { Formik } from "formik"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { createChannel, updateChannel } from "../../store/channels/thunks"
import { ChannelFormProps } from "../ChannelForm/ChannelForm"
import { Dispatch } from "../../utility/common/storeHelper"
import { TabPaneElement } from "../ValidatableFormTabs/ValidatableFormTabs"
import { getValidationSchema } from "../../utility/channels/channelValidation"
import { buildChannelRequest } from "../../utility/channels/channelRequest"
import { getDefaultValues, getValuesFromCommonChannel } from "../../utility/channels/channelValues"
import { selectCurrentProject } from "../../store/projects/selectors"
import { generateChannelId } from "../../utility/channels/channel"
import OnSubmitValidationError from "../OnSubmitValidationError/OnSubmitValidationError"
import Tab from "react-bootstrap/Tab"
import { ChannelValues } from "../../models/channelValues"
import { RootState } from "../../store/rootReducer"
import { selectWebhookPrefix } from "../../store/channels/selectors"

interface Props {
    children: React.ElementType[]
}

const CommonChannelForm: React.FC<ChannelFormProps & Props> = props => {
    const { t } = useTranslation()
    const { channel, channelType, tabEntries, declarations, submitCallback, validateTabs, children } = props

    const dispatch = useDispatch<Dispatch>()
    const project = useSelector(selectCurrentProject)
    const webhookPrefix = useSelector((s: RootState) => selectWebhookPrefix(s, channelType))

    const refs = useRef<RefObject<TabPaneElement>[]>(tabEntries.map(() => createRef()))
    const validateTelegramTabs = useCallback(() => {
        validateTabs?.(refs.current)
    }, [refs, validateTabs])

    const channelId = useMemo(() => (channel ? channel.Id : generateChannelId()), [channel])
    const validationSchema = useMemo(() => getValidationSchema(channelType), [channelType])

    const initialValues = useMemo(
        () =>
            project
                ? channel
                    ? getValuesFromCommonChannel(project.language, channel, declarations[channelType]?.Params)
                    : getDefaultValues(
                          project.language,
                          channelId,
                          channelType,
                          declarations[channelType]?.Params,
                          webhookPrefix
                      )
                : null,
        [project, channel, declarations, channelType, channelId, webhookPrefix]
    )

    if (!project || !initialValues) {
        return null
    }

    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values: ChannelValues) => {
                const request = buildChannelRequest(project.id, channelType, values)
                if (channel) {
                    dispatch(updateChannel(request, declarations, submitCallback))
                } else {
                    dispatch(createChannel(request, declarations, submitCallback))
                }
            }}
        >
            {formikProps => (
                <>
                    <OnSubmitValidationError formikProps={formikProps} onCallback={validateTelegramTabs} />
                    {children.map((Component: React.ElementType, i) => (
                        <Tab.Pane eventKey={tabEntries[i].key} ref={refs.current[i]} key={tabEntries[i].key}>
                            <Component {...props} {...formikProps} t={t} />
                        </Tab.Pane>
                    ))}
                </>
            )}
        </Formik>
    )
}

export default CommonChannelForm
