import React, { KeyboardEvent, ClipboardEvent, FocusEvent, useCallback } from "react"
import Tag from "../Tag/Tag"
import cn from "classnames"
import { useField } from "formik"
import { useTranslation } from "react-i18next"
import { formTranslation } from "../../locales/form"
import { testId } from "../../utility/tests/testId"

import "./TagInput.scss"
import { ClassProps } from "../../utility/common/props"

const UP_ARROW_KEY = 38
const DOWN_ARROW_KEY = 40
const ENTER_KEY_CODE = 13
const SPACE_KEY_CODE = 32
const TAB_KEY_CODE = 9
const COMMA_KEY_CODE = 188

export interface TagInputProps extends ClassProps {
    name: string
    placeholder?: string
    splitOnSpaceOrComma?: boolean
    onAddTags?: (count: number) => void
    onRemoveTag?: (index: number) => void
}

const TagInput: React.FC<TagInputProps> = props => {
    const { t } = useTranslation()
    const [field, meta, helpers] = useField<string[]>(props)
    const { name, placeholder, splitOnSpaceOrComma = true, className, onAddTags, onRemoveTag } = props
    const { setValue } = helpers
    const { value } = meta

    const removeTag = useCallback(
        (index: number) => {
            setValue([...value.filter((_, i) => i !== index)])
            onRemoveTag && onRemoveTag(index)
        },
        [setValue, value, onRemoveTag]
    )

    const addTags = useCallback(
        (elementValue: string[]) => {
            setValue([...value, ...elementValue])
            onAddTags && onAddTags(elementValue.length)
        },
        [setValue, value, onAddTags]
    )

    const handleAddValue = useCallback(
        (e: KeyboardEvent<HTMLInputElement>) => {
            e.preventDefault()

            const elementValue = e.currentTarget.value.trim()
            if (elementValue === "") return

            addTags([elementValue])
            e.currentTarget.value = ""
        },
        [addTags]
    )

    const handleKeyDown = useCallback(
        (e: KeyboardEvent<HTMLInputElement>) => {
            switch (e.keyCode) {
                case ENTER_KEY_CODE:
                case TAB_KEY_CODE:
                    handleAddValue(e)
                    break
                case COMMA_KEY_CODE:
                case SPACE_KEY_CODE:
                    if (splitOnSpaceOrComma) {
                        handleAddValue(e)
                    }
                    break
                case UP_ARROW_KEY:
                case DOWN_ARROW_KEY:
                    e.preventDefault()
                    return
            }
        },
        [handleAddValue, splitOnSpaceOrComma]
    )

    const handlePaste = useCallback(
        (e: ClipboardEvent<HTMLInputElement>) => {
            e.preventDefault()

            let text = ""
            if (e.clipboardData) {
                text = e.clipboardData.getData("text")
            } else if (window.clipboardData) {
                text = window.clipboardData.getData("text")
            }

            const tags = text.trim()
            if (tags) {
                addTags(tags.split(/[\s,]+/))
            }
        },
        [addTags]
    )

    const handleBlur = useCallback(
        (e: FocusEvent<HTMLInputElement>) => {
            field.onBlur(e)

            const elementValue = e.currentTarget.value.trim()
            if (elementValue) {
                addTags([elementValue])
            }
            e.currentTarget.value = ""
        },
        [addTags, field]
    )

    return (
        <>
            <div
                className={cn(
                    "tag-input",
                    "form-control",
                    meta.touched && !!meta.error ? "is-invalid" : undefined,
                    className
                )}
            >
                <div className="tag-input__tags">
                    {value.map((t, i) => (
                        <Tag
                            className="tag-input__tag"
                            key={i}
                            index={i}
                            title={t}
                            onDelete={removeTag}
                            isInvalid={!!(meta.error && meta.error[i])}
                        />
                    ))}
                </div>
                <input
                    type="text"
                    name={name}
                    onKeyDown={handleKeyDown}
                    onBlur={handleBlur}
                    onPaste={handlePaste}
                    placeholder={placeholder}
                    className={"tag-input__input"}
                    data-test-id={testId.tagInput}
                />
            </div>
            {meta.touched && !!meta.error && <div className="tag-input__error">{t(formTranslation.emailInvalid)}</div>}
        </>
    )
}

export default TagInput
