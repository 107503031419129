import React from "react"
import { ContentButton } from "../../ContentButton/ContentButton"
import { DraggableProvided } from "react-beautiful-dnd"
import InitialButtonSettings from "../InitialButtonSettings/InitialButtonSettings"
import { usePopoverShown } from "../../../utility/knowledgeBase/usePopoverShown"
import { InitialButton } from "../../WebChatChannelForm/WebChatAdvancedSettings/WebChatInitialMessageSettings"
import { nameof3, nameof4WithArrayOn3 } from "../../../utility/common/nameof"
import { InitialMessageValues, WebChatChannelValues } from "../../../models/webChatValues"
import { useField, useFormikContext } from "formik"
import { InitialButtonsEditorProps } from "../InitialButtonsEditor"
import styles from "./InitialDraggableButton.module.scss"
import { InitialMessageText } from "../../../models/webChatConfig"
import { ValueType } from "react-select"
import { OptionType } from "../../AsyncSearchableInput/AsyncSearchableInput"

export interface InitialDraggableButtonProps extends InitialButtonsEditorProps {
    provided?: DraggableProvided
    index: number
}

export const InitialDraggableButton: React.FC<InitialDraggableButtonProps> = props => {
    const { buttons, onUpdateButtons, disabled, projectId, dispatch, provided, index } = props
    const button = buttons[index]
    const { shown, showPopover, hidePopover } = usePopoverShown()
    const { setFieldValue } = useFormikContext<WebChatChannelValues>()
    const [field] = useField<string>(
        nameof4WithArrayOn3<WebChatChannelValues, InitialMessageValues, InitialMessageText, InitialButton>(
            "InitialMessageSettings",
            "initialMessageText",
            "actions",
            "action_text",
            index
        )
    )
    const onButtonsChange = (buttons: InitialButton[]) => {
        setFieldValue(
            nameof3<WebChatChannelValues, InitialMessageValues, InitialMessageText>(
                "InitialMessageSettings",
                "initialMessageText",
                "actions"
            ),
            buttons,
            false
        )
    }
    const onDelete = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const updatedButtons = buttons.filter(btn => btn.Id !== button.Id)
        onUpdateButtons(updatedButtons)
        onButtonsChange(updatedButtons)
    }
    const onBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
        buttons[index].action_text = e.target.value
    }

    const onSelect = (e: ValueType<OptionType, false>) => {
        if (e) {
            return (buttons[index].action_id = e.value)
        }
    }

    const onSave: React.MouseEventHandler<HTMLButtonElement> = e => {
        onButtonsChange(buttons)
        hidePopover()
        e.preventDefault()
    }

    return (
        <div className={styles.initialButtonsListItem} ref={provided?.innerRef} {...provided?.draggableProps}>
            <ContentButton
                className={styles.draggableButton}
                disabled={disabled}
                content={
                    <InitialButtonSettings
                        index={index}
                        onHide={hidePopover}
                        projectId={projectId}
                        dispatch={dispatch}
                        onSelect={onSelect}
                        onSave={onSave}
                        name={nameof4WithArrayOn3<
                            WebChatChannelValues,
                            InitialMessageValues,
                            InitialMessageText,
                            InitialButton
                        >("InitialMessageSettings", "initialMessageText", "actions", "action_id", index)}
                    />
                }
                defaultValue={button.action_text}
                onDelete={onDelete}
                provided={provided}
                shown={shown}
                onHidePopover={hidePopover}
                onShowPopover={showPopover}
                onBlur={onBlur}
                field={field}
            />
        </div>
    )
}
