import { PaginationRequest, PaginationResponse } from "./pagination"

export interface SlotDto {
    Id: string
    ExternalId: string
    ProjectId: string
    Title: string
    Type: SlotType
    Kind: SlotKind
    Domain?: SlotDomainValueDto[]
    Question?: SlotQuestionDto
    FilledQuestion?: SlotQuestionDto
    NextValueQuestion?: SlotQuestionDto
    FailedValidationQuestion?: SlotQuestionDto
    AdditionalQuestions?: AdditionalSlotQuestionDto[]
    Prefill?: boolean
    SkipClassification?: boolean
    DontShowButtons?: boolean
    ShowCancelButton?: boolean
    UsesMultipleValues?: boolean
    Permanent?: boolean
    UseExternalDomainSource?: boolean
    ModifiedData: number
    ModifiedUserLogin: string
}

export enum SlotType {
    Integer = "Integer",
    Text = "Text",
    Dictionary = "Dictionary",
    Phone = "Phone",
    Regex = "Regex",
    RegexMatch = "RegexMatch",
    Geo = "Geo",
    FirstName = "FirstName",
    LastName = "LastName",
    Patronymic = "Patronymic",
    Date = "Date",
    Time = "Time",
    Calendar = "Calendar",
    Email = "Email",
    Attachment = "Attachment",
    ExternalDictionary = "ExternalDictionary",
    ExternalSurvey = "ExternalSurvey"
}

export enum SlotKind {
    Default = "Default",
    Background = "Background",
    SurveyInput = "SurveyInput"
}

export interface SynonymDto {
    Id: string
    Value: string
}

export interface DomainMapType {
    [key: string]: SlotDomainValueDto
}

export interface SlotProcessed extends SlotDto {
    DomainMap: DomainMapType
}

export interface SlotsMapType {
    [key: string]: SlotProcessed
}

export interface SlotDomainValueDto {
    Id: string
    Title: string
    Synonyms?: SynonymDto[]
}

interface SlotAttachmentDto {
    Type: string
    Url: string
    Name: string
}

interface SlotQuestionDto {
    Text: string
    Attachments?: SlotAttachmentDto[]
}

export interface AdditionalSlotQuestionDto {
    Id: string
    Question: SlotQuestionDto
}

export interface SlotRequestBase {
    Title: string
    Type: SlotType
    Prefill: boolean
    SkipClassification: boolean
    ShowCancelButton: boolean
    UsesMultipleValues: boolean
    Permanent: boolean
    DontShowButtons: boolean
    Domain: SlotDomainValueDto[]
    Question: SlotQuestionDto
    FailedValidationQuestion: SlotQuestionDto
    NextValueQuestion: SlotQuestionDto
    AdditionalQuestions: AdditionalSlotQuestionDto[]
    UseExternalDomainSource: boolean
}

export interface CreateSlotRequest extends Partial<SlotRequestBase> {
    Title: string
    ExternalId: string
    Type: SlotType
}

export interface CreateSlotResponse {
    CreatedSlot: SlotDto
}

export type UpdateSlotRequest = Partial<SlotRequestBase>

export interface UpdateSlotResponse {
    UpdatedSlot: SlotDto
}

export interface DeleteSlotResponse {
    Success: boolean
}

export interface GetSlotResponse {
    Slot: SlotDto
}

export type GetSlotPageRequest = PaginationRequest

export type GetSlotPageResponse = PaginationResponse<SlotDto>

export interface SlotValues {
    Title: string
    ExternalId: string
    Type: SlotType
    Permanent: boolean
    Question: string
    FailedValidationQuestion: string
    Prefill: boolean
    DontShowButtons: boolean
    SkipClassification: boolean
    ShowCancelButton: boolean
    UsesMultipleValues: boolean
    NextValueQuestion: string
    RegexId: string
    Regex: string
    Domain: SlotDtoDomainValue[]
    CalendarQuestions: CalendarQuestionValues
    UseExternalDomainSource: boolean
}

export interface SlotDtoDomainValue {
    Id: string
    PermanentId: string // for drag-n-drop
    Title: string
    Synonyms: string[]
    SynonymIds: string[]
    IsEmpty?: boolean
}

export interface CalendarQuestionValues {
    DatetimeFailedQuestion: string
    TimeQuestion: string
    NoScheduleQuestion: string
}

export interface FilledSlot {
    Id: string
    Value: string
}

export interface SlotContext {
    FilledSlots: FilledSlot[]
}

export enum SystemSlot {
    FirstName = "sys_firstname",
    LastName = "sys_lastname",
    MiddleName = "sys_middlename",
    Contract = "sys_contract",
    BirthDate = "sys_birthdate",
    Email = "sys_email",
    Phone = "sys_phone",
    OmniUserId = "sys_omniuserid",
    ExtUserId = "sys_extuserid"
}
