import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"
import FormAccordion from "../../FormAccordion/FormAccordion"
import CheckBoxValidatableInput from "../../CheckBoxValidatableInput/CheckBoxValidatableInput"
import { nameof2 } from "../../../utility/common/nameof"
import { InterfaceValues, WebChatChannelValues } from "../../../models/webChatValues"
import AlertIcon from "../../AlertIcon/AlertIcon"
import InfoIcon from "../../InfoIcon/InfoIcon"
import { useFormikContext } from "formik"
import { useTranslation } from "react-i18next"
import DebouncedCheckBox from "../../CheckBoxValidatableInput/DebouncedCheckBox"
import ConfigContext from "../../ConfigContext/ConfigContext"
import { Form, InputGroup } from "react-bootstrap"
import Toggle from "../../Toggle/Toggle"
import RootColorInput from "../../ColorInput/RootColorInput"

const tNamespace = "channel:form."
const tTooltipNamespace = "channel:tooltip."

const WebChatInterfaceSettings: React.FC = () => {
    const { t } = useTranslation()
    const { values, setFieldValue } = useFormikContext<WebChatChannelValues>()

    const { WebConfig } = useContext(ConfigContext)
    const advancedSettingsEnabled = useMemo<boolean>(() => WebConfig.webChat.advancedSettingsEnabled, [WebConfig])
    const mobileVersionEnabled = useMemo(() => values.MobileVersionEnabled, [values.MobileVersionEnabled])
    const isScenarioWebchat = values.SystemSettings.isDebugScenario
    const [logoColorEnabled, setLogoColorEnabled] = useState(!!values.InterfaceSettings.logoColor)
    const handleLogoColorToggle = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const checked = e.target.checked
            setLogoColorEnabled(checked)
            if (!checked) {
                setFieldValue(
                    nameof2<WebChatChannelValues, InterfaceValues>("InterfaceSettings", "logoColor"),
                    "",
                    false
                )
            }
        },
        [setLogoColorEnabled, setFieldValue]
    )

    useEffect(() => {
        if (values.InterfaceSettings.disableInitialOpen) {
            setFieldValue(
                nameof2<WebChatChannelValues, InterfaceValues>("InterfaceSettings", "alwaysOpen"),
                false,
                false
            )
        }
    }, [values.InterfaceSettings.disableInitialOpen, setFieldValue])

    useEffect(() => {
        if (values.InterfaceSettings.alwaysOpen) {
            setFieldValue(
                nameof2<WebChatChannelValues, InterfaceValues>("InterfaceSettings", "disableInitialOpen"),
                false,
                false
            )
        }
    }, [values.InterfaceSettings.alwaysOpen, setFieldValue])

    return (
        <FormAccordion title={t(`${tNamespace}interface`)}>
            <DebouncedCheckBox
                id="formUseEmoji"
                name={nameof2<WebChatChannelValues, InterfaceValues>("InterfaceSettings", "useEmoji")}
                label={t(`${tNamespace}emoji`)}
            />
            <DebouncedCheckBox
                id="formRequireChatDraggable"
                name={nameof2<WebChatChannelValues, InterfaceValues>("InterfaceSettings", "requireChatDraggable")}
                label={t(`${tNamespace}chat-dragging`)}
            />
            <DebouncedCheckBox
                id="formRequireChatResizing"
                name={nameof2<WebChatChannelValues, InterfaceValues>("InterfaceSettings", "requireChatResizing")}
                label={t(`${tNamespace}chat-resizing`)}
            />
            <DebouncedCheckBox
                id="formDisableClosing"
                name={nameof2<WebChatChannelValues, InterfaceValues>("InterfaceSettings", "disableClosing")}
                label={t(`${tNamespace}hide-open-close-button`)}
                icon={
                    mobileVersionEnabled ? (
                        <AlertIcon
                            id="disableClosingAlert"
                            content={t(`${tTooltipNamespace}parameter-selected-as-mobile-version`)}
                        />
                    ) : undefined
                }
                disabled={mobileVersionEnabled || isScenarioWebchat}
            />
            <DebouncedCheckBox
                id="formDisableHeader"
                name={nameof2<WebChatChannelValues, InterfaceValues>("InterfaceSettings", "disableHeader")}
                label={t(`${tNamespace}hide-header`)}
                icon={
                    mobileVersionEnabled ? (
                        <AlertIcon
                            id="disableHeaderAlert"
                            content={t(`${tTooltipNamespace}parameter-selected-as-mobile-version`)}
                        />
                    ) : undefined
                }
                disabled={mobileVersionEnabled || isScenarioWebchat}
            />
            <DebouncedCheckBox
                id="formDisableInitialOpen"
                name={nameof2<WebChatChannelValues, InterfaceValues>("InterfaceSettings", "disableInitialOpen")}
                label={t(`${tNamespace}disable-initial-open`)}
                icon={
                    <>
                        <InfoIcon
                            id="popoverDisableInitialOpen"
                            title={t(`${tNamespace}initial-open`)}
                            content={t(`${tTooltipNamespace}disable-initial-open-info`)}
                        />
                        {values.InterfaceSettings.alwaysOpen && (
                            <AlertIcon
                                id="alertDisableInitialOpen"
                                content={t(`${tTooltipNamespace}blocked-initial-open-selected`)}
                            />
                        )}
                    </>
                }
                disabled={values.InterfaceSettings.alwaysOpen}
            />
            {advancedSettingsEnabled && (
                <CheckBoxValidatableInput
                    id="formDisableCompanyInformation"
                    name={nameof2<WebChatChannelValues, InterfaceValues>(
                        "InterfaceSettings",
                        "disableCompanyInformation"
                    )}
                    label={t(`${tNamespace}disable-crafttalk-information`)}
                />
            )}
            <DebouncedCheckBox
                id="formAlwaysOpen"
                name={nameof2<WebChatChannelValues, InterfaceValues>("InterfaceSettings", "alwaysOpen")}
                label={t(`${tNamespace}chat-always-open`)}
                icon={
                    mobileVersionEnabled || values.InterfaceSettings.disableInitialOpen ? (
                        <AlertIcon
                            id="alwaysOpenAlert"
                            content={
                                mobileVersionEnabled
                                    ? t(`${tTooltipNamespace}parameter-selected-as-mobile-version`)
                                    : t(`${tTooltipNamespace}blocked-automatic-chat-opening-disabled`)
                            }
                        />
                    ) : undefined
                }
                disabled={mobileVersionEnabled || values.InterfaceSettings.disableInitialOpen || isScenarioWebchat}
            />
            <DebouncedCheckBox
                id="formClickablePhoneNumber"
                name={nameof2<WebChatChannelValues, InterfaceValues>("InterfaceSettings", "clickablePhoneNumber")}
                label={t(`${tNamespace}clickable-phone-number`)}
            />
            <DebouncedCheckBox
                id={"formHideMessageInfo:"}
                name={nameof2<WebChatChannelValues, InterfaceValues>("InterfaceSettings", "hideMessageInfo")}
                label={t(`${tNamespace}hide-message-info`)}
            />
            <Form.Group controlId="formLogoColor">
                <Form.Label>{t(`${tNamespace}logo-color-in-open-close-button`)}</Form.Label>
                <InputGroup>
                    <InputGroup.Prepend>
                        <InputGroup.Text>
                            <Toggle
                                id="formLogoColor-toggle"
                                icons={false}
                                checked={logoColorEnabled}
                                onChange={handleLogoColorToggle}
                                small
                            />
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                    {logoColorEnabled ? (
                        <RootColorInput
                            contextMenuContainerClassName="webchat-form__color-input-group"
                            simplified={true}
                            color={values.InterfaceSettings.logoColor}
                            onChange={({ hex }) =>
                                setFieldValue(
                                    nameof2<WebChatChannelValues, InterfaceValues>("InterfaceSettings", "logoColor"),
                                    hex.toUpperCase()
                                )
                            }
                        />
                    ) : (
                        <Form.Control disabled />
                    )}
                </InputGroup>
            </Form.Group>
        </FormAccordion>
    )
}

export default WebChatInterfaceSettings
