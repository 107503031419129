export enum ButtonColor {
    Primary = "primary",
    Secondary = "secondary",
    Negative = "negative",
    Positive = "positive"
}

const buttonColorValues = Object.values(ButtonColor)

export const validateButtonColor = (buttonColorAttrValue: string) => {
    const color = buttonColorAttrValue as ButtonColor
    return buttonColorValues.includes(color) ? color : ButtonColor.Secondary
}

export const buttonColorToClassModifier = (buttonColor: string) => {
    switch (buttonColor) {
        case ButtonColor.Primary:
            return "markdown-buttons__button_primary"
        case ButtonColor.Negative:
            return "markdown-buttons__button_negative"
        case ButtonColor.Positive:
            return "markdown-buttons__button_positive"
        default:
            return "markdown-buttons__button_secondary"
    }
}
