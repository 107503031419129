import React from "react"
import styles from "./ColorInput.module.scss"
import { Form } from "react-bootstrap"
import cn from "classnames"
import { useTranslation } from "react-i18next"
import { useField } from "formik"

import RootColorInput, { ColorInputType } from "./RootColorInput"
import { ClassProps } from "../../utility/common/props"
const tNamespace = "common:color."

export interface ColorInputProps extends ClassProps {
    id: string
    name: string
    simplified?: boolean
    type?: ColorInputType
}

const ColorInput: React.FC<ColorInputProps> = props => {
    const [field, , helpers] = useField<string>(props)
    const { className, id, simplified = false, type } = props
    const { setValue } = helpers
    const { t } = useTranslation()
    return (
        <Form.Group className={cn(styles["color-input"], className)} controlId={id}>
            {!simplified && <Form.Label className={styles["color-input__label"]}>{t(`${tNamespace}label`)}</Form.Label>}
            <RootColorInput
                simplified={simplified}
                color={field.value}
                onChange={({ hex }) => setValue(hex.toUpperCase())}
                type={type}
            />
        </Form.Group>
    )
}

export default ColorInput
