import { ActionResult, Dispatch } from "../../utility/common/storeHelper"
import { IAIAssistHint, actions } from "./slice"
import * as constants from "./constants"
import { Controller as fakeController } from "../../api/fake/controller"
import { handleHttpException } from "../handleHttpException"
import { JsAgentSWBridge } from "../../utility/service-workers"
import store, { runOneOffWatcherThenable } from "../store"
import { dialogDraftsLocalStoreDriver, dialogsApi } from "../../api/controllers/dialogs"
import {
    ApiMessage,
    ApiMessagesDU,
    GetUpdatedMessagesResponse,
    MessageDirection,
    UpdatedMessage
} from "../../models/Dialogs/message"
import callDebounced from "lodash/debounce"
import { TMessageDatagramPayloadUni } from "../../jsagent-bridge"
import dialogsController from "../../api/controllers/dialogs-v1"
import { IAISuggestRequest } from "../../models/Dialogs/aiSuggestDTOs"
import { QueryStatus } from "@reduxjs/toolkit/dist/query"

export const getOperatorDialogs = (): ActionResult<Promise<void>> => async (dispatch: Dispatch) => {
    dispatch(actions.getOperatorDialogsProcess())

    try {
        const dialogs = await fakeController.getDialogBadges()
        dispatch(actions.getOperatorDialogsSuccess(dialogs))
    } catch (e) {
        handleHttpException(e, constants.FETCH_DIALOG_BADGES_FAILED, actions.getOperatorDialogsFailed, dispatch)
    }
}

export const getCurrentDialog =
    (dialogId: string): ActionResult<Promise<void>> =>
    async (dispatch: Dispatch) => {
        dispatch(actions.setCurrentDialogId(dialogId))
    }

export interface IMessageDatagramRoot extends TMessageDatagramPayloadUni {
    mode: "gpt" | "get_request_types"
}

export interface IMessageDatagramGpt extends IMessageDatagramRoot {
    messages: Array<{
        side: "operator" | "client"
        text: string
    }>
    operatorMessage: string
}

const gptJsAgentBridge = new JsAgentSWBridge("", "gpt_assistant_agent")

const getAIAssistHintRootQuery = callDebounced(async (payload: IMessageDatagramGpt, dispatch: Dispatch) => {
    try {
        dispatch(actions.getAIAssistHintProcess())

        const messageResp = await gptJsAgentBridge.postMessageToSW<IMessageDatagramGpt, IAIAssistHint>({
            type: "PROTO_PREDICATE",
            payload
        })

        dispatch(actions.getAIAssistHintSuccess(messageResp))
    } catch (e) {
        handleHttpException(e, "", actions.getAIAssistHintFailed, dispatch)
    }
}, 1000)

export const getAIAssistHintRequestCommandQuery = (reqTypeCommand: string) => (dispatch: Dispatch) => {
    const operatorMessage = store.getState().dialogs.aiAssistHint.data?.message

    if (!operatorMessage) {
        return
    }

    return getAIAssistHintRootQuery(
        {
            messages: [],
            operatorMessage,
            reqTypeCommand,
            mode: "gpt"
        },
        dispatch
    )
}

export type TGetAIAssistHintDialogQueryArgs = {
    omniUserId: string
    reqTypeCommand: string
    draftMessage?: string
}

export const getAIAssistHintDialogQuery =
    (
        { omniUserId, reqTypeCommand = "", draftMessage }: TGetAIAssistHintDialogQueryArgs,
        meta: Record<string, unknown> = {}
    ) =>
    async (dispatch: Dispatch) => {
        const messages: IMessageDatagramGpt["messages"] = []
        const operatorMessage = draftMessage ?? store.getState().dialogs.inputMessage
        const projectId = store.getState().project.selectProject.data?.id

        if (!projectId) {
            return
        }

        gptJsAgentBridge.setCustomerId(projectId)

        const r = await dispatch(
            dialogsApi.endpoints.getDialogMessages.initiate(
                {
                    OmniUserId: omniUserId,
                    Count: 50,
                    FromTodayDialogs: false,
                    StartTime: 0,
                    ProjectId: projectId
                },
                {
                    forceRefetch: true
                }
            )
        )

        const messagesHistory = r.data?.Messages ?? []

        for (let i = 0; ; i++) {
            const messageEntity = messagesHistory[i]

            if (!messageEntity) {
                break
            }

            const messageEntityFields = messageEntity.Fields as UpdatedMessage
            const direction = messageEntityFields.Direction

            if (messageEntity.Case === "Message" && messageEntity.Fields.Text !== "/start") {
                messages.push({
                    side: direction ? "operator" : "client",
                    text: messageEntityFields.Text
                })

                if (messages.length >= 10) {
                    break
                }
            }
        }

        await getAIAssistHintRootQuery(
            {
                messages,
                operatorMessage,
                reqTypeCommand,
                mode: "gpt",
                ...meta
            },
            dispatch
        )
    }

export const getAIAssistHintByClientId =
    (omniUserId: string, reqTypeCommand = "") =>
    async (dispatch: Dispatch) => {
        const draftMessage = dialogDraftsLocalStoreDriver.get(omniUserId)

        await getAIAssistHintDialogQuery(
            {
                omniUserId,
                reqTypeCommand,
                draftMessage
            },
            {
                nonActiveDialog: true
            }
        )(dispatch)
    }

export const getAIAssistHintByDialogId =
    (dialogId: string, reqTypeCommand = "") =>
    async (dispatch: Dispatch) => {
        const selectedDialogInstance = dialogsApi.endpoints.getDialog.select(dialogId)(store.getState())
        const omniUserId = selectedDialogInstance.data?.Client.OmniUserId

        if (omniUserId) {
            const draftMessage = dialogDraftsLocalStoreDriver.get(dialogId)

            await getAIAssistHintDialogQuery({
                omniUserId,
                reqTypeCommand,
                draftMessage
            })(dispatch)
        }
    }

export const getAIAssistRequestTypes = () => async (dispatch: Dispatch) => {
    const projectId = store.getState().project.selectProject.data?.id

    if (!projectId) {
        return
    }

    gptJsAgentBridge.setCustomerId(projectId)

    try {
        dispatch(actions.getAIAssistRequestTypesProcess())

        const messageResp = await gptJsAgentBridge.postMessageToSW<IMessageDatagramRoot, string[]>({
            type: "PROTO_PREDICATE",
            payload: {
                mode: "get_request_types"
            }
        })

        dispatch(actions.getAIAssistRequestTypesSuccess(messageResp))
    } catch (e) {
        handleHttpException(e, "", actions.getAIAssistRequestTypesFailed, dispatch)
    }
}

const getAISuggestsRootQuery = callDebounced(async (payload: IAISuggestRequest, dispatch: Dispatch) => {
    try {
        dispatch(actions.getAISuggestProcess())

        const aiSuggest = await dialogsController.getAISuggest(payload)

        dispatch(actions.getAISuggestSuccess(aiSuggest))
    } catch (e) {
        handleHttpException(e, "", err => actions.getAISuggestFailed(err), dispatch)
    }
}, 1000)

export const getAISuggestsByClientId = (omniUserId: string) => async (dispatch: Dispatch) => {
    const projectId = store.getState().project.selectProject.data?.id

    if (!projectId) {
        return
    }

    const getDialogMessagesRequestArgs = {
        OmniUserId: omniUserId,
        Count: 50,
        FromTodayDialogs: false,
        StartTime: 0,
        ProjectId: projectId
    }

    const messagesCached = dialogsApi.endpoints.getDialogMessages.select(getDialogMessagesRequestArgs)(store.getState())

    let messages: ApiMessagesDU[] = []

    if (messagesCached.status !== QueryStatus.fulfilled) {
        const { action } = await runOneOffWatcherThenable<GetUpdatedMessagesResponse>({
            actionType: "queryApi",
            endpointName: "getDialogMessages",
            args: getDialogMessagesRequestArgs
        })

        messages = action.payload.Messages
    } else {
        messages = messagesCached.data.Messages
    }

    messages = messages.filter(
        x => x.Case === "Message" && x.Fields.Direction === MessageDirection.Incoming && x.Fields.Text !== "/start"
    )
    const lastMessage = messages[0] as ApiMessage
    const currTimeISO = new Date().toISOString()

    if (lastMessage) {
        await getAISuggestsRootQuery(
            {
                projectId,
                omniUserId,
                Added: currTimeISO,
                Actions: lastMessage.Fields.Actions,
                CallCenterOperatorId: "DEFAULT___test",
                Channel: 6,
                ChannelId: lastMessage.Fields.ChannelId,
                Context: lastMessage.Fields.Context,
                DialogID: lastMessage.Fields.DialogId,
                Direction: 1,
                Flags: [],
                IdInChannel: "",
                Info: "",
                IsClientServiceMessage: false,
                IsServiceMessage: false,
                Meta: lastMessage.Fields.Meta,
                MsgId: lastMessage.Fields.Id,
                MsgType: lastMessage.Fields.MessageType,
                OmniUserId: omniUserId,
                OperatorName: "",
                Text: lastMessage.Fields.Text,
                TextType: "Text",
                UserName: "",
                SlotContext: {},
                time: "",
                ChannelTitle: ""
            },
            dispatch
        )
    }
}

export const getAISuggestsByDialogId = (dialogId: string) => async (dispatch: Dispatch) => {
    const selectedDialogInstance = dialogsApi.endpoints.getDialog.select(dialogId)(store.getState())
    const omniUserId = selectedDialogInstance.data?.Client.OmniUserId

    if (omniUserId) {
        getAISuggestsByClientId(omniUserId)(dispatch)
    }
}
