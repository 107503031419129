import React, { Ref } from "react"
import { ApiMessagesDU, UpdatedMessage } from "../../models/Dialogs/message"
import styles from "./MessageList.module.scss"
import DialogMessage from "../Dialogs/DialogMessage/DialogMessage"
import { TSenderSignature } from "../Dialogs/DialogChat/DialogChat"
import { AIAssistent } from "../Dialogs/AIAssistent"

export interface MessageListProps {
    messages: ApiMessagesDU[]
    getSender: (message: UpdatedMessage) => TSenderSignature
    lastMessageRef: Ref<HTMLDivElement>
}

const MessageList: React.FC<MessageListProps> = props => {
    const { messages, getSender, lastMessageRef } = props

    // TODO добавить отображение всех типов сообщений
    const getFilteredMessages = () => {
        const selectDate = (message: ApiMessagesDU) => {
            switch (message.Case) {
                case "Message":
                    return message.Fields.AddedDate
                case "DialogFinish":
                    return message.Fields.Timestamp
                // TODO: Выяснить, будет ли у нас MessageScore,
                // если да, то найти его timestamp
                default:
                    return 0
            }
        }
        const result: ApiMessagesDU[] = []
        for (const message of messages) {
            if (message.Case === "Message" || message.Case === "DialogFinish") {
                result.push(message)
            }
        }
        return result.sort((m1, m2) => selectDate(m1) - selectDate(m2))
    }
    const filteredMessages = getFilteredMessages()

    return (
        <div className={styles.messageList}>
            {filteredMessages.map((v, index) => (
                <DialogMessage
                    key={v.Fields.Id}
                    className={styles.messageList__item}
                    message={v}
                    lastMessageRef={index === 0 ? lastMessageRef : undefined}
                    sender={v.Case === "Message" ? getSender(v.Fields) : undefined}
                />
            ))}
            <div className={styles.messageList__aiassistent}>
                <AIAssistent />
            </div>
        </div>
    )
}

export default MessageList
