import React from "react"
import DropdownItem from "react-bootstrap/DropdownItem"
import styles from "./ActionDropdownButton.module.scss"
import DropdownButton from "react-bootstrap/DropdownButton"
import { DialogSendMessageAction } from "../../models/Dialogs/dialog"
import { testId } from "../../utility/tests/testId"

export interface ActionDropdownButtonProps {
    options: DialogSendMessageAction[]
    onSelect: (key: string) => void
    button: JSX.Element
    variant?: string
    callback?: () => void
    disabled?: boolean
}

const ActionDropdownButton: React.FC<ActionDropdownButtonProps> = props => {
    const { options, button, variant = "primary", disabled, callback, onSelect } = props

    const handleSelect = (eventKey: string | null) => {
        if (eventKey) {
            onSelect(eventKey)
            callback && callback()
        }
    }

    return (
        <div className={styles.buttonGroup} data-test-id={testId.sendMessageAction}>
            <div className={styles.buttonGroup__icon}>{button}</div>
            <DropdownButton
                className={styles.buttonGroup__dropDown}
                drop="up"
                menuAlign="right"
                variant={variant}
                title=""
                id="action-dropdown-button"
                onSelect={handleSelect}
                disabled={disabled}
            >
                {options.map(option => (
                    <DropdownItem key={option.Id} eventKey={option.Id}>
                        {option.Title}
                    </DropdownItem>
                ))}
            </DropdownButton>
        </div>
    )
}

export default ActionDropdownButton
