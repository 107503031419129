import React from "react"
import { nameof3 } from "../../../utility/common/nameof"
import {
    StatisticsAndReportSettingsValues,
    StatisticsProjectSettingsValues
} from "../../../models/projectSettingsValues"
import { ProjectSettingsListValue } from "../../../models/projectSettings"
import ArticleSelect from "../../ArticleSelect/ArticleSelect"
import { ValueType } from "react-select"
import { OptionType } from "../../AsyncSearchableInput/AsyncSearchableInput"
import InfoIcon from "../../InfoIcon/InfoIcon"
import RemovableListItem from "../../FormHelpers/RemovableListItem/RemovableListItem"
import { FieldArray, useFormikContext } from "formik"
import { useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"

const tNamespace = "project:project-settings.statistics."
const tTooltipNamespace = `${tNamespace}tooltip.`

interface Props {
    projectId: string
    onCustomInputChange: <K>(name: string, value: K, initialValues: StatisticsAndReportSettingsValues) => void
    articleTitles: Record<string, string>
    setArticleTitles: (articleTitles: Record<string, string>) => void
}

const UnproductiveAnswersSelect: React.FC<Props> = props => {
    const { t } = useTranslation()
    const { projectId, onCustomInputChange, articleTitles, setArticleTitles } = props
    const dispatch = useDispatch()
    const { initialValues, values } = useFormikContext<StatisticsAndReportSettingsValues>()

    return (
        <FieldArray
            name={nameof3<StatisticsAndReportSettingsValues, StatisticsProjectSettingsValues, ProjectSettingsListValue>(
                "Statistics",
                "UnproductiveAnswers",
                "Values"
            )}
            render={({ push, remove }) => (
                <>
                    <ArticleSelect
                        id="formUnproductiveAnswers"
                        name={nameof3<
                            StatisticsAndReportSettingsValues,
                            StatisticsProjectSettingsValues,
                            ProjectSettingsListValue
                        >("Statistics", "UnproductiveAnswers", "Values")}
                        label={t(`${tNamespace}unproductive-answers`)}
                        projectId={projectId}
                        searchBySymbolCode
                        allowSearchWithoutPermissions
                        dispatch={dispatch}
                        onChange={(option: ValueType<OptionType, false>) => {
                            if (option) {
                                onCustomInputChange(
                                    nameof3<
                                        StatisticsAndReportSettingsValues,
                                        StatisticsProjectSettingsValues,
                                        ProjectSettingsListValue
                                    >("Statistics", "UnproductiveAnswers", "Values"),
                                    option,
                                    initialValues
                                )
                                setArticleTitles({ ...articleTitles, [option.value]: option.label })
                                push(option.value)
                            }
                        }}
                        icon={
                            <InfoIcon
                                id="tooltipUnproductiveAnswers"
                                content={t(`${tTooltipNamespace}unproductive-answers`)}
                            />
                        }
                    >
                        {values.Statistics.UnproductiveAnswers.Values.map((value, index) => (
                            <RemovableListItem
                                key={index}
                                onDelete={() => {
                                    remove(index)
                                    onCustomInputChange(
                                        nameof3<
                                            StatisticsAndReportSettingsValues,
                                            StatisticsProjectSettingsValues,
                                            ProjectSettingsListValue
                                        >("Statistics", "UnproductiveAnswers", "Values"),
                                        values.Statistics.UnproductiveAnswers.Values[index],
                                        initialValues
                                    )
                                }}
                            >
                                {articleTitles[value]}
                            </RemovableListItem>
                        ))}
                    </ArticleSelect>
                </>
            )}
        />
    )
}

export default UnproductiveAnswersSelect
