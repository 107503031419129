import React, { useRef, useState } from "react"
import styles from "./TaskInfo.module.scss"
import cn from "classnames"
import { ClassProps } from "../../utility/common/props"
import { isTask, TaskCommon } from "../../models/task"
import { useTranslation } from "react-i18next"
import { Operator, OperatorSummary } from "../../models/operator"
import InfoField from "../InfoField/InfoField"
import OperatorCard from "../OperatorCard/OperatorCard"
import { mapChannelTypeToClassName } from "../../utility/channels/channel"
import ChannelIcon from "../ChannelIcon/ChannelIcon"
import UserCard from "../UserCard/UserCard"
import { LegacyDialog } from "../../models/Dialogs/legacyDialog"
import Score from "../Score/Score"
import { Button, Overlay, Popover } from "react-bootstrap"
import { Queue } from "../../models/queue"
import RouteAssignedTaskForm from "../RouteAssignedTaskForm/RouteAssignedTaskForm"
import { channelTypeStringConverter } from "../../utility/channels/channelTypeStringConverter"
import { Agent, AgentType } from "../../models/agent"
import AgentIcon from "../AgentIcon/AgentIcon"
import {
    beautifyMsToHMSString,
    formatDistanceToNowLocal,
    formatMillisecondsToFullDate
} from "../../utility/common/formatDate"
import IdField from "../IdField/IdField"
import UpdatedTime from "../UpdatedTime/UpdatedTime"

const tNamespace = "queues:task."
const CHANNEL_TYPE_PREFIX = "task-info__channel-"

interface Props extends ClassProps {
    task: TaskCommon
    channelName?: string
    dialog: LegacyDialog
    agents?: Agent[]
    operator?: Operator
    operatorsNames: OperatorSummary[]
    queues: Queue[]
    onClose: () => void
}

const TaskInfo: React.FC<Props> = props => {
    const { className, task, channelName, dialog, operator, operatorsNames, queues, onClose, agents } = props
    const { t } = useTranslation()

    const [routeMenuOpened, setRouteMenuOpened] = useState(false)
    const routeMenuTarget = useRef<HTMLButtonElement>(null)

    const openRouteForm = () => {
        setRouteMenuOpened(true)
    }

    const closeRouteForm = () => {
        setRouteMenuOpened(false)
    }

    const handleAfterRoute = () => {
        setRouteMenuOpened(false)
        onClose()
    }

    const [agentId, agentType] = getAgentData(task, agents)
    const channelTitle = channelName ? channelName : isTask(task) ? task.Channel.Title : task.ChannelId
    const channelType = channelTypeStringConverter.toChannelType(isTask(task) ? task.Channel.Type : task.ChannelType)
    const getTaskStatistic = (task: TaskCommon) => {
        if (isTask(task)) {
            return (
                <>
                    <InfoField name={t(`${tNamespace}duration`)}>{formatDistanceToNowLocal(task.CreatedAt)}</InfoField>
                    <UpdatedTime
                        title={t(`${tNamespace}operator-duration`)}
                        timestamp={task.AssignedAt}
                        format={beautifyMsToHMSString}
                        titleFallback={t(`${tNamespace}no-operator-duration`)}
                    />
                    <InfoField name={t(`${tNamespace}start-time`)}>
                        {formatMillisecondsToFullDate(task.CreatedAt)}
                    </InfoField>
                </>
            )
        } else {
            return (
                <>
                    <InfoField name={t(`${tNamespace}duration`)}>{formatDistanceToNowLocal(task.StartedAt)}</InfoField>
                    <InfoField name={t(`${tNamespace}started-at`)}>
                        {formatMillisecondsToFullDate(task.StartedAt)}
                    </InfoField>
                    {task.EnqueuedAt > 0 && (
                        <InfoField name={t(`${tNamespace}enqueued-at`)}>
                            {formatMillisecondsToFullDate(task.EnqueuedAt)}
                        </InfoField>
                    )}
                    {task.RoutedAt > 0 && (
                        <InfoField name={t(`${tNamespace}routed-at`)}>
                            {formatMillisecondsToFullDate(task.RoutedAt)}
                        </InfoField>
                    )}
                    {task.CurrentRoutedAt > 0 && (
                        <InfoField name={t(`${tNamespace}current-routed-at`)}>
                            {formatMillisecondsToFullDate(task.CurrentRoutedAt)}
                        </InfoField>
                    )}
                    {task.FirstReplyAt > 0 && (
                        <InfoField name={t(`${tNamespace}first-reply-at`)}>
                            {formatMillisecondsToFullDate(task.FirstReplyAt)}
                        </InfoField>
                    )}
                    {task.CurrentFirstReplyAt > 0 && (
                        <InfoField name={t(`${tNamespace}current-first-reply-at`)}>
                            {formatMillisecondsToFullDate(task.CurrentFirstReplyAt)}
                        </InfoField>
                    )}
                </>
            )
        }
    }
    const getTaskShortId = (task: TaskCommon) =>
        isTask(task) && <IdField id={task.ShortId} className={styles["task-info__id"]} />

    const taskInfoCardClass = mapChannelTypeToClassName(channelType, CHANNEL_TYPE_PREFIX)

    const getRouteAssignedTaskForm = () => {
        const triggerButton = routeMenuTarget.current

        if (!triggerButton) {
            return null
        }

        const triggerButtonRec = triggerButton.getBoundingClientRect()

        return (
            <RouteAssignedTaskForm
                task={task}
                onCancel={closeRouteForm}
                onAfterRoute={handleAfterRoute}
                style={{ width: triggerButtonRec.width }}
                className={styles["task-info__route-form"]}
            />
        )
    }

    return (
        <div className={cn(styles["task-info"], className)}>
            <div className={styles["task-info__route"]}>
                <Button ref={routeMenuTarget} variant="light" disabled={routeMenuOpened} onClick={openRouteForm} block>
                    {t(`${tNamespace}route-task`)}
                </Button>
                <Overlay
                    target={routeMenuTarget.current}
                    show={routeMenuOpened}
                    placement="bottom"
                    onHide={closeRouteForm}
                    rootClose
                >
                    <Popover id="route-task-popover" className={styles["task-info__route-popover"]}>
                        {getRouteAssignedTaskForm()}
                    </Popover>
                </Overlay>
            </div>
            <div className={styles["task-info__section"]}>
                {(task.Client.FirstName !== "" || task.Client.LastName !== "") && (
                    <InfoField name={t(`${tNamespace}client`)}>
                        <UserCard firstName={task.Client.FirstName} lastName={task.Client.LastName} />
                    </InfoField>
                )}
                {getTaskShortId(task)}
                <InfoField name={t(`${tNamespace}call`)}>{task.Id}</InfoField>
                <InfoField name={t(`${tNamespace}channel`)}>
                    <div className={cn(styles["task-info__card"], styles[taskInfoCardClass])}>
                        <ChannelIcon className={styles["task-info__card-icon"]} type={channelType} />
                        <div className={styles["task-info__card-name"]}>{channelTitle}</div>
                    </div>
                </InfoField>
                {agentId && agentType && (
                    <InfoField name={t(`${tNamespace}agent`)}>
                        <div className={cn(styles["task-info__card"], styles["task-info__agent"])}>
                            <AgentIcon className={styles["task-info__card-icon"]} type={agentType} />
                            <div className={styles["task-info__card-name"]}>{agentId}</div>
                        </div>
                    </InfoField>
                )}
                <InfoField name={t(`${tNamespace}topic`)}>
                    {dialog.topic_title || t(`${tNamespace}topic-unknown`)}
                </InfoField>
                <div className={styles["task-info__group"]}>
                    {dialog.score !== undefined ? (
                        <InfoField name={t(`${tNamespace}score`)}>
                            <Score value={dialog.score} />
                        </InfoField>
                    ) : null}
                    {dialog.stat.was_useful !== undefined ? (
                        <InfoField name={t(`${tNamespace}problem-solved`)}>
                            {dialog.stat.was_useful ? t(`${tNamespace}yes`) : t(`${tNamespace}no`)}
                        </InfoField>
                    ) : null}
                </div>
            </div>
            {operator && (
                <div className={styles["task-info__section"]}>
                    <InfoField name={t(`${tNamespace}operator`)}>
                        <OperatorCard operator={operator} />
                    </InfoField>
                </div>
            )}
            <div className={styles["task-info__section"]}>{getTaskStatistic(task)}</div>
        </div>
    )
}

const getAgentData = (task: TaskCommon, agents?: Agent[]): [string | undefined, string | undefined] => {
    if (agents && !isTask(task)) {
        const agent = agents.find(a => a.Id === task.AgentId)
        return agent ? [agent.Id, agent.Type] : [task.AgentId, AgentType.Unknown]
    } else {
        return [undefined, undefined]
    }
}

export default TaskInfo
