import { ChannelTypeString, WebChatChannel } from "../../models/channel"
import { WebChatChannelDeclaration } from "../../models/parameterDeclaration"
import { WebChatChannelValues } from "../../models/webChatValues"
import {
    getAppearanceSettings,
    getAuthAdvancedSettings,
    getAuthSettingsValues,
    getBlockSettings,
    getDialogEvaluationSettings,
    getFileSettings,
    getHistorySettings,
    getInitialMessageSettings,
    getInterfaceSettings,
    getMessageSettings,
    getOperatorSettings,
    getPromoSettings,
    getPushMessageSettings,
    getSyncSettings,
    getSystemSettings,
    isMobileVersionEnabled
} from "./webChatSetting"
import { getValuesFromCommonChannel } from "./channelValues"
import { ChannelValues } from "../../models/channelValues"
import { tl } from "../common/language"
import { getDefaultParams } from "../common/parametersTranslator"
import { extractWebChatConfigParams } from "./channelTranslator"

const WEBCHAT_NAMESPACE_VALUE = "%%namespace%%"
const WEBCHAT_NAMESPACE_COBROWSING_VALUE = "%%namespaceCobrowsing%%"
const WEBCHAT_MEDIATOR_VALUE = "%%mediatorId%%"

export const getValuesFromWebChatChannel = (
    language: string,
    channel: WebChatChannel,
    declarations: WebChatChannelDeclaration
): WebChatChannelValues => {
    const values: ChannelValues = getValuesFromCommonChannel(language, channel, declarations.Params)

    const mainConfig = extractWebChatConfigParams(channel.MainConfig, declarations.MainConfig)
    const clientConfig = extractWebChatConfigParams(channel.ClientConfig, declarations.ClientConfig)
    return {
        ...values,
        AuthSettings: getAuthSettingsValues(mainConfig),
        AppearanceSettings: getAppearanceSettings(clientConfig),
        MobileVersionEnabled: isMobileVersionEnabled(clientConfig),
        SyncSettings: getSyncSettings(mainConfig),
        HistorySettings: getHistorySettings(mainConfig, clientConfig),
        PushMessageSettings: getPushMessageSettings(mainConfig, clientConfig),
        OperatorSettings: getOperatorSettings(clientConfig),
        InterfaceSettings: getInterfaceSettings(clientConfig),
        PromoSettings: getPromoSettings(clientConfig),
        InitialMessageSettings: getInitialMessageSettings(clientConfig),
        AuthAdvanced: getAuthAdvancedSettings(mainConfig, clientConfig),
        FileSettings: getFileSettings(mainConfig, clientConfig, values.Params),
        DialogEvaluationSettings: getDialogEvaluationSettings(clientConfig),
        BlockSettings: getBlockSettings(channel.Params, clientConfig),
        SystemSettings: getSystemSettings(channel.Params, mainConfig, clientConfig),
        MessageSettings: getMessageSettings(channel.Params, clientConfig)
    }
}

export const getWebChatDefaultValues = (
    Id: string,
    language: string,
    declarations: WebChatChannelDeclaration
): WebChatChannelValues => {
    let defaultChannel: WebChatChannel = {
        Id,
        Title: "",
        ProjectId: "",
        Type: ChannelTypeString.WebChat,
        Token: "",
        WebHook: "",
        PreprocessorWebHook: "",
        Params: getDefaultParams(declarations.Params),
        MainConfig: getDefaultParams(declarations.MainConfig),
        ClientConfig: getDefaultParams(declarations.ClientConfig)
    }

    defaultChannel.ClientConfig.language = language

    defaultChannel.Params["operatorName"] = tl("channel:default-value.bot-chat-name", language)
    defaultChannel.Params["operatorTimeServiceName"] = tl("channel:default-value.bot-chat-name", language)

    defaultChannel.ClientConfig["blockMessage"] = tl("channel:default-value.block-message", language)
    defaultChannel.ClientConfig["locationMessage"] = tl("channel:default-value.location-message", language)
    defaultChannel.ClientConfig["promoWelcomeMessage"] = tl("channel:default-value.promo-welcome-message", language)
    defaultChannel.ClientConfig["ratingAnswerText"] = tl("channel:default-value.rating-answer-text", language)
    defaultChannel.ClientConfig["phoneNumberMask"] = "+0(000)000-0000"

    defaultChannel = replaceWebChatDefaultValues(defaultChannel)
    return getValuesFromWebChatChannel(language, defaultChannel, declarations)
}

export const replaceWebChatDefaultValues = (channel: WebChatChannel): WebChatChannel => {
    Object.keys(channel.MainConfig).forEach((paramName: string) => {
        switch (channel.MainConfig[paramName]) {
            case WEBCHAT_MEDIATOR_VALUE:
            case WEBCHAT_NAMESPACE_VALUE:
                channel.MainConfig[paramName] = channel.Id
                break
            case WEBCHAT_NAMESPACE_COBROWSING_VALUE:
                channel.MainConfig[paramName] = channel.Id + "_cobrowsing"
                break
        }
    })

    return channel
}

export const fileSizes: string[] = [
    "b",
    "bit",
    "bits",
    "B",
    "Byte",
    "Bytes",
    "bytes",
    "Kb",
    "k",
    "K",
    "kb",
    "KB",
    "KiB",
    "Ki",
    "ki",
    "Mb",
    "m",
    "M",
    "mb",
    "MB",
    "MiB",
    "Mi",
    "mi",
    "Gb",
    "g",
    "G",
    "gb",
    "GB",
    "GiB",
    "Gi",
    "gi",
    "Tb",
    "t",
    "T",
    "tb",
    "TB",
    "TiB",
    "Ti",
    "ti",
    "Pb",
    "p",
    "P",
    "pb",
    "PB",
    "PiB",
    "Pi",
    "pi",
    "Eb",
    "e",
    "E",
    "eb",
    "EB",
    "EiB",
    "Ei",
    "ei"
]
