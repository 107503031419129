import { ProjectSettings } from "../../../models/projectSettings"
import { SystemSettingsValues } from "../../../models/projectSettingsValues"
import { v4 as uuidv4 } from "uuid"

export const defaultSystemProjectSettings = {
    Security: {
        InformationMaskingPatterns: {
            Values: []
        }
    },
    System: {
        UpdateClientDataFromChannel: true,
        ChannelOnDialogPanel: false,
        OperatorQuestionaryId: "",
        DialogSwitchNotificationWebhook: "",
        SwitchToBotWindowTitle: "",
        SwitchToBotWindowMessage: "",
        DisableMarkupModal: true,
        KnowledgeBaseConvertTemplate: {
            FileName: "",
            FileUrl: ""
        },
        EnableMessageSendBehaviour: false,
        AdditionalResendConfigDependsOnQueue: false,
        CustomMenus: {
            Values: []
        }
    }
}

export const getSystemSettingsInitialValues = (settings: ProjectSettings): SystemSettingsValues => ({
    Security: { ...defaultSystemProjectSettings.Security, ...settings.Security },
    System: {
        ...defaultSystemProjectSettings.System,
        ...settings.System,
        CustomMenus: {
            Values: settings.System
                ? settings.System.CustomMenus?.Values.map(item => {
                      return {
                          Id: item.Id ? item.Id : uuidv4(),
                          Title: item.Title ? item.Title : "",
                          Url: item.Url ? item.Url : "",
                          Icon: item.Icon ? item.Icon : ""
                      }
                  })
                : []
        }
    }
})

export const buildUpdateSystemSettingsRequest = (values: SystemSettingsValues): ProjectSettings => {
    return {
        Security: {
            InformationMaskingPatterns: {
                Values: values.Security.InformationMaskingPatterns.Values.filter(Boolean)
            }
        },
        System: values.System
    }
}
