var prefix = "fal"
var iconName = "paper-plane-top"
var width = 18
var height = 18
var ligatures = []
var unicode = ""
var svgPathData = "M17.32 7.97 1.57 1.22c-.14-.07-.3-.13-.44-.13-.3 0-.6.13-.82.36-.33.37-.4.88-.2 1.3L3.38 9 .13 15.25a1.13 1.13 0 0 0 1.45 1.53l15.75-6.75c.41-.17.68-.58.68-1.03a1.17 1.17 0 0 0-.68-1.03ZM1.12 2.25l14.44 6.19H4.35L1.12 2.25Zm0 13.52 3.23-6.2h11.18l-14.41 6.2Z"

exports.definition = {
  prefix: prefix,
  iconName: iconName,
  icon: [
    width,
    height,
    ligatures,
    unicode,
    svgPathData
  ]
}

exports.faPaperPlaneTop = exports.definition
exports.prefix = prefix
exports.iconName = iconName
exports.width = width
exports.height = height
exports.ligatures = ligatures
exports.unicode = unicode
exports.svgPathData = svgPathData