import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFile, faFileExcel, faFilePdf, faFileWord, faFileImage } from "@fortawesome/pro-light-svg-icons"
import { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import mime from "mime"
import truncate from "lodash/truncate"
import cn from "classnames"

import { ReactComponent as XMark } from "../../assets/images/icons/x-mark.svg"
import styles from "./AttachmentItem.module.scss"
import { testId } from "../../utility/tests/testId"
import { getBeautifiedFileSize, getFileExt } from "../../utility/common/files"

export enum ItemType {
    image = "image",
    file = "file",
    video = "video"
}

export enum DocType {
    Doc = "doc",
    Docx = "docx",
    Rtf = "rtf",
    Xls = "xls",
    Xlsx = "xlsx",
    Pdf = "pdf"
}

const selectDocIcon = (fileExtension: string): IconDefinition => {
    switch (fileExtension) {
        case DocType.Doc:
        case DocType.Docx:
        case DocType.Rtf:
            return faFileWord
        case DocType.Xls:
        case DocType.Xlsx:
            return faFileExcel
        case DocType.Pdf:
            return faFilePdf
        default:
            return faFile
    }
}

export const selectIcon = (itemType: ItemType, fileExtension: string): IconDefinition => {
    switch (itemType) {
        case ItemType.file:
            return selectDocIcon(fileExtension)
        case ItemType.image:
            return faFileImage
        case ItemType.video:
            return faFile
    }
}

const selectItemType = (mimeType: string): ItemType => {
    switch (mimeType.split("/")[0]) {
        case "video":
            return ItemType.video
        case "image":
            return ItemType.image
        default:
            return ItemType.file
    }
}

export const getFileInfo = (url: string): { itemType: ItemType; fileExtension: string } => {
    const fileExtension = getFileExt(url)
    const mimeType = mime.getType(fileExtension) ?? ""
    const itemType = selectItemType(mimeType)

    return {
        itemType,
        fileExtension
    }
}

export interface AttachmentItemProps {
    title: string
    url: string
    size: number
    onPressCloseButton?: () => void
    onPressItem?: () => void
    containerClassName?: string
}

const AttachmentItem: React.FC<AttachmentItemProps> = ({
    title,
    url,
    size,
    onPressCloseButton,
    onPressItem,
    containerClassName
}) => {
    const { itemType, fileExtension } = getFileInfo(url)
    const shortTitle = truncate(title, { length: 16, omission: "..." })
    const sizeStr = getBeautifiedFileSize(size)
    const urlWithNameParam = url + `?file_name=${title}`
    const renderInner = () => {
        switch (itemType) {
            case ItemType.file:
            case ItemType.video:
                return (
                    <FontAwesomeIcon
                        className={styles.attachmentItem__icon}
                        color="#1066A8"
                        size="4x"
                        icon={selectIcon(itemType, fileExtension)}
                    />
                )
            case ItemType.image:
                return <img src={url} className={styles.attachmentItem__image} alt={title} />
        }
    }

    return (
        <div className={cn(styles.attachmentItem, containerClassName)} data-testid={testId.attachmentItem}>
            <div className={styles.attachmentItem__container}>
                <div className={styles.attachmentItem__headerContainer}>
                    <FontAwesomeIcon color="#1066A8" icon={selectIcon(itemType, fileExtension)} size="2x" />
                    <div className={styles.attachmentItem__headerTextContainer}>
                        <a
                            className={styles.attachmentItem__headerTextTitle}
                            href={urlWithNameParam}
                            data-testid={testId.attachmentLink}
                        >
                            {shortTitle}
                        </a>
                        {/* <span className={styles.attachmentItem__headerTextSub}>{sizeStr}</span> */}
                    </div>
                </div>
                <div
                    className={styles.attachmentItem__previewContainer}
                    onClick={onPressItem}
                    data-testid={testId.attachmentPreview}
                >
                    {renderInner()}
                </div>
            </div>
            {Boolean(onPressCloseButton) && (
                <button onClick={onPressCloseButton} className={styles.attachmentItem__closeButton}>
                    <XMark className={styles.attachmentItem__closeIcon} title="Закрыть" />
                </button>
            )}
        </div>
    )
}

export default AttachmentItem
