import React from "react"
import { useTranslation } from "react-i18next"
import LoadingButton from "../../LoadingButton/LoadingButton"
import { Dispatch } from "../../../utility/common/storeHelper"
import ArticleSelect from "../../ArticleSelect/ArticleSelect"
import { Form } from "react-bootstrap"
import style from "./InitialButtonSettings.module.scss"
import { ValueType } from "react-select"
import { OptionType } from "../../AsyncSearchableInput/AsyncSearchableInput"

const tNamespace = "channel:form."

export interface InitialButtonSettingsProps {
    index: number
    onHide: () => void
    projectId?: string
    name: string
    dispatch: Dispatch
    onSelect: (option: ValueType<OptionType, false>) => void
    onSave: React.MouseEventHandler<HTMLButtonElement>
}

const InitialButtonSettings: React.FC<InitialButtonSettingsProps> = props => {
    const { index, onHide, projectId, dispatch, onSelect, onSave, name } = props
    const { t } = useTranslation()
    return (
        <Form>
            <div className={style.initialButtonSettings}>
                <ArticleSelect
                    id={`button-article-select${index}`}
                    label={t(`${tNamespace}open-article`)}
                    projectId={projectId}
                    searchBySymbolCode
                    allowSearchWithoutPermissions
                    dispatch={dispatch}
                    className={style.initialButtonSettings__articleSelect}
                    onSelect={onSelect}
                    name={name}
                />
                <div className={style.initialButtonSettings__buttonContainer}>
                    <LoadingButton variant="light" onClick={onHide} className={style.initialButtonSettings__button}>
                        {t(`${tNamespace}initial-button-cancel`)}
                    </LoadingButton>
                    <LoadingButton className={style.initialButtonSettings__button} onClick={onSave}>
                        {t(`${tNamespace}initial-button-save`)}
                    </LoadingButton>
                </div>
            </div>
        </Form>
    )
}

export default InitialButtonSettings
