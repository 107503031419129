import React from "react"
import styles from "./MessageInput.module.scss"
import Skeleton from "../Skeleton/Skeleton"

const MessageInputLoader: React.FC = () => {
    return (
        <div className={styles.messageInput}>
            <div className={styles.messageInput__control} style={{ height: "3rem" }}>
                <Skeleton />
            </div>
            <div className={styles.messageInput__buttonGroup}>
                <div className={styles.messageInput__submit} style={{ height: "1.5rem", width: "60px" }}>
                    <Skeleton />
                </div>
            </div>
        </div>
    )
}

export default MessageInputLoader
