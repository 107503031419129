import { saveNotificationInfo } from "../notifications/thunks"
import { selectCurrentProjectId } from "../projects/selectors"
import { RootState } from "../rootReducer"
import { FileUpload } from "../../models/file"
import { ActionResult, Dispatch } from "../../utility/common/storeHelper"
import * as constants from "./constants"
import usersController from "../../api/controllers/users"
import { UpdateUserRequest } from "../../models/user"
import { UpdateProjectUserRequest } from "../../models/projectUser"
import { UpdateGlobalUserRequest } from "../../models/globalUser"
import { actions } from "./slice"
import { getCurrentUserFailed, getCurrentUserProcess, getCurrentUserSuccess } from "./actions"
import { handleHttpException } from "../handleHttpException"
import { NotificationInternalPayload } from "../../models/notification"
import { ITEMS_PER_PAGE, PaginationRequest } from "../../models/pagination"
import { selectProjectUsersState } from "./selectors"

export const getCurrentUser =
    (redirect: boolean): ActionResult<Promise<void>> =>
    async (dispatch: Dispatch) => {
        dispatch(getCurrentUserProcess())

        try {
            const user = await usersController.getCurrentUser()
            localStorage.setItem("QuestionaryFields", JSON.stringify(user.QuestionaryFields))
            dispatch(getCurrentUserSuccess(user))
        } catch (e) {
            handleHttpException(
                e,
                constants.GET_CURRENT_USER_FAILED_MESSAGE,
                err => getCurrentUserFailed(err),
                dispatch,
                false,
                redirect
            )
        }
    }

const USER_SETTINGS_UPDATED_KEY = "info:users:user-settings-updated"

export const reloadUserAfterSettingsUpdate =
    (projectId?: string): ActionResult<Promise<void>> =>
    async (dispatch: Dispatch, getState: () => RootState) => {
        try {
            const currentProjectId = selectCurrentProjectId(getState())

            if (projectId && projectId !== currentProjectId) {
                return
            }

            dispatch(getCurrentUserProcess())
            saveNotificationInfo(dispatch, {
                Title: {
                    Value: USER_SETTINGS_UPDATED_KEY,
                    NeedLocalization: true
                }
            })
            const user = await usersController.getCurrentUser()
            dispatch(getCurrentUserSuccess(user))
        } catch (e) {
            handleHttpException(
                e,
                constants.GET_CURRENT_USER_FAILED_MESSAGE,
                err => getCurrentUserFailed(err),
                dispatch,
                false,
                false
            )
        }
    }

export const reloadProjectUsers =
    (projectId: string, payload: NotificationInternalPayload): ActionResult<Promise<void>> =>
    async (dispatch: Dispatch, getState: () => RootState) => {
        try {
            saveNotificationInfo(dispatch, payload)

            const currentProjectId = selectCurrentProjectId(getState())
            if (projectId && projectId !== currentProjectId) {
                return
            }

            dispatch(actions.getProjectUsersProcess())

            const state = getState()
            const projectUsers = selectProjectUsersState(state)

            let paginationParams: PaginationRequest = {
                PageFrom: 0,
                PageSize: ITEMS_PER_PAGE,
                Search: ""
            }

            if (projectUsers.data) {
                paginationParams = {
                    PageFrom: projectUsers.data.PageFrom,
                    PageSize: projectUsers.data.PageSize,
                    Search: ""
                }
            }

            const users = await usersController.getPaginatedUsersByProject(projectId, paginationParams)
            dispatch(actions.getProjectUsersSuccess(users))
        } catch (e) {
            handleHttpException(
                e,
                constants.GET_USERS_BY_PROJECT_FAILED_MESSAGE,
                err => actions.getProjectUsersFailed(err),
                dispatch,
                false
            )
        }
    }

export const getUsersByProject =
    (projectId: string, paginationParams: PaginationRequest): ActionResult<Promise<void>> =>
    async (dispatch: Dispatch) => {
        dispatch(actions.getProjectUsersProcess())
        try {
            const response = await usersController.getPaginatedUsersByProject(projectId, paginationParams)
            dispatch(actions.getProjectUsersSuccess(response))
        } catch (e) {
            handleHttpException(
                e,
                constants.GET_USERS_PAGE_FAILED_MESSAGE,
                err => actions.getProjectUsersFailed(err),
                dispatch,
                false
            )
        }
    }

export function updateUser(request: UpdateUserRequest) {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(actions.updateUserProcess())
            const user = await usersController.update(request)
            dispatch(actions.updateUserSuccess(user))
        } catch (e) {
            handleHttpException(e, constants.UPDATE_USER_FAILED_MESSAGE, err => actions.updateUserFailed(err), dispatch)
        }
    }
}

export function updateProjectUser(projectId: string, request: UpdateProjectUserRequest, callback: () => void) {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(actions.updateProjectUserProcess())
            const user = await usersController.updateProjectUser(projectId, request)
            dispatch(actions.updateProjectUserSuccess(user))
            callback()
        } catch (e) {
            handleHttpException(
                e,
                constants.UPDATE_PROJECT_USER_FAILED_MESSAGE,
                err => actions.updateProjectUserFailed(err),
                dispatch
            )
        }
    }
}

export function updateAvatar(avatar?: FileUpload<Blob>) {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(actions.updateAvatarProcess())
            const user = await usersController.updateAvatar(avatar)
            dispatch(actions.updateAvatarSuccess(user))
        } catch (e) {
            handleHttpException(
                e,
                constants.UPDATE_AVATAR_FAILED_MESSAGE,
                err => actions.updateAvatarFailed(err),
                dispatch
            )
        }
    }
}

export const updateGlobalUser = (request: UpdateGlobalUserRequest, callback: () => void) => {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(actions.updateGlobalUserProcess())
            const user = await usersController.updateGlobalUser(request)
            dispatch(actions.updateGlobalUserSuccess(user))
            callback()
        } catch (e) {
            handleHttpException(
                e,
                constants.UPDATE_GLOBAL_USER_FAILED_MESSAGE,
                err => actions.updateGlobalUserFailed(err),
                dispatch
            )
        }
    }
}

export const getAllUsers = () => {
    return async (dispatch: Dispatch) => {
        dispatch(actions.getAllUsersProcess())
        try {
            const users = await usersController.getAll()
            dispatch(actions.getAllUsersSuccess(users))
        } catch (e) {
            handleHttpException(
                e,
                constants.GET_ALL_USERS_FAILED_MESSAGE,
                err => actions.getAllUsersFailed(err),
                dispatch
            )
        }
    }
}

export const getRemoteWorkplaceAuthToken = () => {
    return async (dispatch: Dispatch) => {
        dispatch(actions.getRemoteWorkplaceAuthTokenProcess())
        try {
            const token = await usersController.getRemoteWorkplaceAuthToken()
            dispatch(actions.getRemoteWorkplaceAuthTokenSuccess(token))
        } catch (e) {
            handleHttpException(
                e,
                constants.GET_REMOTE_WORKPLACE_AUTH_TOKEN_FAILED_MESSAGE,
                err => actions.getRemoteWorkplaceAuthTokenFailed(err),
                dispatch
            )
        }
    }
}

export const refreshRemoteWorkplaceAuthToken = () => {
    return async (dispatch: Dispatch) => {
        dispatch(actions.refreshRemoteWorkplaceAuthTokenProcess())
        try {
            const token = await usersController.refreshRemoteWorkplaceAuthToken()
            dispatch(actions.refreshRemoteWorkplaceAuthTokenSuccess(token))
        } catch (e) {
            handleHttpException(
                e,
                constants.REFRESH_REMOTE_WORKPLACE_AUTH_TOKEN_FAILED_MESSAGE,
                err => actions.refreshRemoteWorkplaceAuthTokenFailed(err),
                dispatch
            )
        }
    }
}

export const getClientTypes =
    (projectId: string): ActionResult<Promise<void>> =>
    async (dispatch: Dispatch) => {
        dispatch(actions.getClientTypesProcess())
        try {
            const clientTypes = await usersController.getUserTypes(projectId)
            dispatch(actions.getClientTypesSuccess(clientTypes))
        } catch (e) {
            handleHttpException(
                e,
                constants.GET_CLIENT_TYPES_FAILED_MESSAGE,
                err => actions.getClientTypesFailed(err),
                dispatch,
                false
            )
        }
    }
