import { TFunction } from "i18next"
import { articleContentEditorTranslation } from "../../locales/articleContentEditor"

export const makeArticleContentEditorDictionary = (t: TFunction) => {
    const dictionary = { ...articleContentEditorTranslation }

    for (const [key, value] of Object.entries(dictionary)) {
        dictionary[key] = t(value)
    }

    return dictionary
}
