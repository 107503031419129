import React from "react"
import styles from "./MessageListLoader.module.scss"
import useDelay from "../../utility/common/useDelay"
import MessageBasicLoader from "../Dialogs/DialogMessage/components/MessageBasic/MessageBasicLoader"

export interface MessageListLoaderProps {
    count: number
    disableDelay?: boolean
}

const MessageListLoader: React.FC<MessageListLoaderProps> = props => {
    const { count, disableDelay = false } = props

    const display = useDelay(undefined, disableDelay)

    if (!display) {
        return null
    }

    return (
        <div className={styles.messageListLoader}>
            {new Array<number>(count).fill(0).map((_, i) => (
                <div key={i} className={styles.messageListLoader__item}>
                    <MessageBasicLoader key={i} isOutgoing={i % 2 === 0} />
                </div>
            ))}
        </div>
    )
}

export default MessageListLoader
