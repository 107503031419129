import React from "react"
import styles from "./ProjectStickers.module.scss"
import { useTranslation } from "react-i18next"
import { Sticker } from "../../models/sticker"
import ProjectSettingsLayout from "../ProjectSettingsLayout/ProjectSettingsLayout"
import StickerItem from "./StickerItem/StickerItem"
import { testId } from "../../utility/tests/testId"

const tNamespace = "stickers:"

interface Props {
    projectId: string
    title: string
    stickers: Sticker[]
    onAddClick: () => void
    onSettingsClick: (sticker: Sticker) => void
}

const ProjectStickers: React.FC<Props> = props => {
    const { title, stickers, onAddClick, onSettingsClick } = props
    const { t } = useTranslation()

    return (
        <ProjectSettingsLayout.Container
            onButtonClick={onAddClick}
            title={title}
            buttonText={t(`${tNamespace}add-sticker`)}
            addButton
            testId={testId.addSticker}
        >
            <div className={styles.projectStickers}>
                {stickers.map(sticker => (
                    <StickerItem key={sticker.Id} sticker={sticker} onClick={onSettingsClick} />
                ))}
            </div>
        </ProjectSettingsLayout.Container>
    )
}

export default ProjectStickers
