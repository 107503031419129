import React, { createRef, RefObject, useCallback, useMemo, useRef } from "react"
import { Formik } from "formik"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { createChannel, updateChannel } from "../../store/channels/thunks"
import { ChannelFormProps } from "../ChannelForm/ChannelForm"
import { Dispatch } from "../../utility/common/storeHelper"
import { TabPaneElement } from "../ValidatableFormTabs/ValidatableFormTabs"
import { getValidationSchema } from "../../utility/channels/channelValidation"
import { buildWhatsappChannelRequest } from "../../utility/channels/channelRequest"
import { getDefaultWhatsappValues, getValuesFromWhatsappChannel } from "../../utility/channels/channelValues"
import { selectCurrentProject } from "../../store/projects/selectors"
import { generateChannelId } from "../../utility/channels/channel"
import OnSubmitValidationError from "../OnSubmitValidationError/OnSubmitValidationError"
import Tab from "react-bootstrap/Tab"
import { WhatsAppChannelValues } from "../../models/channelValues"
import FormikWhatsAppChannelFormGeneral from "./WhatsAppChannelGeneral"
import FormikWhatsAppChannelFormAppearance from "./WhatsAppChannelAppearance"
import FormikWhatsAppChannelFormAdvanced from "./WhatsAppChannelAdvanced"

const WhatsappChannelForm: React.FC<ChannelFormProps> = props => {
    const { t } = useTranslation()
    const { channel, channelType, tabEntries, declarations, submitCallback, validateTabs } = props

    const dispatch = useDispatch<Dispatch>()
    const project = useSelector(selectCurrentProject)

    const refs = useRef<RefObject<TabPaneElement>[]>(tabEntries.map(() => createRef()))

    const validateTelegramTabs = useCallback(() => {
        validateTabs && validateTabs(refs.current)
    }, [refs, validateTabs])

    const channelId = useMemo(() => (channel ? channel.Id : generateChannelId()), [channel])
    const validationSchema = useMemo(() => getValidationSchema(channelType), [channelType])

    const initialValues = useMemo(
        () =>
            channel
                ? getValuesFromWhatsappChannel(channel, declarations)
                : getDefaultWhatsappValues(channelId, declarations),
        [channel, channelId, declarations]
    )

    if (!project) {
        return null
    }

    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values: WhatsAppChannelValues) => {
                const request = buildWhatsappChannelRequest(project.id, values)
                if (channel) {
                    dispatch(updateChannel(request, declarations, submitCallback))
                } else {
                    dispatch(createChannel(request, declarations, submitCallback))
                }
            }}
        >
            {formikProps => (
                <>
                    <OnSubmitValidationError formikProps={formikProps} onCallback={validateTelegramTabs} />
                    <Tab.Pane eventKey={tabEntries[0].key} ref={refs.current[0]}>
                        <FormikWhatsAppChannelFormGeneral {...props} {...formikProps} t={t} />
                    </Tab.Pane>
                    <Tab.Pane eventKey={tabEntries[1].key} ref={refs.current[1]}>
                        <FormikWhatsAppChannelFormAppearance {...props} {...formikProps} t={t} />
                    </Tab.Pane>
                    <Tab.Pane eventKey={tabEntries[2].key} ref={refs.current[2]}>
                        <FormikWhatsAppChannelFormAdvanced {...props} {...formikProps} t={t} />
                    </Tab.Pane>
                </>
            )}
        </Formik>
    )
}

export default WhatsappChannelForm
