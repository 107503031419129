import React from "react"
import ReactDOM from "react-dom"
import { Provider, ReactReduxContext } from "react-redux"
import ResizeObserver from "resize-observer-polyfill"
import NetworkService from "./api/networkService"
import "./utility/common/setupYupCustomValidations"

import "./index.scss"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import store, { history } from "./store/store"
import "./i18n"
import { isMobileOnly } from "react-device-detect"
import MobileLockScreen from "./components/MobileLockScreen/MobileLockScreen"
import { setupListeners } from "@reduxjs/toolkit/query"
import initGlobalPerformanceMeasuring from "./performance"

// Use ResizeObserver polyfill
if (!window.ResizeObserver) {
    window.ResizeObserver = ResizeObserver
}

// initialize axios interceptors for auth
NetworkService.setupInterceptors(store)
setupListeners(store.dispatch)
initGlobalPerformanceMeasuring()

ReactDOM.render(
    isMobileOnly ? (
        <MobileLockScreen />
    ) : (
        <Provider store={store}>
            <App history={history} context={ReactReduxContext} />
        </Provider>
    ),
    document.getElementById("root")
)

serviceWorker.register()
