import React from "react"
import "./TaskInfoLoader.scss"
import useDelay from "../../utility/common/useDelay"
import InfoFieldLoader from "../InfoField/InfoFieldLoader"
import { duplicateComponent } from "../../utility/common/duplicateComponent"

interface Props {
    count: number
}

const TaskInfoLoader: React.FC<Props> = props => {
    const { count } = props

    const display = useDelay()

    if (!display) {
        return null
    }

    return (
        <div className="task-info-loader">
            <div className="task-info-loader__content">
                {duplicateComponent(count)(() => (
                    <div className="task-info-loader__item">
                        <InfoFieldLoader />
                    </div>
                ))}
            </div>
        </div>
    )
}

export default TaskInfoLoader
