import React from "react"
import styles from "./MenuItemRest.module.scss"
import { faGripHorizontal } from "@fortawesome/pro-light-svg-icons/faGripHorizontal"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ClassProps } from "../../utility/common/props"
import cn from "classnames"
import { MenuItem } from "../../models/menu"
import MenuItemRow from "../MenuItemRow/MenuItemRow"
import MenuIcon from "../MenuIcon/MenuIcon"
import { useTranslation } from "react-i18next"
import SidebarContextMenu from "../SIdebarContextMenu/SidebarContextMenu"
import { testId } from "../../utility/tests/testId"

const tNamespace = "project:"

export interface MenuItemRestProps extends ClassProps {
    items: MenuItem[]
    onClick: (menuId: string) => void
    getSelectedMenu: (menuId: string) => boolean
    onToggleSidebar?: () => void
}

const MenuItemRest: React.FC<MenuItemRestProps> = props => {
    const { className, items, onClick, getSelectedMenu } = props
    const { t } = useTranslation()
    return (
        <SidebarContextMenu
            placement="top-end"
            items={
                <>
                    {items.map(menuItem => (
                        <MenuItemRow
                            key={menuItem.id}
                            icon={<MenuIcon menuId={menuItem.id} />}
                            title={t(tNamespace + menuItem.title)}
                            selected={getSelectedMenu(menuItem.id)}
                            onClick={() => onClick(menuItem.id)}
                            testId={menuItem.id}
                        />
                    ))}
                </>
            }
            containerClassName={cn("menu-item-rest__container", className)}
        >
            <button className={styles.menuItemRest} data-test-id={testId.additionalMenu}>
                <FontAwesomeIcon icon={faGripHorizontal} className={styles.menuItemRest__icon} />
            </button>
        </SidebarContextMenu>
    )
}

export default MenuItemRest
