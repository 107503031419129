import * as Yup from "yup"
import { EDialogTransferType } from "./DialogTransferModalForm"

const tNamespace = "dialogs:error."

export const validationSchema = Yup.object().shape({
    selectedOperatorId: Yup.string().when(["transferType"], {
        is: transferType => transferType === EDialogTransferType.Operator,
        then: Yup.string().requiredExcludeEmpty(`${tNamespace}operator-must-be-selected`)
    }),
    queueIdOnOperatorRefused: Yup.string().when(["transferType"], {
        is: transferType => transferType === EDialogTransferType.Operator,
        then: Yup.string().requiredExcludeEmpty(`${tNamespace}queue-must-be-selected`)
    }),
    selectedQueueId: Yup.string().when(["transferType"], {
        is: transferType => transferType === EDialogTransferType.Queue,
        then: Yup.string().requiredExcludeEmpty(`${tNamespace}queue-must-be-selected`)
    })
})
