import { ChannelTypeString, SimpleChannel } from "../channel"
import { SlotContext, SlotDomainValueDto, SlotType } from "../slot"
import isEmpty from "lodash/isEmpty"

export interface DialogPerson {
    IdInChannel: string
    OmniUserId: string
    Firstname: string
    Middlename?: string
    Lastname: string
    Picture?: string
    Id?: string //@TODO Temporarly because backend tell
}

/**
 * A client who have a dialog with an operator
 */
export interface DialogClient {
    /**
     * Client's ID in the system across all channels
     **/
    OmniUserId: string
    /** Client's ID within a channel **/
    Firstname: string
    Lastname: string
    Middlename?: string
    IdInChannel?: string
    Picture?: string
}

export interface DialogProject {
    Id: string
    Title?: string
    Picture?: string
}

export interface DialogQueue {
    Id: string
    Title?: string
}

export interface IGetOperatorClientBadgesResponse {
    OmniUserId: string
    Firstname?: string
    Lastname?: string
    Middlename?: string
    Picture?: string
    ProjectId: string
}

export interface ISearchClientsByCriterionResponse {
    OmniUserId: string
    Firstname?: string
    Lastname?: string
    Middlename?: string
    Picture?: string
    ProjectId: string
}

export interface ISearchOperatorsByCriterionResponse {
    OperatorId: string
    Firstname: string
    Lastname: string
    Middlename: string
    Picture?: string
    Login: string
    RoleId: string
}

export interface ISearchOperatorsByCriterionRequest {
    query: string
    roles: string[]
    statuses: string[]
    queues: string[]
    projectId?: string
    includeCurrentUser: boolean
}

export interface IGetOperatorsListResponse {
    OmniUserId: string
    Firstname?: string
    Lastname?: string
    Middlename?: string
    Picture?: string
}

export interface IGetOperatorsListRequest {
    projectId: string
    operators: string[]
}

export interface DialogTopic {
    Id: string
    Title: string
    Nodes: DialogTopic[]
}

export interface IGetDialogTopicsRequest {
    ProjectId: string
}

export type IGetDialogTopicsResponse = DialogTopic

export interface IGetOperatorClientResponse {
    Id: string
    Firstname: string
    Lastname: string
    Middlename: string
    Picture?: string
    ProjectId: string
    ActiveDialogId?: string
    SlotContext: SlotContext
}

export interface IGetOperatorChannelsResponse {
    channel_id: string
    channel_title: string
    channel_type: ChannelTypeString
}

export interface IGetOperatorClientRequest {
    omniUserId: string
    projectId: string
}

export interface IGetOperatorChannelsRequest {
    omniUserId: string
    projectId: string
}

export interface DialogBadge {
    Id: string
    Client: DialogPerson
    Channel?: SimpleChannel
    Project: DialogProject
    Queue?: DialogQueue
    Preview: string
    UnreadMessages: number
    IsReplied?: boolean
    IsHoldEnabled: boolean
}

export interface DialogsSurveySlot {
    Id: string
    Slot: {
        SlotId: string
        Title: string
        Type: SlotType
        Domain: SlotDomainValueDto[]
    }
}

type DialogSurveysEmptyResponse = {}

export type DialogsSurveysResponse = {
    DialogSurvey: {
        Id: string
        Title: string
        Fields: DialogsSurveySlot[]
    }
    ClientSurvey: {
        Id: string
        Title: string
        Fields: DialogsSurveySlot[]
    }
}

export enum DialogSendMessageActionsIds {
    SendMessageAndHold = "send-message-and-hold"
}

export interface DialogSendMessageAction {
    Id: DialogSendMessageActionsIds
    Title: string
}

// Пустой ответ получается в случае, когда у нас не установлены рефы ClientSurveyId и DialogSurveyId
export type DialogsSurveys = DialogsSurveysResponse | DialogSurveysEmptyResponse

export const isDialogsSurveysResponse = (data: DialogsSurveys): data is DialogsSurveysResponse => {
    return !isEmpty(data)
}

export interface DialogAgent {
    // agent_id
    // For instance aiassist2, routingagent, initialmsgagent etc
    Id: string
    // cust_agent_id
    // For instance aiassist2_my_cool_agent, initialmsgagent1, initialmsgagent2
    Name: string
}

export interface DialogBadgeUpdate {
    Id: string
    Preview?: string
    UnreadMessages?: number
}

export interface ApiDialogStatistic {
    Dispatched: number
}

export type DialogTopicArray = { Id: string; Title: string }[]

export interface IUpdateDialogTopicsRequest {
    DialogId: string
    Topics: DialogTopicArray[]
}

export interface Dialog {
    Id: string
    Client: DialogClient
    Operator: DialogPerson
    Channel: SimpleChannel
    Project: DialogProject
    Queue?: DialogQueue
    Agent: DialogAgent
    Stat: ApiDialogStatistic
    SlotContext: SlotContext
    Topics?: DialogTopicArray[]
}

export interface IOperatorDialogTransferToQueueRequest {
    DialogId: string
    QueueId: string
    Message: string
}

export interface IOperatorDialogTransferToOperatorRequest {
    DialogId: string
    OperatorId: string
    QueueId: string
    Message: string
}

export interface IGetDialogTransferAvailableQueues {
    Categories: Array<{
        Id: string
        Name: string
        Queues: Array<{
            Id: string
            Name: string
        }>
    }>
}

export interface IGetDialogTransferAvailableQueuesByOperatorRequest {
    projectId: string
    operatorId: string
}

export interface ISearchOperatorsByCriterionNativeResponse {
    login: string
    firstname: string
    lastname: string
    middlename: string
    picture: string
    MaxDialogs: number
    isDeleted: boolean
    position: {
        ID: string
        Title: string
        CustomerId: string
    }
}
