import { Agent } from "../../models/agent"
import { initialWebhookOption } from "../../components/WebChatChannelForm/WebChatAdvancedSettings/WebChatInitialMessageSettings"

export const getJsAgentsId = (agents: Agent[]) => {
    const initial: initialWebhookOption[] = []
    return agents.reduce((accumulator, agent) => {
        if (agent.Type === "jsagent") {
            return [...accumulator, { value: agent.Id, label: agent.Id, useJsAgent: true }]
        }
        return accumulator
    }, initial)
}
