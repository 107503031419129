import React, { useCallback, useMemo, useState } from "react"
import { SlotDto } from "../../../models/slot"

import Can from "../../Can/Can"
import { Admin, ModifyUsers } from "../../../permissions"
import { useTranslation } from "react-i18next"
import { useModal } from "../../../utility/common/useModal"
import AlertDialog from "../../AlertDialog/AlertDialog"
import { formTranslation } from "../../../locales/form"
import SettingsContextMenuItem from "../../SettingsContextMenuItem/SettingsContextMenuItem"
import { faPen, faTrashAlt } from "@fortawesome/pro-light-svg-icons"
import { CellProps, Column } from "react-table"
import SettingsContextMenu from "../../SettingsContextMenu/SettingsContextMenu"
import SettingsButton from "../../SettingsButton/SettingsButton"
import SettingsTableWithPagination from "../../SettingsTable/SettingsTableWithPagination/SettingsTableWithPagination"
import { ITEMS_PER_PAGE } from "../../../models/pagination"

const tNamespace = "slot:"

interface Props {
    slots: SlotDto[]
    onSettingsClick: (slot: SlotDto) => void
    onDelete: (slotId: string) => void
    onPageChange: (index: number, searchValue?: string) => void
    recordsTotal: number
    pageIndex: number
    error?: JSX.Element
}

const SlotSettingsTable: React.FC<Props> = props => {
    const { t } = useTranslation()
    const { onPageChange, slots, onSettingsClick, onDelete, recordsTotal, pageIndex, error } = props

    const [slotToDelete, setSlotToDelete] = useState<string | null>(null)
    const { modalOpen, openModal, closeModal, onExited } = useModal(() => (
        <AlertDialog
            show={modalOpen}
            title={t(`${tNamespace}delete-slot-confirmation.title`)}
            message={t(`${tNamespace}delete-slot-confirmation.message`)}
            submitBtnText={t(formTranslation.delete)}
            onClose={closeModal}
            onSubmit={() => {
                slotToDelete && onDelete(slotToDelete)
            }}
            variant="danger"
            onExited={onExited}
        />
    ))

    const getItems = useCallback(
        (slot: SlotDto) => {
            return (
                <>
                    <Can permission={ModifyUsers}>
                        <SettingsContextMenuItem
                            id="slot-edit"
                            icon={faPen}
                            text={t(formTranslation.edit)}
                            onClick={() => onSettingsClick(slot)}
                        />
                    </Can>
                    <Can permission={Admin}>
                        <SettingsContextMenuItem
                            id="slot-delete"
                            icon={faTrashAlt}
                            text={t(formTranslation.delete)}
                            useSeparator={true}
                            danger={true}
                            onClick={() => {
                                setSlotToDelete(slot.Id)
                                openModal()
                            }}
                        />
                    </Can>
                </>
            )
        },
        [t, onSettingsClick, openModal]
    )

    const columns: Array<Column<SlotDto>> = useMemo(
        () => [
            {
                Header: t(formTranslation.title).toString(),
                id: "Title",
                accessor: original => original.Title
            },
            {
                Header: t(formTranslation.type).toString(),
                id: "Type",
                accessor: original => t(`slot:slot-data-types.${original.Type.toLowerCase()}`)
            },
            {
                id: "Settings",
                disableSortBy: true,
                width: "50px",
                Cell: (props: CellProps<SlotDto>) => (
                    <SettingsContextMenu items={getItems(props.row.original)}>
                        <SettingsButton />
                    </SettingsContextMenu>
                )
            }
        ],
        [t, getItems]
    )

    if (error) {
        return error
    }

    return (
        <SettingsTableWithPagination
            onPageIndexChange={onPageChange}
            columns={columns}
            data={slots}
            onRowClick={onSettingsClick}
            recordsTotal={recordsTotal}
            hideSearch={true}
            pageSize={ITEMS_PER_PAGE}
            pageIndex={pageIndex}
        />
    )
}

export default SlotSettingsTable
