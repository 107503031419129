import {
    TaskAssignedMsg,
    TaskAssignedMsgDto,
    TaskCompletedMsg,
    TaskDetachedMsg,
    TaskFromQueueDeletedMsg,
    TaskQueuedMsg,
    TaskQueuedMsgDto,
    TaskRoutedMsg,
    TaskRoutedMsgDto,
    TasksMovedMsg
} from "../../models/task"
import { Dispatch } from "../../utility/common/storeHelper"
import { actions as queuesActions } from "../../store/queues/slice"
import { actions as operatorsActions } from "../../store/operators/slice"
import { logError, logHubError } from "../../utility/common/logError"
import { Hub } from "../hub"
import { IHub } from "../interfaces/IHub"
import { Store } from "../../store/store"
import {
    OperatorBecameActiveMsg,
    OperatorBecameActiveMsgDto,
    OperatorBecameInactiveMsg,
    OperatorQueuesUpdatedMsg,
    OperatorStatusUpdatedMsg,
    OperatorStatusUpdatedMsgDto
} from "../../models/operator"
import { QueueCategory, QueueCategoryRemoveResponse } from "../../models/queueCategory"
import {
    QueueAddedMsg,
    QueueAwtUpdatedMsg,
    QueueExtendSettingsUpdatedMsg,
    QueueFinishedDialogsDailyUpdatedMsg,
    QueueFinishedDialogsUpdatedMsg,
    QueueOperatorTasksCountUpdatedMsg,
    QueueSlUpdatedMsg,
    QueueUpdatedMsg
} from "../../models/queue"
import { updateSearch } from "../../store/queues/actions"
import debounce from "lodash/debounce"
import { RootState } from "../../store/rootReducer"
import { OperatorDtoConverter } from "../../utility/operators/convert"
import { toSelectedOperatorStatusModel } from "../../utility/operatorStatus/convert"
import { taskConverter } from "../../utility/common/taskConverter"

const TASK_QUEUED = "TaskQueued"
const TASKS_MOVED = "TasksMoved"
const TASK_DETACHED = "TaskDetached"
const TASK_ASSIGNED = "TaskAssigned"
const TASK_ROUTED = "TaskRouted"
const TASK_COMPLETED = "TaskCompleted"
const TASK_FROM_QUEUE_DELETED = "TaskFromQueueDeleted"
const OPERATOR_STATUS_UPDATED = "OperatorStatusUpdated"
const OPERATOR_BECAME_ACTIVE = "OperatorBecameActive"
const OPERATOR_BECAME_INACTIVE = "OperatorBecameInactive"
const OPERATOR_QUEUES_UPDATED = "OperatorQueuesUpdated"
const QUEUE_ADDED = "QueueAdded"
const QUEUE_UPDATED = "QueueUpdated"
const QUEUE_SL_UPDATED = "QueueSlUpdated"
const QUEUE_AWT_UPDATED = "QueueAwtUpdated"
const QUEUE_OPERATOR_TASKS_COUNT_UPDATED = "OperatorTasksCountUpdated"
const QUEUE_FINISHED_DIALOGS_UPDATED = "QueueFinishedDialogsUpdated"
const QUEUE_FINISHED_DIALOGS_DAILY_UPDATED = "QueueFinishedDialogsDailyUpdated"
const QUEUE_EXTEND_SETTINGS_UPDATED = "QueueExtendSettingsUpdated"
const QUEUE_CATEGORY_ADDED = "QueueCategoryAdded"
const QUEUE_CATEGORY_UPDATED = "QueueCategoryUpdated"
const QUEUE_CATEGORY_REMOVED = "QueueCategoryRemoved"

const HUB_NAME = "QueuesHub"

class QueuesHub {
    private _hub: IHub
    private _tenantId?: string

    constructor(store: Store) {
        const reduxState = store.getState()
        let useAllTransportSignalR = false

        if (reduxState.config.config.data?.WebConfig.appSettings.useAllTransportSignalR) {
            useAllTransportSignalR = true
        }

        this._hub = new Hub(`/queues-hub`, useAllTransportSignalR)
        this.registerServerEvents(store.dispatch, store.getState)
    }

    async subscribe(tenantId: string) {
        await this._hub.subscribe("Subscribe", tenantId).catch(e => logError(e))
        this._tenantId = tenantId
    }

    async unsubscribe(tenantId: string) {
        await this._hub.unsubscribe("Unsubscribe", tenantId).catch(e => logError(e))
        this._tenantId = undefined
    }

    private registerServerEvents(dispatch: Dispatch, getState: () => RootState) {
        const debouncedUpdateSearch = debounce(() => dispatch(updateSearch(this._tenantId)), 2000)

        this._hub.registerEvent(TASK_QUEUED, data => {
            try {
                const taskQueuedMsgDto = data as TaskQueuedMsgDto
                const payload: TaskQueuedMsg = {
                    ...taskQueuedMsgDto,
                    Task: taskConverter.toTaskModel(taskQueuedMsgDto.Task)
                }
                dispatch(queuesActions.addTask(payload))
                debouncedUpdateSearch()
            } catch (e) {
                logHubError(HUB_NAME, TASK_QUEUED, e)
            }
        })
        this._hub.registerEvent(TASKS_MOVED, data => {
            try {
                dispatch(queuesActions.moveTasks(data as TasksMovedMsg))
                debouncedUpdateSearch()
            } catch (e) {
                logHubError(HUB_NAME, TASK_QUEUED, e)
            }
        })
        this._hub.registerEvent(TASK_DETACHED, data => {
            try {
                dispatch(operatorsActions.detachTask(data as TaskDetachedMsg))
            } catch (e) {
                logHubError(HUB_NAME, TASK_DETACHED, e)
            }
        })
        this._hub.registerEvent(TASK_ASSIGNED, data => {
            try {
                const taskAssignedMsgDto = data as TaskAssignedMsgDto
                const payload: TaskAssignedMsg = {
                    ...taskAssignedMsgDto,
                    Task: taskConverter.toTaskModel(taskAssignedMsgDto.Task)
                }
                dispatch(
                    operatorsActions.addTask({
                        Task: payload.Task,
                        OperatorId: payload.OperatorId
                    })
                )
                debouncedUpdateSearch()
            } catch (e) {
                logHubError(HUB_NAME, TASK_ASSIGNED, e)
            }
        })
        this._hub.registerEvent(TASK_ROUTED, data => {
            try {
                const taskRoutedMsgDto = data as TaskRoutedMsgDto
                const payload: TaskRoutedMsg = {
                    ...taskRoutedMsgDto,
                    Task: taskConverter.toTaskModel(taskRoutedMsgDto.Task)
                }
                dispatch(
                    queuesActions.removeTask({
                        QueueId: payload.FromQueueId,
                        IsIndividual: payload.FromIndividual,
                        TaskId: payload.Task.Id
                    })
                )
                dispatch(
                    operatorsActions.addTask({
                        Task: payload.Task,
                        OperatorId: payload.OperatorId
                    })
                )
                debouncedUpdateSearch()
            } catch (e) {
                logHubError(HUB_NAME, TASK_ROUTED, e)
            }
        })
        this._hub.registerEvent(TASK_COMPLETED, data => {
            try {
                const msg = data as TaskCompletedMsg
                if (!msg.QueueId) {
                    return
                }
                dispatch(
                    queuesActions.removeTask({
                        QueueId: msg.QueueId,
                        IsIndividual: false,
                        TaskId: msg.TaskId
                    })
                )
                debouncedUpdateSearch()
            } catch (e) {
                logHubError(HUB_NAME, TASK_COMPLETED, e)
            }
        })
        this._hub.registerEvent(TASK_FROM_QUEUE_DELETED, data => {
            try {
                const msg = data as TaskFromQueueDeletedMsg
                dispatch(
                    queuesActions.removeTask({
                        QueueId: msg.FromQueueId,
                        IsIndividual: false,
                        TaskId: msg.TaskId
                    })
                )
                debouncedUpdateSearch()
            } catch (e) {
                logHubError(HUB_NAME, TASK_FROM_QUEUE_DELETED, e)
            }
        })
        this._hub.registerEvent(OPERATOR_STATUS_UPDATED, data => {
            try {
                const dto = data as OperatorStatusUpdatedMsgDto
                const state = getState()
                const payload: OperatorStatusUpdatedMsg = {
                    ...dto,
                    Status: toSelectedOperatorStatusModel(dto.Status, state.userOperator.statuses)
                }

                dispatch(operatorsActions.updateOperatorStatus(payload))
                debouncedUpdateSearch()
            } catch (e) {
                logHubError(HUB_NAME, OPERATOR_STATUS_UPDATED, e)
            }
        })
        this._hub.registerEvent(OPERATOR_BECAME_ACTIVE, data => {
            try {
                const dto = data as OperatorBecameActiveMsgDto
                const state = getState()
                const payload: OperatorBecameActiveMsg = {
                    Operator: OperatorDtoConverter.toOperator(dto.Operator, state.userOperator.statuses)
                }

                dispatch(operatorsActions.addActiveOperator(payload))
                debouncedUpdateSearch()
            } catch (e) {
                logHubError(HUB_NAME, OPERATOR_BECAME_ACTIVE, e)
            }
        })
        this._hub.registerEvent(OPERATOR_BECAME_INACTIVE, data => {
            try {
                dispatch(operatorsActions.removeInactiveOperator(data as OperatorBecameInactiveMsg))
                debouncedUpdateSearch()
            } catch (e) {
                logHubError(HUB_NAME, OPERATOR_BECAME_INACTIVE, e)
            }
        })
        this._hub.registerEvent(OPERATOR_QUEUES_UPDATED, data => {
            try {
                dispatch(queuesActions.updateQueueOperators(data as OperatorQueuesUpdatedMsg))
            } catch (e) {
                logHubError(HUB_NAME, OPERATOR_QUEUES_UPDATED, e)
            }
        })
        this._hub.registerEvent(QUEUE_ADDED, data => {
            try {
                dispatch(queuesActions.addQueue(data as QueueAddedMsg))
                debouncedUpdateSearch()
            } catch (e) {
                logHubError(HUB_NAME, QUEUE_ADDED, e)
            }
        })
        this._hub.registerEvent(QUEUE_UPDATED, data => {
            try {
                dispatch(queuesActions.updateQueue(data as QueueUpdatedMsg))
            } catch (e) {
                logHubError(HUB_NAME, QUEUE_UPDATED, e)
            }
        })
        this._hub.registerEvent(QUEUE_SL_UPDATED, data => {
            try {
                dispatch(queuesActions.updateQueueSl(data as QueueSlUpdatedMsg))
            } catch (e) {
                logHubError(HUB_NAME, QUEUE_SL_UPDATED, e)
            }
        })
        this._hub.registerEvent(QUEUE_AWT_UPDATED, data => {
            try {
                dispatch(queuesActions.updateQueueAwt(data as QueueAwtUpdatedMsg))
            } catch (e) {
                logHubError(HUB_NAME, QUEUE_AWT_UPDATED, e)
            }
        })
        this._hub.registerEvent(QUEUE_OPERATOR_TASKS_COUNT_UPDATED, data => {
            try {
                dispatch(queuesActions.updateOperatorTasksCount(data as QueueOperatorTasksCountUpdatedMsg))
            } catch (e) {
                logHubError(HUB_NAME, QUEUE_OPERATOR_TASKS_COUNT_UPDATED, e)
            }
        })
        this._hub.registerEvent(QUEUE_FINISHED_DIALOGS_UPDATED, data => {
            try {
                dispatch(queuesActions.updateQueueFinishedDialogs(data as QueueFinishedDialogsUpdatedMsg))
            } catch (e) {
                logHubError(HUB_NAME, QUEUE_FINISHED_DIALOGS_UPDATED, e)
            }
        })
        this._hub.registerEvent(QUEUE_FINISHED_DIALOGS_DAILY_UPDATED, data => {
            try {
                dispatch(queuesActions.updateQueueFinishedDialogsDaily(data as QueueFinishedDialogsDailyUpdatedMsg))
            } catch (e) {
                logHubError(HUB_NAME, QUEUE_FINISHED_DIALOGS_DAILY_UPDATED, e)
            }
        })
        this._hub.registerEvent(QUEUE_EXTEND_SETTINGS_UPDATED, data => {
            try {
                dispatch(queuesActions.updateQueueExtendedSettings((data as QueueExtendSettingsUpdatedMsg).Settings))
            } catch (e) {
                logHubError(HUB_NAME, QUEUE_EXTEND_SETTINGS_UPDATED, e)
            }
        })
        this._hub.registerEvent(QUEUE_CATEGORY_ADDED, data => {
            try {
                dispatch(queuesActions.createQueueCategorySuccess(data as QueueCategory))
            } catch (e) {
                logHubError(HUB_NAME, QUEUE_CATEGORY_ADDED, e)
            }
        })
        this._hub.registerEvent(QUEUE_CATEGORY_UPDATED, data => {
            try {
                dispatch(queuesActions.updateQueueCategorySuccess(data as QueueCategory))
            } catch (e) {
                logHubError(HUB_NAME, QUEUE_CATEGORY_UPDATED, e)
            }
        })
        this._hub.registerEvent(QUEUE_CATEGORY_REMOVED, data => {
            try {
                dispatch(queuesActions.deleteQueueCategorySuccess((data as QueueCategoryRemoveResponse).CategoryId))
            } catch (e) {
                logHubError(HUB_NAME, QUEUE_CATEGORY_REMOVED, e)
            }
        })
    }
}

export default QueuesHub
