import React, { useMemo } from "react"
import FormAccordion from "../../FormAccordion/FormAccordion"
import { nameof2 } from "../../../utility/common/nameof"
import { AuthAdvancedValues, AuthType, WebChatChannelValues } from "../../../models/webChatValues"
import { useTranslation } from "react-i18next"
import { useFormikContext } from "formik"
import DebouncedValidatableInput from "../../ValidatableInput/DebouncedValidatableInput"
import DebouncedCheckBox from "../../CheckBoxValidatableInput/DebouncedCheckBox"
import { formTranslation } from "../../../locales/form"
import InfoIcon from "../../InfoIcon/InfoIcon"

const tNamespace = "channel:form."
const tTooltipNamespace = "channel:tooltip."

const WebChatAuthorizationSettings = () => {
    const { t } = useTranslation()
    const { values } = useFormikContext<WebChatChannelValues>()

    const authType = useMemo(() => values.AuthSettings.type, [values.AuthSettings])
    const nonRequireAuthFieldsAuth = authType !== AuthType.RequireAuthFields
    let isDisabledRegexps = false

    try {
        isDisabledRegexps = !!JSON.parse(values.AuthAdvanced.userFormFields).length
    } catch (e) {}

    return (
        <FormAccordion title={t(`${tNamespace}authorization`)} disabled={nonRequireAuthFieldsAuth}>
            <DebouncedValidatableInput
                id="formPhoneNumberMask"
                type="text"
                name={nameof2<WebChatChannelValues, AuthAdvancedValues>("AuthAdvanced", "phoneNumberMask")}
                label={t(`${tNamespace}phone-number-mask`)}
                disabled={isDisabledRegexps}
                placeholder={t(formTranslation.enterText)}
            />
            <DebouncedValidatableInput
                id="formAllowedSymbolsRegexp"
                type="text"
                name={nameof2<WebChatChannelValues, AuthAdvancedValues>("AuthAdvanced", "allowedSymbolsRegexp")}
                label={t(`${tNamespace}form-fields-allowed-symbols`)}
                placeholder={t(formTranslation.enterRegex)}
                disabled={isDisabledRegexps}
            />
            <DebouncedCheckBox
                id="formClearNonValidUserData"
                name={nameof2<WebChatChannelValues, AuthAdvancedValues>("AuthAdvanced", "clearNonValidUserData")}
                label={t(`${tNamespace}not-remove-inappropriate-characters-from-form-fields`)}
            />
            <DebouncedCheckBox
                id="formUsePersonalDataAgreement"
                name={nameof2<WebChatChannelValues, AuthAdvancedValues>("AuthAdvanced", "usePersonalDataAgreement")}
                label={t(`${tNamespace}use-personal-data-agreement`)}
            />
            <div className="extra-settings">
                <DebouncedValidatableInput
                    id="formPersonalDataText"
                    type="string"
                    as="textarea"
                    name={nameof2<WebChatChannelValues, AuthAdvancedValues>("AuthAdvanced", "personalDataText")}
                    label={t(`${tNamespace}personal-data-text`)}
                    className="channel-form__message"
                    icon={<InfoIcon id="iconPersonalDataText" content={t(`${tTooltipNamespace}personal-data-text`)} />}
                    disabled={!values.AuthAdvanced.usePersonalDataAgreement}
                    placeholder={t(formTranslation.enterText)}
                />
            </div>
            <DebouncedValidatableInput
                id="formAuthButtonText"
                type="string"
                name={nameof2<WebChatChannelValues, AuthAdvancedValues>("AuthAdvanced", "authButtonText")}
                label={t(`${tNamespace}auth-button-text`)}
                placeholder={t(formTranslation.enterText)}
            />
            <DebouncedValidatableInput
                type="string"
                as="textarea"
                id="formUserFormFields"
                name={nameof2<WebChatChannelValues, AuthAdvancedValues>("AuthAdvanced", "userFormFields")}
                label={t(`${tNamespace}user-form-fields`)}
                className="channel-form__message"
                placeholder={t(formTranslation.enterText)}
                icon={<InfoIcon id="iconUserFormFields" content={t(`${tTooltipNamespace}user-form-fields`)} />}
            />
        </FormAccordion>
    )
}

export default WebChatAuthorizationSettings
