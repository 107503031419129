import React, { useState } from "react"
import styles from "./CopyInput.module.scss"
import { Button } from "react-bootstrap"
import { faCopy, faSync, faTrash } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { formTranslation } from "../../locales/form"
import { useTranslation } from "react-i18next"
import copy from "copy-to-clipboard"
import { ClassProps } from "../../utility/common/props"
import cn from "classnames"
import LoadingButton from "../LoadingButton/LoadingButton"
import ActionInput from "../ActionInput/ActionInput"
import VerticalInputGroup from "../FormHelpers/VerticalInputGroup/VerticalInputGroup"

export interface CopyInputProps extends ClassProps {
    id: string
    label?: string
    value?: string
    as?: React.ElementType
    blocked?: boolean
    loading?: boolean
    onUpdate?: () => void
    deleteLoading?: boolean
    onDelete?: () => void
    testId?: string
}

const CopyInput: React.FC<CopyInputProps> = props => {
    const { id, label, value = "", className, as, blocked, loading, onUpdate, onDelete, deleteLoading, testId } = props
    const { t } = useTranslation()
    const [tooltipLabel, setTooltipLabel] = useState<string>()

    const handleClick = () => {
        copy(value)
        setTooltipLabel(t(formTranslation.copied))
    }

    const actions = (
        <>
            <Button
                as="div"
                className={cn(styles.copyInput__control, blocked && "blocked")}
                variant="light"
                onClick={handleClick}
            >
                <FontAwesomeIcon icon={faCopy} />
            </Button>
            {onUpdate && (
                <LoadingButton
                    as="div"
                    className={cn(styles.copyInput__control)}
                    onClick={onUpdate}
                    variant="light"
                    loading={loading}
                >
                    <FontAwesomeIcon icon={faSync} />
                </LoadingButton>
            )}
            {onDelete && (
                <LoadingButton
                    as="div"
                    className={cn(styles.copyInput__control)}
                    onClick={onDelete}
                    variant="light"
                    loading={deleteLoading}
                >
                    <FontAwesomeIcon icon={faTrash} />
                </LoadingButton>
            )}
        </>
    )

    return (
        <VerticalInputGroup label={label} controlId={id}>
            <ActionInput
                id={id}
                actions={actions}
                tooltipLabel={tooltipLabel}
                readOnly
                value={value}
                as={as}
                className={className}
                testId={testId}
            />
        </VerticalInputGroup>
    )
}

export default CopyInput
