import React, { useCallback, useMemo, useRef } from "react"
import { Formik } from "formik"
import * as Yup from "yup"
import { ClassifierFormValues, ClassifierLanguage } from "../../../models/classifier"
import { createClassifier, updateClassifier } from "../../../store/classifiers/thunks"
import {
    buildCreateClassifierRequest,
    buildUpdateClassifierRequest
} from "../../../utility/classifier/classifierRequest"
import { TabPaneElement, ValidateTabsCallback } from "../../ValidatableFormTabs/ValidatableFormTabs"
import OnSubmitValidationError from "../../OnSubmitValidationError/OnSubmitValidationError"
import Tab from "react-bootstrap/Tab"
import FormikClassifierFormGeneral from "./FormikClassifierFormGeneral"
import FormikClassifierFormTraining from "./FormikClassifierFormTraining"
import FormikClassifierFormTrainingHistory from "./FormikClassifierFormTrainingHistory"
import { Props as ClassifierFormProps } from "./ClassifierForm"
import { v4 as uuidV4 } from "uuid"
import { getDefaultValues, getValuesFromClassifier } from "../../../utility/classifier/classifierForm"
import { TabEntry } from "../../../types/tabEntry"
import { useDispatch, useSelector } from "react-redux"
import { Dispatch } from "../../../utility/common/storeHelper"
import { WithT } from "i18next"
import { selectClassifierServers, selectCurrentClassifier } from "../../../store/classifiers/selectors"
import { usePopoverShown } from "../../../utility/knowledgeBase/usePopoverShown"
import { PopoverContext } from "../../PopoverContext/PopoverContext"
import { LocaleId } from "../../../locales"
import { ClassifierModelDto } from "../../../models/classifierEvent"

const tErrorNamespace = "classifiers:errors."

export interface Props extends ClassifierFormProps, WithT {
    validateTabs?: ValidateTabsCallback
    tabEntries: TabEntry[]
    classifierModels: ClassifierModelDto[]
}

const FormikClassifierForm: React.FC<Props> = props => {
    const { t, validateTabs, tabEntries, project, classifier, classifierType, submitCallback } = props
    const dispatch = useDispatch<Dispatch>()
    const classifierServers = useSelector(selectClassifierServers)
    const currentClassifier = useSelector(selectCurrentClassifier)

    const trainPopoverControls = usePopoverShown()

    const mainRef = useRef<TabPaneElement>(null)
    const validateFormTabs = useCallback(() => {
        validateTabs?.([mainRef])
    }, [validateTabs])

    const classifierConfigId = useMemo(() => (classifier ? classifier.ClassifierConfigId : uuidV4()), [classifier])

    const initialValues = useMemo(
        () =>
            classifier
                ? getValuesFromClassifier(classifier)
                : getDefaultValues(
                      classifierConfigId,
                      classifierType,
                      project.language === LocaleId.En ? ClassifierLanguage.English : ClassifierLanguage.Russian,
                      classifierServers
                  ),
        [classifier, classifierConfigId, classifierType, project, classifierServers]
    )

    return (
        <PopoverContext.Provider value={trainPopoverControls}>
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={Yup.object().shape({
                    Url: Yup.string().requiredExcludeEmpty(`${tErrorNamespace}server-required`),
                    Name: Yup.string().requiredExcludeEmpty(`${tErrorNamespace}name-required`),
                    Language: Yup.string().requiredExcludeEmpty(`${tErrorNamespace}language-required`)
                })}
                onSubmit={(values: ClassifierFormValues) => {
                    if (classifier) {
                        dispatch(
                            updateClassifier(
                                buildUpdateClassifierRequest(project.id, values, {
                                    ...classifier,
                                    Active: currentClassifier.Active,
                                    Status: currentClassifier.Status,
                                    Version: currentClassifier.Version
                                }),
                                submitCallback
                            )
                        )
                    } else {
                        dispatch(createClassifier(buildCreateClassifierRequest(project.id, values), submitCallback))
                    }
                }}
            >
                {formikProps => (
                    <>
                        <OnSubmitValidationError formikProps={formikProps} onCallback={validateFormTabs} />
                        <Tab.Pane eventKey={tabEntries[0].key} ref={mainRef} key={tabEntries[0].key}>
                            <FormikClassifierFormGeneral {...props} {...formikProps} t={t} />
                        </Tab.Pane>
                        <Tab.Pane
                            eventKey={tabEntries[1].key}
                            key={tabEntries[1].key}
                            onExited={trainPopoverControls.hidePopover}
                        >
                            <FormikClassifierFormTraining {...props} {...formikProps} t={t} />
                        </Tab.Pane>
                        <Tab.Pane eventKey={tabEntries[2].key} key={tabEntries[2].key}>
                            <FormikClassifierFormTrainingHistory {...props} {...formikProps} t={t} />
                        </Tab.Pane>
                    </>
                )}
            </Formik>
        </PopoverContext.Provider>
    )
}

export default FormikClassifierForm
