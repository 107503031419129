import React from "react"
import styles from "./DialogChat.module.scss"
import MessageListLoader from "../../MessageList/MessageListLoader"
import MessageInputLoader from "../../MessageInput/MessageInputLoader"

const DialogChat: React.FC = () => {
    return (
        <div className={styles.dialogChat}>
            <div className={styles.dialogChat__header} />
            <div className={styles.dialogChat__body}>
                <MessageListLoader count={4} disableDelay />
            </div>
            <div className={styles.dialogChat__footer}>
                <MessageInputLoader />
            </div>
        </div>
    )
}

export default DialogChat
