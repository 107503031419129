import React from "react"
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd"
import { InitialButton } from "../WebChatChannelForm/WebChatAdvancedSettings/WebChatInitialMessageSettings"
import { InitialDraggableButton } from "./InitialDraggableButton/InitialDraggableButton"
import { Button } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/pro-light-svg-icons/faPlus"
import { useTranslation } from "react-i18next"
import style from "./InitialButtonsEditor.module.scss"
import { Dispatch } from "../../utility/common/storeHelper"

const tNamespace = "channel:form."

export interface InitialButtonsEditorProps {
    buttons: InitialButton[]
    onUpdateButtons: (updated: InitialButton[]) => void
    onDragEnd: (result: DropResult) => void
    disabled: boolean
    projectId?: string
    dispatch: Dispatch
}

export const InitialButtonsEditor: React.FC<InitialButtonsEditorProps> = props => {
    const { buttons, onUpdateButtons, onDragEnd, disabled } = props
    const { t } = useTranslation()
    return (
        <div>
            <Button
                variant="light"
                className={style.initialButtonsEditorAddButton}
                onClick={() => {
                    onUpdateButtons([
                        ...buttons,
                        { Id: "initialButton" + buttons.length, action_id: "", action_text: "" }
                    ])
                }}
                disabled={disabled}
            >
                <FontAwesomeIcon icon={faPlus} size={"lg"} className={style.initialButtonsEditorAddButton__icon} />
                {t(`${tNamespace}initial-add-button-text`)}
            </Button>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable
                    droppableId={"initial-buttons-editor-drag-drop"}
                    renderClone={(provided, snapshot, rubric) => {
                        return <InitialDraggableButton {...props} provided={provided} index={rubric.source.index} />
                    }}
                >
                    {provided => (
                        <div ref={provided.innerRef} {...provided.droppableProps} className={style.initialButtonsList}>
                            <>
                                {buttons.map((btn, index) => {
                                    return (
                                        <Draggable draggableId={btn.Id} index={index} key={btn.Id}>
                                            {provided => (
                                                <InitialDraggableButton {...props} provided={provided} index={index} />
                                            )}
                                        </Draggable>
                                    )
                                })}
                            </>
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </div>
    )
}
