import React, { useCallback } from "react"
import "./AddMarkdownButtonForm.scss"
import { preventSubmitOnEnter } from "../../../../../../utility/common/preventSubmitOnEnter"
import { FormikProps } from "formik/dist/types"
import { WithT } from "i18next"
import { Button, Form } from "react-bootstrap"
import { nameof } from "../../../../../../utility/common/nameof"
import { formTranslation } from "../../../../../../locales/form"
import ValidatableInput from "../../../../../ValidatableInput/ValidatableInput"
import { KeyboardButtonFormValues } from "./AddMarkdownButtonForm"
import RadioButtonValidatableInput from "../../../../../RadioButtonValidatableInput/RadioButtonValidatableInput"
import DebouncedValidatableInput from "../../../../../ValidatableInput/DebouncedValidatableInput"
import { ButtonType } from "../../../../../../utility/articleContentEditor/buttonsParser/buttonAttributes/buttonType"
import ArticleSelect from "../../../../../ArticleSelect/ArticleSelect"
import { Dispatch } from "../../../../../../utility/common/storeHelper"
import { ValueType } from "react-select"
import { OptionType } from "../../../../../AsyncSearchableInput/AsyncSearchableInput"

const tNamespace = "knowledgeBase:article-buttons."

interface Props {
    onCancel: () => void
    projectId: string
    dispatch: Dispatch
}

const FormikAddMarkdownButtonForm: React.FC<Props & FormikProps<KeyboardButtonFormValues> & WithT> = props => {
    const { t, handleSubmit, values, onCancel, setFieldValue, projectId, dispatch } = props

    const onSelectAction = useCallback(
        (option: ValueType<OptionType, false>) => {
            setFieldValue(nameof<KeyboardButtonFormValues>("Action"), option ? option.meta || option.value : "", true)
        },
        [setFieldValue]
    )

    return (
        <Form className="add-markdown-button-form" onSubmit={handleSubmit} onKeyPress={preventSubmitOnEnter}>
            <div className="add-markdown-button-form__content">
                <ValidatableInput
                    id="formTitle"
                    type="text"
                    name={nameof<KeyboardButtonFormValues>("Title")}
                    label={t(`${tNamespace}button-title`)}
                    placeholder={t(formTranslation.enterText)}
                />
                {t(`${tNamespace}action`)}
                <RadioButtonValidatableInput
                    value={ButtonType.Action}
                    id="formOpenArticle"
                    name={nameof<KeyboardButtonFormValues>("Type")}
                    label={t(`${tNamespace}open-article`)}
                />
                <div className="edit-markdown-button-form__subsection">
                    <ArticleSelect
                        id="formSelectArticle"
                        name={nameof<KeyboardButtonFormValues>("Action")}
                        projectId={projectId}
                        disabled={values.Type !== ButtonType.Action}
                        dispatch={dispatch}
                        onSelect={onSelectAction}
                        searchBySymbolCode
                        allowSearchWithoutPermissions
                    />
                </div>
                <RadioButtonValidatableInput
                    value={ButtonType.Url}
                    id="formOpenUrl"
                    name={nameof<KeyboardButtonFormValues>("Type")}
                    label={t(`${tNamespace}open-url`)}
                />
                <div className="edit-markdown-button-form__subsection">
                    <DebouncedValidatableInput
                        id="formURL"
                        type="text"
                        name={nameof<KeyboardButtonFormValues>("Url")}
                        disabled={values.Type !== ButtonType.Url}
                    />
                </div>
            </div>
            <div className="add-markdown-button-form__footer">
                <Button className="add-markdown-button-form__cancel" variant="light" onClick={onCancel}>
                    {t(formTranslation.cancel)}
                </Button>
                <Button className="add-markdown-button-form__submit" variant="primary" type="submit">
                    {t(formTranslation.save)}
                </Button>
            </div>
        </Form>
    )
}

export default FormikAddMarkdownButtonForm
