import SearchFormInput from "../SearchInput/SearchFormInput"
import { useCallback, useEffect, useRef, useState } from "react"
import styles from "./DialogTransferModal.module.scss"
import { useHotkeys } from "react-hotkeys-hook"
import { DialogTransferOperatorSearchDropdown } from "./components/DialogTransferOperatorSearchDropdown"
import { TSelectedSearchOperator } from "./components/DialogTransferOperatorListItem"
import { detectDocumentAnyClick } from "../../helpers/events"
import { useField } from "formik"
import { FormikCustomErrorMsg } from "../../helpers/formik"
import cn from "classnames"

export interface IDialogTransferOperatorSearchProps {
    name: string
    disabled?: boolean
    className?: string
    disabledInlineErrors?: boolean
    includeCurrentUser: boolean
}

export const DialogTransferOperatorSearch: React.FC<IDialogTransferOperatorSearchProps> = props => {
    const { name, disabled, className, disabledInlineErrors, includeCurrentUser } = props
    const [, , { setValue, setError }] = useField(name)

    const [dropdownShown, setDropdownShown] = useState<boolean>(false)
    const [queryCriterion, setQueryCriterion] = useState<string>("")
    const dropdownRef = useRef<HTMLDivElement>(null)
    const searchInputRef = useRef<HTMLDivElement>(null)

    const handleOnFieldFocus = useCallback(() => {
        setDropdownShown(true)
    }, [])

    const handleOnFieldClear = useCallback(() => {
        setDropdownShown(false)
        setQueryCriterion("")
        setValue("")
        setError(undefined)
    }, [setValue, setError])

    const handleOnFieldChange = useCallback((queryCriterion: string) => {
        setQueryCriterion(queryCriterion)
    }, [])

    useHotkeys("esc", () => setDropdownShown(false), {
        enableOnFormTags: true,
        enableOnContentEditable: true
    })

    const handleOnSelectOperator = useCallback(
        (operator: TSelectedSearchOperator) => {
            setValue(operator.OperatorId)
            setQueryCriterion(operator.Fullname)
            setDropdownShown(false)
            setError(undefined)
        },
        [setValue, setError]
    )

    const handleDocumentClick = (e: MouseEvent) => {
        const targetEl = e.target as HTMLDivElement
        const dropdownCls = dropdownRef.current?.className
        const searchInputCls = searchInputRef.current?.className

        const wasClicked = detectDocumentAnyClick({
            targetEl,
            clickableExcludedCls: [dropdownCls, searchInputCls]
        })

        if (wasClicked) {
            return
        }

        setDropdownShown(false)
    }

    useEffect(() => {
        document.addEventListener("mousedown", handleDocumentClick)

        return () => {
            document.removeEventListener("mousedown", handleDocumentClick)
        }
    }, [])

    return (
        <div className={cn(className, styles.dialogTransferOperatorSearch)}>
            <SearchFormInput
                value={queryCriterion}
                disabled={disabled}
                ref={searchInputRef}
                onFocus={handleOnFieldFocus}
                onClear={handleOnFieldClear}
                onChange={handleOnFieldChange}
            />
            {!disabledInlineErrors && (
                <FormikCustomErrorMsg name={name} className={styles.dialogTransferOperatorSearch__error} />
            )}
            {dropdownShown && (
                <DialogTransferOperatorSearchDropdown
                    ref={dropdownRef}
                    queryCriterion={queryCriterion}
                    onSelectOperator={handleOnSelectOperator}
                    includeCurrentUser={includeCurrentUser}
                />
            )}
        </div>
    )
}
