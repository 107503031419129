import { RefObject, useEffect } from "react"

export function useOutsideClick<T extends HTMLElement>(action: () => void, ref: RefObject<T>) {
    const handleClickOutside = (e: MouseEvent) => {
        if (!e.target) {
            return
        }
        if (!ref?.current?.contains(e.target as Node)) {
            action()
        }
    }
    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside)
        return () => document.removeEventListener("mousedown", handleClickOutside)
    })
}
