import React, { ReactNode, useCallback } from "react"
import { Image } from "react-bootstrap"
import cn from "classnames"
import styles from "./UserCard.module.scss"
import { ClassProps } from "../../utility/common/props"
import { testId } from "../../utility/tests/testId"
import TooltipTrigger from "../TooltipTrigger/TooltipTrigger"
import { useTranslation } from "react-i18next"
import { colorByName } from "../../helpers/color"

const tNamespace = "dialogs:info."

export interface UserCardProps extends ClassProps {
    firstName?: string
    lastName?: string
    middleName?: string
    picture?: string
    isShort?: boolean
    onClick?: () => void
    indicator?: ReactNode
    userCardMaxLength?: number
    isClient?: boolean
    isNewWorkplace?: boolean
    inactive?: boolean
}

const tooltipId = "user-card-tooltip"
const tooltipDelay = { show: 450, hide: 0 }

const UserCard: React.FC<UserCardProps> = props => {
    const {
        firstName,
        lastName,
        middleName,
        picture,
        isShort,
        onClick,
        indicator,
        userCardMaxLength = 15,
        isClient,
        isNewWorkplace,
        inactive
    } = props
    const { t } = useTranslation()
    const fullUserName = `${firstName} ${lastName}`

    const getLetter = useCallback(
        (type: "firstName" | "lastName"): string => {
            const typeValue = props[type]

            if (typeValue && typeof typeValue === "string" && typeValue.length > 0) {
                return typeValue[0]
            }

            return ""
        },
        [props]
    )

    const unknownClientFullName = `${t(`${tNamespace}unknown-client-firstname`)} ${t(
        `${tNamespace}unknown-client-lastname`
    )}`

    const operatorFullName =
        firstName || lastName || middleName ? `${lastName ?? ""} ${firstName ?? ""} ${middleName ?? ""}` : ""

    const clientFullName =
        firstName || lastName || middleName
            ? `${lastName ?? ""} ${firstName ?? ""} ${middleName ?? ""}`
            : unknownClientFullName

    const getLetters = useCallback(() => {
        let resultStr = ""

        if (lastName && lastName.length) {
            resultStr += lastName[0]
        }

        if (firstName && firstName.length) {
            resultStr += firstName[0]
        }

        if (resultStr.length < 1 && middleName && middleName.length) {
            resultStr += middleName[0]
        }

        if (resultStr.length || !isClient) {
            return resultStr
        }

        return t(`${tNamespace}unknown-client-firstname`)[0] + t(`${tNamespace}unknown-client-lastname`)[0]
    }, [firstName, lastName, middleName, isClient, t])

    return (
        <div
            className={cn(styles.userCard, props.className, isShort && styles.userCard_short)}
            onClick={onClick}
            data-test-id={testId.userCard}
        >
            <div className={styles.userCard__pictureContainer}>
                {picture ? (
                    <Image className={styles.userCard__picture} src={picture} />
                ) : (
                    <div
                        className={cn(styles.userCard__picture, styles.userCard__picture_default)}
                        style={{ backgroundColor: !inactive ? colorByName(clientFullName) : "#E0E0E0" }}
                    >
                        {getLetters()}
                    </div>
                )}
                {indicator && <div className={styles.userCard__indicator}>{indicator}</div>}
            </div>
            {!isShort && (
                <TooltipTrigger
                    id={tooltipId}
                    placement="top"
                    content={fullUserName}
                    condition={fullUserName ? fullUserName.length > userCardMaxLength : false}
                    delay={tooltipDelay}
                >
                    <div className={styles.userCard__info}>
                        {isNewWorkplace ? (
                            isClient ? (
                                <div className={styles.userCard__clientFullName}>{clientFullName}</div>
                            ) : (
                                <div className={styles.userCard__clientFullName}>{operatorFullName}</div>
                            )
                        ) : (
                            <div className={styles.userCard__introduction}>
                                <div className={styles.userCard__firstName}>{lastName}</div>
                                <div className={styles.userCard__lastName}>{firstName}</div>
                            </div>
                        )}
                        {props.children}
                    </div>
                </TooltipTrigger>
            )}
        </div>
    )
}

export default UserCard
