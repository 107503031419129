import React from "react"
import { useTranslation } from "react-i18next"
import { AsyncDataProps, AsyncErrorProps } from "../../utility/common/asyncHelper"
import AsyncState from "../../core/asyncState"
import { getSystemErrorMessage } from "../../core/error"

interface Props<T> {
    dataState: AsyncState<T>
    processView: JSX.Element
    errorView: (props: AsyncErrorProps) => JSX.Element
    children: (props: AsyncDataProps<T>) => JSX.Element
    nonDataView?: JSX.Element
    validator?: (data?: T) => boolean
}

function Async<T>(props: Props<T>): JSX.Element {
    const { dataState, errorView, processView, children, nonDataView = null, validator } = props
    const { t } = useTranslation()

    if (dataState.inProcess) {
        return processView
    }

    if (dataState.error) {
        return errorView({ message: getSystemErrorMessage(dataState.error, t) })
    }

    if (validator) {
        return <>{dataState.data && validator(dataState.data) ? children({ data: dataState.data }) : nonDataView}</>
    } else {
        return <>{dataState.data ? children({ data: dataState.data }) : nonDataView}</>
    }
}

export default Async
