export interface IUseScrollKookProps {
    scrollableEl?: HTMLElement | null
    behavior?: ScrollBehavior
    top?: number
}

const useScroll = (props: IUseScrollKookProps) => {
    const { scrollableEl, behavior = "smooth", top: topOrig = 0 } = props

    const scrollToTarget = (top?: number) => {
        if (!scrollableEl) {
            return
        }

        scrollableEl.scrollTo({
            behavior,
            top: top ?? topOrig
        })
    }

    return scrollToTarget
}

export default useScroll
