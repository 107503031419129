import { PayloadAction, createSlice } from "@reduxjs/toolkit"

export interface IDialogSystemSlotState {
    dialogIds: {
        [id: number | string]: {
            shown: boolean
            meta: unknown
        }
    }
}

export type TPayloadActionShowDialog<T = unknown> = {
    dialogId: number | string
    dialogMeta?: T
}

export type TPayloadActionHideDialog = {
    dialogId: number | string
}

export type TPayloadActionSetDialogMeta = {
    dialogId: number | string
    dialogMeta: unknown
}

const initialState: IDialogSystemSlotState = {
    dialogIds: {}
}

const dialogSystemSlice = createSlice({
    name: "dialogSystem",
    initialState,
    reducers: {
        showDialog: <T = unknown>(
            state: IDialogSystemSlotState,
            action: PayloadAction<TPayloadActionShowDialog<T>>
        ) => {
            const { dialogId, dialogMeta = null } = action.payload

            state.dialogIds[dialogId] = {
                shown: true,
                meta: dialogMeta
            }
        },
        hideDialog: (state, action: PayloadAction<TPayloadActionHideDialog>) => {
            const dialogId = action.payload.dialogId

            if (!state.dialogIds[dialogId]) {
                return
            }

            state.dialogIds[dialogId] = {
                ...state.dialogIds[dialogId],
                shown: false
            }
        },
        setDialogMeta: (state, action: PayloadAction<TPayloadActionSetDialogMeta>) => {
            const { dialogId, dialogMeta } = action.payload

            if (!state.dialogIds[dialogId]) {
                return
            }

            state.dialogIds[dialogId] = {
                ...state.dialogIds[dialogId],
                meta: dialogMeta
            }
        }
    }
})

export const { showDialog, hideDialog, setDialogMeta } = dialogSystemSlice.actions

export const dialogSystemActions = {
    showDialog,
    hideDialog,
    setDialogMeta
}

export const actions = dialogSystemSlice.actions

export default dialogSystemSlice.reducer
