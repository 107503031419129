import { useTranslation } from "react-i18next"
import { Collapser } from "../../Collapser/Collapser"
import styles from "./AIAssistent.module.scss"
import { ReactComponent as FaMicrochipAi } from "../../../assets/images/faMicrochipAi.svg"
import React, { useCallback, useLayoutEffect } from "react"
import { aiAssistentExpandHelper } from "./helpers"
import { useAppDispatch, useAppSelector } from "../../../store/store"
import { selectAIAssistRequestTypes } from "../../../store/dialogs/selectors"
import { getAIAssistRequestTypes } from "../../../store/dialogs/thunks"
import { AIAssistentGptHint } from "./AIAssistentGptHint"
import { AIAssistentSuggest } from "./AIAssistentSuggest"

const tAiAssistentNamespace = "dialogs:aiassistent"

const AIAssistent: React.FC = React.memo(() => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()

    const aIAssistRequestTypes = useAppSelector(selectAIAssistRequestTypes)

    useLayoutEffect(() => {
        if (!aIAssistRequestTypes.data) {
            dispatch(getAIAssistRequestTypes())
        }
    }, [aIAssistRequestTypes.data, dispatch])

    const handleOnExpand = useCallback((state: boolean) => (aiAssistentExpandHelper.expandedState = state), [])

    const collapserTitle = (
        <span className={styles.aiAssistent__collapserTitle}>
            <FaMicrochipAi /> {t(`${tAiAssistentNamespace}.title`)}
        </span>
    )

    const expandedState = aiAssistentExpandHelper.expandedState

    return (
        <div className={styles.aiAssistent}>
            <Collapser
                title={collapserTitle}
                className={styles.aiAssistent__collapser}
                expanderCls={styles.aiAssistent__expander}
                value={expandedState}
                onExpand={handleOnExpand}
            >
                <div className={styles.aiAssistent__body}>
                    <AIAssistentGptHint />
                    <AIAssistentSuggest />
                </div>
            </Collapser>
        </div>
    )
})

export { AIAssistent }
