import React, { useEffect, useState } from "react"
import { faClock, faPauseCircle } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { beautifyMsToHMSString } from "../../utility/common/formatDate"
import styles from "./DialogTime.module.scss"
import cn from "classnames"

interface DialogTimeProps {
    dispatchedTime: number // time in ms
    isHold?: boolean
}

const calculateCurrentTimeStr = (dispatchedTime: number): string => beautifyMsToHMSString(Date.now() - dispatchedTime)

const DialogTime = ({ dispatchedTime, isHold }: DialogTimeProps) => {
    const [currentTimeStr, setCurrentTimeStr] = useState(calculateCurrentTimeStr(dispatchedTime))

    useEffect(() => {
        let timer: NodeJS.Timeout | null = null

        timer = setInterval(() => {
            setCurrentTimeStr(calculateCurrentTimeStr(dispatchedTime))
        }, 1000)

        return () => {
            if (timer) {
                clearInterval(timer)
            }
        }
    }, [dispatchedTime])

    return (
        <div className={styles.dialogTime}>
            <FontAwesomeIcon
                icon={isHold ? faPauseCircle : faClock}
                size="2x"
                className={cn(styles.dialogTime__icon, isHold && styles.dialogTime__icon_disabled)}
                color="#1066A8"
            />
            <span className={cn(styles.dialogTime__timeSpan, isHold && styles.dialogTime__timeSpan_disabled)}>
                {currentTimeStr}
            </span>
        </div>
    )
}

export default DialogTime
