import axios from "axios"
import QueuesHub from "./hubs/queues"
import store from "../store/store"
import UserHub from "./hubs/users"
import ChannelsHub from "./hubs/channels"
import KnowledgeBaseHub from "./hubs/knowledgeBase"
import ClassifiersHub from "./hubs/classifiers"
import SharedKnowledgeBaseHub from "./hubs/sharedKnowledgeBase"
import ProjectSettingsHub from "./hubs/projectSettings"
import DialogsHubV2 from "./hubs/dialogs-hub-v2"
import UsersHubV2 from "./hubs/users-hub-v2"
import DialogsHubAddons from "./hubs/dialogs-hub-v2-addons"
import WorkplaceKnowledgeBaseHub from "./hubs/workplaceKnowledgeBase"

export const instanceConfig = {
    baseURL: `/api/v2`,
    timeout: 25000,
    withCredentials: true
}

export const apiInstance = axios.create(instanceConfig)
export const apiInstanceV1 = axios.create({
    ...instanceConfig,
    baseURL: `/api`
})
export const apiInstanceRemoteWorkplace = axios.create({
    ...instanceConfig,
    baseURL: `/remote-workplace-authentication-token`
})
export const queuesHub = new QueuesHub(store)
export const usersHub = new UserHub(store)
export const channelsHub = new ChannelsHub(store)
export const knowledgeBaseHub = new KnowledgeBaseHub(store)
export const classifiersHub = new ClassifiersHub(store)
export const sharedKnowledgeBaseHub = new SharedKnowledgeBaseHub(store)
export const workplaceKnowledgeBaseHub = new WorkplaceKnowledgeBaseHub(store)
export const projectSettingsHub = new ProjectSettingsHub(store)
export const dialogsHubV2 = new DialogsHubV2(store)
export const usersHubV2 = new UsersHubV2(store)
export const dialogsHubAddons = new DialogsHubAddons(store)
