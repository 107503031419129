import React from "react"
import InfoCard from "../../../../InfoCard/InfoCard"
import { useTranslation } from "react-i18next"
import styles from "./WebchatCard.module.scss"
import { Formik } from "formik"
import { Dispatch } from "redux"
import WebchatCardForm from "./WebchatCardForm"
import { parseJson } from "../../../../../helpers/json"

export const defaultDebugWebchat: DebugWebchat = {
    articleId: "",
    showInput: "true",
    channelId: ""
}

export interface DebugWebchat {
    articleId: string
    showInput: string
    channelId: string
}

export interface WebchatCardProps {
    debugWebchat: DebugWebchat
    projectId: string
    dispatch: Dispatch
    onSelect: () => void
    onChange: (debugWebchat: DebugWebchat) => void
    onDelete: () => void
    channels: string[]
    articleSymbolCode: string
}

const tNamespace = "knowledgeBase:webchat-card."

const WebchatCard: React.FC<WebchatCardProps> = props => {
    const { debugWebchat, projectId, dispatch, onSelect, onChange, onDelete, channels, articleSymbolCode } = props
    const { t } = useTranslation()
    debugWebchat.showInput = parseJson(debugWebchat.showInput)
    return (
        <InfoCard
            className={styles.webchatCard}
            headerClassName={styles.webchatCard__header}
            bodyClassName={styles.webchatCard__content}
            content={{
                header: (
                    <div className={styles.webchatCard__headerContent}>
                        <span className={styles.webchatCard__headerText}>{t(`${tNamespace}header`)}</span>
                    </div>
                ),
                text: (
                    <Formik<DebugWebchat>
                        initialValues={{ ...debugWebchat }}
                        onSubmit={values => {
                            onSelect()
                            onChange(values)
                        }}
                    >
                        {formikProps => (
                            <WebchatCardForm
                                {...formikProps}
                                projectId={projectId}
                                dispatch={dispatch}
                                t={t}
                                onSelect={onSelect}
                                onDelete={onDelete}
                                channels={channels}
                                articleSymbolCode={articleSymbolCode}
                            />
                        )}
                    </Formik>
                )
            }}
        />
    )
}

export default WebchatCard
