import React from "react"
import "./InfoCardListLoader.scss"
import Skeleton from "../Skeleton/Skeleton"
import InfoCardList from "./InfoCardList"
import { ClassProps } from "../../utility/common/props"
import { duplicateComponent } from "../../utility/common/duplicateComponent"
import cn from "classnames"

interface Props extends ClassProps {
    count: number
    cardClassName: string
}

const InfoCardListLoader: React.FC<Props> = props => {
    const { count, className, cardClassName } = props

    return (
        <InfoCardList className={className}>
            {duplicateComponent(count)(() => (
                <div className={cn("info-card-list-loader__item", cardClassName)}>
                    <Skeleton />
                </div>
            ))}
        </InfoCardList>
    )
}

export default InfoCardListLoader
