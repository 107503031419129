import React, { useCallback } from "react"
import { preventSubmitOnEnter } from "../../../../../../utility/common/preventSubmitOnEnter"
import { FormikProps } from "formik/dist/types"
import { WithT } from "i18next"
import { Button, Form } from "react-bootstrap"
import { nameof } from "../../../../../../utility/common/nameof"
import { formTranslation } from "../../../../../../locales/form"
import RadioButtonValidatableInput from "../../../../../RadioButtonValidatableInput/RadioButtonValidatableInput"
import DebouncedCheckBox from "../../../../../CheckBoxValidatableInput/DebouncedCheckBox"
import DebouncedValidatableInput from "../../../../../ValidatableInput/DebouncedValidatableInput"
import { EditMarkdownButtonFormValues } from "../../../../../../models/markdownButton"
import ImageUploadInput from "../../../../../ImageUploadInput/ImageUploadInput"
import { ButtonColor } from "../../../../../../utility/articleContentEditor/buttonsParser/buttonAttributes/buttonColor"
import { ButtonType } from "../../../../../../utility/articleContentEditor/buttonsParser/buttonAttributes/buttonType"
import { ButtonImageAlign } from "../../../../../../utility/articleContentEditor/buttonsParser/buttonAttributes/buttonImageAlign"
import ArticleSelect from "../../../../../ArticleSelect/ArticleSelect"
import { Dispatch } from "../../../../../../utility/common/storeHelper"
import { ValueType } from "react-select"
import { GetOptionsFunction, OptionType } from "../../../../../AsyncSearchableInput/AsyncSearchableInput"
import { logError } from "../../../../../../utility/common/logError"

const tNamespace = "knowledgeBase:article-buttons."

interface Props {
    onCancel: () => void
    projectId: string
    dispatch: Dispatch
}

const FormikEditMarkdownButtonForm: React.FC<Props & FormikProps<EditMarkdownButtonFormValues> & WithT> = props => {
    const { t, values, handleSubmit, onCancel, projectId, dispatch, setFieldValue } = props

    const onSelectArticle = useCallback(
        (option: ValueType<OptionType, false>) => {
            const isExternal = !!option?.meta
            setFieldValue(nameof<EditMarkdownButtonFormValues>("isExternal"), isExternal, true)
            setFieldValue(
                nameof<EditMarkdownButtonFormValues>("article"),
                option ? option.meta || option.value : "",
                true
            )
        },
        [setFieldValue]
    )

    const getLabel = useCallback(
        async (newValue: string, getOptions: GetOptionsFunction) => {
            const isExtId = values.isExternal
            const defaultOptions = await getOptions(
                isExtId ? "" : newValue,
                undefined,
                isExtId ? newValue : undefined
            ).catch(error => {
                logError("Failed to get options", error)
                return []
            })

            const fieldValue = defaultOptions.find(o => (isExtId ? o.meta === newValue : o.value === newValue))
            return fieldValue?.label ?? ""
        },
        [values.isExternal]
    )

    return (
        <Form className="edit-markdown-button-form" onSubmit={handleSubmit} onKeyPress={preventSubmitOnEnter}>
            <div className="edit-markdown-button-form__content">
                <div className="edit-markdown-button-form__title">{t(`${tNamespace}button-settings`)}</div>
                <div className="edit-markdown-button-form__subtitle">{t(`${tNamespace}action`)}</div>
                <RadioButtonValidatableInput
                    value={ButtonType.Action}
                    id="formOpenArticle"
                    name={nameof<EditMarkdownButtonFormValues>("type")}
                    label={t(`${tNamespace}open-article`)}
                />
                <div className="edit-markdown-button-form__subsection">
                    <ArticleSelect
                        id="formSelectArticle"
                        name={nameof<EditMarkdownButtonFormValues>("article")}
                        projectId={projectId}
                        disabled={values.type !== ButtonType.Action}
                        dispatch={dispatch}
                        onSelect={onSelectArticle}
                        getLabel={getLabel}
                        searchBySymbolCode
                        allowSearchWithoutPermissions
                    />
                </div>
                <RadioButtonValidatableInput
                    value={ButtonType.Url}
                    id="formOpenUrl"
                    name={nameof<EditMarkdownButtonFormValues>("type")}
                    label={t(`${tNamespace}open-url`)}
                />
                <div className="edit-markdown-button-form__subsection">
                    <DebouncedValidatableInput
                        id="formURL"
                        type="text"
                        name={nameof<EditMarkdownButtonFormValues>("url")}
                        disabled={values.type !== ButtonType.Url}
                    />
                </div>
                <div className="edit-markdown-button-form__subtitle">{t(`${tNamespace}appearance`)}</div>
                <DebouncedCheckBox
                    id="formVisible"
                    className="edit-markdown-button-form__checkbox"
                    name={nameof<EditMarkdownButtonFormValues>("invisible")}
                    label={t(`${tNamespace}invisible`)}
                />
                {t(`${tNamespace}button-color`)}
                <div className="edit-markdown-button-form__inline">
                    <RadioButtonValidatableInput
                        value={ButtonColor.Primary}
                        id="formPrimary"
                        name={nameof<EditMarkdownButtonFormValues>("color")}
                        label={t(`${tNamespace}primary`)}
                    />
                    <RadioButtonValidatableInput
                        value={ButtonColor.Secondary}
                        id="formSecondary"
                        name={nameof<EditMarkdownButtonFormValues>("color")}
                        label={t(`${tNamespace}secondary`)}
                    />
                    <RadioButtonValidatableInput
                        value={ButtonColor.Negative}
                        id="formNegative"
                        name={nameof<EditMarkdownButtonFormValues>("color")}
                        label={t(`${tNamespace}negative`)}
                    />
                    <RadioButtonValidatableInput
                        value={ButtonColor.Positive}
                        id="formPositive"
                        name={nameof<EditMarkdownButtonFormValues>("color")}
                        label={t(`${tNamespace}positive`)}
                    />
                </div>
                <DebouncedCheckBox
                    id="formImageEnabled"
                    className="edit-markdown-button-form__checkbox"
                    name={nameof<EditMarkdownButtonFormValues>("image_enabled")}
                    label={t(`${tNamespace}image`)}
                />
                <div className="edit-markdown-button-form__subsection">
                    <ImageUploadInput
                        id="formImage"
                        name={nameof<EditMarkdownButtonFormValues>("image")}
                        disabled={!values.image_enabled}
                    />
                </div>
                {t(`${tNamespace}image-placement`)}
                <div className="edit-markdown-button-form__inline">
                    <RadioButtonValidatableInput
                        value={ButtonImageAlign.Center}
                        id="formButtonImageAlignCenter"
                        name={nameof<EditMarkdownButtonFormValues>("image_align")}
                        label={t(`${tNamespace}below-the-text`)}
                    />
                    <RadioButtonValidatableInput
                        value={ButtonImageAlign.Left}
                        id="formButtonImageAlignLeft"
                        name={nameof<EditMarkdownButtonFormValues>("image_align")}
                        label={t(`${tNamespace}left`)}
                    />
                </div>
            </div>
            <div className="edit-markdown-button-form__footer">
                <Button className="edit-markdown-button-form__cancel" variant="light" onClick={onCancel}>
                    {t(formTranslation.cancel)}
                </Button>
                <Button className="edit-markdown-button-form__submit" variant="primary" type="submit">
                    {t(formTranslation.save)}
                </Button>
            </div>
        </Form>
    )
}

export default FormikEditMarkdownButtonForm
