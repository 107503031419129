import React, { ReactNode, useCallback } from "react"
import { ClassProps } from "../../utility/common/props"
import AsyncSearchableInput, { GetLabelFunction, OptionType } from "../AsyncSearchableInput/AsyncSearchableInput"
import { ValueType } from "react-select"
import { useTranslation } from "react-i18next"
import { Dispatch } from "../../utility/common/storeHelper"
import { AsyncProps } from "react-select/src/Async"
import { searchChannel } from "../../utility/knowledgeBase/channelSelect"

export interface ChannelSelectProps extends ClassProps {
    id: string
    name: string
    label?: string
    disabled?: boolean
    projectId?: string
    onSelect?: (option: ValueType<OptionType, false>) => void
    getLabel?: GetLabelFunction
    onChange?: (option: ValueType<OptionType, false>) => void
    dispatch: Dispatch
    useValueForDefaultOptions?: boolean
    defaultOptions?: AsyncProps<OptionType>["defaultOptions"]
    icon?: ReactNode
    filter: string[]
}

const ChannelSelect: React.FC<ChannelSelectProps> = props => {
    const {
        className,
        id,
        name,
        label,
        disabled,
        projectId,
        onSelect,
        getLabel,
        onChange,
        dispatch,
        useValueForDefaultOptions,
        defaultOptions,
        icon,
        children,
        filter
    } = props
    const { t } = useTranslation()

    const getIntentOptions = useCallback(searchChannel(dispatch, filter, projectId), [dispatch, projectId])
    return (
        <AsyncSearchableInput
            id={id}
            className={className}
            label={
                <>
                    {label}
                    {icon}
                </>
            }
            name={name}
            getOptions={getIntentOptions}
            isDisabled={disabled}
            onSelect={onSelect}
            getCustomLabel={getLabel}
            useValueForDefaultOptions={useValueForDefaultOptions}
            defaultOptions={defaultOptions}
            onChange={onChange}
        >
            {children}
        </AsyncSearchableInput>
    )
}

export default ChannelSelect
