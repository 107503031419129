import { SlotDto } from "../../models/slot"
import { OptionType } from "../../components/AsyncSearchableInput/AsyncSearchableInput"
import { SystemSlot } from "../../models/slot"

export const getVisitorFields = (slots: SlotDto[]): OptionType[] => {
    return slots.map(slot => {
        const parsedSlot = parseToVisitorFields(slot)
        return { value: parsedSlot, label: parsedSlot }
    })
}

const parseToVisitorFields = (slot: SlotDto) => {
    const field = slot.ExternalId
    switch (field) {
        case SystemSlot.FirstName:
            return "first_name"
        case SystemSlot.MiddleName:
            return "middle_name"
        case SystemSlot.LastName:
            return "last_name"
        default:
            return field.replace("sys_", "")
    }
}
